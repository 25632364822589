import React, { Component, Fragment } from 'react'
import { Loading } from 'components'
import {
  Helpers, Base, Routes, history, Validation,
} from 'utils'
import Styles from './Styles'
import marvlous from 'images/marvlous.png'

const Footer = ({ onClick, navbarOpen }) => {
  return(
    <footer className={Helpers.mergeCss(Styles.footer, Base.paddingTop5, Base.paddingBottom3)}>
      <div className={Helpers.mergeCss(Base.container)}>
        <div className={Helpers.mergeCss(Base.row)}>
          <div className={Helpers.mergeCss(Base.col, Styles.w2, Styles.marginFooter, Base.w2)}>
            <ul className={Base.ulUnstyled}>
              <span style={{ fontWeight: 'bold' }}>
                Designers
              </span>
              <li>
                <a href="#" className={Base.textReadWhite}>
                  Marvlous Meetups
                  {/* <span>Soon</span> */}
                </a>
              </li>
              <li>
                <a href="#" className={Base.textReadWhite}>
                  Guideline
                </a>
              </li>
              <li>
                <a href="#" className={Base.textReadWhite}>
                  Blog
                  {/* <span>Soon</span> */}
                </a>
              </li>
              <li>
                <a href="#" className={Base.textReadWhite}>
                  Jobs
                  {/* <span>Soon</span> */}
                </a>
              </li>
              <li>
                <a href="#" className={Base.textReadWhite}>
                  Join
                </a>
              </li>
            </ul>
          </div>
          <div className={Helpers.mergeCss(Base.col, Styles.w2, Styles.marginFooter, Base.w2)}>
            <ul className={Base.ulUnstyled}>
              <span style={{ fontWeight: 'bold' }}>
                Company
              </span>
              <li>
                <a href="#" className={Base.textReadWhite}>
                  About
                </a>
              </li>
              <li>
                <a href="#" className={Base.textReadWhite}>
                  Careers
                  {/* <span>Soon</span> */}
                </a>
              </li>
              <li>
                <a href="#" className={Base.textReadWhite}>
                  Support
                </a>
              </li>
              <li>
                <a href="#" className={Base.textReadWhite}>
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="#" className={Base.textReadWhite}>
                  Terms
                </a>
              </li>
            </ul>
          </div>
          <div className={Helpers.mergeCss(Base.col, Styles.w2, Styles.marginFooter, Base.w2)}>
            <ul className={Base.ulUnstyled}>
              <span style={{ fontWeight: 'bold' }}>
                Hire Designer
              </span>
              <li>
                <a href="#" className={Base.textReadWhite}>
                  Post a job
                  <span>Soon</span>
                </a>
              </li>
              <li>
                <a href="#" className={Base.textReadWhite}>
                  Post a project
                  <span>Soon</span>
                </a>
              </li>
              <li>
                <a href="#" className={Base.textReadWhite}>
                  Find best designer
                  <span>Soon</span>
                </a>
              </li>
            </ul>
          </div>
          <div className={Helpers.mergeCss(Base.col, Styles.w3, Styles.marginFooter, Base.w3)}>
            <ul className={Base.ulUnstyled}>
              <span style={{ fontWeight: 'bold' }}>
                Brands
              </span>
              <li>
                <a href="#" className={Base.textReadWhite}>
                  Partnership with us
                  <span>Soon</span>
                </a>
              </li>
              <li>
                <a href="#" className={Base.textReadWhite}>
                  Advertise with us
                  <span>Soon</span>
                </a>
              </li>
            </ul>
          </div>
          <div className={Helpers.mergeCss(Base.col, Styles.w3, Base.w3)}>
            <span style={{ fontWeight: 'bold' }}>
              Marvlous
            </span>
            <span style={{ marginTop: '16px' }} className={Helpers.mergeCss(Base.textReadWhite, Base.dFlex)}>
              Marvlous is #1 place to showcase your masterpiece artwork design and monetize it
            </span>
            <ul className={Base.ulInline}>
              <li style={{ marginRight: '15px' }}><a target="_blank" rel="noopener" className={Base.textReadWhite} href="https://www.linkedin.com/company/marvlous"><i className="fab fa-linkedin" /></a></li>
              <li style={{ marginRight: '15px' }}><a target="_blank" rel="noopener" className={Base.textReadWhite} href="https://www.instagram.com/marvlouscom"><i className="fab fa-instagram" /></a></li>
              <li style={{ marginRight: '15px' }}><a target="_blank" rel="noopener" className={Base.textReadWhite} href="https://www.facebook.com/marvlouscom"><i className="fab fa-facebook" /></a></li>
              <li><a target="_blank" rel="noopener" className={Base.textReadWhite} href="https://www.twitter.com/marvlouscom"><i className="fab fa-twitter" /></a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className={Helpers.mergeCss(Base.container, Styles.borderTop)}>
        <div className={Helpers.mergeCss(Base.row)}>
          <div className={Helpers.mergeCss(Base.col, Base.w12, Base.textCenter)}>
            <span style={{ fontSize: '14px' }} className={Base.textReadWhite}>
              © 2017 - {new Date().getFullYear()} Marvlous
            </span>
          </div>
        </div>
      </div>
    </footer>
    )
  }

  export default Footer