import React, { Fragment } from 'react'
import { Helpers, Base } from 'utils'
import { Link, matchPath } from 'react-router-dom'
import Styles from './Styles'

const CardPreview = ({
  coverData,
  tiny,
  images,
  item,
  gif,
  video,
}) => {
  let content = null
  if (Helpers.isImage(coverData) && !video) {
    const extension = gif ? 'gif' : ''
    content = (
      <div key={coverData} className={Styles.previewWrapper('img', extension, tiny, `${item.attributes.loading_color}`)}>
        <div className={Helpers.mergeCss(Base.dInlineBlock, Base.positionRelative)}>
          {gif
            ? (
              <Fragment>
                <img alt={item.attributes.title} className={Helpers.mergeCss(Styles.imgFluid(tiny), Styles.imgAbsolute)} src={coverData} />
                <img alt={item.attributes.title} className={Styles.imgFluid(tiny)} src={item.attributes.images[0].url} />
              </Fragment>
            )
            : (
              <Fragment>
                <img alt={item.attributes.title} className={Styles.imgFluid(tiny)} src={coverData} />
              </Fragment>
            )
            }
        </div>
        {images.length > 1 ? (<p />) : coverData.includes('gif') && (<span />)}
      </div>
    )
  } else if (video) {
    content = (
      <div key={coverData} className={Styles.previewWrapper('video', 'mp4', tiny, `${item.attributes.loading_color}`)}>
        <div className={Helpers.mergeCss(Base.dInlineBlock, Base.positionRelative)}>
          {
              video
                ? (
                  <video
                    muted
                    loop
                    onMouseOver={event => event.target.play()}
                    onMouseOut={event => event.target.pause()}
                    poster={coverData}
                    className={Styles.imgFluid(tiny)}
                  >
                    <source src={item.attributes.images[0].url} type="video/mp4" />
                    <source src={item.attributes.images[0].url} type="video/ogg" />
                  </video>
                )
                : (
                  <video
                    muted
                    loop
                    onMouseOver={event => event.target.play()}
                    onMouseOut={event => event.target.pause()}
                    className={Styles.imgFluid(tiny)}
                  >
                    <source src={item.attributes.images[0].url} type="video/mp4" />
                    <source src={item.attributes.images[0].url} type="video/ogg" />
                  </video>
                )
            }
        </div>
        {images.length > 1 ? (<p />) : (<span />)}
      </div>
    )
  }
  return content
}

export default CardPreview
