import React, { Fragment, Component } from 'react'

class AdsenseMobile extends Component {
  componentDidMount () {
    process.env.ENV_APP === 'production' && (window.adsbygoogle = window.adsbygoogle || []).push({})
  }

  render () {
    return (
      <Fragment>
        {
          process.env.ENV_APP === 'production' &&
          <div>
            {/* Ads marvlous new mobile */}
            <ins class="adsbygoogle"
            style={{display:'inline-block', width:'660px', height:'70px', marginBottom: '25px'}}
            data-ad-client="ca-pub-8794465840040120"
            data-ad-slot="5801629399"></ins>
          </div>
        }
      </Fragment>
    )
  }
}

export default AdsenseMobile