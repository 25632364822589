import { css } from 'emotion'

const maxLimit = css`
  height: 45px;
  font-weight: bold;
  font-size: 1rem;
  color: rgb(41, 41, 41);
  display: flex;
  align-items: center;
  justify-content: center;
`

export default{
  maxLimit,
}