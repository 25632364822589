import { css } from 'emotion'

function profileWrapper(done) {
  let borderDone
  if (done) {
    borderDone = 'border-bottom: 0px solid #eee;'
  }

  return css`
    height: 410px;
    ${borderDone}
    position: relative;
    padding-top: 60px;
  `
}

const imgProfileWrapper = css`
  width: 100%;
  height: 175px;
  object-fit: cover;
`

const imageProfile = css`
  position: relative;
  width: 100px;
  height: 100px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  border: 4px solid #fff;
`

const profileDescription = css`
  position: relative;
  padding: 15px;
  margin-top: 0px;
  display: flex;
  border-top: 1px solid #eee;
  h5{
    margin-bottom:0px;
  }
  span{
    font-size: 14px;
  }
`

const info = css`
  margin-top: 0px;
`

const bio = css`
  width: 75%;
  overflow: hidden !important;
	display: -webkit-box !important;
	-webkit-box-orient: vertical !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2 !important;
`

function btnAbsolute(subscribe) {
  let subscribeStyle = ''
  if (subscribe) {
    subscribeStyle = `
      background: #f3f3f4 !important;
      color: #212529!important;
      box-shadow: none !important;
    `
  }

  return css`
    position: relative;
    margin: 0px;
    width: auto;
    padding: 0 12px !important;
    height: 30px;
    display: inline-flex;
    margin-top: 10px;
    ${subscribeStyle}
    &:hover{
      color: #fff;
    }
  `
}

const portfolioOverflow = css`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
`

const portfolioWrapper = css`
  border-bottom: 1px solid #eee;
  background: #fff;
  height: 50px;
  display:flex;
  align-items:center;
  padding-left: 15px;
  padding-right: 15px;
  i{
    font-size: 26px;
    color: #bbb !important;
  }
`

const dBlock = css`
  position:relative;
  i{
    font-size: 16px;
  }
  div{
    &:last-of-type{
      span{
        a{
          color: #6e6d7a !important;
        }
        &:nth-child(2){
          a{
            color: #1181eb !important;
          }
        }
      }
    }
  }
`

const rowProfile = css`
  margin-left:0px !important;
  margin-right:0px !important;
  &:first-of-type{
    div{
      &:first-of-type{
        width:100px !important;
        max-width: 100px !important;
        flex: 0 0 100px !important;
        margin-top: -65px !important;
      }
      &:last-of-type{
        padding-left: 0px !important;
        margin-top: 5px !important;
      }
      padding: 0px !important;
    }
  }
  div{
    padding: 0px !important;
  }
`

const profilePadding = css`
  border-top: 1px solid #eee;
  align-items: center!important;
  display: flex;
  height: auto;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  justify-content:center;
`

const profileDescriptionHeight = css`
  height: calc(100% - 21px);
  text-align: center;
`

const profileInfo = css`
`

const tabPortfolio = css`
  border-bottom: 1px solid #eee;
`

function tabCol(active) {
  const activeStyle = active ? `
    font-weight: bold;
    border-bottom: 2px solid #292929;
    color: #292929;
  ` : ''

  return css`
    cursor: pointer;
    ${activeStyle}
  `
}

const rowPortfolio = css`
  margin-left: -5px;
  margin-right: -5px;
  padding-left: 20px;
  padding-right: 35px;
  margin-top: 30px;
  @media only screen and (min-width:0px) and (max-width:767px){
    padding-right: 20px;
  }
`

const noResult = css`
  font-weight: bold;
  display: block;
  padding: 0px 20px;
`

const rangeCover = css`
  position:absolute;
  z-index: 111;
  right: 45px;
  bottom: 20px;
  width: 200px;
`

const avatarWrapper = css`
  height: 175px;
  canvas{
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
    border: dashed 2px #1181eb;
  }
`

const buttonPosition = css`
  position: absolute;
  top: 20px;
  right: 45px;
`

const buttonEdit = css`
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  font-weight: bold;
  height: auto;
  border-radius: 2px;
  color: #fff;
  background: rgba(255,255,255, 0.4) !important;
  font-size: 14px;
  -webkit-letter-spacing: 0.5px;
  -moz-letter-spacing: 0.5px;
  -ms-letter-spacing: 0.5px;
  letter-spacing: 0.5px;
  border: 0px;
  text-align: center;
  cursor: pointer;
  border: 0px !important;
  padding: .25rem 1rem !important;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-right: 0px;
  outline: 0;
  opacity:0;
  &:hover, &:focus{
    outline: 0;
    border:0px;
    box-shadow:0 0 0 0 rgba(51,105,231,0.45);
  }

  @media only screen and (max-width:1023px){
    display: none !important;
    z-index: -111;
  }
`

const buttonSave = css`
  height: 29px !important;
  min-width: 82px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  font-weight: bold;
  height: auto;
  border-radius: 2px;
  color: #fff;
  background: rgba(255,255,255, 0.4) !important;
  font-size: 14px;
  -webkit-letter-spacing: 0.5px;
  -moz-letter-spacing: 0.5px;
  -ms-letter-spacing: 0.5px;
  letter-spacing: 0.5px;
  border: 0px;
  text-align: center;
  cursor: pointer;
  border: 0px !important;
  padding: .25rem 1rem !important;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-right: 5px;
  outline: 0;
  &:hover, &:focus{
    outline: 0;
    border:0px;
    box-shadow:0 0 0 0 rgba(51,105,231,0.4);
  }
`

const dropzone = css`
  width: auto;
  height: auto;
  display:inline-block;
  border-radius: 0px;
  background: transparent !important;
  height: auto;
  position: relative !important;
`

function overlayDropzone(margin) {
  const marginStyle = margin ? 'margin-right: 5px;' : ''

  return css`
    width: 100%;
    height: 100%;
    display:flex;
    align-items:center;
    justify-content:center;
    opacity: 1;
    font-weight: bold;
    color: #fff;
    position:absolute;
    top:0;
    left:0;
    background: rgba(0,0,0, 0.4);
    color: #fff;
    font-size: 24px;
    > div{
      ${marginStyle}
      cursor: pointer;
    }
    span{
      margin-left: 5px;
      display:inline-block;
      cursor: pointer;
      i{
        font-size: 20px;
      }
    }
  `
}

const hintDropzone = css`
  position: absolute;
  top: 5px;
  right: 10px;
`

const buttonCancel = css`
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  font-weight: bold;
  height: auto;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.4) !important;
  color: #fff;
  font-size: 14px;
  -webkit-letter-spacing: 0.5px;
  -moz-letter-spacing: 0.5px;
  -ms-letter-spacing: 0.5px;
  letter-spacing: 0.5px;
  border: 0px;
  text-align: center;
  cursor: pointer;
  border: 0px !important;
  padding: .25rem 1rem !important;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-left: 5px;
  outline: 0;
  &:hover, &:focus{
    outline: 0;
    border:0px;
    box-shadow:0 0 0 0 rgba(33, 37, 41, 0.4);
  }
`

const defaultCover = css`
  width: 100%;
  height: 175px;
  background: #212529;
  display: flex;
`

const coverWrapper = css`
  &:hover{
    button{
      -webkit-transition: opacity 0.3s ease-in;
       -moz-transition: opacity 0.3s ease-in;
         -o-transition: opacity 0.3s ease-in;
                                  opacity: 1;
    }
  }
`

const defaultCoverLoading = css`
  width: 100%;
  height: 200px;
  display: flex;
  background: linear-gradient(to bottom,
    transparent 0%,
    transparent 8.1%, 
    rgba(17,129,235,.001) 15.5%, 
    rgba(17,129,235,.003) 22.5%, 
    rgba(17,129,235,.005) 29%, 
    rgba(17,129,235,.008) 35.3%, 
    rgba(17,129,235,.011) 41.2%, 
    rgba(17,129,235,.014) 47.1%, 
    rgba(17,129,235,.016) 52.9%, 
    rgba(17,129,235,.019) 58.8%, 
    rgba(17,129,235,.022) 64.7%, 
    rgba(17,129,235,.025) 71%, 
    rgba(17,129,235,.027) 77.5%, 
    rgba(17,129,235,.029) 84.5%, 
    rgba(17,129,235,.03) 91.9%, 
    rgba(17,129,235,.03) 100%);
`

const socialMedia = css`
  margin: 0px;
  position: absolute;
  display: inline-flex;
  bottom: 20px;
  right: 45px;
  background: #fff;
  border-radius: 2px;
  padding: 3px 12px;
  margin:0px;
  list-style: none;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  li{
    margin-right: 12px;
    display: inline-block;
    a{
      cursor:pointer;
    }
    &:last-of-type{
      margin-right: 0;
    }
  }
`

const w2 = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    flex: 0 0 25%;
    max-width: 25%;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
`

const subscriptionsWrapperItem = css`
  border-bottom: 1px solid #eee;
  padding: 10px 20px;
  padding-left: 20px !important;
  &:first-of-type{
    padding-top: 0px;
  }
  @media only screen and (min-width:0px) and (max-width:767px){
    margin-bottom: 20px;
  }
`

const imgSubscriptions = css`
  width: 65px;
  height: 65px;
  border-radius: 50%;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
`

const descSubscriptions = css`
  width: calc(100% - 75px);
  a{
    color: #292929;
    &:hover{
      text-decoration: none;
    }
  }
`

const bioSubscriptions = css`
  display:-webkit-box ;
  overflow: hidden ;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  @media only screen and (min-width:0px) and (max-width:767px){
    display: none;
  }
`

function btnMarvlous(subscribe) {
  const subscribeStyle = subscribe ? `background: #f3f3f4 !important;
  color: #212529!important;
  box-shadow: none !important;` : ''
  return css`
    ${subscribeStyle}
    margin: 0px;
    width: auto;
    margin-top: 10px;
    height: 30px;
    font-size: 14px !important;
    border-radius: 3px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
  `
}

function slick(last) {
  const lastStyle = last ? '' : ' padding-right: 25px;'
  return css`
    ${lastStyle}
  `
}

const team = css`
  font-size: 10px;
  padding: 0px 3px;
  background: #f3f3f3;
  border-radius: 2px;
  display: inline-block;
  color: #6c757d;
  margin-left: 5px;
  font-weight: bold;
  height: 16px;
  border: 1px solid #eee;
  text-transform: uppercase;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
`

const mobilenoMargin = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    margin-left: 0px !important;
  }
`

const infoDescription = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    flex-wrap: wrap;
    > a{
      width: 100%
    }
    > div{
      margin-top: 10px;
      width: 100%;
      padding-left: 0px !important;
    }
  }
`

const subscriberCount = css`
  display: block;
  @media only screen and (min-width:0px) and (max-width:990px){
    display: none;
  }
`

const subscriberCountMobile = css`
  display: block;
  @media only screen and (min-width:768px){
    display: none;
  }
`

function donut(tiny) {
  const tinyStyle = tiny ? `
    width: 14px;
    height: 14px;
    border: 0.16rem solid rgba(17, 129, 235, 0.25);
  ` : `
    width: 30px;
    height: 30px;
    border: 0.25rem solid rgba(17, 129, 235, 0.25);
  `

  return css`{
    ${tinyStyle}
    border-radius: 50%;
    border-top-color: #1181eb;
    animation: 1s spin infinite linear;
    }
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }`
}


const paddingDesktop = css`
  @media only screen and (min-width:768px){
    padding-left: 37px;
  }
`

const w4 = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }
`

const w8 = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const desktop = css`
  display: block;
  @media only screen and (min-width:0px) and (max-width:767px){
    display: none;
  }
`

const mobile = css`
  margin-left: .25rem!important;
  color: #6c757d!important;
  @media only screen and (min-width:768px){
    display: none;
  }
`

const buttonWrapper = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    justify-content: center;
  }
`

export default{
  profileWrapper,
  imgProfileWrapper,
  imageProfile,
  profileDescription,
  info,
  bio,
  btnAbsolute,
  portfolioWrapper,
  dBlock,
  rowProfile,
  profilePadding,
  profileDescriptionHeight,
  profileInfo,
  tabPortfolio,
  tabCol,
  rowPortfolio,
  portfolioOverflow,
  noResult,
  rangeCover,
  avatarWrapper,
  buttonPosition,
  buttonSave,
  buttonCancel,
  buttonEdit,
  dropzone,
  hintDropzone,
  overlayDropzone,
  defaultCover,
  coverWrapper,
  defaultCoverLoading,
  socialMedia,
  w2,
  subscriptionsWrapperItem,
  imgSubscriptions,
  descSubscriptions,
  bioSubscriptions,
  btnMarvlous,
  slick,
  team,
  mobilenoMargin,
  infoDescription,
  subscriberCount,
  subscriberCountMobile,
  donut,
  paddingDesktop,
  w4,
  w8,
  desktop,
  mobile,
  buttonWrapper
}
