import { I18n } from 'utils'
import { v4 } from 'uuid'

const menu = [
  {
    id: 0,
    icon: 'la la-home',
    label: I18n.t('menu.feed'),
    page: 'feed',
  },
  {
    id: 1,
    icon: 'la la-box',
    label: I18n.t('menu.notifications'),
    page: 'notifications',
  },
  {
    id: 2,
    icon: 'la la-hashtag',
    label: I18n.t('menu.trends'),
    page: 'trends',
  },
  // {
  //   id: 3,
  //   icon: 'las la-sms',
  //   label: I18n.t('menu.messages'),
  //   page: 'messages'
  // },
  {
    id: 4,
    icon: 'la la-list',
    label: I18n.t('menu.subscriptions'),
    page: 'subscriptions',
  },
  // {
  //   id: 5,
  //   icon: 'la la-suitcase',
  //   label: I18n.t('menu.jobs'),
  //   page: 'jobs'
  // },
  {
    id: 6,
    icon: 'la la-bookmark',
    label: I18n.t('menu.saved'),
    page: 'saved',
  },
  // {
  //   id: 7,
  //   icon: 'la la-ellipsis-h',
  //   label: I18n.t('menu.more'),
  //   page: 'more'
  // },
  {
    id: 8,
    icon: 'las la-clipboard-check',
    label: 'Revenue',
    page: 'revenue',
  },
  {
    id: 9,
    icon: 'las la-chart-line',
    label: 'Analytics',
    page: 'analytics',
  },
  // {
  //   id: 7,
  //   icon: 'las la-cog',
  //   label: 'Settings',
  //   page: 'settings'
  // },
  // {
  //   id: 8,
  //   icon: 'la la-columns',
  //   label: I18n.t('menu.portfolio'),
  //   page: 'portfolio'
  // },
]

const menuMobile = [
  {
    id: 0,
    icon: 'la la-home',
    label: I18n.t('menu.feed'),
    page: 'feed',
  },
  {
    id: 2,
    icon: 'la la-search',
    label: I18n.t('menu.trends'),
    page: 'trends',
  },
  {
    id: 1,
    icon: 'la la-box',
    label: I18n.t('menu.notifications'),
    page: 'notifications',
  },
  {
    id: 3,
    icon: 'la la-columns',
    label: I18n.t('menu.portfolio'),
    page: 'portfolio',
  },
]


const menuLeftMobile = [
  {
    id: 2,
    icon: 'la la-hashtag',
    label: I18n.t('menu.trends'),
    page: 'trends',
  },
  {
    id: 4,
    icon: 'la la-list',
    label: I18n.t('menu.subscriptions'),
    page: 'subscriptions',
  },
  {
    id: 6,
    icon: 'la la-bookmark',
    label: I18n.t('menu.saved'),
    page: 'saved',
  },
  {
    id: 7,
    icon: 'las la-cog',
    label: 'Settings',
    page: 'settings'
  },
  {
    id: 8,
    icon: 'las la-door-open',
    label: 'Logut',
    page: 'logout'
  },
  // {
  //   id: 7,
  //   icon: 'la la-ellipsis-h',
  //   label: I18n.t('menu.more'),
  //   page: 'more'
  // },
  // {
  //   id: 8,
  //   icon: 'las la-clipboard-check',
  //   label: 'Revenue',
  //   page: 'revenue',
  // },
  // {
  //   id: 9,
  //   icon: 'las la-chart-line',
  //   label: 'Analytics',
  //   page: 'analytics',
  // },
  // {
  //   id: 8,
  //   icon: 'la la-columns',
  //   label: I18n.t('menu.portfolio'),
  //   page: 'portfolio'
  // },
]


const images = [
  {
    id: 0,
    imageData: null,
    valid: false,
    error: '',
  },
  {
    id: 1,
    imageData: null,
    valid: false,
    error: '',
  },
  {
    id: 2,
    imageData: null,
    valid: false,
    error: '',
  },
  {
    id: 3,
    imageData: null,
    valid: false,
    error: '',
  },
  {
    id: 4,
    imageData: null,
    valid: false,
    error: '',
  },
  {
    id: 5,
    imageData: null,
    valid: false,
    error: '',
  },
  {
    id: 6,
    imageData: null,
    valid: false,
    error: '',
  },
]

const files = [
  {
    id: v4(),
    fileData: null,
  },
]

const portfolio = [
  {
    id: 1,
    page: 1,
    title: 'Designs',
    resultExist: false,
    loading: true,
    data: [],
    activeHeightScroll: '',
    endOfPortfolio: false,
    loadingDataPortfolio: false,
    count: 0,
  },
  {
    id: 2,
    page: 1,
    title: 'Images',
    resultExist: false,
    loading: true,
    data: [],
    activeHeightScroll: '',
    endOfPortfolio: false,
    loadingDataPortfolio: false,
    count: 0,
  },
  {
    id: 3,
    page: 1,
    title: 'Videos',
    resultExist: false,
    loading: true,
    data: [],
    activeHeightScroll: '',
    endOfPortfolio: false,
    loadingDataPortfolio: false,
    count: 0,
  },
  {
    id: 4,
    page: 1,
    title: '',
    resultExist: false,
    loading: true,
    data: [],
    activeHeightScroll: '',
    endOfPortfolio: false,
    loadingDataPortfolio: false,
    count: 0,
  }
]

const search = [
  // {
  //   id: 1,
  //   title: 'Result',
  //   data: null,
  //   resultExist: false,
  //   loading: true,
  //   label: 'result',
  //   keyword: '',
  //   page: 1,
  //   activeHeightScroll: '',
  //   endOfSearch: false,
  //   loadingDataSearch: false,
  //   users_count: 0,
  //   designs_count: 0,
  // },
  {
    id: 3,
    title: 'Designs',
    data: [],
    resultExist: false,
    loading: true,
    label: 'designs',
    keyword: '',
    page: 1,
    activeHeightScroll: '',
    endOfSearch: false,
    loadingDataSearch: false,
    count: 0,
  },
  {
    id: 2,
    title: 'Users',
    data: [],
    resultExist: false,
    loading: true,
    label: 'users',
    keyword: '',
    page: 1,
    activeHeightScroll: '',
    endOfSearch: false,
    loadingDataSearch: false,
    count: 0,
  },
  {
    id: 4,
    title: 'Trends',
    data: [],
    resultExist: false,
    loading: true,
    label: 'trends',
    keyword: '',
    page: 1,
    activeHeightScroll: '',
    endOfSearch: false,
    loadingDataSearch: false,
    count: 0,
  },
  {
    id: 5,
    title: 'Colors',
    data: [],
    resultExist: false,
    loading: true,
    label: 'colors',
    keyword: '',
    page: 1,
    activeHeightScroll: '',
    endOfSearch: false,
    loadingDataSearch: false,
    count: 0,
  },
]

const analytics = [
  {
    id: 0,
    title: 'view',
    data: null,
    resultExist: false,
    loading: true,
    fetchingData: true,
    statisticsData: [],
  },
  {
    id: 1,
    title: 'comment',
    data: null,
    resultExist: false,
    loading: true,
    fetchingData: true,
    statisticsData: [],
  },
  {
    id: 2,
    title: 'like',
    data: null,
    resultExist: false,
    loading: true,
    fetchingData: true,
    statisticsData: [],
  },
]

const settingsMenu = [
  {
    id: 0,
    label: 'links',
    title: 'Links'
  },
  {
    id: 1,
    label: 'profile',
    title: 'Profile'
  },
  {
    id: 2,
    label: 'members',
    title: 'Members'
  },
  {
    id: 3,
    label: 'account',
    title: 'Account'
  },
  {
    id: 4,
    label: 'password',
    title: 'Password'
  },
  {
    id: 5,
    label: 'alert',
    title: 'Alert (email notifications)'
  }
]

export default {
  menu,
  menuMobile,
  menuLeftMobile,
  images,
  files,
  portfolio,
  search,
  analytics,
  settingsMenu,
}
