import { css } from 'emotion'
import { Helpers } from 'utils'

const imgFluid = css`
  width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
`

const videoFluid = css`
  width: 100%;
  height:auto;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
`

const hAuto = css`
  height: auto;
`

const mediaWrapper = css`
  min-height: 750px;
  width: 100%;
  @media (min-width: 992px){
    width: 100%;
  }
  @media (min-width: 1200px){
    width: 100%;
  }
  @media (min-width: 1600px){
    width: 100%;
  }
`

const profileWrapper = css`
  img{
    width: 50px;
    border-radius: 50%;
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
    height: 50px;
  }
  display: flex;
  align-items: center;
  margin-bottom:0px;
  h5{
    margin-bottom: 0px;
  }
  span{
    font-size: 14px;
  }
  a{
    &:hover{
      text-decoration: none;
    }
  }
`

function subscribe(subscribe) {
  let subscribeStyle = ''
  if (subscribe) {
    subscribeStyle = `
      color: #292929 !important;
    `
  } else {
    subscribeStyle = `    
      background: -webkit-linear-gradient(0deg,#1181eb,#0fb8ad);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `
  }

  return css`
    font-weight: bold;
    cursor: pointer;
    ${subscribeStyle}
  `
}

const buttonAction = css`
  display: inline-flex;
  width: auto;
  button{
    &:first-of-type{
      background: #1181eb;
      box-shadow: 0 2px 6px 0 rgba(51,105,231,0.4);
      color: #fff;
      &:focus{
        background: #1181eb ;
        box-shadow: 0 0 0 0 rgba(51, 105, 231, 0.4);
        color: #fff;
        outline: 0;
      }
      &:hover{
        background: #1181eb ;
        box-shadow: 0 0 0 0 rgba(51, 105, 231, 0.4);
        color: #fff;
        outline: 0;
        text-decoration: none;
      }
    }
    &:last-of-type{
      background: #0fb8ad;
      box-shadow: 0 2px 6px 0 rgba(15, 184, 173, 0.4);
      color: #fff;
      &:focus{
        background: #0fb8ad;
        box-shadow: 0 0 0 0 rgba(15, 184, 173, 0.4);
        color: #fff;
        outline: 0;
      }
      &:hover{
        background: #0fb8ad;
        box-shadow: 0 0 0 0 rgba(15, 184, 173, 0.4);
        color: #fff;
        outline: 0;
      }
    }
    height: 35px;
    width: 100%;
    border-radius: 2px;
    background: #f3f3f4;
    box-shadow: 0 2px 6px 0 rgba(243, 243, 244, 0.4);
    font-size: 14px;
    letter-spacing: 0.5px;
    border: 0px;
    text-align: center;
    cursor: pointer;
    border:0px !important;
    padding: 0rem 1.5rem !important;
    text-decoration: none;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    &:last-of-type{
      margin-right: 0px;
    }
  }
  @media (min-width: 768px){
    i{
      display: none;
    }
    button{
      width: 85px;
    }
  }
`

function buttonLike(like) {
  let likeStyle = ''
  if (like) {
    likeStyle = `
      background: #f3f3f4 !important;
      color: #212529!important;
      box-shadow: none !important;
      &:hover{
        background: #f3f3f4 !important;
        color: #212529!important;
        box-shadow: none !important;
      }
    `
  }

  return css`
    ${likeStyle}
  `
}

function buttonBookmark(bookmark) {
  let likeStyle = ''
  if (bookmark) {
    likeStyle = `
      background: #f3f3f4 !important;
      color: #212529!important;
      box-shadow: none !important;
      &:hover{
        background: #f3f3f4 !important;
        color: #212529!important;
        box-shadow: none !important;
      }
    `
  }

  return css`
    ${likeStyle}
  `
}

const profileInfoWrapper = css`
  margin-bottom: 20px;
  align-items: center;
`

const description = css`
  margin-top: 20px;
  hr{
    border-top: 1px solid #eee;
  }
  textarea{
    height: 100px;
    resize: none;
    border: 1px solid #f3f3f4;
    &:focus{
      box-shadow: 0 0 0 4px rgba(17, 129, 235, 0.1) !important;
      border: 1px solid rgba(17, 129, 235, 0.4) !important;
    }
  }
`

const leftDescription = css`
  padding-right: 15px;
`

const rightDescription = css`
  padding-left: 25px;
`

const rightDescriptionDetail = css`
  &:nth-child(2){
    margin-bottom: 0px;
    i{
      font-size: 22px;
    }
  }
  &:not(:nth-child(1)):not(:nth-child(2)){
    p{
      align-items: center;
    }
    align-items: center;
  }
  color: #6e6d7a !important;
  margin-bottom: 15px;
  &:first-of-type{
    margin-bottom: 20px;
    i{
        position: relative;
        top: -2px;
      }
  }
  p{
    margin-bottom: 0px;
    width: 20px;
    display: inline-flex;
    justify-content: center;
    margin-right: 10px;
  }
  i{
    font-size: 20px;
  }
  div{
    width: calc(100% - 0px);
  }
  &:last-of-type{
    display: flex;
    align-items: center;
  }
`

const icon = css`
  font-size: 20px;
  font-weight: 400;
`

function baseColor(color) {
  return css`
    width: auto;
    height: 20px;
    display: inline-flex;
    border-radius: 30px;
    margin-right: 10px;
    cursor: pointer;
    width: 50px;
    text-align: center;
    background: ${color};
  `
}

const previewWrapper = css`
  width: 100%;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
`

const buttonShare = css`
  padding: 2px 8px !important;
  border-radius: 2px;
  background-color: #f3f3f4 !important;
  font-size: 1rem !important;
  color: #6e6d7a !important;
  cursor: pointer;
  display: inline-flex !important;
  align-items:center !important;
  font-weight: 500;
  justify-content: center;
  transition: all 200ms ease;
  &:hover{
    background: #e7e7e9 !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  }
  &:focus{
    background: #e7e7e9 !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  }
  i{
    font-size: 14px;
  }
  width: 30% !important;
  &:nth-child(3){
    i{
      color: #000 !important;
      font-size: 12px;
    }
  }
  &:nth-child(1){
    i{
      color: #3b5998 !important;
      font-size: 16px;
    }
  }
  &:nth-child(2){
    margin-left: 14px;
    margin-right: 14px;
    i{
      color: #1da1f2 !important;
    }
  }
`

const contentComment = css`
  height: 100px;
`

const positionRelative = css`
  position: relative;
`

function userMention(comment) {
  const commentStyle = comment ? 'top: -210px;' : 'top: -189px;'
  return css`
    position: absolute;
    width: 100%;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    height: 217px;
    ${commentStyle}
    display: flex;
    align-items: flex-end;
    background: transparent;
    ul{
      box-shadow: 0 1px 4px rgba(0,0,0,0.05);
      height:auto;
      width: 100%;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      border: 1px solid rgba(17, 129, 235, 0.4) !important;
      border-bottom: 0px !important;
      background: #fff;
      li{
        cursor: pointer;
        width: 100%;
        padding: .375rem .75rem;
        background: #fff;
        &:hover{
          background: rgba(17,129,235, 0.2);
        }
        &:first-of-type{
          border-top-left-radius: 2px;
          border-top-right-radius: 2px;
        }
      }
    }
    img{
      width:24px !important;
      height:24px !important;
      border-radius: 50%;
      margin-right: 0.5rem;
      box-shadow: 0 1px 4px rgba(0,0,0,0.05);
    }
  `
}


function activeTextarea(active) {
  if (active) {
    return css`
      box-shadow: 0 0 0 4px rgba(17, 129, 235, 0.1) !important;
      border: 1px solid rgba(17, 129, 235, 0.4) !important;
    `
  }
}

const commentsWrapper = css`
  img{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  }
`

function commentsWrapperDetail(loading) {
  if (loading) {
    return css`
      margin-bottom: 20px;
      &:last-of-type{
        margin-bottom: 0px;
      }
      opacity: 0.45;
    `
  }

  return css`
    margin-bottom: 20px;
    scroll-margin: 80px;
    scroll-padding-top: 80px;
    scroll-margin-top: 80px;
    &:last-of-type{
      margin-bottom: 0px;
    }
    opacity: 1;
  `
}

const contentPostCommment = css`
  padding-left: calc(35px + 1rem);
  a{
    color: #1181eb !important;
  }
`

const btnWrapper = css`
  button{
    margin: 0px;
    width: auto;
    padding: .5rem 1.5rem !important;
    display: inline-flex;
  }
`

const buttonCommentPage = css`
  cursor: pointer;
`

const designer = css`
  font-size: 12px;
  padding: 2px 6px;
  background: #eee;
  border-radius: 2px;
  margin-left: 6px;
  color: #6c757d!important;
`

const linkUsername = css`
  color: #292929;
  &:hover{
    text-decoration: none !important;
    color: #292929;
  }
`

const thumbWrapper = css`
  width: 100%;
  height: 0;
  padding-bottom: calc(75% + 0px);
  overflow: hidden;
  position: relative;
  border-radius: 3px;
  video{
    display:block;
    width: 100%;
    height: auto;
    border-radius: 3px;
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
    margin-top: 0rem !important;
  }
  img{
    width: 100%;
    height: auto;
    border-radius: 3px;
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
    margin-top: 0rem !important;
  }
`

function userHashtag(comment) {
  const commentStyle = comment ? 'top: -210px;' : 'top: -189px;'
  return css`
    position: absolute;
    width: 100%;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    height: 217px;
    ${commentStyle}
    display: flex;
    align-items: flex-end;
    background: transparent;
    ul{
      box-shadow: 0 1px 4px rgba(0,0,0,0.05);
      height:auto;
      width: 100%;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      border: 1px solid rgba(17, 129, 235, 0.4) !important;
      border-bottom: 0px !important;
      background: #fff;
      li{
        cursor: pointer;
        width: 100%;
        padding: .15rem .75rem;
        background: #fff;
        &:hover{
          background: rgba(17,129,235, 0.2);
        }
        &:first-of-type{
          border-top-left-radius: 2px;
          border-top-right-radius: 2px;
        }
      }
    }
    img{
      width:24px !important;
      height:24px !important;
      border-radius: 50%;
      margin-right: 0.5rem;
      box-shadow: 0 1px 4px rgba(0,0,0,0.05);
    }
  
  `
}

const rightProfileWrapper = css`
  height: 100vh;
  position: sticky;
  padding-top: 20px;
  width: 30%;
  display: inline-flex;
  justify-content: center;
  margin-left: 10px;
  border-left: 0px solid #eee;
  padding-left: 15px;
  @media only screen and (min-width:0px) and (max-width:767px){
    display: none;
  }
`

const mediaScroll = css`
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: sticky;
  padding-top: 20px;
  width: 70%;
  display: inline-block;
  padding-right: 0px;
  &:after{
    display: block;
    height: 100px;
    content: '';
  }
  @media only screen and (min-width:0px) and (max-width:767px){
    &:after{
      display: block;
      height: 160px;
      content: '';
    }
    width: 100%;
  }
`

const cardCustom = css`
  > div{
    border-radius: 2px !important
  }
  video, img{
    border-radius: 2px !important
  }
`

const actionPlace = css`
  li{
    margin-bottom: 20px;
    p{
      margin:0;
      display: inline-flex;
      font-size: 30px;
      align-items:center;
      justify-content: center;
    }
  }
`

function liveActive(active) {
  const activeStyle = active ? 'color:#212529 !important;' : 'color: #6e6d7a !important;'

  return css`
    p{
      cursor:pointer;
    }
    p, span{
      ${activeStyle}
    }
    i{
      ${activeStyle}
    }
  `
}

const deleteComment = css`
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 14px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #212529;
  color: #fff;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
`

const rightAds = css`
  margin-top: 75px;
`

const adsCustom = css`
  width: 50%;
  box-shadow: 0 0 0 0 rgba(0,0,0,0.1);
  display:inline-block;
`

const socialMedia = css`
  ul{
    li{
      &:nth-child(1), &:nth-child(2), &:nth-child(3){
        i{
          font-size: 18px;
          position: relative;
          top: 2px;
        }
      }
      margin-right: 13px;
    }
  }
`

const w2 = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    display: none;
  }
`

const w10 = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const prev = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    display:none;
  }
`

const next = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    display:none;
  }
`

const w7 = css`
  @media only screen and (min-width:0px) and (max-width:320px){
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const w5 = css`
  @media only screen and (min-width:0px) and (max-width:320px){
    flex: 0 0 100%;
    max-width: 100%;
    text-align: left!important;
    > div{
      display: inline-block !important;
      text-align: left!important;
    }
  }
`

const menuMobile = css`
  @media only screen and (min-width: 768px){
    display: none;
  }
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 1111;
  height: 45px;
  border-top: 1px solid #eee;
  ul{
    display:flex;
    height: 100%;
  }
`

const menuList = css`
  position: relative;
  a{
    position: relative;
  }
`

const notifications = css`
  position: absolute;
  margin: 0px;
  width: 18px;
  height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #FC271D;
  border-radius: 50%;
  box-shadow:0 1px 4px rgba(0,0,0,0.05);
  font-size: 10px;
  top: -5px;
  left: 9px;
`


export default{
  thumbWrapper,
  imgFluid,
  videoFluid,
  hAuto,
  mediaWrapper,
  profileWrapper,
  subscribe,
  buttonAction,
  buttonLike,
  buttonBookmark,
  profileInfoWrapper,
  description,
  leftDescription,
  rightDescription,
  rightDescriptionDetail,
  icon,
  baseColor,
  previewWrapper,
  buttonShare,
  contentComment,
  positionRelative,
  userMention,
  activeTextarea,
  commentsWrapper,
  commentsWrapperDetail,
  contentPostCommment,
  btnWrapper,
  buttonCommentPage,
  designer,
  linkUsername,
  userHashtag,
  rightProfileWrapper,
  mediaScroll,
  cardCustom,
  actionPlace,
  liveActive,
  deleteComment,
  rightAds,
  socialMedia,
  w2,
  w10,
  prev,
  next,
  w7,
  w5,
  menuMobile,
  menuList,
  notifications,
  adsCustom
}
