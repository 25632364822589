import React, { Fragment } from 'react'
import { Base, Helpers } from 'utils'
import Styles from './Styles'

const Input = ({
  id,
  title,
  onBlur,
  onKeyUp,
  onChange,
  onFocus,
  error,
  type,
  placeholder,
  value,
  className,
  readOnly,
  min,
}) => (
  <Fragment>
    <h6 className={Helpers.mergeCss(Styles.title, Base.dFlex, Base.alignItemsCenter, Base.textMuted)}>
      {title}
      <label style={{ fontSize: '14px' }} className="mb-0 ml-2 text-danger">{`${error === '' ? '' : `(${error})`}`}</label>
    </h6>
    {
      type === 'textarea'
        ? (
          <textarea
            id={id}
            type={type}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            onKeyUp={onKeyUp}
            placeholder={placeholder}
            readOnly={readOnly}
            className={typeof className !== 'undefined' ? Helpers.mergeCss(Base.formControl, Styles.defaultInput, className) : Helpers.mergeCss(Base.formControl, Styles.defaultInput)
        }
          />
        )
        : 
        min !== 'undefined' ?
        (
          <input
            id={id}
            type={type}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            onKeyUp={onKeyUp}
            placeholder={placeholder}
            min={min}
            readOnly={readOnly}
            className={typeof className !== 'undefined' ? Helpers.mergeCss(Base.formControl, Styles.defaultInput, className) : Helpers.mergeCss(Base.formControl, Styles.defaultInput)}
          />
        )
        :
          (
          <input
            id={id}
            type={type}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            onKeyUp={onKeyUp}
            placeholder={placeholder}
            readOnly={readOnly}
            className={typeof className !== 'undefined' ? Helpers.mergeCss(Base.formControl, Styles.defaultInput, className) : Helpers.mergeCss(Base.formControl, Styles.defaultInput)
        }
          />
        )
    }
  </Fragment>
)

export default Input
