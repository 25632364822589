export default {
  INDEX: '/',
  NOTIFICATIONS: '/notifications',
  TRENDS: '/trends',
  SEARCH: '/search/:keyword',
  EXPLORES: '/explores',
  SUBSCRIPTIONS: '/subscriptions',
  JOBS: '/jobs',
  SAVED: '/saved',
  DESIGNUPLOAD: '/upload',
  DESIGNDETAIL: '/designs/:slug',
  PORTFOLIODETAIL: '/:username',
  ANALYTICS: '/analytics',
  SETTINGS: '/settings',
  REVENUE: '/revenue',
  CHALLENGES: {
    CREATE: '/create',
    DETAIL: '/:slug'
  },
  JOIN: {
    SUBMISSION: '/submission',
  },
  SETUP: {
    PROFILE: '/setup/profile/:token',
    TEAM: '/setup/team',
  },
}
