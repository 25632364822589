import base from './api.base'

function find(keyword) {
  return base.get(`finds/${keyword}`)
}

const ApiFinds = {
  find,
}

export default ApiFinds
