import React, { Fragment } from 'react'
import { Link, matchPath } from 'react-router-dom'
import { Helpers, Base } from 'utils'
import { Loading } from 'components'
import placeholderImage from 'images/user-placeholder.jpg'
import Styles from './Styles'

const UserInfo = ({
  user,
  state,
  submitSubscribe,
  portfolio,
}) => (
  <Fragment>
    <div className={Base.row}>
      <div className={Helpers.mergeCss(Base.col, Base.w12)}>
        <div className={Base.dBlock}>
          <div className={Helpers.mergeCss(Base.row, Base.alignItemsCenter, Base.justifyContentCenter)}>
            <div className={Helpers.mergeCss(Base.col, Base.w12)}>
              <div className={Helpers.mergeCss(Base.row, Styles.rowProfile(portfolio), Base.justifyContentCenter, Base.textCenter, Base.alignItemsCenter)}>
                <div className={Helpers.mergeCss(Base.col, Base.textCenter, Base.justifyContentCenter, Base.w12)}>
                  {portfolio
                    ? (
                      <img
                        className={Styles.imageProfile(portfolio)}
                        src={(user.info.attributes.avatar.url === null || user.info.attributes.avatar.url === '') ? placeholderImage : user.info.attributes.avatar.url}
                      />
                    )
                    : (
                      <Link to={`/${user.info.attributes.username}`}>
                        <img
                          className={Styles.imageProfile(portfolio)}
                          src={(user.info.attributes.avatar.url === null || user.info.attributes.avatar.url === '') ? placeholderImage : user.info.attributes.avatar.url}
                        />
                      </Link>
                    )
                      }
                  {/* <img className={Styles.imageProfile} src={placeholderImage}/> */}
                </div>
                <div className={Helpers.mergeCss(Base.col, Base.w12)}>
                  <span style={{marginRight: user.info.attributes.type_user === 'team' ? '-40px': '0px'}} className={Helpers.mergeCss(Base.dInlineFlex, Base.alignItemsCenter, Base.justifyContentCenter)}>
                    <h5 className={Base.margin0}>{portfolio ? user.info.attributes.name : user.info.attributes.username}</h5>
                    <span className={Helpers.mergeCss(Base.textMuted, Base.marginLeft)}>
                      {portfolio && `${user.info.attributes.username}`}
                      {user.info.attributes.verified && (
                      <i
                        style={{
                          marginLeft: '4px', fontSize: '12px', position: 'relative', top: '-0.1px', color: '#1181eb',
                        }}
                        className="fa fa-check-circle"
                      />
                      )}
                    </span>
                    {user.info.attributes.type_user === 'team' && (<span className={Styles.team}>Team</span>)}
                  </span>
                  {portfolio
                        && (
                        <span style={{ fontSize: '14px' }} className={Helpers.mergeCss(Base.textMuted, Base.dBlock)}>
                          {user.info.attributes.location === '' || user.info.attributes.location === null
                            ? null
                            : (
                              <Fragment>
                                <b style={{ fontWeight: '500' }} className={Base.marginRight2}>
                                  {user.info.attributes.location}
                                </b>
                                •
                              </Fragment>
                            )
                          }
                          <b style={{ fontWeight: '500' }} className={Base.marginLeft2}>
                            {user.info.attributes.subscribers - 1}
                          </b>
                          {' '}
                          subscribers
                        </span>
                        )
                      }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className={Helpers.mergeCss(Base.row, Base.justifyContentCenter, Base.rowProfile)}>
      <div className={Helpers.mergeCss(Base.col, Base.textCenter, Base.w12)}>
        {portfolio
              && (
              <span style={{ fontSize: '14px' }} className={Helpers.mergeCss(Base.text, Base.dBlock)}>
                {user.info.attributes.bio}
              </span>
              )
            }
        {
              state.user.info === null
                ? (state.feed.loadingFeed && state.login
                  ? (
                    <div
                      style={{ marginTop: '13px', marginBottom: '13px' }}
                      className={Helpers.mergeCss(Base.dFlex, Base.justifyContentCenter)}
                    >
                      <Loading tiny />
                    </div>
                  )
                  : (
                    <div
                      onClick={() => {
                        window.location.href = '/users/login'
                      }}
                      className={Helpers.mergeCss(Base.btnMarvlous, Styles.btnAbsolute(false))}
                    >
                      Subscribe
                    </div>
                  )
                )
                : state.user.info.attributes.username !== user.info.attributes.username
                  ? (
                    <button
                      onClick={submitSubscribe(user.info.attributes.subscribed)}
                      className={Helpers.mergeCss(Base.btnMarvlous, Styles.btnAbsolute(user.info.attributes.subscribed))}
                    >
                      {
                  user.info.attributes.subscribed ? 'Subscribed' : 'Subscribe'
                }
                </button>
              ) : ''
            }
      </div>
    </div>
  </Fragment>
)

export default UserInfo
