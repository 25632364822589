import React, { Component, Fragment } from 'react'
import { Loading } from 'components'
import {
  Helpers, Base, Routes, history, Validation,
} from 'utils'

const EndPage = ({ loading, endPage }) => (
  <Fragment>
    {loading
        && (
        <div className={Helpers.mergeCss(Base.col, Base.marginTop4, Base.marginBottom4, Base.w12)}>
          <div className={Helpers.mergeCss(Base.dFlex, Base.justifyContentCenter)}>
            <Loading />
          </div>
        </div>
        )
      }
    {endPage
        && (
        <div className={Helpers.mergeCss(Base.col, Base.marginTop4, Base.marginBottom4, Base.w12)}>
          <div className={Helpers.mergeCss(Base.dFlex, Base.justifyContentCenter)}>
            <b>You reach at the end of the page</b>
          </div>
        </div>
        )
      }
  </Fragment>
)

export default EndPage
