import { css } from 'emotion'

const footer = css`
  background: #292929;
  color: #fff;
  ul{
    li{
      a{
        display:flex;
        margin-top: 16px;
        align-items: center;
        &:hover{
          color:rgba(255,255,255,0.75);
          text-decoration: none;
        }
        span{
          font-size: 10px;
          padding: 0px 4px;
          background: rgba(243, 243, 243, 0.5);
          border-radius: 2px;
          display: inline-block;
          color: rgba(255, 255, 255, 0.8);
          margin-left: 10px;
          font-weight: bold;
          height: auto;
        }
      }
    }
  }
`

const marginFooter = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    margin-bottom: 30px;
  }
`

const w2 = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const w3 = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const borderTop = css`
  margin-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  padding-top: 15px;
`

export default{
  footer,
  marginFooter,
  w2,
  w3,
  borderTop
}