import base from './api.base'

function popular() {
  return base.get('tags/popular')
}

function suggestion() {
  return base.get('tags/suggestion')
}

function search(keyword) {
  return base.get(`tags/search/${keyword}`)
}

const ApiTags = {
  popular,
  suggestion,
  search,
}

export default ApiTags
