import { css } from 'emotion'

const imgFluid = css`
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
`

const cardFeed = css`
  border: 0px;
  padding: 0px;
`

const cardFeedBody = css`
  padding: 0px;
  font-size: 14px;
`

const colFeed = css`
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 20px;
  text-decoration: none;
  color: #212529;
  &:hover{
    text-decoration: none;
    color: #212529;
  }
`

const coverCard = css`
  background: linear-gradient(180deg, transparent 62%, 
    rgba(0,0,0,0.00345888) 63.94%, 
    rgba(0,0,0,0.014204) 65.89%, 
    rgba(0,0,0,0.0326639) 67.83%, 
    rgba(0,0,0,0.0589645) 69.78%, 
    rgba(0,0,0,0.0927099) 71.72%, 
    rgba(0,0,0,0.132754) 73.67%, 
    rgba(0,0,0,0.177076) 75.61%, 
    rgba(0,0,0,0.222924) 77.56%, 
    rgba(0,0,0,0.267246) 79.5%, 
    rgba(0,0,0,0.30729) 81.44%, 
    rgba(0,0,0,0.341035) 83.39%, 
    rgba(0,0,0,0.367336) 85.33%, 
    rgba(0,0,0,0.385796) 87.28%, 
    rgba(0,0,0,0.396541) 89.22%, 
    rgba(0,0,0,0.4) 91.17%);
`

const linkProfile = css`
    color: #292929;
    cursor: pointer;
    img{
      border-radius: 50% !important;
    }
    &:hover{
      color: #292929;
      text-decoration: none;
    }
`

const w3 = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media only screen and (min-width:768px) and (max-width:1023px){
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media only screen and (min-width:1024px) and (max-width:1199px){
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
`

const imgProfile = css`
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  border-radius: 2px;
  margin-right: 6px;
  width: 25px;
  height: 25px;
  display: inline-block;
  background: rgba(17,129,235, 0.05);
  @media only screen and (min-width:0px) and (max-width:767px){
    width: 25px;
    height: 25px;
  }
`

const team = css`
  font-size: 10px;
  padding: 0px 3px;
  background: #f3f3f3;
  border-radius: 2px;
  display: inline-block;
  color: #6c757d;
  margin-left: 5px;
  font-weight: bold;
  height: 16px;
  border: 1px solid #eee;
  text-transform: uppercase;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
`

const username = css`
  line-height: 1;
  font-weight: 500;
`

const w12 = css`
  display: flex;
  align-items: center;
`

export default{
  imgFluid,
  cardFeed,
  cardFeedBody,
  colFeed,
  coverCard,
  linkProfile,
  w3,
  imgProfile,
  team,
  username,
  w12
}
