import { css } from 'emotion'

const imgLogo = css`
  width:30px;
  height:auto;
  object-fit: cover;
  object-position: center;
  position:relative;
`

const newMarvlous = css`
  display: flex;
  margin: 0;
  align-items: center;
  height: 60px;
  span{
    background: -webkit-linear-gradient(0deg, #1181eb, #0fb8ad);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    margin-left: 3px;
  }
`

const marvlous = css`
  display: flex;
  margin: 0;
  align-items: center;
  height: 60px;
  padding: 30px;
  span{
    color: #1181eb;
    font-weight: bold;
    margin-left: 3px;
  }
  @media (max-width: 1199px) {
    display: inline-flex;
    padding-left: 24px;
    padding-right: 22px;
    span{
      display: none;
    }
  }
`

function feed(arrow){
  const arrowStyle = arrow ? `
    padding-right: 45px;
    @media only screen and (min-width:0px) and (max-width:767px){
      padding-right: 0px !important;
    }
  `
  :
  `
    padding-right: 45px;
  `
  return css`
    height: 60px;
    align-items: center;
    display: flex;
    font-weight: bold;
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    margin-left: 0px;
    margin-right: 0px;
    z-index: 1111;
    background: #fff;
    position: absolute;
    width: 100%;
    padding-left: 28px;
    ${arrowStyle}
  `
}

const feedOption = css`
  position: absolute;
  right: 15px;
`

const wrapper = css`
  padding: 0px;
`

const menu = css`
  padding: 30px;
  padding-top: 15px;
  padding-bottom: 0px;
  margin-top: 0px;
  position: relative;
  @media (max-width: 1199px) {
    padding: 0px;
    display: inline-flex;
    width: 100%;
    justify-content: center;
    padding-left: 10px;
  }
`

const btnMarvlous = css`
  font-weight: bold;
  height: auto;
  border-radius: 2px;
  background: linear-gradient(to right, #1181eb, #0fb8ad) !important;
  color: #fff;
  box-shadow: 0 2px 6px 0 rgba(51, 105, 231, 0.4);
  font-size: 14px;
  letter-spacing: 0.5px;
  border: 0px;
  text-align: center;
  cursor: pointer;
  border:0px !important;
  padding: .5rem 3rem !important;
  text-decoration: none;
  width: auto;
  &:focus{
    box-shadow: 0 0 0 0 rgba(51, 105, 231, 0.4);
    outline: 0;
  }
  &:hover{
    box-shadow: 0 0 0 0 rgba(51, 105, 231, 0.4);
    text-decoration: none;
  }
`

const footer = css`
  ul{
    li{
      display: inline-flex;
      align-items: center;
      font-size: 12px;
      margin-right:0px;
      &:last-of-type{
				&::after{
					display: none;
				}
			}
      &::after{
        content: '';
        width: 4px;
        height: 4px;
        display: inline-block;
        border-radius: 50%;
        position: relative;
        background: #6e6d7a;
        margin-bottom: 0px;
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }
  span{
    font-size: 12px;
    margin-top: 5px;
    display: block;
  }
`

const search = css`
  height: 60px;
  display: flex;
  width: 100%;
  align-items:center;
`

const container = css` 
  overflow: hidden;
`

const designTrend = css`
  background: #f3f3f4 !important;
  padding: 0px 0px;
  margin-top: 15px;
  border-radius: 2px;
`

const tagList = css`
  list-style: none;
  padding: 0px;
  margin: 0px;
  li{
    a{
      display: block;
      padding: 5px 10px;
      cursor: pointer;
      &:hover{
        background: #e7e7e9 !important;
        text-decoration: none;
      }
      p{
        color: #292929;
        font-weight: bold;
        margin: 0px;
      }
      span{
        font-size: 14px;
      }
      border-bottom: 1px solid #eee;
    }
    &:last-of-type{
      div{
        border-bottom:0px;
      }
    }
  }
`

const popularDesign = css`
  padding: 8px 10px;
  font-weight: bold;
  margin-bottom: 5px;
  border-bottom: 1px solid #eee;
  display: block;
`

const ads = css`
  margin-top: 10px;
  span{
    &:first-of-type{
      display: block;
      font-weight: bold;
      margin-bottom: 5px;
    }
    &:last-of-type{
      font-size: 12px;
      color: #6e6d7a !important;
      display: block;
    }
  }
  img{
    width: 100%;
    height: auto;
    border-radius: 2px;
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
    margin-bottom: 10px;
  }
  @media (max-width: 1199px) {
    display: none;
  }
`

const menuList = css`
  position: relative;
  a{
    position: relative;
  }
`

function moreMenu(login) {
  const loginStyle = login ? 'top: 70px;' : 'top: 110px;'
  return css`
    position: absolute;
    box-shadow:0 1px 4px rgba(0,0,0,0.05);
    border: 1px solid #eee;
    border-radius: 2px;
    width: 100%;
    background: white;
    ${loginStyle}
    padding: 10px;
    li{
      margin-bottom: 17.5px;
      &:last-of-type{
        margin-bottom: 0px;
      }
      a, div{
        cursor: pointer;
        display: inline-flex;
        &:hover{
          > div{
            i{
              color: #1181eb !important;
            }
          }
          span{
            p{
              background: -webkit-linear-gradient(0deg,#1181eb,#0fb8ad);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            background: -webkit-linear-gradient(0deg,#1181eb,#0fb8ad);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        text-decoration: none;
        align-items: center;
        p{
          &:last-of-type{
            font-size: 14px;
          }
        }
      }
    }
    div > div{
      width: auto;
      height: auto;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      i{
        font-size: 20px;
        color: #6e6d7a !important;
      }
    }
    span{
      margin-left: 8px;
    }
    img{
      width: 32px;
      height: 32px;
      box-shadow:0 1px 4px rgba(0,0,0,0.05);
      border-radius: 2px;
    }
  `
}

const notifications = css`
  position: absolute;
  margin: 0px;
  width: 18px;
  height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #FC271D;
  border-radius: 50%;
  box-shadow:0 1px 4px rgba(0,0,0,0.05);
  font-size: 10px;
  top: -5px;
  left: 9px;
`

const active = css`
  i{
    color:#1181eb !important;
  }
  div{
    i{
      color:#1181eb !important;
    }
  }
  span{
    background: -webkit-linear-gradient(0deg,#1181eb,#0fb8ad);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }
  img{
    border: 2px solid #1181eb !important;
    border-radius: 2px;
  }
`

function indication(exist) {
  const indicationStyle = exist ? `
    opacity: 1;
    z-index: 111;
  ` : `
    opacity: 0;
    z-index: -11;
  `
  return css`
    ${indicationStyle}
  `
}

const text = css`
  color: #212529 !important;
`

const linkUpload = css`
  background: #1181eb;
  font-weight: bold;
  font-size: 14px;
  display: inline-flex;
  height: 30px;
  border-radius: 3px;
  color: #fff;
  padding: 0 12px;
  box-shadow: 0 2px 6px 0 rgba(51,105,231,0.2);
  align-items: center;
  justify-content:center;
  cursor: pointer;
  &:hover{
    color: #fff;
  }
`

const linkUploadMobile = css`
  background: #1181eb;
  font-weight: bold;
  font-size: 22px;
  display: inline-flex;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  color: #fff;
  padding: 0 0px;
  box-shadow: 0 2px 6px 0 rgba(51,105,231,0.2);
  align-items: center;
  justify-content:center;
  cursor: pointer;
  margin-right: 0px;
  &:hover{
    color: #fff;
  }
`

function activeProfile(active) {
  const activeStyle = active
    ? 'border: 2px solid #1181eb;' : 'border: 0px solid transparent;'

  return css`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
    margin-right: 8px;
    ${activeStyle}
  `
}

function activeText(active) {
  const activeStyle = active
    ? `color:#1181eb !important;
   font-weight:bold !important;` : ''

  return css`   
    ${activeStyle}
  `
}

const defaultProfile = css`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  margin-right: 8px;
`

const dropdownList = css`
  position: absolute;
  top: 49px;
  right: 15px;
  background: #fff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  display: flex;
  border: 1px solid #eee;
  width: auto;
  li{
    a, div{
      &:hover{
        text-decoration:none;
      }
      color:#212529;
      font-weight:normal;
      display:flex;
      align-items: center;
      padding: 8px 10px;
      i{
        font-size: 20px;
        margin-right: 8px;
      }
    }
  }
`

const w2 = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    display: none;
  }
  @media only screen and (min-width:768px) and (max-width:1023px){
    width: 90px;
    flex: unset;
    padding-right: 0px;
    max-width: unset;
  }
  @media only screen and (min-width:1024px) and (max-width:1199px){
    width: 90px;
    flex: unset;
    padding-right: 0px;
    max-width: unset;
  }
`

const w10 = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    width: calc(100%);
    max-width: unset;
    flex: unset;
  }
  @media only screen and (min-width:768px) and (max-width:1023px){
    width: calc(100% - 90px);
    max-width: unset;
    flex: unset;
  }
  @media only screen and (min-width:1024px) and (max-width:1199px){
    width: calc(100% - 90px);
    max-width: unset;
    flex: unset;
  }
`

const w6 = css`
  padding-right: 13px;
`

const desktopHeader = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    display: none !important;
  }
`

const mobileHeader = css`
  @media only screen and (min-width: 768px){
    display: none !important;
  }
`

const centerPosition = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    align-items: center;
  }
`

const nothing = css`

`

const menuMobile = css`
  @media only screen and (min-width: 768px){
    display: none;
  }
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 1111;
  height: 45px;
  border-top: 1px solid #eee;
  ul{
    display:flex;
    height: 100%;
  }
`

const uploadMobile = css`
  @media only screen and (min-width: 768px){
    display: none;
  }
  position: absolute;
  bottom: 60px;
  right: 50px;
  z-index: 1111;
`

const imgLogoMobile = css`
  width:30px;
  height:auto;
  object-fit: cover;
  object-position: center;
  position:relative;
  @media only screen and (min-width:0px) and (max-width:767px){
    margin-right: -15px;
  }
`

const hamburgerMenu = css`
  cursor: pointer;
  span{
    width: 25px;
    background: #1181eb;
    display: flex;
    height: 2px;
    border-radius: 1px;
    &:nth-child(2){
      margin-top: 4px;
      margin-bottom: 4px;
    }
    &:last-of-type{
      width: 15px;
    }
  }
`

function leftMenu(open = false){
  const openStyle = open ? `
    left: 0;
  `
  :
  `
    left: -250px;
  `
  return css`
    display: none;
    @media only screen and (min-width:0px) and (max-width:767px){
      display: block;
      ${openStyle}
      position: fixed;
      width: 250px;
      background: #fff;
      top: 0;
      height: 100%;
      z-index: 1121;
      transition: all 0.5s;
      -webkit-transition: all 0.25s;
      overflow: hidden;
    }
  `
}

function overlayMenu(open = false){
  const openStyle = open ? `
    right: 0;
  `
  :
  `
    right: -100%;
  `
    return css`
      @media only screen and (min-width:0px) and (max-width:767px){
        position: fixed;
        ${openStyle}
        width: 100%;
        background: rgba(0,0,0, 0.5);
        top: 0;
        height: 100%;
        z-index: 1111;
        overflow: hidden;
      }
    `
}

const headerInfo = css`
  border-bottom: 1px solid #eee;
  height: 60px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  display: flex;
  font-weight: bold;
  padding-left: 0.75rem;
`

const menuListMobile = css`
  li{
    a{
      span{
        display: flex !important;
      }
    }
  }
`

const closeButton = css`
  position: absolute;
  right: 15px;
  cursor: pointer;
  z-index: 111;
  span{
    width: 19px;
    background: #1181eb;
    display: flex;
    height: 2px;
    &:first-of-type{
      transform: rotate(45deg);
      top: 2px;
      position: relative;
    }
    &:last-of-type{
      transform: rotate(-45deg);
    }
  }
`

export default {
  imgLogo,
  marvlous,
  feed,
  feedOption,
  wrapper,
  menu,
  btnMarvlous,
  footer,
  search,
  container,
  designTrend,
  tagList,
  popularDesign,
  ads,
  menuList,
  moreMenu,
  notifications,
  active,
  indication,
  text,
  linkUpload,
  linkUploadMobile,
  activeProfile,
  activeText,
  defaultProfile,
  dropdownList,
  w2,
  w10,
  w6,
  desktopHeader,
  mobileHeader,
  nothing,
  centerPosition,
  menuMobile,
  uploadMobile,
  imgLogoMobile,
  hamburgerMenu,
  leftMenu,
  overlayMenu,
  headerInfo,
  menuListMobile,
  closeButton
}
