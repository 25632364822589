import React, { Fragment } from 'react'
import { Base, Helpers } from 'utils'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-tippy'
import Styles from './Styles'

import 'react-tippy/dist/tippy.css'

const Hint = ({ title, position, content }) => (
  <Tooltip
    title={title}
    position={position}
    trigger="mouseenter"
    style={{ display: 'inline-block' }}
    arrow
    distance={15}
    sticky
    tabIndex={1111}
  >
    {content}
  </Tooltip>
)

export default Hint
