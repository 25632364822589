import { css } from 'emotion'

function donut(tiny) {
  const tinyStyle = tiny ? `
    width: 14px;
    height: 14px;
    border: 0.16rem solid rgba(17, 129, 235, 0.25);
  ` : `
    width: 30px;
    height: 30px;
    border: 0.25rem solid rgba(17, 129, 235, 0.25);
  `

  return css`{
    ${tinyStyle}
    border-radius: 50%;
    border-top-color: #1181eb;
    animation: 1s spin infinite linear;
    }
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }`
}

export default{
  donut,
}
