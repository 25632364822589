import { css } from 'emotion'

function btnAbsolute(subscribe) {
  let subscribeStyle = ''
  if (subscribe) {
    subscribeStyle = `
      background: #f3f3f4 !important;
      color: #212529!important;
      box-shadow: none !important;
    `
  }

  return css`
    position: relative;
    margin: 0px;
    width: auto;
    padding: 0 12px !important;
    height: 30px;
    display: inline-flex;
    margin-top: 10px;
    border-radius: 3px !important;
    ${subscribeStyle}
    &:hover{
      color: #fff;
    }
  `
}

function imageProfile(portfolio) {
  const portfolioStyle = portfolio ? `
    width: 103px;
    height: 103px;
    border: 3px solid #fff;
  `
    : `width: 50px;
   height: 50px;
  `
  return css`
    position: relative;
    ${portfolioStyle}
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  `
}

function rowProfile(portfolio) {
  const portfolioStyle = portfolio ? `
    margin-left:0px !important;
    margin-right:0px !important;
    &:first-of-type{
      div{
        &:first-of-type{
          width:103px !important;
          max-width:103px !important;
          flex: 0 0 103px !important;
          margin-top: -70px !important;
        }
        &:last-of-type{
          padding-left: 0px !important;
          margin-top: 5px !important;
        }
        padding: 0px !important;
      }
    }
    div{
      padding: 0px !important;
    }`
    : `
  &:first-of-type{
    div{
      &:last-of-type{
        padding-left: 0px !important;
        margin-top: 5px !important;
      }
      padding: 0px !important;
    }
  }
  `
  return css`
    ${portfolioStyle}
  `
}

const team = css`
  font-size: 10px;
  padding: 0px 3px;
  background: #f3f3f3;
  border-radius: 2px;
  display: inline-block;
  color: #6c757d;
  margin-left: 5px;
  font-weight: bold;
  height: 16px;
  border: 1px solid #eee;
  text-transform: uppercase;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
`

export default {
  btnAbsolute,
  imageProfile,
  rowProfile,
  team
}
