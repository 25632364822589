import React, { Component, Fragment } from 'react'
import { Base, Helpers, I18n } from 'utils'
import { Link } from 'react-router-dom'
import {
  ApiDesigns, ApiUsers, ApiComments, ApiSubscriptions, ApiLikes, ApiBookmarks, ApiTags, ApiLikeComments,
} from 'api'
import {
  Input, InputTag, Checkbox, Hint, UserInfo, CardPreview, ListitemMobile, Adsense, AdsenseMobile
} from 'components'
import moment from 'moment'
import { EmailShareButton, FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share'
import uuid from 'uuid'
import Fingerprint2 from 'fingerprintjs2'
import ReactHtmlParser from 'react-html-parser'
import upload from 'images/upload.svg'

import styles from 'react-responsive-carousel/lib/styles/carousel.min.css'
import Store from '../Store'
import Model from '../Model'
import Data from '../Data'
import functions from '../../functions'
import Styles from './Styles'
import CarouselThumb from './CarouselThumb'
import placeholderImage from 'images/user-placeholder.jpg'
import ColorThief from 'colorthief'

function transform(node) {
  if (node.type === 'tag' && node.name === 'a' && node.attribs.class === 'mention') {
    return <Link key={node.attribs.href} to={`/${node.children[0].data.substr(1)}`}>{node.children[0].data}</Link>
  }

  if (node.type === 'tag' && node.name === 'a' && node.attribs.class === 'hashtag') {
    return <Link key={node.attribs.href} to={`/search/${node.children[0].data.substr(1)}?tab=trends`}>{node.children[0].data}</Link>
  }
}

const newOptions = {
  decodeEntities: true,
  transform,
}

class DesignDetail extends Component {
  state = {
    feedDetail: null,
    feedDetailLoading: true,
    feedDetailLeftLoading: true,
    feedDetailLoadingDescription: true,
    comment: '',
    commentTyping: '',
    commentError: '',
    resultUserData: [],
    resultTagData: [],
    commentFocus: false,
    replyComments: [],
    replyCommentContent: '',
    commentPage: {
      edited: [],
      comments: [],
      deletedConfirm: [],
      rangePageComment: 10,
      activePageComment: 1,
      totalComment: '',
      totalPageComment: '',
    },
  }

  renderThumbnailPreview = (coverData) => {
    let content = (<div />)
    if (Helpers.isImage(coverData)) {
      content = (
        <div key={coverData} className={Styles.previewWrapper}>
          <img key={coverData} className={Styles.imgFluid} src={coverData} />
        </div>
      )
    } else if (coverData.includes('mp4')) {
      content = (
        <div key={coverData} className={Styles.previewWrapper}>
          <video
            className={Styles.imgFluid}
            autoPlay
            loop
          >
            <source src={coverData} type="video/mp4" />
            <source src={coverData} type="video/ogg" />
          </video>
        </div>
      )
    }

    // console.log(content)
    return content
  }

  componentDidMount() {
    const { props: { state: { user, index, fingerPrint }, history, slug } } = this
    if (fingerPrint !== '') {
      this.initializeData(slug, fingerPrint, this.props.state)
    } else {
      const options = {  }
      Fingerprint2.get(options, (components) => {
        const values = components.map(component => component.value)
        const fingerPrint = Fingerprint2.x64hash128(values.join(''), 31)
        this.initializeData(slug, fingerPrint, this.props.state)
      })
    }

    history.listen((location, action) => {
      if (location.pathname.split('/').filter(any => any).includes('designs')) {
        this.setState({
          feedDetailLoadingDescription: true,
          feedDetailLoading: true,
        }, () => {
          const newSlug = location.pathname.split('/').filter(any => any)[1]
          this.initializeData(newSlug, fingerPrint, this.props.state)
        })
      } else {
        this.props.resetState()
      }
    })
  }

  initializeData = (slug, fingerPrint, state) => {
    const objectToSend = {
      view: fingerPrint,
    }
    const data = Model.designViewsParams(objectToSend)
    ApiDesigns.show(slug, data).then(({ data }) => {
      this.props.handleViews(data, state)

      const metaData = {
        title: data.attributes.title,
        image: data.attributes.cover.url,
        url: data.attributes.url,
        description: data.attributes.description,
      }

      this.props.updateMetaTitle(metaData)

      this.setState((currState) => {
        if (!this.props.viewComment) {
          return ({
            feedDetail: data,
            feedDetailLoadingDescription: false,
            feedDetailLeftLoading: false,
            commentPage: {
              ...currState.commentPage,
              comments: Helpers.groupArrWithRange(data.attributes.comments, currState.commentPage.rangePageComment),
              totalComment: data.attributes.comments.length,
              totalPageComment: Helpers.groupArrWithRange(data.attributes.comments, currState.commentPage.rangePageComment).length,
            },
          })
        }
        const commentPosition = data.attributes.comments.map(item => item.attributes.uuid).indexOf(this.props.viewComment) + 1
        const activePageComment = Math.ceil(commentPosition / currState.commentPage.rangePageComment)
        return ({
          feedDetail: data,
          feedDetailLoadingDescription: false,
          feedDetailLeftLoading: false,
          commentPage: {
            ...currState.commentPage,
            activePageComment,
            comments: Helpers.groupArrWithRange(data.attributes.comments, currState.commentPage.rangePageComment),
            totalComment: data.attributes.comments.length,
            totalPageComment: Helpers.groupArrWithRange(data.attributes.comments, currState.commentPage.rangePageComment).length,
          },
        })
      }, () => {
        if (Helpers.isImage(this.state.feedDetail.attributes.images[0].url)) {
          this.imageReady(slug)
        } else {
          this.videoReady(slug)
        }
      })
    })
  }

  videoReady = (slug) => {
    Helpers.addVideoProcess(this.state.feedDetail.attributes.images[0].url).then((video) => {
      if (video.height >= 570) {
        this.setState({
          feedDetailLoading: false,
        }, () => {
          if(this.state.feedDetail.attributes.loading_color === null){
            setTimeout(async () => {
              const canvas = document.getElementById('thecanvas')
              const thevideo = document.getElementById('video-thumb')
              thevideo.pause()
              thevideo.currentTime = 0
              canvas.width = video.width
              canvas.height = video.height
              canvas.getContext('2d').drawImage(thevideo, 0, 0, video.width, video.height)
              const url = canvas.toDataURL()
              const img = document.getElementById('img-preview')
              img.crossOrigin = 'anonymous'
              img.src = url
              Helpers.addImageProcess(url).then((image) => {
                const colorThief = new ColorThief()
                const dominantColor = colorThief.getColor(img)
                const loadingColor = Helpers.rgbToHex(dominantColor[0], dominantColor[1], dominantColor[2])
                const data = Model.designViewsParams({loadingColor})
                ApiDesigns.show(slug, data)
                thevideo.play()
              })
            }, 3000)
          }
          if (this.props.viewComment) {
            setTimeout(() => {
              this.handleScrollToComment(this.props.viewComment)
            }, 500)
          }
        })
      }
    })
  }

  imageReady = (slug) => {
    Helpers.addImageProcess(this.state.feedDetail.attributes.images[0].url).then((image) => {
      if (image.height >= 570) {
        this.setState({
          feedDetailLoading: false,
        }, () => {
          if(this.state.feedDetail.attributes.loading_color === null){
            const img = document.getElementById('img-preview')
            img.src = this.state.feedDetail.attributes.images[0].url
            img.crossOrigin = 'anonymous'
            Helpers.addImageProcess(img.src).then((image) => {
              if (image.height >= 570) {
                const colorThief = new ColorThief()
                const dominantColor = colorThief.getColor(img)
                const loadingColor = Helpers.rgbToHex(dominantColor[0], dominantColor[1], dominantColor[2])
                const data = Model.designViewsParams({loadingColor})
                ApiDesigns.show(slug, data)
              }
            })
          }
          if (this.props.viewComment) {
            setTimeout(() => {
              this.handleScrollToComment(this.props.viewComment)
            }, 500)
          }
        })
      }
    })
  }

  getCaretPosition = (position) => {
    let start,
      end
    if (position.setSelectionRange) {
      start = position.selectionStart
      end = position.selectionEnd
    } else if (document.selection && document.selection.createRange) {
      const range = document.selection.createRange()
      start = 0 - range.duplicate().moveStart('character', -100000)
      end = start + range.text.length
    }

    return {
      start,
      end,
    }
  }

  getMention = (value, cursor) => {
    let endPos = value.indexOf(' ', cursor.end)
    if (endPos === -1) {
      endPos = value.length
    }

    const result = /\S+$/.exec(value.slice(0, endPos))
    let lastWord = result ? result[0] : null
    if (lastWord) {
      lastWord = lastWord.replace(/['";:,.\/?\\-]$/, '')
    }

    const mention = (lastWord && lastWord.indexOf('@') == 0) ? lastWord : ''

    return mention
  }

  getHashtag = (value, cursor) => {
    let endPos = value.indexOf(' ', cursor.end)
    if (endPos === -1) {
      endPos = value.length
    }

    const result = /\S+$/.exec(value.slice(0, endPos))
    let lastWord = result ? result[0] : null
    if (lastWord) {
      lastWord = lastWord.replace(/['";:,.\/?\\-]$/, '')
    }

    const hashtag = (lastWord && lastWord.indexOf('#') == 0) ? lastWord : ''

    return hashtag
  }

  handleSetStateInputEditComment = (type, id) => (e) => {
    const { value } = e.target

    const cursor = this.getCaretPosition(e.target)
    const mention = this.getMention(value, cursor)
    const hashtag = this.getHashtag(value, cursor)

    if (mention.includes('@') && mention !== '' && mention.substr(1).length > 0) {
      ApiUsers.search(mention.substr(1)).then(({ data }) => {
        this.setState(currState => ({
          commentPage: {
            ...currState.commentPage,
            edited: currState.commentPage.edited.map((any) => {
              if (any.id === id) {
                return {
                  ...any,
                  resultUserData: data,
                  resultTagData: [],
                }
              }
              return { ...any }
            }),
          },
        }))
      })
    } else if (hashtag.includes('#') && hashtag !== '' && hashtag.substr(1).length > 0) {
      ApiTags.search(hashtag.substr(1)).then(({ data }) => {
        this.setState(currState => ({
          commentPage: {
            ...currState.commentPage,
            edited: currState.commentPage.edited.map((any) => {
              if (any.id === id) {
                return {
                  ...any,
                  resultTagData: data,
                  resultUserData: [],
                }
              }
              return { ...any }
            }),
          },
        }))
      })
    } else {
      this.setState(currState => ({
        commentPage: {
          ...currState.commentPage,
          edited: currState.commentPage.edited.map((any) => {
            if (any.id === id) {
              return {
                ...any,
                resultTagData: [],
                resultUserData: [],
              }
            }
            return { ...any }
          }),
        },
      }))
    }

    this.setState(currState => ({
      commentPage: {
        ...currState.commentPage,
        edited: currState.commentPage.edited.map((any) => {
          if (any.id === id) {
            return { ...any, [type]: value }
          }
          return { ...any }
        }),
      },
    }))
  }

  handleSetStateEditComment = (type, value, id) => (e) => {
    const { commentPage } = this.state
    const newEdited = commentPage.edited.map((any) => {
      if (any.id === id) {
        return { ...any, [type]: value }
      }
      return { ...any }
    })

    this.setState(currState => ({
      commentPage: functions.createStateObj(currState.commentPage, 'edited', newEdited),
    }))
  }

  handleSetStateInput = type => (e) => {
    const { comment, commentTyping, replyCommentContent } = this.state
    const { value } = e.target

    const cursor = this.getCaretPosition(e.target)
    const mention = this.getMention(value, cursor)
    const hashtag = this.getHashtag(value, cursor)

    if (mention.includes('@') && mention !== '' && mention.substr(1).length > 0) {
      ApiUsers.search(mention.substr(1)).then(({ data }) => {
        this.setState({ resultUserData: data, resultTagData: [] })
      })
    } else if (hashtag.includes('#') && hashtag !== '' && hashtag.substr(1).length > 0) {
      ApiTags.search(hashtag.substr(1)).then(({ data }) => {
        this.setState({ resultTagData: data, resultUserData: [] })
      })
    } else {
      this.setState({ resultUserData: [], resultTagData: [] })
    }

    this.setState({ [type]: value })
  }

  handleSetState = (type, value) => () => {
    this.setState({ [type]: value })
  }

  handleSetNestedState = (type, subtype, value) => () => {
    this.setState(currState => ({
      [type]: functions.createStateObj(currState[type], subtype, value),
    }))
  }

  handleMention = username => () => {
    const { state: { comment }, getMention } = this
    const cursor = this.getCaretPosition(document.getElementsByTagName('textarea')[0])
    const mention = getMention(comment, cursor)
    const newComment = comment.split(' ').map((any, index, array) => {
      if (!any.includes('\n') && any === mention) {
        const atSignPosition = any.indexOf('@')
        return (index == array.length - 1) ? `@${any.substr(0, atSignPosition)}${username} ` : `@${any.substr(0, atSignPosition)}${username}`
      } if (any.includes('\n') && any.includes('@')) {
        return any.split('\n').map((any) => {
          if (any === mention) {
            return `@${username} `
          }
          return any
        }).join('\n')
      }
      return any
    }).join(' ')

    this.setState({ comment: newComment, resultUserData: [], resultTagData: [] }, () => {
      document.getElementsByTagName('textarea')[0].focus()
    })
  }

  handleMentionComment = (username, id) => () => {
    const { state: { commentPage }, getMention } = this
    const { comment } = commentPage.edited.find(any => any.id === id)
    const cursor = this.getCaretPosition(document.getElementById(id))
    const mention = getMention(comment, cursor)
    const newComment = comment.split(' ').map((any, index, array) => {
      if (!any.includes('\n') && any === mention) {
        const atSignPosition = any.indexOf('@')
        return (index == array.length - 1) ? `@${any.substr(0, atSignPosition)}${username} ` : `@${any.substr(0, atSignPosition)}${username}`
      } if (any.includes('\n') && any.includes('@')) {
        return any.split('\n').map((any) => {
          if (any === mention) {
            return `@${username} `
          }
          return any
        }).join('\n')
      }
      return any
    }).join(' ')
    this.setState(currState => ({
      commentPage: {
        ...currState.commentPage,
        edited: currState.commentPage.edited.map((any) => {
          if (any.id === id) {
            return {
              ...any, comment: newComment, resultUserData: [], resultTagData: [],
            }
          }
          return { ...any }
        }),
      },
    }), () => document.getElementById(id).focus())
  }

  handleHashtag = hashtag => () => {
    const { state: { comment }, getHashtag } = this
    const cursor = this.getCaretPosition(document.getElementsByTagName('textarea')[0])
    const hashtagValue = getHashtag(comment, cursor)
    const newComment = comment.split(' ').map((any, index, array) => {
      if (!any.includes('\n') && any === hashtagValue) {
        const atSignPosition = any.indexOf('#')
        return (index == array.length - 1) ? `#${any.substr(0, atSignPosition)}${hashtag} ` : `#${any.substr(0, atSignPosition)}${hashtag}`
      } if (any.includes('\n') && any.includes('#')) {
        return any.split('\n').map((any) => {
          if (any === hashtagValue) {
            return `#${hashtag} `
          }
          return any
        }).join('\n')
      }
      return any
    }).join(' ')

    this.setState({ comment: newComment, resultUserData: [], resultTagData: [] }, () => {
      document.getElementsByTagName('textarea')[0].focus()
    })
  }

  handleHashtagComment = (hashtag, id) => () => {
    const { state: { commentPage }, getHashtag } = this
    const { comment } = commentPage.edited.find(any => any.id === id)
    const cursor = this.getCaretPosition(document.getElementById(id))
    const hashtagValue = getHashtag(comment, cursor)
    const newComment = comment.split(' ').map((any, index, array) => {
      if (!any.includes('\n') && any === hashtagValue) {
        const atSignPosition = any.indexOf('#')
        return (index == array.length - 1) ? `#${any.substr(0, atSignPosition)}${hashtag} ` : `#${any.substr(0, atSignPosition)}${hashtag}`
      } if (any.includes('\n') && any.includes('#')) {
        return any.split('\n').map((any) => {
          if (any === hashtagValue) {
            return `#${hashtag} `
          }
          return any
        }).join('\n')
      }
      return any
    }).join(' ')

    this.setState(currState => ({
      commentPage: {
        ...currState.commentPage,
        edited: currState.commentPage.edited.map((any) => {
          if (any.id === id) {
            return {
              ...any, comment: newComment, resultUserData: [], resultTagData: [],
            }
          }
          return { ...any }
        }),
      },
    }), () => document.getElementById(id).focus())
  }

  submitComment = () => {
    const { state, props } = this
    const slug = props.slug
    const designId = slug.split('-')[0]
    const objectToSend = {
      comment: state.comment,
      userId: props.state.user.info.attributes.id.toString(),
      designId,
      postedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
      uuid: uuid.v4(),
      name: props.state.user.info.attributes.name,
      username: props.state.user.info.attributes.username,
      url: (props.state.user.info.attributes.avatar.url === null || props.state.user.info.attributes.avatar.url === '') ? placeholderImage : props.state.user.info.attributes.avatar.url,
      designUserId: state.feedDetail.attributes.user.attributes.id,
      designUsername: state.feedDetail.attributes.user.attributes.username,
      ...this.state,
    }

    const commentState = Store.commentState(objectToSend)

    this.setState((currState) => {
      const allComment = [].concat.apply([], currState.commentPage.comments)
      return ({
        commentPage: {
          ...currState.commentPage,
          activePageComment: Helpers.groupArrWithRange(allComment.concat(commentState), currState.commentPage.rangePageComment).length,
          comments: Helpers.groupArrWithRange(allComment.concat(commentState), currState.commentPage.rangePageComment),
          totalComment: allComment.concat(commentState).length,
          totalPageComment: Helpers.groupArrWithRange(allComment.concat(commentState), currState.commentPage.rangePageComment).length,
        },
        comment: '',
        resultUserData: [],
      })
    })

    const data = Model.commentParams(objectToSend)
    ApiComments.create(data).then(({ data }) => {
      this.setState((currState) => {
        const allComment = [].concat.apply([], currState.commentPage.comments)
        const newComments = allComment.map((any) => {
          if (any.attributes.uuid === data.attributes.uuid) {
            return { ...data }
          }
          return { ...any }
        })
        return ({
          commentPage: {
            ...currState.commentPage,
            activePageComment: Helpers.groupArrWithRange(newComments, currState.commentPage.rangePageComment).length,
            comments: Helpers.groupArrWithRange(newComments, currState.commentPage.rangePageComment),
            totalComment: newComments.length,
            totalPageComment: Helpers.groupArrWithRange(newComments, currState.commentPage.rangePageComment).length,
          },
        })
      })
    })
  }

  updateComment = id => () => {
    const { state, props } = this
    const { comment } = state.commentPage.edited.find(any => any.id === id)
    const data = Model.commentUpdateParams({
      comment,
      userId: props.state.user.info.attributes.id.toString(),
      designUserId: state.feedDetail.attributes.user.attributes.id,
      designUsername: state.feedDetail.attributes.user.attributes.username,
    })
    ApiComments.update(id, data).then(({ data }) => {
      this.setState((currState) => {
        const allComment = [].concat.apply([], currState.commentPage.comments)
        const newComments = allComment.map((any) => {
          if (any.attributes.uuid === data.attributes.uuid) {
            return { ...data }
          }
          return { ...any }
        })
        return ({
          commentPage: {
            ...currState.commentPage,
            edited: currState.commentPage.edited.filter(any => any.id !== data.id),
            activePageComment: Helpers.groupArrWithRange(newComments, currState.commentPage.rangePageComment).length,
            comments: Helpers.groupArrWithRange(newComments, currState.commentPage.rangePageComment),
            totalComment: newComments.length,
            totalPageComment: Helpers.groupArrWithRange(newComments, currState.commentPage.rangePageComment).length,
          },
        })
      })
    })
  }

  handleScrollToComment = (id) => {
    document.getElementById(id).scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  submitSubscribe = subscribe => () => {
    const objectToSend = {
      userId: this.state.feedDetail.attributes.user.attributes.id,
    }
    const data = Model.subscriptionParams(objectToSend)

    if (subscribe) {
      ApiSubscriptions.unsubscribe(data).then(({ data }) => {
        this.setState(currState => ({
          feedDetail: {
            ...currState.feedDetail,
            attributes: {
              ...currState.feedDetail.attributes,
              subscribed: data.attributes.subscribed,
              user: {
                ...currState.feedDetail.attributes.user,
                attributes: { ...currState.feedDetail.attributes.user.attributes, subscribed: data.attributes.subscribed },
              },
            },
          },
        }))
      })
    } else {
      ApiSubscriptions.subscribe(data).then(({ data }) => {
        this.setState(currState => ({
          feedDetail: {
            ...currState.feedDetail,
            attributes: {
              ...currState.feedDetail.attributes,
              subscribed: data.attributes.subscribed,
              user: {
                ...currState.feedDetail.attributes.user,
                attributes: { ...currState.feedDetail.attributes.user.attributes, subscribed: data.attributes.subscribed },
              },
            },
          },
        }))
      })
    }
  }

  submitLike = like => () => {
    const slug = this.props.slug
    const designId = slug.split('-')[0]
    const objectToSend = {
      userId: this.props.state.user.info.attributes.id.toString(),
      designId,
    }

    const data = Model.likeParams(objectToSend)
    if (like) {
      ApiLikes.unlike(data).then(({ data }) => {
        this.setState(currState => ({
          feedDetail: {
            ...currState.feedDetail,
            attributes: {
              ...currState.feedDetail.attributes,
              liked: data.attributes.design.attributes.liked,
              likes: data.attributes.design.attributes.likes,
            },
          },
        }))
      })
    } else {
      ApiLikes.like(data).then(({ data }) => {
        this.setState(currState => ({
          feedDetail: {
            ...currState.feedDetail,
            attributes: {
              ...currState.feedDetail.attributes,
              liked: data.attributes.design.attributes.liked,
              likes: data.attributes.design.attributes.likes,
            },
          },
        }))
      })
    }
  }

  submitLikeComment = (like, commentId) => () => {
    const objectToSend = {
      userId: this.props.state.user.info.attributes.id.toString(),
      commentId,
    }

    const { commentPage } = this.state

    const data = Model.likeCommentParams(objectToSend)
    if (like) {
      ApiLikeComments.unlike(data).then(({ data }) => {
        const activePagecommentArr = commentPage.comments[commentPage.activePageComment - 1]
        const newActivePagecommentArr = activePagecommentArr.map((any) => {
          if (any.id === commentId) {
            return {
              ...any,
              attributes: {
                ...any.attributes,
                liked_comment: data.attributes.liked_comment,
                likes_comment: data.attributes.likes_comment,
              },
            }
          }
          return { ...any }
        })
        const index = commentPage.activePageComment - 1
        const newComments = commentPage.comments.map((any, i) => {
          if (index === i) {
            return newActivePagecommentArr
          }
          return any
        })

        this.setState(currState => ({
          commentPage: {
            ...currState.commentPage,
            comments: newComments,
          },
        }))
      })
    } else {
      ApiLikeComments.like(data).then(({ data }) => {
        const activePagecommentArr = commentPage.comments[commentPage.activePageComment - 1]
        const newActivePagecommentArr = activePagecommentArr.map((any) => {
          if (any.id === commentId) {
            return {
              ...any,
              attributes: {
                ...any.attributes,
                liked_comment: data.attributes.liked_comment,
                likes_comment: data.attributes.likes_comment,
              },
            }
          }
          return { ...any }
        })
        const index = commentPage.activePageComment - 1
        const newComments = commentPage.comments.map((any, i) => {
          if (index === i) {
            return newActivePagecommentArr
          }
          return any
        })

        this.setState(currState => ({
          commentPage: {
            ...currState.commentPage,
            comments: newComments,
          },
        }))
      })
    }
  }

  submitBookmark = bookmark => () => {
    const slug = this.props.slug
    const designId = slug.split('-')[0]
    const objectToSend = {
      userId: this.props.state.user.info.attributes.id.toString(),
      designId,
    }

    const data = Model.bookmarkParams(objectToSend)
    if (bookmark) {
      ApiBookmarks.unbookmark(data).then(({ data }) => {
        this.setState(currState => ({
          feedDetail: {
            ...currState.feedDetail,
            attributes: {
              ...currState.feedDetail.attributes,
              bookmarked: data.attributes.design.attributes.bookmarked,
              bookmarks: data.attributes.design.attributes.bookmarks,
            },
          },
        }))
      })
    } else {
      ApiBookmarks.bookmark(data).then(({ data }) => {
        this.setState(currState => ({
          feedDetail: {
            ...currState.feedDetail,
            attributes: {
              ...currState.feedDetail.attributes,
              bookmarked: data.attributes.design.attributes.bookmarked,
              bookmarks: data.attributes.design.attributes.bookmarks,
            },
          },
        }))
      })
    }
  }

  // id stand for userId here
  handleReply = (id, username) => () => {
    const { state: { replyComments, comment } } = this
    const dataComment = { id, username }
    const isExist = replyComments.filter(any => any.username === username).length > 0
    const newReplyComment = isExist ? replyComments : replyComments.concat(dataComment)
    const newComment = (isExist && comment.includes(`@${username}`)) ? comment : (/\s/.test(comment[comment.length - 1]) || comment.length === 0) ? `${comment}@${username} ` : `${comment} @${username} `
    this.setState({
      replyComments: newReplyComment,
      comment: newComment,
      replyCommentContent: newComment,
    }, () => {
      document.getElementsByTagName('textarea')[0].focus()
    })
  }

  handleDeleteComment = id => () => {
    ApiComments.destroy(id).then(({ data }) => {
      this.setState((currState) => {
        const allComment = [].concat.apply([], currState.commentPage.comments)
        const newComments = allComment.filter(any => any.attributes.uuid !== data.attributes.uuid)
        return ({
          commentPage: {
            ...currState.commentPage,
            edited: currState.commentPage.edited.filter(any => any.id !== data.id),
            activePageComment: Helpers.groupArrWithRange(newComments, currState.commentPage.rangePageComment).length,
            comments: Helpers.groupArrWithRange(newComments, currState.commentPage.rangePageComment),
            totalComment: newComments.length,
            totalPageComment: Helpers.groupArrWithRange(newComments, currState.commentPage.rangePageComment).length,
          },
        })
      })
    })
  }

  renderContent = (feedDetailLoading, feedDetailLoadingDescription, feedDetail, info, state, stateProps, page) => {
    const { comment, commentError, commentPage } = state

    const {
      handleSetStateInput,
      handleSetState,
      handleMention,
      handleMentionComment,
      handleHashtag,
      handleHashtagComment,
      handleSetNestedState,
      handleReply,
      handleSetStateInputEditComment,
      handleSetStateEditComment,
      submitComment,
      submitLike,
      submitLikeComment,
      submitBookmark,
      updateComment,
      handleDeleteComment,
      props: {
        closeModal,
      },
    } = this

    const comments = [].concat.apply([], commentPage.comments)

    // console.log(this.state)

    return (
      <Fragment>
        {feedDetailLoading && (<div className="image-detail" />)}
        {!feedDetailLoading && (
        <CarouselThumb
          feedDetail={feedDetail}
        />
        )}
        {feedDetailLoadingDescription && (
          <div className={Helpers.mergeCss(Base.row, Styles.description)}>
            <div className={Helpers.mergeCss(Base.col, Base.w2)} />
            <div className={Helpers.mergeCss(Base.col, Base.w10, Styles.leftDescription)}>
              <div className="description-detail">
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>
        )
        }
        {!feedDetailLoadingDescription
          && (
            <div className={Helpers.mergeCss(Base.row, Styles.description)}>
              <div className={Helpers.mergeCss(Base.col, Base.w12)}>
                <div className={Base.row}>
                  <div className={Helpers.mergeCss(Base.col, Base.w2, Styles.w2)} />
                  <div className={Helpers.mergeCss(Base.col, Base.w10, Styles.w10)}>
                    <div className={Helpers.mergeCss(Base.dInlineBlock, Base.marginBottom3)}>
                      {feedDetail.attributes.tags.length > 0
                        && feedDetail.attributes.tags.map(any => (
                          <Link
                            to={`/search/${any.slug}?tab=trends`}
                            style={{
                              fontWeight: 'bold',
                              textDecoration: 'none',
                              fontSize: '14px',
                              marginRight: '8px',
                              background: 'rgba(17, 129, 235, 0.1)',
                              padding: '2px 12px',
                              borderRadius: '30px',
                              marginBottom: '8px',
                              display: 'inline-block',
                            }}
                            key={any.slug}
                          >
                            #
                            {any.name}
                          </Link>
                        ))
                      }
                      <div className={Helpers.mergeCss(Base.dBlock, Base.marginTop)}>
                        {
                          feedDetail.attributes.colors.map(any => <span key={any.code} className={Helpers.mergeCss(Styles.baseColor(`#${any.code}`), Base.dInlineBlock)} />)
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Base.row}>
                  <div className={Helpers.mergeCss(Base.col, Base.w2, Styles.w2)}>
                    <div className={Helpers.mergeCss(Base.dFlex, Base.justifyContentEnd)}>
                      <ul className={Helpers.mergeCss(Base.ulUnstyled, Styles.actionPlace)}>
                        <li>
                          <div className={Helpers.mergeCss(Base.dInlineFlex, Base.alignItemsCenter, Styles.liveActive(feedDetail.attributes.liked))}>
                            <p onClick={submitLike(feedDetail.attributes.liked)}>
                              <i className="la la-thumbs-up" />
                            </p>
                            <span className={Helpers.mergeCss(Base.marginLeft2, Base.text)}>
                              {feedDetail.attributes.likes}
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className={Helpers.mergeCss(Base.dInlineFlex, Base.alignItemsCenter, Styles.liveActive(feedDetail.attributes.bookmarked))}>
                            <p onClick={submitBookmark(feedDetail.attributes.bookmarked)}>
                              <i className="la la-bookmark" />
                            </p>
                            <span className={Helpers.mergeCss(Base.marginLeft2, Base.text)}>
                              {feedDetail.attributes.bookmarks}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={Helpers.mergeCss(Base.col, Base.w10, Styles.w10, Styles.leftDescription)}>
                    <h5 className={Base.margin0}>{feedDetail.attributes.title}</h5>
                    <span className={Helpers.mergeCss(Base.textMuted, Base.dFlex, Base.alignItemsCenter)}>
                      by&nbsp;
                      <Link to={`/${feedDetail.attributes.user.attributes.username}`}>{feedDetail.attributes.user.attributes.name}</Link>
                      {feedDetail.attributes.team !== null && feedDetail.attributes.user.attributes.type_user === 'user'
                        && (
                        <Fragment>
                          &nbsp;for&nbsp;
                          <Link to={`/${feedDetail.attributes.team.attributes.username}`}>
                            {feedDetail.attributes.team.attributes.name}
                          </Link>
                        </Fragment>
                        )
                      }
                      <span style={{ marginLeft: '5px', marginRight: '5px' }}>•</span>
                      {feedDetail.attributes.views}
                      {' '}
                      views
                      <span style={{ marginLeft: '5px', marginRight: '5px' }}>•</span>
                      {moment(feedDetail.attributes.created_at).format('MMM DD, YYYY')}
                    </span>
                    <span className={Helpers.mergeCss(Base.text, Base.marginTop3, Base.dBlock)}>
                      {ReactHtmlParser(feedDetail.attributes.description, newOptions)}
                    </span>
                    <hr />
                    <div style={{marginBottom: '20px'}} className={Helpers.mergeCss(Base.dInlineFlex, Styles.socialMedia)}>
                      Share
                      <ul className={Helpers.mergeCss(Base.ulInline, Base.marginLeft3)}>
                        <li>
                          <TwitterShareButton url={window.location.href}>
                            <i className="fab fa-twitter" />
                          </TwitterShareButton>
                        </li>
                        <li>
                          <FacebookShareButton url={window.location.href}>
                            <i className="fab fa-facebook-square" />
                          </FacebookShareButton>
                        </li>
                        <li>
                          <LinkedinShareButton url={window.location.href}>
                            <i className="fab fa-linkedin" />
                          </LinkedinShareButton>
                        </li>
                        <li>
                          <EmailShareButton url={window.location.href}>
                            <i className="fas fa-envelope-open-text" />
                          </EmailShareButton>
                        </li>
                      </ul>
                    </div>
                    <div className={Base.dBlock}>
                      <AdsenseMobile/>
                    </div>
                    {!feedDetail.attributes.comment_disable && comments.length > 0 && (
                      <Fragment>
                        <div className={Helpers.mergeCss(Base.marginBottom3, comments.length > 0 ? Base.dFlex : Base.marginTop3, Base.row)}>
                          <div className={Helpers.mergeCss(Base.col, Base.w7, Styles.w7)}>
                            <b>
                              {commentPage.comments.length > 0 && `${commentPage.totalComment} Responses`}
                            </b>
                            <span
                              className={Base.textMuted}
                              style={{ fontSize: '12px', marginLeft: '8px', marginRight: '8px' }}
                            >
                              {commentPage.comments.length > 1 && (<Fragment>&bull;</Fragment>)}
                            </span>
                            <span className={Base.text}>
                              {commentPage.comments.length > 1 && `Page ${commentPage.activePageComment} of ${commentPage.totalPageComment}`}
                            </span>
                          </div>
                          <div className={Helpers.mergeCss(Base.col, Base.textRight, Base.w5, Styles.w5)}>
                            {commentPage.comments.length > 1
                            && (
                            <div className={Helpers.mergeCss(Base.dBlock, Base.textRight)}>
                              <span
                                onClick={
                                commentPage.comments.length - (commentPage.activePageComment - 1) !== commentPage.totalPageComment
                                  ? handleSetNestedState('commentPage', 'activePageComment', (commentPage.activePageComment - 1)) : null
                                }
                                className={Helpers.mergeCss(Base.text, Styles.buttonCommentPage)}
                              >
                                <i className="las la-angle-left mr-1" />
                                <b className={Styles.prev} style={{ fontWeight: 'normal' }}>
                                  Prev
                                </b>
                              </span>
                              <span
                                className={Base.textMuted}
                                style={{ fontSize: '12px', marginLeft: '8px', marginRight: '8px' }}
                              >
                                &bull;
                              </span>
                              <span
                                onClick={
                                commentPage.comments.length - commentPage.activePageComment !== 0
                                  ? handleSetNestedState('commentPage', 'activePageComment', (commentPage.activePageComment + 1)) : null
                                }
                                className={Helpers.mergeCss(Base.text, Styles.buttonCommentPage)}
                              >
                                <b className={Styles.next} style={{ fontWeight: 'normal' }}>
                                  Next
                                </b>
                                <i className="las la-angle-right ml-1" />
                              </span>
                            </div>
                            )
                          }
                          </div>
                        </div>
                        <div className={Styles.commentsWrapper}>
                          {commentPage.comments[commentPage.activePageComment - 1].map((any) => {
                            const humanize_date = any.id === ''
                              ? moment(any.attributes.posted_at, 'YYYY-MM-DD HH:mm:ss')
                              : moment(any.attributes.posted_at.split('T').join(' '), 'YYYY-MM-DD HH:mm:ss')

                            const newEdited = {
                              id: any.id,
                              comment: any.attributes.original_content,
                              commentTyping: any.attributes.original_content,
                              commentError: '',
                              resultUserData: [],
                              resultTagData: [],
                              commentFocus: false,
                              replyComments: [],
                              replyCommentContent: '',
                            }

                            const edited = commentPage.edited.filter(item => item.id === any.id).length > 0 ? commentPage.edited : commentPage.edited.concat(newEdited)
                            const deletedConfirm = commentPage.deletedConfirm.filter(item => item === any.id).length > 0 ? commentPage.deletedConfirm : commentPage.deletedConfirm.concat(any.id)
                            const activeEditState = commentPage.edited.length > 0 && commentPage.edited.find(yolo => yolo.id === any.id)
                            const cancelEdited = commentPage.edited.length > 0 ? commentPage.edited.filter(yolo => yolo.id !== any.id) : []
                            return (
                              <div id={any.attributes.uuid} key={any.attributes.uuid} className={Helpers.mergeCss(Styles.commentsWrapperDetail(any.id === ''), Base.dBlock)}>
                                <div className={Helpers.mergeCss(Base.dFlex, Base.alignItemsStart)}>
                                  {this.props.state.activeProfile.username === any.attributes.user.username === 'portfolio'
                                    ? (
                                      <div style={{ cursor: 'pointer' }} onClick={closeModal}>
                                        <img src={(any.attributes.user.avatar.url === null || any.attributes.user.avatar.url === '') ? placeholderImage : any.attributes.user.avatar.url} />
                                      </div>
                                    )
                                    : (
                                      <Link to={`/${any.attributes.user.username}`}>
                                        <img src={(any.attributes.user.avatar.url === null || any.attributes.user.avatar.url === '') ? placeholderImage : any.attributes.user.avatar.url} />
                                      </Link>
                                    )
                                }
                                  {this.props.state.activeProfile.username === any.attributes.user.username && page === 'portfolio'
                                    ? (
                                      <div style={{ marginLeft: '10px', width: '100%' }} className={Helpers.mergeCss(Base.dBlock, Base.marginBottom2, Base.positionRelative)}>
                                        <div className={Styles.linkUsername} style={{ cursor: 'pointer' }} onClick={closeModal}>
                                          <span>
                                            <b>
                                              {any.attributes.user.name}
                                            </b>
                                            <span className={Helpers.mergeCss(Base.marginLeft2, Base.textMuted)}>
                                              {`${any.attributes.user.username}`}
                                              {any.attributes.user.verified && (
                                              <i
                                                style={{
                                                  marginLeft: '4px', fontSize: '12px', position: 'relative', top: '-0.1px', color: '#1181eb',
                                                }}
                                                className="fa fa-check-circle"
                                              />
                                              )}
                                            </span>
                                            {feedDetail.attributes.user.attributes.username === any.attributes.user.username
                                          && (<span className={Styles.designer}>designer</span>)}
                                          </span>
                                        </div>
                                        <span className={Base.dBlock}>
                                          {ReactHtmlParser(any.attributes.content, newOptions)}
                                        </span>
                                      </div>
                                    )
                                    : (
                                      <div style={{ marginLeft: '10px', width: '100%' }} className={Helpers.mergeCss(Base.dBlock, Base.marginBottom2, Base.positionRelative)}>
                                        <Link className={Styles.linkUsername} to={`/${any.attributes.user.username}`}>
                                          <span>
                                            <b>
                                              {any.attributes.user.name}
                                            </b>
                                            <span className={Helpers.mergeCss(Base.marginLeft2, Base.textMuted)}>
                                              {`${any.attributes.user.username}`}
                                              {any.attributes.user.verified && (
                                              <i
                                                style={{
                                                  marginLeft: '4px', fontSize: '12px', position: 'relative', top: '-0.1px', color: '#1181eb',
                                                }}
                                                className="fa fa-check-circle"
                                              />
                                              )}
                                            </span>
                                            {feedDetail.attributes.user.attributes.username === any.attributes.user.username
                                          && (<span className={Styles.designer}>designer</span>)}
                                          </span>
                                        </Link>
                                        { this.props.state.user.info !== null
                                        && this.props.state.user.info.attributes.username === any.attributes.user.username
                                        && !(commentPage.edited.filter(item => item.id === any.id).length > 0)
                                        && (commentPage.deletedConfirm.filter(item => item === any.id).length > 0
                                          ? (
                                            <div
                                              className={Base.dInlineBlock}
                                              style={{
                                                fontSize: '14px',
                                                position: 'absolute',
                                                right: 0,
                                                color: '#6c757d',
                                              }}
                                            >
                                              Delete comment?
                                              <div className={Helpers.mergeCss(Base.dInlineBlock, Base.marginLeft2)}>
                                                <span onClick={(handleDeleteComment(any.id))} style={{ marginRight: '3px', cursor: 'pointer' }}>Yes</span>
                                                <span onClick={handleSetNestedState('commentPage', 'deletedConfirm', commentPage.deletedConfirm.filter(item => item !== any.id))} style={{ marginLeft: '3px', cursor: 'pointer' }}>No</span>
                                              </div>
                                            </div>
                                          )
                                          : (<span onClick={handleSetNestedState('commentPage', 'deletedConfirm', deletedConfirm)} className={Styles.deleteComment}><i className="la la-trash" /></span>))
                                      }
                                        {
                                        commentPage.edited.length > 0 && commentPage.edited.filter(item => item.id == any.id).length > 0
                                          ? (
                                            <div className={Helpers.mergeCss(Base.dBlock)}>
                                              <div className={Styles.positionRelative}>
                                                {
                                            activeEditState.resultUserData.length > 0 && activeEditState.commentFocus
                                            && (
                                            <div className={Styles.userMention(true)}>
                                              <ul className={Base.ulUnstyled}>
                                                {activeEditState.resultUserData.map(item => (
                                                  <li onClick={handleMentionComment(item.username, any.id)} key={item.username}>
                                                    <div className={Base.dFlex}>
                                                      <img src={(item.avatar.url === null || item.avatar.url === '') ? placeholderImage : item.avatar.url} />
                                                      <b className={Base.marginRight}>
                                                        {item.name}
                                                      </b>
                                                      <span>
                                                        {`${item.username}`}
                                                      </span>
                                                    </div>
                                                  </li>
                                                )).slice(0, 5)
                                                }
                                              </ul>
                                            </div>
                                            )
                                          }
                                                {
                                            activeEditState.resultTagData.length > 0 && activeEditState.commentFocus
                                            && (
                                            <div className={Styles.userHashtag(true)}>
                                              <ul className={Base.ulUnstyled}>
                                                {activeEditState.resultTagData.map(item => (
                                                  <li onClick={handleHashtagComment(item.tag.name, any.id)} key={item.tag.name}>
                                                    <div className={Base.dFlex}>
                                                      <b className={Base.marginRight}>
                                                        #
                                                        {item.tag.name}
                                                      </b>
                                                    </div>
                                                    <span className={Base.text}>
                                                      {item.count}
                                                      {' '}
                                                      designs
                                                    </span>
                                                  </li>
                                                )).slice(0, 5)
                                                }
                                              </ul>
                                            </div>
                                            )
                                          }
                                                <Input
                                                  type="textarea"
                                                  title=""
                                                  id={any.id}
                                                  value={activeEditState.comment}
                                                  error={activeEditState.commentError}
                                                  className={Styles.activeTextarea(activeEditState.resultUserData.length > 0)}
                                                  onFocus={handleSetStateEditComment('commentFocus', true, any.id)}
                                                  onChange={handleSetStateInputEditComment('comment', any.id)}
                                                  onKeyUp={handleSetStateInputEditComment('commentTyping', any.id)}
                                                />
                                                <div className={Helpers.mergeCss(Base.dBlock, Styles.btnWrapper, Base.textRight)}>
                                                  <button style={{ borderRadius: '3px', marginRight: '15px' }} onClick={handleSetNestedState('commentPage', 'edited', cancelEdited)} className={Base.btnMarvlousCancel}>Cancel</button>
                                                  <button style={{ borderRadius: '3px' }} onClick={updateComment(any.id)} className={Base.btnMarvlous}>Finish</button>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                          : (
                                            <span className={Base.dBlock}>
                                              {ReactHtmlParser(any.attributes.content, newOptions)}
                                            </span>
                                          )
                                      }
                                      </div>
                                    )
                                  }
                                </div>
                                {
                                  commentPage.edited.length > 0 && commentPage.edited.filter(item => item.id == any.id).length > 0
                                    ? null
                                    : (
                                      <div className={Helpers.mergeCss(Base.dBlock, Styles.contentPostCommment)}>
                                        <div className={Helpers.mergeCss(Base.dInlineFlex, Base.alignItemsCenter)}>
                                          {any.attributes.liked_comment
                                            ? (
                                              <div
                                                className={Helpers.mergeCss(Base.dInlineFlex, Base.alignItemsCenter)}
                                                style={{ cursor: 'pointer', color: '#292929' }}
                                                onClick={submitLikeComment(any.attributes.liked_comment, any.id)}
                                              >
                                                <i
                                                  style={{
                                                    color: '#292929',
                                                    cursor: 'pointer',
                                                  }}
                                                  className="la la-thumbs-up"
                                                />
                                                {
                                            any.attributes.likes_comment ? (
                                              <span className={Base.marginLeft} style={{ fontSize: '14px' }}>
                                                {any.attributes.likes_comment}
                                              </span>
                                            )
                                              : null
                                          }
                                              </div>
                                            )
                                            : (
                                              <div
                                                className={Helpers.mergeCss(Base.dInlineFlex, Base.alignItemsCenter)}
                                                style={{ cursor: 'pointer' }}
                                                onClick={submitLikeComment(any.attributes.liked_comment, any.id)}
                                              >
                                                <i
                                                  style={{
                                                    color: '#6c757d',
                                                    cursor: 'pointer',
                                                  }}
                                                  className="la la-thumbs-up"
                                                />
                                                {
                                            any.attributes.likes_comment ? (
                                              <span className={Base.marginLeft} style={{ fontSize: '14px', color: '#6c757d' }}>
                                                {any.attributes.likes_comment}
                                              </span>
                                            )
                                              : null
                                          }
                                              </div>
                                            )
                                      }
                                          <span
                                            className={Base.textMuted}
                                            style={{ fontSize: '12px', marginLeft: '6px', marginRight: '6px' }}
                                          >
                                            &bull;
                                          </span>
                                          { this.props.state.user.info !== null && this.props.state.user.info.attributes.username === any.attributes.user.username && (
                                          <Fragment>
                                            <span onClick={handleSetNestedState('commentPage', 'edited', edited)} style={{ fontSize: '14px', cursor: 'pointer' }} className={Base.textMuted}>
                                              Edit
                                            </span>
                                            <span
                                              className={Base.textMuted}
                                              style={{ fontSize: '12px', marginLeft: '6px', marginRight: '6px' }}
                                            >
                                              &bull;
                                            </span>
                                          </Fragment>
                                          )}
                                          {
                                        !(commentPage.edited.length > 0)
                                        && (
                                        <Fragment>
                                          <span onClick={handleReply(any.attributes.user.id, any.attributes.user.username)} style={{ fontSize: '14px', cursor: 'pointer' }} className={Base.textMuted}>
                                            Reply
                                          </span>
                                          <span
                                            className={Base.textMuted}
                                            style={{ fontSize: '12px', marginLeft: '6px', marginRight: '6px' }}
                                          >
                                            &bull;
                                          </span>
                                        </Fragment>
                                        )
                                      }
                                          <span style={{ fontSize: '14px' }} className={Helpers.mergeCss(Base.textMuted)}>
                                            {humanize_date.fromNow()}
                                          </span>
                                        </div>
                                      </div>
                                    )
                                }
                              </div>
                            )
                          })}
                        </div>

                      </Fragment>
                    )}
                    
                    {!feedDetail.attributes.comment_disable &&
                      <div className={Helpers.mergeCss(Base.row, comments.length > 0 ? Base.marginTop3 : Base.dFlex)}>
                        <div className={Helpers.mergeCss(Base.col, Base.w12)}>
                          <div className={Styles.positionRelative}>
                            {
                              state.resultUserData.length > 0 && state.commentFocus
                              && (
                              <div className={Styles.userMention(false)}>
                                <ul className={Base.ulUnstyled}>
                                  {state.resultUserData.map(any => (
                                    <li onClick={handleMention(any.username)} key={any.username}>
                                      <div className={Base.dFlex}>
                                        <img src={(any.avatar.url === null || any.avatar.url === '') ? placeholderImage : any.avatar.url} />
                                        <b className={Base.marginRight}>
                                          {any.name}
                                        </b>
                                        <span>
                                          {`${any.username}`}
                                        </span>
                                      </div>
                                    </li>
                                  )).slice(0, 5)
                                  }
                                </ul>
                              </div>
                              )
                            }
                            {
                              state.resultTagData.length > 0 && state.commentFocus
                              && (
                              <div className={Styles.userHashtag(false)}>
                                <ul className={Base.ulUnstyled}>
                                  {state.resultTagData.map(any => (
                                    <li onClick={handleHashtag(any.tag.name)} key={any.tag.name}>
                                      <div className={Base.dFlex}>
                                        <b className={Base.marginRight}>
                                          #
                                          {any.tag.name}
                                        </b>
                                      </div>
                                      <span className={Base.text}>
                                        {any.count}
                                        {' '}
                                        designs
                                      </span>
                                    </li>
                                  )).slice(0, 5)
                                  }
                                </ul>
                              </div>
                              )
                            }
                            <Input
                              type="textarea"
                              title="Write a response"
                              value={comment}
                              error={commentError}
                              className={Styles.activeTextarea(state.resultUserData.length > 0)}
                              onFocus={handleSetState('commentFocus', true)}
                              // onBlur={handleSetState('commentFocus', false)}
                              onChange={handleSetStateInput('comment')}
                              onKeyUp={handleSetStateInput('commentTyping')}
                            />
                            <div className={Helpers.mergeCss(Base.dBlock, Styles.btnWrapper, Base.textRight)}>
                              <button style={{ borderRadius: '3px' }} onClick={submitComment} className={Base.btnMarvlous}>Post Response</button>
                            </div>
                        </div>
                      </div>
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </Fragment>
    )
  }

  render() {
    const {
      props: {
        closeModal,
        state,
        page,
        match,
        handleSetState,
        typeState,
        slug
      },
      state: {
        feedDetail,
        feedDetailLoading,
        feedDetailLeftLoading,
        feedDetailLoadingDescription,
      },
      submitSubscribe,
    } = this

    console.log(this.state)

    return (
      <Fragment>
        <img
          style={{
            width: '100%',
            boxShadow: '0 1px 4px rgba(0,0,0,0.05)',
            borderRadius: '2px',
            zIndex: -111,
            opacity: 0,
            position: 'absolute',
            height: 'auto',
            top: 0,
            display: 'block'
          }}
          crossOrigin='anonymous'
          id='img-preview'
        />
        <canvas
          style={{
            opacity: 0,
            position: 'absolute',
            zIndex: '-111',
            display: 'none',
            top: 0,
          }}
          width="800"
          height="600"
          id="thecanvas"
        />
        <div className={Base.feedOverlay}>
          <div className={Helpers.mergeCss(Base.backdropOverlay, Base.text, Base.dFlex, Base.alignItemsCenter)}>
            <span
              style={{ cursor: 'pointer', marginLeft: '0.5rem' }}
              onClick={closeModal}
              className={Base.dBlock}
            >
              <i className="la la-arrow-left" />
            </span>
            <span style={{ fontWeight: 'bold', marginLeft: '1rem', color: 'rgb(41, 41, 41)' }}>
              Design
            </span>
          </div>
          <div className={Base.feedContentOverlay(feedDetailLoading)}>
            <div className={Styles.mediaScroll}>
              <div className={Styles.mediaWrapper}>
                {this.renderContent(feedDetailLoading, feedDetailLoadingDescription, feedDetail, state.user.info, this.state, this.props.state, page)}
              </div>
            </div>
            {!feedDetailLeftLoading
              && (
              <div className={Styles.rightProfileWrapper}>
                <div className={Helpers.mergeCss(Base.dBlock, Base.w100)}>
                  <div className={Base.dBlock}>
                    <UserInfo
                      state={this.props.state}
                      user={
                        {
                          info: { ...feedDetail.attributes.user },
                        }
                      }
                      submitSubscribe={submitSubscribe}
                      portfolio={false}
                    />
                  </div>
                  <div style={{ marginTop: '20px' }} className={Base.dBlock}>
                    <div style={{ marginLeft: '-10px', marginRight: '-10px' }} className={Base.row}>
                      {feedDetail.attributes.user.attributes.designs.filter(any => any.id !== feedDetail.id).map(any => (
                        <Link
                          key={any.id}
                          to={`/designs/${any.attributes.slug}`}
                          style={{ marginBottom: '20px', paddingLeft: '10px', paddingRight: '10px' }}
                          className={Helpers.mergeCss(Base.col, Styles.cardCustom, Base.w4)}
                        >
                          <CardPreview
                            images={any.attributes.images}
                            item={any}
                            gif={any.attributes.cover.url !== null && any.attributes.cover.url.includes('gif')}
                            video={any.attributes.cover.url !== null && any.attributes.cover.url.includes('mp4')}
                            coverData={any.attributes.cover.url}
                          />
                        </Link>
                      )).slice(0, 6)}
                    </div>
                    <Link to={`/${feedDetail.attributes.user.attributes.username}`}>
                      More from
                      {' '}
                      {feedDetail.attributes.user.attributes.name}
                      's portfolio
                    </Link>
                  </div>
                  <div className={Helpers.mergeCss(Styles.rightAds, Base.textCenter)}>
                    {process.env.ENV_APP === 'development' && <Adsense/>}
                  </div>
                </div>
              </div>
              )
            }
          </div>
          <div className={Styles.menuMobile}>
            <div style={{marginLeft: '-30px', marginRight: '-30px', height: '100%'}} className={Base.row}>
              <div className={Helpers.mergeCss(Base.col, Base.w12, Base.margin0)}>
                <ul className={Helpers.mergeCss(Base.ulUnstyled, Styles.menuList)}>
                  {
                    Data.menuMobile.map((item) => {
                      const active = page === 'portfolio'
                        ? state.user.info !== null && state.user.info.attributes.username === slug ? page === item.page : false
                        : page === item.page

                      const modalActive = (active && page === 'notifications' && state[page].slug !== '' || active && page === 'feed' && state[page].slug !== '' || active && page === 'saved' && state[page].slug !== '' || active && page === 'subscriptions' && state[page].slug !== '' || active && page === 'portfolio' && state[page].slug !== '')

                      return (
                        <ListitemMobile
                          key={item.id}
                          active={active}
                          page={item.page}
                          user={state.user.info}
                          login={state.login}
                          icon={item.icon}
                          label={item.label}
                          match={match}
                          handleSetState={handleSetState}
                          typeState={typeState}
                          modalActive={modalActive}
                          closeModal={closeModal}
                          contentOverlay={
                            (item.page === 'notifications' && (state.notifications.count !== ''))
                            && (state.notifications.count === 0 ? null : <p className={Styles.notifications}>{state.notifications.count}</p>)
                          }
                          slug={slug}
                          state={state}
                        />
                      )
                    })
                    }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default DesignDetail
