import React, { Component, Fragment } from 'react'
import { Base, Helpers, I18n } from 'utils'
import { Link } from 'react-router-dom'
import {
  Input, InputTag, Checkbox, Hint, Loading, CardPreview, EndPage,
} from 'components'
import { ApiNotifications } from 'api'
import moment from 'moment'
import Dropzone from 'react-dropzone'

import marvlous from 'images/marvlous.png'
import upload from 'images/upload.svg'
import Styles from './Styles'
import placeholderImage from 'images/user-placeholder.jpg'

class Notifications extends Component {
  componentWillUnmount() {
    this.props.resetState()
    const { props: { typeState, handleScroll } } = this
    document.getElementById('marvlous').removeEventListener('scroll', handleScroll(typeState), true)
  }

  componentDidMount() {
    const { props: { typeState, handleScroll } } = this
    document.getElementById('marvlous').addEventListener('scroll', handleScroll(typeState), true)
    // setInterval(() => {
    this.props.getRealTimeNotif('notifications')
    // }, 1000)
  }

  renderContentComment = (any, state, type) => {
    let content = null

    if (state.user.info !== null) {
      if (any.attributes[type].content.reply.includes(state.user.info.attributes.id)
         && any.attributes[type].member.user.id === state.user.info.attributes.id) {
        content = (
          <Fragment>
            <Link to={`/${any.attributes[type].user.username}`}>
              {any.attributes[type].user.name}
              {any.attributes[type].user.verified && (
              <i
                style={{
                  marginLeft: '4px', fontSize: '12px', position: 'relative', top: '-0.1px', color: '#1181eb',
                }}
                className="fa fa-check-circle"
              />
              )}
            </Link>
            {' '}
            replied you on your own design
          </Fragment>
        )
      } else if (any.attributes[type].content.reply.includes(state.user.info.attributes.id)
                && any.attributes[type].member.user.id !== state.user.info.attributes.id) {
        content = (
          <Fragment>
            <Link to={`/${any.attributes[type].user.username}`}>
              {any.attributes[type].user.name}
              {any.attributes[type].user.verified && (
              <i
                style={{
                  marginLeft: '4px', fontSize: '12px', position: 'relative', top: '-0.1px', color: '#1181eb',
                }}
                className="fa fa-check-circle"
              />
              )}
            </Link>
            {' '}
            replied you on
            {any.attributes[type].user.username === any.attributes[type].member.user.username ? ' his' : ` ${any.attributes[type].member.user.name}'s`}
            {' '}
            design
          </Fragment>

        )
      } else if (any.attributes[type].content.mention.includes(state.user.info.attributes.id)
                && any.attributes[type].member.user.id === state.user.info.attributes.id) {
        content = (
          <Fragment>
            <Link to={`/${any.attributes[type].user.username}`}>
              {any.attributes[type].user.name}
              {any.attributes[type].user.verified && (
              <i
                style={{
                  marginLeft: '4px', fontSize: '12px', position: 'relative', top: '-0.1px', color: '#1181eb',
                }}
                className="fa fa-check-circle"
              />
              )}
            </Link>
            {' '}
            mentioned you on your own design
          </Fragment>
        )
      } else if (any.attributes[type].content.mention.includes(state.user.info.attributes.id)
                && any.attributes[type].member.user.id !== state.user.info.attributes.id) {
        content = (
          <Fragment>
            <Link to={`/${any.attributes[type].user.username}`}>
              {any.attributes[type].user.name}
              {any.attributes[type].user.verified && (
              <i
                style={{
                  marginLeft: '4px', fontSize: '12px', position: 'relative', top: '-0.1px', color: '#1181eb',
                }}
                className="fa fa-check-circle"
              />
              )}
            </Link>
            {' '}
            mentioned you on
            {any.attributes[type].user.username === any.attributes[type].member.user.username ? ' his' : ` ${any.attributes[type].member.user.name}'s`}
            {' '}
            design
          </Fragment>
        )
      } else {
        content = (
          <Fragment>
            <Link to={`/${any.attributes[type].user.username}`}>
              {any.attributes[type].user.name}
              {any.attributes[type].user.verified && (
              <i
                style={{
                  marginLeft: '4px', fontSize: '12px', position: 'relative', top: '-0.1px', color: '#1181eb',
                }}
                className="fa fa-check-circle"
              />
              )}
            </Link>
            {' '}
            commented to your design
          </Fragment>
        )
      }
    }

    return content
  }

  renderContentMentionDesign = (any, state, type) => {
    let content = null
    if (state.user.info !== null && any.attributes[type].content.mention.includes(state.user.info.attributes.id)
      && any.attributes[type].member.user.id !== state.user.info.attributes.id) {
      content = (
        <Fragment>
          <Link to={`/${any.attributes[type].user.username}`}>
            {any.attributes[type].user.name}
            {any.attributes[type].user.verified && (
            <i
              style={{
                marginLeft: '4px', fontSize: '12px', position: 'relative', top: '-0.1px', color: '#1181eb',
              }}
              className="fa fa-check-circle"
            />
            )}
          </Link>
          {' '}
          mentioned you on
          {any.attributes[type].user.username === any.attributes[type].member.user.username ? ' his' : ` ${any.attributes[type].member.user.name}'s`}
          {' '}
          design
        </Fragment>
      )
    }

    return content
  }

  renderMentionDesign = (any, state, type) => {
    const { props: { handleSetMergeState, typeState }, renderContentMentionDesign } = this

    const newState = {
      modalView: true,
      slugView: `/designs/${any.attributes[type].design.slug}`,
      slug: any.attributes[type].design.slug,
      design: { item: { attributes: any.attributes[type].design } },
      viewComment: any.attributes[type].content.uuid,
      previousSlug: '/notifications',
    }

    const dozen = {
      attributes: {
        cover: {
          url: any.attributes[type].design.cover.url,
        },
        images: any.attributes[type].design.images,
      },
    }

    const humanizeDate = moment(any.attributes.created_at.split('T').join(' '), 'YYYY-MM-DD HH:mm:ss').fromNow()
    const isOpen = state.user.info !== null && !(any.attributes.opened_by !== null && any.attributes.opened_by.includes(state.user.info.attributes.id))

    return (
      <div id={any.id} key={any.id} className={Helpers.mergeCss(Base.dFlex, Styles.wrapperNotifications(isOpen))}>
        <div className={Styles.iconNotifications}>
          <div className={Base.positionRelative}>
            <span className={Styles.iconIndication}>
              <i className="fas fa-at" />
            </span>
            <Link to={`/${any.attributes[type].user.username}`}>
              <img src={(any.attributes[type].user.avatar.url === null || any.attributes[type].user.avatar.url === '') ? placeholderImage : any.attributes[type].user.avatar.url} alt="" />
            </Link>
          </div>
        </div>
        <div className={Styles.descNotifications}>
          <div className={Base.marginTop}>
            <span
              style={{ fontSize: '14px' }}
              className={Helpers.mergeCss(Base.text, Base.dBlock, Base.marginBottom)}
            >
              {humanizeDate}
            </span>
            {renderContentMentionDesign(any, state, type)}
            <div className={Base.dBlock}>
              <span
                style={{ cursor: 'pointer' }}
                onClick={handleSetMergeState(typeState, newState)}
                className={Helpers.mergeCss(Base.inlineBlock, Base.marginTop, Styles.contentComment, Base.marginBottom, Base.text)}
                dangerouslySetInnerHTML={{ __html: any.attributes[type].content.description }}
              />
            </div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={handleSetMergeState(typeState, newState)}
              className={Styles.parentWrapper}
            >
              <div className={Helpers.mergeCss(Base.positionRelative, Base.dInlineFlex, Styles.cardNotif)}>
                <CardPreview
                  images={dozen.attributes.images}
                  item={dozen}
                  gif={dozen.attributes.cover.url !== null && dozen.attributes.cover.url.includes('gif')}
                  video={dozen.attributes.cover.url !== null && dozen.attributes.cover.url.includes('mp4')}
                  coverData={dozen.attributes.cover.url}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderComment = (any, state, type) => {
    const { props: { handleSetMergeState, typeState }, renderContentComment } = this

    const newState = {
      modalView: true,
      slugView: `/designs/${any.attributes[type].design.slug}`,
      slug: any.attributes[type].design.slug,
      design: { item: { attributes: any.attributes[type].design } },
      viewComment: any.attributes[type].content.uuid,
      previousSlug: '/notifications',
    }

    const dozen = {
      attributes: {
        cover: {
          url: any.attributes[type].design.cover.url,
        },
        images: any.attributes[type].design.images,
      },
    }

    const humanizeDate = moment(any.attributes.created_at.split('T').join(' '), 'YYYY-MM-DD HH:mm:ss').fromNow()
    const isOpen = state.user.info !== null && !(any.attributes.opened_by !== null && any.attributes.opened_by.includes(state.user.info.attributes.id))

    return (
      <div id={any.id} key={any.id} className={Helpers.mergeCss(Base.dFlex, Styles.wrapperNotifications(isOpen))}>
        <div className={Styles.iconNotifications}>
          <div className={Base.positionRelative}>
            <span className={Styles.iconIndication}>
              <i className="fas fa-comment" />
            </span>
            <Link to={`/${any.attributes[type].user.username}`}>
              <img src={(any.attributes[type].user.avatar.url === null || any.attributes[type].user.avatar.url === '') ? placeholderImage : any.attributes[type].user.avatar.url} alt="" />
            </Link>
          </div>
        </div>
        <div className={Styles.descNotifications}>
          <div className={Base.marginTop}>
            <span
              style={{ fontSize: '14px' }}
              className={Helpers.mergeCss(Base.text, Base.dBlock, Base.marginBottom)}
            >
              {humanizeDate}
            </span>
            {renderContentComment(any, state, type)}
            <div className={Base.dBlock}>
              <span
                style={{ cursor: 'pointer' }}
                onClick={handleSetMergeState(typeState, newState)}
                className={Helpers.mergeCss(Base.inlineBlock, Base.marginTop, Styles.contentComment, Base.marginBottom, Base.text)}
                dangerouslySetInnerHTML={{ __html: any.attributes[type].content.content }}
              />
            </div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={handleSetMergeState(typeState, newState)}
              className={Styles.parentWrapper}
            >
              <div className={Helpers.mergeCss(Base.positionRelative, Base.dInlineFlex, Styles.cardNotif)}>
                <CardPreview
                  images={dozen.attributes.images}
                  item={dozen}
                  gif={dozen.attributes.cover.url !== null && dozen.attributes.cover.url.includes('gif')}
                  video={dozen.attributes.cover.url !== null && dozen.attributes.cover.url.includes('mp4')}
                  coverData={dozen.attributes.cover.url}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderLikeOrBookmark = (any, state, type) => {
    const { props: { handleSetMergeState, typeState } } = this

    const newState = {
      modalView: true,
      slugView: `/designs/${any.attributes[type].design.slug}`,
      slug: any.attributes[type].design.slug,
      design: { item: { attributes: any.attributes[type].design } },
      viewComment: any.attributes[type].content.uuid,
      previousSlug: '/notifications',
    }

    const dozen = {
      attributes: {
        cover: {
          url: any.attributes[type].design.cover.url,
        },
        images: any.attributes[type].design.images,
      },
    }

    const humanizeDate = moment(any.attributes.created_at.split('T').join(' '), 'YYYY-MM-DD HH:mm:ss').fromNow()
    const isOpen = state.user.info !== null && !(any.attributes.opened_by !== null && any.attributes.opened_by.includes(state.user.info.attributes.id))

    return (
      <div id={any.id} key={any.id} className={Helpers.mergeCss(Base.dFlex, Styles.wrapperNotifications(isOpen))}>
        <div className={Styles.iconNotifications}>
          <div className={Base.positionRelative}>
            <span className={Styles.iconIndication}>
              <i className={`fas ${type === 'bookmark' ? 'fa-bookmark' : 'fa-heart'} `} />
            </span>
            <Link to={`/${any.attributes[type].user.username}`}>
              <img src={(any.attributes[type].user.avatar.url === null || any.attributes[type].user.avatar.url === '') ? placeholderImage : any.attributes[type].user.avatar.url} alt="" />
            </Link>
          </div>
        </div>
        <div className={Styles.descNotifications}>
          <div className={Base.marginTop}>
            <div>
              <span
                style={{ fontSize: '14px' }}
                className={Helpers.mergeCss(Base.text, Base.dBlock, Base.marginBottom)}
              >
                {humanizeDate}
              </span>
              <Link to={`/${any.attributes[type].user.username}`}>
                {any.attributes[type].user.name}
                {any.attributes[type].user.verified && (
                <i
                  style={{
                    marginLeft: '4px', fontSize: '12px', position: 'relative', top: '-0.1px', color: '#1181eb',
                  }}
                  className="fa fa-check-circle"
                />
                )}
              </Link>
              {' '}
              {type === 'bookmark' ? 'saved' : 'liked'}
              {' '}
              to your design
            </div>
            <div
              className={Styles.parentWrapper}
              style={{ cursor: 'pointer' }}
              onClick={handleSetMergeState(typeState, newState)}
            >
              <div className={Helpers.mergeCss(Base.positionRelative, Base.dInlineFlex, Styles.cardNotif)}>
                <CardPreview
                  images={dozen.attributes.images}
                  item={dozen}
                  gif={dozen.attributes.cover.url !== null && dozen.attributes.cover.url.includes('gif')}
                  video={dozen.attributes.cover.url !== null && dozen.attributes.cover.url.includes('mp4')}
                  coverData={dozen.attributes.cover.url}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderSubscription = (any, state, type) => {
    const humanizeDate = moment(any.attributes.created_at.split('T').join(' '), 'YYYY-MM-DD HH:mm:ss').fromNow()
    const isOpen = state.user.info !== null && !(any.attributes.opened_by !== null && any.attributes.opened_by.includes(state.user.info.attributes.id))

    return (
      <div id={any.id} key={any.id} className={Helpers.mergeCss(Base.dFlex, Base.alignItemsCenter, Styles.wrapperNotifications(isOpen))}>
        <div className={Styles.iconNotifications}>
          <div className={Base.positionRelative}>
            <span className={Styles.iconIndication}>
              <i className="fas fa-plus" />
            </span>
            <Link to={`/${any.attributes[type].user.username}`}>
              <img src={(any.attributes[type].user.avatar.url === null || any.attributes[type].user.avatar.url === '') ? placeholderImage : any.attributes[type].user.avatar.url} alt="" />
            </Link>
          </div>
        </div>
        <div className={Styles.descNotifications}>
          <div>
            <span
              style={{ fontSize: '14px' }}
              className={Helpers.mergeCss(Base.text, Base.dBlock, Base.marginBottom)}
            >
              {humanizeDate}
            </span>
            <Link to={`/${any.attributes[type].user.username}`}>
              {any.attributes[type].user.name}
              {any.attributes[type].user.verified && (
              <i
                style={{
                  marginLeft: '4px', fontSize: '12px', position: 'relative', top: '-0.1px', color: '#1181eb',
                }}
                className="fa fa-check-circle"
              />
              )}
            </Link>
            {' '}
            subscribed to you
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { state: { notifications } } = this.props
    return (
      <Fragment>
        <div id="marvlous" className={Styles.scrollNotifications}>
          <div className={Helpers.mergeCss(Base.row, Styles.rowNotifications)}>
            {
              notifications.loading ? (
                <div className={Helpers.mergeCss(Base.col, Base.w12, Base.marginTop2, Styles.colNotifications)}>
                  <div className={Helpers.mergeCss(Base.dFlex, Base.justifyContentCenter)}>
                    <Loading />
                  </div>
                </div>
              ) : (notifications.isDataExist
                ? (
                  <div className={Helpers.mergeCss(Base.col, Base.w12, Styles.colNotifications)}>
                    {notifications.data.length > 0
                  && notifications.data.map((any) => {
                    if (any.attributes.comment !== null) {
                      return this.renderComment(any, this.props.state, 'comment')
                    } if (any.attributes.like !== null) {
                      return this.renderLikeOrBookmark(any, this.props.state, 'like')
                    } if (any.attributes.bookmark !== null) {
                      return this.renderLikeOrBookmark(any, this.props.state, 'bookmark')
                    } if (any.attributes.subscription !== null) {
                      return this.renderSubscription(any, this.props.state, 'subscription')
                    } if (any.attributes.design !== null) {
                      return this.renderMentionDesign(any, this.props.state, 'design')
                    }
                  })}
                  </div>
                ) : '')
            }
            <EndPage
              loading={notifications.loadingData}
              endPage={notifications.endOfData}
            />
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Notifications
