import { Validation, Helpers } from 'utils'

function designUploadParams(data) {
  const isEnterExist = data.descriptionDesign.split('').filter(item => (item === '\n' || item === '\r')).length > 0 ? Helpers.addLineBreak(data.descriptionDesign) : data.descriptionDesign
  const getUrlandMentionHashtag = isEnterExist.split(' ')
    .filter(item => (Validation.webPattern(item) || item.includes('@') || item.includes('#')))
  const mapUrlandMentionHashtag = isEnterExist.split(' ')
    .map((item) => {
      if (!Validation.emailPattern(item)) {
        return `<a href=mailto:${item} rel=noreferrer nofollow>${item}</a>`
      } if (Validation.webPattern(item)) {
        return `<a class=web target=_blank href=${item}>${item}</a>`
      } if (item.includes('@')) {
        if (!Validation.usernamePattern(item.substr(1))) {
          return `<a class=mention href=/${item.substr(1)}>${item}</a>`
        }else{
          return `<a class=mention href=/${item.substr(1).slice(0, -1)}>${item.slice(0, -1)}</a>${item.slice(item.length - 1)}`
        }
      } if (item.includes('#')) {
        if (Validation.hashtagPattern(item)) {
          return `<a target=_blank class=hashtag href=/trends/${item.substr(1)}>${item}</a>`
        }
        return `<a target=_blank class=hashtag href=/trends/${item.substr(1).slice(0, -1)}>${item.slice(0, -1)}</a>${item.slice(item.length - 1)}`
      }
      return item
    })

  const isUrlMentionHashtagExist = getUrlandMentionHashtag.length > 0
  // this is mentions array from content comment
  const mentions = isEnterExist.split(' ').filter(any => any.includes('@')).map(any => any.substring(1))
  // this is mention to send
  const mention = mentions.filter(any => any !== data.username)
  const collectionId = Object.keys(data.teamsOptionValue).length === 0 && data.teamsOptionValue.constructor === Object ? data.collectionId : data.teamsOptionValue.value
  const form = new FormData()
  const dataImages = data.imagesData.filter(item => item.imageData !== null).map(item => item.imageData)
  const params = {
    title: data.titleDesign,
    collection_id: collectionId,
    description: isUrlMentionHashtagExist ? mapUrlandMentionHashtag.join(' ') : isEnterExist,
    cover: data.coverData === null ? dataImages[0] : data.coverData,
    images: dataImages,
    files: data.filesData
      .filter(item => item.fileData !== null)
      .map(item => item.fileData),
    loading_color: data.loadingColor,
    tag_arr: data.tagOptionValue.filter(item => item.value !== null).map(item => item.value),
    color_arr: data.colorValue.filter(item => item !== null).map(item => item),
    delivered_by: mention,
    comment_disable: data.commentActive,
    mention,
    type_feed: dataImages.length > 1 ? 'multiple' : 'single',
    type_design: dataImages.every(any => (any.name.includes('.png') || any.name.includes('.jpg'))) ? 'image'
      : dataImages.every(any => (any.name.includes('.gif') || any.name.includes('.mp4'))) ? 'video' : 'mix',
    original_description: data.descriptionDesign,
  }

  debugger
  Object.keys(params).map((key) => {
    if (key === 'files') {
      return (
        Array.from(params.files).map(f => form.append('design_upload[files][]', f))
      )
    } if (key === 'images') {
      return (
        Array.from(params.images).map(i => form.append('design_upload[images][]', i))
      )
    } if (key === 'tag_arr') {
      return (
        Array.from(params.tag_arr).map(t => form.append('design_upload[tag_arr][]', t))
      )
    } if (key === 'color_arr') {
      return (
        Array.from(params.color_arr).map(c => form.append('design_upload[color_arr][]', c))
      )
    } if (key === 'mention') {
      return (
        Array.from(params.mention).map(m => form.append('design_upload[mention][]', m))
      )
    } if (key === 'delivered_by') {
      return (
        Array.from(params.delivered_by).map(m => form.append('design_upload[delivered_by][]', m))
      )
    }
    return (
      form.append(`design_upload[${key}]`, params[key])
    )
  })

  return form
}

function designViewsParams(data) {
  const form = new FormData()
  const params = {
    view: data.view,
    loading_color: data.loadingColor
  }

  Object.keys(params).map(key => form.append(`design[${key}]`, params[key]))

  return form
}

function commentParams(data) {
  const isEnterExist = data.comment.split('').filter(item => (item === '\n' || item === '\r')).length > 0 ? Helpers.addLineBreak(data.comment) : data.comment
  const getUrlandMentionHashtag = isEnterExist.split(' ')
    .filter(item => (Validation.webPattern(item) || item.includes('@') || item.includes('#')))
  const mapUrlandMentionHashtag = isEnterExist.split(' ')
    .map((item) => {
      if (Validation.webPattern(item)) {
        return `<a class=web target=_blank href=${item}>${item}</a>`
      } if (item.includes('@')) {
        if (!Validation.usernamePattern(item.substr(1))) {
          return `<a class=mention href=/${item.substr(1)}>${item}</a>`
        }else{
          return `<a class=mention href=/${item.substr(1).slice(0, -1)}>${item.slice(0, -1)}</a>${item.slice(item.length - 1)}`
        }
      } if (item.includes('#')) {
        if (Validation.hashtagPattern(item)) {
          return `<a target=_blank class=hashtag href=/trends/${item.substr(1)}>${item}</a>`
        }
        return `<a target=_blank class=hashtag href=/trends/${item.substr(1).slice(0, -1)}>${item.slice(0, -1)}</a>${item.slice(item.length - 1)}`
      }
      return item
    })

  const isUrlMentionHashtagExist = getUrlandMentionHashtag.length > 0
  // this is mentions array from content comment
  const mentions = isEnterExist.split(' ').filter(any => any.includes('@')).map(any => any.substring(1))
  // this is mention to send
  const mention = mentions.filter(any => !(data.replyComments.filter(any => mentions.includes(any.username)).map(any => any.username).includes(any)))

  const form = new FormData()
  const params = {
    content: isUrlMentionHashtagExist ? mapUrlandMentionHashtag.join(' ') : isEnterExist,
    user_id: data.userId,
    design_id: data.designId,
    uuid: data.uuid,
    posted_at: data.postedAt,
    delivered_by: data.userId !== data.designUserId.toString()
      ? mentions.filter(any => any === data.designUsername).length > 0 ? mentions : mentions.concat(data.designUsername)
      : mentions,
    reply: data.replyComments.filter(any => mentions.includes(any.username)).map(any => any.username),
    mention,
    original_content: data.comment,

  }

  Object.keys(params).map((key) => {
    if (key === 'delivered_by') {
      return (
        Array.from(params.delivered_by).map(d => form.append('comment[delivered_by][]', d))
      )
    } if (key === 'reply') {
      return (
        Array.from(params.reply).map(r => form.append('comment[reply][]', r))
      )
    } if (key === 'mention') {
      return (
        Array.from(params.mention).map(m => form.append('comment[mention][]', m))
      )
    }
    return (
      form.append(`comment[${key}]`, params[key])
    )
  })

  return form
}

function commentUpdateParams(data) {
  const isEnterExist = data.comment.split('').filter(item => (item === '\n' || item === '\r')).length > 0 ? Helpers.addLineBreak(data.comment) : data.comment
  const getUrlandMentionHashtag = isEnterExist.split(' ')
    .filter(item => (Validation.webPattern(item) || item.includes('@') || item.includes('#')))
  const mapUrlandMentionHashtag = isEnterExist.split(' ')
    .map((item) => {
      if (Validation.webPattern(item)) {
        return `<a class=web target=_blank href=${item}>${item}</a>`
      } if (item.includes('@')) {
        if (Validation.mentionPattern(item)) {
          return `<a class=mention href=/${item.substr(1)}>${item}</a>`
        }
        return `<a class=mention href=/${item.substr(1).slice(0, -1)}>${item.slice(0, -1)}</a>${item.slice(item.length - 1)}`
      } if (item.includes('#')) {
        if (Validation.hashtagPattern(item)) {
          return `<a target=_blank class=hashtag href=/trends/${item.substr(1)}>${item}</a>`
        }
        return `<a target=_blank class=hashtag href=/trends/${item.substr(1).slice(0, -1)}>${item.slice(0, -1)}</a>${item.slice(item.length - 1)}`
      }
      return item
    })

  const isUrlMentionHashtagExist = getUrlandMentionHashtag.length > 0
  // this is mentions array from content comment
  const mentions = isEnterExist.split(' ').filter(any => any.includes('@')).map(any => any.substring(1))
  const mention = mentions
  const form = new FormData()
  const params = {
    content: isUrlMentionHashtagExist ? mapUrlandMentionHashtag.join(' ') : isEnterExist,
    delivered_by: data.userId !== data.designUserId.toString()
      ? mentions.filter(any => any === data.designUsername).length > 0 ? mentions : mentions.concat(data.designUsername)
      : mentions,
    mention,
    original_content: data.comment,

  }

  Object.keys(params).map((key) => {
    if (key === 'delivered_by') {
      return (
        Array.from(params.delivered_by).map(d => form.append('comment[delivered_by][]', d))
      )
    } if (key === 'mention') {
      return (
        Array.from(params.mention).map(m => form.append('comment[mention][]', m))
      )
    }
    return (
      form.append(`comment[${key}]`, params[key])
    )
  })

  return form
}

function subscriptionParams(data) {
  const form = new FormData()
  const params = {
    user_id: data.userId,
  }

  Object.keys(params).map(key => form.append(`subscription[${key}]`, params[key]))

  return form
}

function likeParams(data) {
  const form = new FormData()
  const params = {
    user_id: data.userId,
    design_id: data.designId,
  }

  Object.keys(params).map(key => form.append(`like[${key}]`, params[key]))

  return form
}

function likeCommentParams(data) {
  const form = new FormData()
  const params = {
    user_id: data.userId,
    comment_id: data.commentId,
  }

  Object.keys(params).map(key => form.append(`like[${key}]`, params[key]))

  return form
}

function bookmarkParams(data) {
  const form = new FormData()
  const params = {
    user_id: data.userId,
    design_id: data.designId,
  }

  Object.keys(params).map(key => form.append(`bookmark[${key}]`, params[key]))

  return form
}

function userParams(data) {
  const form = new FormData()
  const params = {
    cover: data.resultImage,
    avatar: data.resultImage,
  }

  Object.keys(params).map(key => form.append(`user[${key}]`, params[key]))

  return form
}

function profileParams(data) {
  const form = new FormData()
  const params = {
    name: data.profileName,
    avatar: data.profileEditImageResult,
    location: data.profileLocation,
    bio: data.profileBio,
  }

  Object.keys(params).map(key => form.append(`setting[${key}]`, params[key]))

  return form
}

function linkParams(data) {
  const form = new FormData()
  const params = {
    facebook: Helpers.formatUrl(data.facebookLink),
    instagram: Helpers.formatUrl(data.instagramLink),
    twitter: Helpers.formatUrl(data.twitterLink),
    youtube: Helpers.formatUrl(data.youtubeLink),
    medium: Helpers.formatUrl(data.mediumLink),
    website: Helpers.formatUrl(data.websiteLink),
  }

  Object.keys(params).map(key => form.append(`setting[${key}]`, params[key]))

  return form
}

// this is for member
function userEmailParams(data) {
  const form = new FormData()
  const params = {
    value_check: data.value,
  }

  Object.keys(params).map(key => form.append(`user[${key}]`, params[key]))

  return form
}

function emailParams(data, type) {
  const form = new FormData()
  const params = {
    email: data.email,
  }

  Object.keys(params).map(key => form.append(`${type}[${key}]`, params[key]))

  return form
}

function userPasswordParams(data) {
  const form = new FormData()
  const params = {
    email: data.email,
    password: data.password,
  }

  Object.keys(params).map(key => form.append(`user[${key}]`, params[key]))

  return form
}

function accountParams(data) {
  const form = new FormData()
  const params = {
    username: data.accountUsername,
    email: data.accountEmail,
  }

  Object.keys(params).map(key => form.append(`setting[${key}]`, params[key]))

  return form
}

function passwordParams(data) {
  const form = new FormData()
  const params = {
    password: data.password,
    old_password: data.oldPassword,
  }

  Object.keys(params).map(key => form.append(`setting[${key}]`, params[key]))

  return form
}

function alertParams(data) {
  const form = new FormData()
  const params = {
    company_news: data.alertCompanyNews,
    account_activity: data.alertAccountActivity,
    meetups_near_you: data.alertMeetupsNearYou,
    someone_comment_on_design: data.alertSomeoneCommentOnDesign,
    someone_mention_me: data.alertSomeoneMentionMe,
    receive_invitations: data.alertReceiveInvitations,
    invitations_expired: data.alertInvitationsExpired,
    anyone_subscribed: data.alertAnyoneSubscribed,
  }

  Object.keys(params).map(key => form.append(`setting[${key}]`, params[key]))

  return form
}

function notifParams(data) {
  const form = new FormData()
  const params = {
    open_ids: data.openIds,
  }

  Object.keys(params).map((key) => {
    if (key === 'open_ids') {
      return (
        Array.from(params.open_ids).map(d => form.append('notification[open_ids][]', d))
      )
    }
  })

  return form
}

export default {
  designUploadParams,
  designViewsParams,
  commentParams,
  commentUpdateParams,
  subscriptionParams,
  likeParams,
  bookmarkParams,
  userParams,
  profileParams,
  linkParams,
  userEmailParams,
  emailParams,
  userPasswordParams,
  accountParams,
  passwordParams,
  alertParams,
  notifParams,
  likeCommentParams,
}
