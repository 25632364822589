import React, { Fragment } from 'react'
import { Helpers, Base } from 'utils'
import Styles from './Styles'

const Modal = ({ goBack, content, tiny = true }) => (
  <div className={Styles.overlay(tiny)}>
    <div onClick={goBack} className={Styles.backdropOverlay(tiny)} />
    <div className={Styles.contentOverlay(tiny)}>
      <div className={Styles.mediaWrapper(tiny)}>
        {content}
      </div>
    </div>
  </div>
)

export default Modal
