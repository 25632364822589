import base from './api.base'

function create(data) {
  return base.post('submissions', data)
}

const ApiSubmissions = {
  create,
}

export default ApiSubmissions
