import React, { Component, Fragment } from 'react'
import {
  Helpers, Base, Routes, history, Validation,
} from 'utils'
import { Link } from 'react-router-dom'
import {
  SearchBar, Loading, Card, EndPage,
} from 'components'
import {
  ApiTags, ApiFinds, ApiSubscriptions, ApiBookmarks,
} from 'api'
import moment from 'moment'
import Dropzone from 'react-dropzone'
import Slider from 'react-slick'
import Styles from './Styles'
import functions from '../../functions'

class Saved extends Component {
  componentDidMount() {
    const { props: { typeState, handleScroll } } = this
    document.getElementById('marvlous').addEventListener('scroll', handleScroll(typeState), true)
  }

  componentWillUnmount() {
    this.props.resetState()
    const { props: { typeState, handleScroll } } = this
    document.getElementById('marvlous').removeEventListener('scroll', handleScroll(typeState), true)
  }

  render() {
    const { props: { handleSetMergeState, typeState, state: { saved } } } = this
    return (
      <div id="marvlous" className={Helpers.mergeCss(Base.dBlock, Styles.savedWrapper)}>
        <div className={Helpers.mergeCss(Base.row, Styles.rowSaved)}>
          <span className={Helpers.mergeCss(Styles.designSaved, Base.w100)}>
            Your bookmark designs
          </span>
        </div>
        <div className={Helpers.mergeCss(Base.row, Styles.rowSavedCard)}>
          {
            saved.loading ? (
              <div className={Helpers.mergeCss(Base.col, Base.w12)}>
                <div className={Helpers.mergeCss(Base.dFlex, Base.justifyContentCenter)}>
                  <Loading />
                </div>
              </div>
            ) : (saved.isDataExist
              ? (
                <Fragment>
                  {saved.data.map((any) => {
                    const humanizeDate = moment(any.attributes.created_at.split('T').join(' '), 'YYYY-MM-DD HH:mm:ss').fromNow()
                    const state = {
                      modalView: true,
                      slugView: `/designs/${any.attributes.slug}`,
                      slug: any.attributes.slug,
                      design: any,
                      previousSlug: '/saved',
                    }
                    return (
                      <Card
                        key={any.id}
                        item={any}
                        noload
                        handleSetState={handleSetMergeState(typeState, state)}
                        humanizeDate={humanizeDate}
                      />
                    )
                  })}
                </Fragment>
              ) : '')
          }
          <EndPage
            loading={saved.loadingData}
            endPage={saved.endOfData}
          />
        </div>
      </div>
    )
  }
}

export default Saved
