import base from './api.base'

function index(page) {
  return base.get(`notifications/${page}`)
}

function count() {
  return base.get('notifications/count/result')
}

function open(id) {
  return base.get(`notifications/open/${id}`)
}

function openAll(data) {
  return base.post('notifications/open_all', data)
}

const ApiNotifications = {
  index,
  count,
  open,
  openAll,
}

export default ApiNotifications
