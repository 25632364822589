import React, { Component, Fragment } from 'react'
import {
  Helpers, Base, Routes, history, Validation,
} from 'utils'
import { Link } from 'react-router-dom'
import {
  Loading, Card, Hint, CardPreview,
} from 'components'
import {
  ApiTags, ApiFinds, ApiSubscriptions, ApiBookmarks,
} from 'api'
import moment from 'moment'
import Dropzone from 'react-dropzone'
import Slider from 'react-slick'
import Styles from './Styles'
import functions from '../../functions'

class Revenue extends Component {
  componentDidMount() {
    const { props: { getRevenue } } = this
    getRevenue()
  }

  componentWillUnmount() {
    this.props.resetState()
  }

  render() {
    const { props: { state: { revenue } } } = this

    return (
      <div className={Helpers.mergeCss(Styles.scrollRevenue)}>
        <div className={Helpers.mergeCss(Base.row, Styles.rowRevenue)}>
          {
          revenue.loading
            ? (
              <div className={Helpers.mergeCss(Base.col, Base.marginTop2, Base.w12)}>
                <div className={Helpers.mergeCss(Base.dFlex, Base.justifyContentCenter)}>
                  <Loading />
                </div>
              </div>
            )
            : (
              <div className={Helpers.mergeCss(Base.col, Base.w12)}>
                <div className={Helpers.mergeCss(Base.row, Styles.bgRevenue, Styles.borderBottom)}>
                  <div className={Helpers.mergeCss(Base.col, Base.w5, Styles.borderRight)}>
                    <div className={Helpers.mergeCss(Base.row, Styles.title)}>
                      <div className={Helpers.mergeCss(Base.col, Styles.paddingCustom, Base.w12)}>
                        <h6>Estimated revenue</h6>
                      </div>
                    </div>
                    <div className={Helpers.mergeCss(Base.row)}>
                      <div style={{ marginBottom: '10px' }} className={Helpers.mergeCss(Base.col, Styles.paddingCustom, Styles.revenueMoney, Base.w6)}>
                        <h6>Today</h6>
                        <span>
                          $
                          {revenue.revenue.attributes.today}
                        </span>
                      </div>
                      <div style={{ marginBottom: '10px' }} className={Helpers.mergeCss(Base.col, Styles.paddingCustom, Styles.revenueMoney, Base.w6)}>
                        <h6>Yesterday</h6>
                        <span>
                          $
                          {revenue.revenue.attributes.yesterday}
                        </span>
                      </div>
                      <div className={Helpers.mergeCss(Base.col, Styles.paddingCustom, Styles.revenueMoney, Base.w6)}>
                        <h6>Last 7 days</h6>
                        <span>
                          $
                          {revenue.revenue.attributes.last_seven_days}
                        </span>
                      </div>
                      <div className={Helpers.mergeCss(Base.col, Styles.paddingCustom, Styles.revenueMoney, Base.w6)}>
                        <h6>This month</h6>
                        <span>
                          $
                          {revenue.revenue.attributes.this_month}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={Helpers.mergeCss(Base.col, Base.w2, Styles.borderRight)}>
                    <div className={Helpers.mergeCss(Base.row, Styles.title)}>
                      <div className={Helpers.mergeCss(Base.col, Styles.paddingCustom, Base.w12)}>
                        <h6>Balance</h6>
                      </div>
                    </div>
                    <div className={Helpers.mergeCss(Base.row)}>
                      <div className={Helpers.mergeCss(Base.col, Styles.paddingCustom, Styles.revenueMoney, Base.w3)}>
                        <h6>Total</h6>
                        <span>
                          $
                          {revenue.revenue.attributes.total}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={Helpers.mergeCss(Base.col, Base.w5, Styles.borderRight)}>
                    <div className={Helpers.mergeCss(Base.row, Styles.title)}>
                      <div className={Helpers.mergeCss(Base.col, Styles.paddingCustom, Base.w12)}>
                        <h6>Payment</h6>
                      </div>
                    </div>
                    <div className={Helpers.mergeCss(Base.row)}>
                      <div className={Helpers.mergeCss(Base.col, Styles.paddingCustom, Styles.revenueMoney, Base.w12)}>
                        <p style={{ marginBottom: 0 }} className={Base.text}>Last payment</p>
                        <p style={{ marginBottom: 0 }} className={Base.text}>
                          You don't need to setup a payment method until your revenue has been reached the minimum($200), connect to your
                          {' '}
                          <a style={{ color: '#1181eb', textDecoration: 'underline' }}>PayPal</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Helpers.mergeCss(Base.row, Styles.paddingAdd)}>
                  <div className={Helpers.mergeCss(Base.col, Base.w12, Base.padding0)}>
                    <div className={Helpers.mergeCss(Base.dBlock, Styles.wrapperAnalytics)}>
                      <div className={Base.row}>
                        <div className={Helpers.mergeCss(Base.col, Base.w8, Styles.paddCustom)}>
                          <b style={{
                            fontSize: '14px',
                            display: 'block',
                          }}
                          >
                            Detail
                          </b>
                        </div>
                        <div className={Helpers.mergeCss(Base.col, Base.w2, Base.textCenter, Styles.paddCustom)}>
                          <b style={{
                            fontSize: '14px',
                            display: 'block',
                          }}
                          >
                            Views
                          </b>
                        </div>
                        <div className={Helpers.mergeCss(Base.col, Base.w2, Base.textCenter, Styles.paddCustom)}>
                          <b style={{
                            fontSize: '14px',
                            display: 'block',
                          }}
                          >
                            Earning
                          </b>
                        </div>
                      </div>
                      {
                      revenue.list.map(any => (
                        <div key={any.id} className={Helpers.mergeCss(Base.row, Styles.engagementWrapper, Base.paddingTop2, Base.paddingBottom2)}>
                          <div className={Helpers.mergeCss(Base.col, Base.w8, Styles.paddCustom)}>
                            <div className={Base.dFlex}>
                              <div className={Styles.cardWrapper}>
                                <CardPreview
                                  images={any.attributes.images}
                                  item={any}
                                  gif={any.attributes.cover.url !== null && any.attributes.cover.url.includes('gif')}
                                  video={any.attributes.cover.url !== null && any.attributes.cover.url.includes('mp4')}
                                  coverData={any.attributes.cover.url}
                                />
                              </div>
                              <div className={Base.dBlock}>
                                <span className={Helpers.mergeCss(Base.dBlock, Base.text)}>
                                  {moment(any.attributes.created_at.split('T').join(' '), 'YYYY-MM-DD HH:mm:ss').format('dddd, MMMM Do YYYY, h:mm:ss a')}
                                </span>
                                {any.attributes.title}
                              </div>
                            </div>
                          </div>
                          <div className={Helpers.mergeCss(Base.col, Base.w2, Base.textCenter, Styles.paddCustom)}>
                            {any.attributes.views}
                          </div>
                          <div className={Helpers.mergeCss(Base.col, Base.w2, Base.textCenter, Styles.paddCustom)}>
                            <span className={Styles.textPrimay}>
                              $
                              {any.attributes.earning.total}
                            </span>
                          </div>
                        </div>
                      ))
                    }
                    </div>
                  </div>
                </div>
              </div>
            )
        }
        </div>
      </div>
    )
  }
}

export default Revenue
