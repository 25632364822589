import { css } from 'emotion'

const rowFeed = css`
  margin-left: -5px;
  margin-right: -5px;
  padding-left: 20px;
  padding-right: 35px;
  margin-top: 60px;
  @media only screen and (min-width:0px) and (max-width:767px){
    padding-right: 20px;
    margin-bottom: 60px;
  }
`

const feedWrapper = css`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
`

const dropdown = css`
  display: inline-block;
  padding: 8px 16px;
  cursor: pointer;
  border: none;
  text-decoration: none;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-weight: bold;
  appearance: none;
  position: relative;
  outline: none;
  border: 1px solid rgb(238, 238, 238);
  margin-left: 15px;
  margin-bottom: 20px;
  margin-top: 10px;
  font-size: 14px;
  color: #212529;
  i{
    margin-left: 10px;
    font-weight: bold;
  }
`

const floatMenu = (openFloat) => {
    const openFloatStyle = openFloat ? `
      padding: 5px 14px;
      padding-left: 5px;
    ` : `
      padding: 5px 5px;
    `
    return css`
      position: absolute;
      left: 10px;
      top: 200px;
      display: inline-flex;
      align-items: center;
      z-index: 11;
      ${openFloatStyle}
      background: #fff;
      border-radius: 30px;
      box-shadow: 0 -2px 10px rgba(17,129,235, 0.1), 0 2px 3px rgba(17,129,235,0.1);
      cursor: pointer;
      > p{
        margin: 0px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: rgba(17,129,235,0.1);
        color: #1181eb;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 1px 4px rgba(0,0,0,0.05);
        i{
          color: #1181eb;
          font-size: 18px;
        }
      }
      > span{
        margin-left: 10px;
        > span{
          color: #1181eb;
          margin-right: 10px;
          font-weight: bold;
          height: 18px;
          position: relative;
          font-size: 14px;
        }
        p{
          color: #6c757d!important;
          margin-bottom: 0px;
          font-size: 10px;
        }
        
      }
    `
}

export default{
  rowFeed,
  feedWrapper,
  dropdown,
  floatMenu
}
