import React, { Fragment } from 'react'
import { Base, Helpers } from 'utils'
import PropTypes from 'prop-types'
import Styles from './Styles'

const Checkbox = ({
  checked, onChange, title, label,
}) => (
  <Fragment>
    <h6 className={Helpers.mergeCss(Base.textMuted, Styles.title)}>{title}</h6>
    <label className={Styles.containerCheckbox}>
      <div className={Helpers.mergeCss(Base.text, Base.dInlineBlock)}>
        {label}
      </div>
      <input onChange={onChange} checked={checked} type="checkbox" />
      <span className={Styles.checkmark} />
    </label>
  </Fragment>
)

export default Checkbox
