import base from './api.base'

function list(page) {
  return base.get(`bookmarks/list/${page}`)
}

function bookmark(data) {
  return base.post('bookmarks/bookmark', data)
}

function unbookmark(data) {
  return base.post('bookmarks/unbookmark', data)
}

const ApiBookmarks = {
  list,
  bookmark,
  unbookmark,
}

export default ApiBookmarks
