import { css } from 'emotion'

function imgFluid(tiny) {
  const tinyStyle = tiny ? `
    max-width: 100%;
    margin-right: 10px;
    border-radius: 2px;
  ` : `
    max-width: 100%;
    margin: 0px;
    border-radius: 4px;
  `

  return css`
    ${tinyStyle}
    object-fit: cover;
    height: auto;
  `
}

const iconIndicator = css`
  position: absolute;
  bottom: 6px;
  right: 12px;
  width: 24px;
  height: 24px;
`

const gifBg = css`
  background-position: 96px 0;
`

function previewWrapper(type, extension, tiny, color) {
  const topStyle = type === 'video' ? '' : `
      top: 0px;
    `

  const gifStyle = extension === 'gif' ? `img{
      &:first-of-type{
        opacity: 0;
      }
    }` : ''

  const bgColorStyle = color !== null ? 
    `
      background: #${color};
    `
    :
    `
      background: linear-gradient(to bottom, transparent 0%, transparent 8.1%, 
        rgba(17,129,235, .001) 15.5%, 
        rgba(17,129,235, .003) 22.5%, 
        rgba(17,129,235, .005) 29%, 
        rgba(17,129,235, .008) 35.3%, 
        rgba(17,129,235, .011) 41.2%, 
        rgba(17,129,235, .014) 47.1%, 
        rgba(17,129,235, .016) 52.9%, 
        rgba(17,129,235, .019) 58.8%, 
        rgba(17,129,235, .022) 64.7%, 
        rgba(17,129,235, .025) 71%, 
        rgba(17,129,235, .027) 77.5%, 
        rgba(17,129,235, .029) 84.5%, 
        rgba(17,129,235, .03) 91.9%, 
        rgba(17,129,235, .03) 100%);
  `

  const tinyStyle = tiny ? `
      width: 140px;
      padding-bottom: 0;
      height:auto;
      margin-right: 15px;
      border-radius:2px;
    ` : `
      width: 100%;
      padding-bottom: calc(75% - 1px);
      height: 0;
      box-shadow:0 1px 4px rgba(0,0,0,0.05);
      ${bgColorStyle}
      border-radius:4px;
    `

  return css`
      ${tinyStyle}
      overflow: hidden;
      position:relative;
      video{
        display: block;
      }
      video[poster]{
        display: block;
        object-fit: cover;
      }
      &:hover{
        video, img{
          cursor: pointer;
        }
        ${gifStyle}
        span{
          &:before{
            content: '';
            width: 2px;
            height: 7px;
            background: #292929;
            display: block;
            margin-right: 1px;
            border:0px;
            left: 0px;
            position:relative;
            ${topStyle}
          }
          &:after{
            content: '';
            width: 2px;
            height: 7px;
            background: #292929;
            display: block;
            margin-left: 1px;
            position:relative;
            border:0px;
            ${topStyle}
          }
        }
      }
      span{
        position: absolute;
        width: 18px;
        height: 18px;
        background: #fff;
        right: 13px;
        bottom: 10px;
        box-shadow: 0 1px 4px rgba(0,0,0, 0.1);
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:before{
          width: 0;
          height: 0;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
          border-left: 6px solid #292929;
          content: '';
          position: relative;
          left: 1px;
          content: '';
        }
      }
      p{
        position: absolute;
        right: 15px;
        bottom: 15px;
        margin: 0px;
        &:after{
          margin: 0px;
          z-index: 1;
          position: relative;
          width: 15px;
          height: 15px;
          background: #fff;
          box-shadow: 0 1px 4px rgba(0,0,0,0.1);
          border-radius: 2px;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          content: '';
        }
        &:before{
          margin: 0px;
          z-index: 1;
          position: relative;
          width: 15px;
          height: 15px;
          background: #fff;
          box-shadow: 0 1px 4px rgba(0,0,0,0.1);
          border-radius: 2px;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          content: '';
          bottom:-19px;
          right: -4px;
        }
      }
    `
}

const imgAbsolute = css`
  position:absolute;
  top:0;
`

const team = css`
  position: absolute;
  right: 15px;
  top: 15px;
  color: #fff;
  padding: 1px 10px;
  display: block;
  background: rgb(255 255 255 / 0.3);
  border-radius: 2px;
  font-weight: bold;
  font-size: 14px;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  z-index: 111;
`

export default {
  imgFluid,
  iconIndicator,
  gifBg,
  previewWrapper,
  imgAbsolute,
  team
}
