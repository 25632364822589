import { css } from 'emotion'

const containerCheckbox = css`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  &:hover{
    input ~ span {
      background-color: #f3f3f4;
    }
  }
  input:checked ~ span{
    background-color: #1181eb;
  }
  span{
    &::after{
      content: "";
      position: absolute;
      display: none;
      left: 9px;
      top: 4px;
      width: 8px;
      height: 14px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  input:checked ~ span:after{
    display: block;
  }
`

const checkmark = css`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #f3f3f4;
  border-radius: 2px;
`

const title = css`
`

export default{
  title,
  containerCheckbox,
  checkmark,
}
