import React, { Component, Fragment } from 'react'
import { Base, Helpers, I18n } from 'utils'
import { Link } from 'react-router-dom'
import {
  Loading, Card, Hint, Input, Checkbox, Modal, Button,
} from 'components'
import {
  ApiUsers, ApiSubscriptions, ApiDesigns, ApiLikes, ApiComments,
} from 'api'
import moment from 'moment'
import Dropzone from 'react-dropzone'
import InputRange from 'react-input-range'
import AvatarEditor from 'react-avatar-editor'

import marvlous from 'images/marvlous.png'
import upload from 'images/upload.svg'
import placeholderImage from 'images/user-placeholder.jpg'
import Styles from './Styles'
import Model from '../Model'
import Data from '../Data'
import functions from '../../functions'

class Settings extends Component {
  componentWillUnmount() {
    this.props.resetState()
  }

  renderProfilePicture = (settings) => {
    const { props: { handleSetState, typeState } } = this
    const content = (settings.profileAvatar === null || settings.profileAvatar === '') ? 
    (<img src={placeholderImage} />) : (<Fragment>
        <img src={settings.profileAvatar} />
        <span onClick={handleSetState(typeState, 'profileAvatar', '')} className={Styles.deleteImage}>
          <i className="la la-times" />
        </span>
      </Fragment>
    )

    return content
  }

  renderContent = (tab, settings, state) => {
    const {
      width,
      height,
      position,
      borderRadius,
      profileEditImage,
      profileEditImageResult,
      profileAvatarError,
      scale,
      rotate,
      alertCompanyNews,
      alertAccountActivity,
      alertMeetupsNearYou,
      alertSomeoneCommentOnDesign,
      alertSomeoneMentionMe,
      alertReceiveInvitations,
      alertInvitationsExpired,
      alertAnyoneSubscribed,
      modalAccount,
      modalPassword,
      accountLoading,
      profileLoading,
      alertLoading,
      passwordLoading,
      loadingLink,
    } = settings

    const {
      props: {
        handleSetStateInput, handleSetStateFile,
        typeState, saveImage,
        handleScale, handlePositionChange,
        setEditorRef, handleSetState,
        handleUpdateSettings,
        handleUpdateSettingsConfirm,
        handleMemberInvite,
        handleDeleteMember,
      },
    } = this

    let content = null

    switch (tab) {
      case 'account':
        content = (
          <Fragment>
            <li>
              <div>
                <Input
                  title="Username"
                  placeholder="Add a username"
                  value={settings.accountUsername}
                  error={settings.accountUsernameError}
                  onKeyUp={handleSetStateInput(typeState, 'accountUsernameTyping')}
                  onChange={handleSetStateInput(typeState, 'accountUsername')}
                />
                {settings.accountUsernameError === ''
                  ? (<i className="fa fa-check-circle" />)
                  : (<i className="fa fa-times-circle" />)}
              </div>
            </li>
            <li>
              <div>
                <Input
                  title="Email"
                  placeholder="Add an email"
                  value={settings.accountEmail}
                  error={settings.accountEmailError}
                  onKeyUp={handleSetStateInput(typeState, 'accountEmailTyping')}
                  onChange={handleSetStateInput(typeState, 'accountEmail')}
                />
                {settings.accountEmailError === ''
                  ? (<i className="fa fa-check-circle" />)
                  : (<i className="fa fa-times-circle" />)}
              </div>
            </li>
            <li>
              <div>
                <Button onClick={handleUpdateSettings('account')} loading={accountLoading} label="Update" />
              </div>
            </li>
            {modalAccount
              && (
              <Modal
                content={(
                  <div className={Helpers.mergeCss(Base.dBlock, Styles.modalConfirm, Base.textCenter)}>
                    <span className={Styles.locked}>
                      <i className="fas fa-lock" />
                    </span>
                    <h6 className={Base.margin0}>This changes is password protected</h6>
                    {settings.accountPasswordConfirmError
                      ? <p className="text-danger">{settings.accountPasswordConfirmError}</p> : <p className={Base.text}>Please enter password below to make changes</p>
                    }
                    <Input
                      type="password"
                      placeholder="Add a password"
                      value={settings.accountPasswordConfirm}
                      error=""
                      onChange={handleSetStateInput(typeState, 'accountPasswordConfirm')}
                    />
                    <Button onClick={handleUpdateSettingsConfirm('account')} loading={accountLoading} label="Submit" />
                  </div>
                )}
                goBack={handleSetState(typeState, 'modalAccount', !modalAccount)}
              />
              )
            }
          </Fragment>
        )
        break
      case 'password':
        content = (
          <Fragment>
            <li>
              <div>
                <Input
                  type="password"
                  title="Old password"
                  placeholder="Add an old password"
                  value={settings.oldPassword}
                  error={settings.oldPasswordError}
                  onChange={handleSetStateInput(typeState, 'oldPassword')}
                />
              </div>
            </li>
            <li>
              <div>
                <Input
                  type="password"
                  title="New password"
                  placeholder="Add a new password"
                  value={settings.password}
                  error={settings.passwordError}
                  onChange={handleSetStateInput(typeState, 'password')}
                />
              </div>
            </li>
            <li>
              <div>
                <Button onClick={handleUpdateSettings('password')} loading={passwordLoading} label="Update" />
              </div>
            </li>
            {modalPassword
              && (
              <Modal
                content={(
                  <div className={Helpers.mergeCss(Base.dBlock, Styles.modalConfirm, Base.textCenter)}>
                    <span className={Styles.locked}>
                      <i className="fas fa-lock" />
                    </span>
                    <h6 className={Base.margin0}>This changes is password protected</h6>
                    {settings.passwordPasswordConfirmError
                      ? <p className="text-danger">{settings.passwordPasswordConfirmError}</p> : <p className={Base.text}>Please enter password below to make changes</p>
                    }
                    <Input
                      type="password"
                      placeholder="Add a password"
                      value={settings.passwordPasswordConfirm}
                      error=""
                      onChange={handleSetStateInput(typeState, 'passwordPasswordConfirm')}
                    />
                    <Button onClick={handleUpdateSettingsConfirm('password')} loading={passwordLoading} label="Submit" />
                  </div>
                )}
                goBack={handleSetState(typeState, 'modalPassword', !modalPassword)}
              />
              )
            }
          </Fragment>
        )
        break
      case 'profile':
        content = (
          <Fragment>
            <li>
              <div className={Helpers.mergeCss(Base.dInlineFlex, Styles.wrapperUpload)}>
                <div className={Helpers.mergeCss(Base.dInlineBlock, Base.positionRelative)}>
                  {profileEditImage === null && this.renderProfilePicture(settings)}
                  <div className={Helpers.mergeCss(Base.dBlock, Styles.avatarEditorWrapper)}>
                    {profileEditImage !== null && (
                      <Fragment>
                        <span className={Styles.actionAvaWrapper}>
                          {profileEditImageResult === null && (
                          <span onClick={saveImage('settings')} className={Helpers.mergeCss(Styles.checkAva, Base.marginRight2)}>
                            <i className="la la-check" />
                          </span>
                          )}
                          <span onClick={handleSetStateFile('settings', 'delete')} className={Styles.deleteAva}>
                            <i className="la la-trash" />
                          </span>
                        </span>
                        {profileEditImageResult !== null && (
                          <img className={Styles.imgPreview} src={resultPreview} />
                        )}
                        {profileEditImageResult === null && (
                          <Fragment>
                            <AvatarEditor
                              ref={setEditorRef}
                              scale={parseFloat(scale)}
                              width={width}
                              height={height}
                              position={position}
                              onPositionChange={handlePositionChange('settings', 'position')}
                              rotate={parseFloat(rotate)}
                              borderRadius={width / (100 / borderRadius)}
                              border={0}
                              image={profileEditImage !== null && profileEditImage.preview}
                              className="editor-canvas"
                            />
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </div>
                </div>
                <div className={Base.dInlineBlock}>
                  <h6 className={Base.marginTop2}>Avatar</h6>
                  <Dropzone
                    onDrop={handleSetStateFile('settings', 'add')}
                    className={Helpers.mergeCss(Base.dInlineBlock, Styles.dropzoneWrapper)}
                  >
                    <button className={Base.btnDefault}>
                      Upload new
                    </button>
                  </Dropzone>
                  {profileAvatarError !== '' && (<span style={{ fontSize: '14px', fontWeight: '500' }} className="text-danger mt-2 d-block">{profileAvatarError}</span>)}
                  {profileEditImage !== null && profileEditImageResult === null && (
                    <div className={Helpers.mergeCss(Base.row, Base.margin0, Base.marginTop2)}>
                      <div className={Helpers.mergeCss(Base.col, Base.padding0, Base.w10)}>
                        <div className="input-range input-range-setup-profile">
                          <InputRange
                            maxValue={2}
                            minValue={1}
                            step={0.01}
                            value={scale}
                            onChange={handleScale('settings', 'scale')}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </li>
            <li>
              <div>
                <Input
                  title="Name"
                  placeholder="Add a name"
                  value={settings.profileName}
                  error={settings.profileNameError}
                  onChange={handleSetStateInput(typeState, 'profileName')}
                />
              </div>
            </li>
            <li>
              <div>
                <Input
                  title="Location"
                  placeholder="Add a location, eg. Jakarta, Indonesia"
                  value={settings.profileLocation}
                  error={settings.profileLocationError}
                  onChange={handleSetStateInput(typeState, 'profileLocation')}
                />
              </div>
            </li>
            <li>
              <div>
                <Input
                  type="textarea"
                  title="Bio"
                  placeholder="Add a bio"
                  value={settings.profileBio}
                  error={settings.profileBioError}
                  onChange={handleSetStateInput(typeState, 'profileBio')}
                />
              </div>
            </li>
            <li>
              <div>
                <Button onClick={handleUpdateSettings('profile')} loading={profileLoading} label="Update" />
              </div>
            </li>
          </Fragment>
        )
        break
      case 'members':
        content = (
          <Fragment>
            <li>
              <div className={Styles.emailMember(settings.membersToInvites.length > 0)}>
                <Input
                  title="Invite team member by email"
                  placeholder="Add an email"
                  value={settings.memberEmailInvitation}
                  error={settings.memberEmailInvitationError}
                  onChange={handleSetStateInput(typeState, 'memberEmailInvitation')}
                  onKeyUp={handleSetStateInput(typeState, 'memberEmailInvitationTyping')}
                />
                {settings.memberEmailInvitationError === ''
                  ? (settings.memberEmailInvitation.length > 0 ? (<i className="fa fa-check-circle" />) : '')
                  : (<i className="fa fa-times-circle" />)
                }
                {
                  <div className={Styles.memberInviteItem}>
                    {settings.openMembersToInvites && settings.membersToInvites.map(any => {
                      return(
                        <p onClick={settings.membersToInvitesLoading.filter(any => any.includes(any.email)).length > 0 ? null : handleMemberInvite(any.email, settings.membersToInvitesLoading)} key={any.id}>
                          <img className={Base.imgFluid} src={any.avatar.url}/>
                          <span>{any.email}</span>
                          {settings.membersToInvitesLoading.filter(item => item.includes(any.email)).length > 0 &&                          
                            <span className={Styles.loadingPosition}>
                              <Loading tiny={true}/>
                            </span>
                          }
                        </p>
                        )
                      })
                    }
                  </div>
                }
              </div>
              <hr/>
              <div>
                {settings.members.map(any => {
                  return(
                    <div key={any.email} className={Helpers.mergeCss(Styles.memberItem, Base.positionRelative, Base.dFlex)}>
                      <img className={Base.imgFluid} src={any.avatar} />
                      <span>{any.email}</span>
                      {
                       !any.status && <span className={Styles.team}>waiting confirmation</span>
                      }
                      {
                        settings.membersToInvitesDeleteConfirm.filter(item => item.includes(any.email)).length > 0 ?
                        <div
                          className={Base.dInlineBlock}
                          style={{
                            fontSize: '14px',
                            position: 'absolute',
                            right: 0,
                            color: '#6c757d',
                          }}
                        >
                          Delete member?
                          <div className={Helpers.mergeCss(Base.dInlineBlock, Base.marginLeft2)}>
                            <span onClick={settings.membersToInvitesDeleteConfirmLoading.filter(any => any.includes(any.email)).length > 0 ? null : 
                              handleDeleteMember(any.email, settings.membersToInvitesDeleteConfirmLoading, any.status)} 
                              style={{ marginRight: '3px', cursor: 'pointer' }}>Yes</span>
                            <span onClick={handleSetState('settings', 'membersToInvitesDeleteConfirm', settings.membersToInvitesDeleteConfirm.filter(item => !item.includes(any.email)))} 
                              style={{ marginLeft: '3px', cursor: 'pointer' }}>No</span>
                          </div>
                        </div>
                        :
                        state.user.info.attributes.team_created_by !== any.email &&
                        <span onClick={handleSetState(typeState, 'membersToInvitesDeleteConfirm', 
                            settings.membersToInvitesDeleteConfirm.filter(item => item.includes(any.eamil)).length > 0 ? 
                            settings.membersToInvitesDeleteConfirm : settings.membersToInvitesDeleteConfirm.concat(any.email))} 
                          className={Styles.removeButton}>
                          Remove
                        </span>
                      }
                    </div>
                  )
                })}
              </div>
            </li>
          </Fragment>
        )
        break
      case 'alert':
        content = (
          <Fragment>
            <li>
              <div>
                <Checkbox
                  onChange={handleSetState(typeState, 'alertCompanyNews', !alertCompanyNews)}
                  label="Get Marvlous news, announcements, and product updates"
                  checked={alertCompanyNews}
                />
              </div>
            </li>
            <li>
              <div>
                <Checkbox
                  onChange={handleSetState(typeState, 'alertAccountActivity', !alertAccountActivity)}
                  label={"Get important notifications about you or activity you've missed"}
                  checked={alertAccountActivity}
                />
              </div>
            </li>
            <li>
              <div>
                <Checkbox
                  onChange={handleSetState(typeState, 'alertMeetupsNearYou', !alertMeetupsNearYou)}
                  label="Get an email when a Marvlous Meetup is posted close to my location"
                  checked={alertMeetupsNearYou}
                />
              </div>
            </li>
            <li>
              <div>
                <Checkbox
                  onChange={handleSetState(typeState, 'alertSomeoneCommentOnDesign', !alertSomeoneCommentOnDesign)}
                  label="Someone comments on one of my designs"
                  checked={alertSomeoneCommentOnDesign}
                />
              </div>
            </li>
            <li>
              <div>
                <Checkbox
                  onChange={handleSetState(typeState, 'alertSomeoneMentionMe', !alertSomeoneMentionMe)}
                  label="Someone mentions me"
                  checked={alertSomeoneMentionMe}
                />
              </div>
            </li>
            <li>
              <div>
                <Checkbox
                  onChange={handleSetState(typeState, 'alertReceiveInvitations', !alertReceiveInvitations)}
                  label="I receive invitations to invite new players"
                  checked={alertReceiveInvitations}
                />
              </div>
            </li>
            <li>
              <div>
                <Checkbox
                  onChange={handleSetState(typeState, 'alertInvitationsExpired', !alertInvitationsExpired)}
                  label="My invitations are about to expire"
                  checked={alertInvitationsExpired}
                />
              </div>
            </li>
            <li>
              <div>
                <Checkbox
                  onChange={handleSetState(typeState, 'alertAnyoneSubscribed', !alertAnyoneSubscribed)}
                  label="Anyone subscribes me"
                  checked={alertAnyoneSubscribed}
                />
              </div>
            </li>
            <li>
              <div>
                <Button onClick={handleUpdateSettings('alert')} loading={alertLoading} label="Update" />
              </div>
            </li>
          </Fragment>
        )
        break
      default:
        content = (
          <Fragment>
            <li>
              <div>
                <Input
                  title="Facebook"
                  placeholder="Add a facebook, eg. facebook.com/marvlous"
                  value={settings.facebookLink}
                  error={settings.facebookLinkError}
                  onChange={handleSetStateInput(typeState, 'facebookLink')}
                />
              </div>
            </li>
            <li>
              <div>
                <Input
                  title="Instagram"
                  placeholder="Add an instagram, eg. instagram.com/marvlous"
                  value={settings.instagramLink}
                  error={settings.instagramLinkError}
                  onChange={handleSetStateInput(typeState, 'instagramLink')}
                />
              </div>
            </li>
            <li>
              <div>
                <Input
                  title="Twitter"
                  placeholder="Add a twitter eg. twitter.com/marvlous"
                  value={settings.twitterLink}
                  error={settings.twitterLinkError}
                  onChange={handleSetStateInput(typeState, 'twitterLink')}
                />
              </div>
            </li>
            <li>
              <div>
                <Input
                  title="Youtube"
                  placeholder="Add a youtube, eg. youtube.com/channel/marvlous"
                  value={settings.youtubeLink}
                  error={settings.youtubeLinkError}
                  onChange={handleSetStateInput(typeState, 'youtubeLink')}
                />
              </div>
            </li>
            <li>
              <div>
                <Input
                  title="Medium"
                  placeholder="Add a medium, eg. medium.com/@marvlous"
                  value={settings.mediumLink}
                  error={settings.mediumLinkError}
                  onChange={handleSetStateInput(typeState, 'mediumLink')}
                />
              </div>
            </li>
            <li>
              <div>
                <Input
                  title="Website"
                  placeholder="Add a website, eg. marvlous.com"
                  value={settings.websiteLink}
                  error={settings.websiteLinkError}
                  onChange={handleSetStateInput(typeState, 'websiteLink')}
                />
              </div>
            </li>
            <li>
              <div>
                <Button onClick={handleUpdateSettings('links')} loading={loadingLink} label="Update" />
              </div>
            </li>
          </Fragment>
        )
    }

    return content
  }

  renderContentTitle = (tab) => {
    let content = ''
    switch (tab) {
      case 'account':
        content = 'Account'
        break
      case 'password':
        content = 'Password'
        break
      case 'links':
        content = 'Links'
        break
      case 'alert':
        content = 'Alert (email notifications)'
        break
      case 'members':
        content = 'Members'
        break
      default:
        content = 'Profile'
    }

    return content
  }

  render() {
    const { props: { state, typeState, handleSetState, handleSetMergeState } } = this
    const { activeTab } = state.settings

    return (
      <Fragment>
        {state.user.info !== null && (
        <div className={Styles.scrollSettings}>
          <div className={Helpers.mergeCss(Base.row, Base.h100, Styles.rowSettings)}>
            <div className={Helpers.mergeCss(Base.col, Base.w12, Base.h100, Styles.settingsWrapper)}>
              <div className={Helpers.mergeCss(Base.row, Base.h100)}>
                <div className={Helpers.mergeCss(Base.col, Base.h100, Base.w12)}>
                  <div className={Helpers.mergeCss(Base.row, Styles.settingsLabel)}>
                    <div className={Helpers.mergeCss(Base.col, Base.w5, Styles.w5)}>
                      {`${state.user.info.attributes.name} accounts`} 
                      {state.user.info.attributes.type_user === 'team' && (
                        <span className={Styles.team}>Team</span>
                      )}
                    </div>
                    <div className={Helpers.mergeCss(Base.col, Styles.borderLeft, Styles.mobileNoPadding, Base.w7, Styles.w7)}>
                      <div className={Styles.contentDesktop}>
                        {this.renderContentTitle(activeTab)}
                      </div>
                      <div className={Helpers.mergeCss(Styles.contentMobile, Styles.marginMinus)}>
                        <div onClick={handleSetState(typeState, 'collapseOpen', !state.settings.collapseOpen)} className={Styles.opener(state.settings.collapseOpen)}>
                          {this.renderContentTitle(activeTab)}
                        </div>
                        {
                          state.settings.collapseOpen &&
                          <Fragment>
                            {state.user.info.attributes.type_user === 'team' ? 
                              Data.settingsMenu.filter(item => item.label !== activeTab).map(item => {
                                const newState = {
                                  activeTab: item.label,
                                  collapseOpen: false,
                                }
                                return(
                                  <div onClick={handleSetMergeState('settings', newState)} key={item.id} className={Styles.menuItem}>
                                    {item.title}
                                  </div>
                                )
                              }) 
                              : 
                              Data.settingsMenu.filter(item => item.label !== 'members').filter(item => item.label !== activeTab).map(item => {
                                const newState = {
                                  activeTab: item.label,
                                  collapseOpen: false,
                                }
                                return(
                                  <div onClick={handleSetMergeState('settings', newState)} key={item.id} className={Styles.menuItem}>
                                    {item.title}
                                  </div>
                                )
                              })
                            }
                          </Fragment>
                        }
                      </div>
                    </div>
                  </div>
                  <div className={Helpers.mergeCss(Base.row, Base.h100, Styles.settingsLabel)}>
                    <div className={Helpers.mergeCss(Base.col, Base.w5, Styles.w5, Styles.settingsMenuLeft)}>
                      <ul className={Helpers.mergeCss(Base.ulUnstyled, Base.margin0, Styles.settingsMenu, Styles.settingsMenuLeft)}>
                        <li className={Styles.activeMenu(activeTab === 'links')}><div onClick={handleSetState(typeState, 'activeTab', 'links')}>Links</div></li>
                        <li className={Styles.activeMenu(activeTab === 'profile')}><div onClick={handleSetState(typeState, 'activeTab', 'profile')}>Profile</div></li>
                        {state.user.info.attributes.type_user === 'team' && <li className={Styles.activeMenu(activeTab === 'members')}><div onClick={handleSetState(typeState, 'activeTab', 'members')}>Members</div></li>}
                        <li className={Styles.activeMenu(activeTab === 'account')}><div onClick={handleSetState(typeState, 'activeTab', 'account')}>Account</div></li>
                        <li className={Styles.activeMenu(activeTab === 'password')}><div onClick={handleSetState(typeState, 'activeTab', 'password')}>Password</div></li>
                        <li className={Styles.activeMenu(activeTab === 'alert')}><div onClick={handleSetState(typeState, 'activeTab', 'alert')}>Alert (email notifications)</div></li>
                      </ul>
                    </div>
                    <div className={Helpers.mergeCss(Base.col, Styles.borderLeft, Styles.w7, Base.w7)}>
                      <div className={Helpers.mergeCss(Base.dBlock, Styles.contentSettings(activeTab))}>
                        <ul className={Helpers.mergeCss(Base.ulUnstyled, Base.margin0, Styles.settingsMenu)}>
                          {this.renderContent(activeTab, state.settings, state)}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        )}
      </Fragment>
    )
  }
}

export default Settings
