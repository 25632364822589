import base from './api.base'

function index() {
  return base.get('users')
}

function create(data) {
  return base.post('users', data)
}

function show(id) {
  return base.get(`users/${id}`)
}

function userNameOrEmail(type, typeUser, data) {
  return base.post(`users/available/${type}/${typeUser}`, data)
}

function profile(data) {
  return base.post('users/profile/', data)
}

function team(data) {
  return base.post('users/team/', data)
}

function active() {
  return base.get('users/active')
}

function search(data) {
  return base.get(`users/search/${data}`)
}

function detail(data) {
  return base.get(`users/detail/${data}`)
}

function cover(data) {
  return base.post('users/cover/', data)
}

function avatar(data) {
  return base.post('users/avatar/', data)
}

function password(data) {
  return base.post('users/password/', data)
}

const ApiUsers = {
  index,
  create,
  show,
  userNameOrEmail,
  profile,
  team,
  active,
  search,
  detail,
  cover,
  avatar,
  password,
}

export default ApiUsers
