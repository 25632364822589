import React, { Component, Fragment } from 'react'
import { Loading } from 'components'
import {
  Helpers, Base, Routes, history, Validation,
} from 'utils'
import Styles from './Styles'
import marvlous from 'images/marvlous.png'

const Header = ({ onClick, navbarOpen }) => {
  return(
    <header>
      <nav className={Helpers.mergeCss(Base.navbar, Styles.navbar)}>
        <div className={Helpers.mergeCss(Base.container, Base.alignItemsCenter, Base.positionRelative, Styles.container, Base.dFlex)}>
          <div onClick={() => {
              window.location.href = `${process.env.DEFAULT_DOMAIN}`
            }} className={Helpers.mergeCss(Base.navbarBrand, Styles.navbarBrand)}>
            <img src={marvlous} />
            <span>
              Marvlous
            </span>
          </div>
          <div onClick={onClick} className={Styles.hamburgerMenu}>
            <span/>
            <span/>
            <span/>
          </div>
          <div className={Helpers.mergeCss(Base.navbarCollapse, Styles.navbarCollapse)} id="navbarSupportedContent">
            <ul className={Helpers.mergeCss(Base.navbarNav, Styles.navbarNav)}>
              <li>
                <div onClick={() => {
                  window.location.href = `${process.env.DEFAULT_JOIN_DOMAIN}`
                }} className={Helpers.mergeCss(Base.navbarLink, Styles.navbarLink(window.location.host.includes('join')))}>Join</div>
              </li>
              <li>
                <div onClick={() => {
                  window.location.href = `${process.env.DEFAULT_ABOUT_DOMAIN}`
                }} className={Helpers.mergeCss(Base.navbarLink, Styles.navbarLink(window.location.host.includes('about')))}>About</div>
              </li>
              <li>
                <div onClick={() => {
                  window.location.href = `${process.env.DEFAULT_TERMS_DOMAIN}`
                }} className={Helpers.mergeCss(Base.navbarLink, Styles.navbarLink(window.location.host.includes('terms')))}>Terms</div>
              </li>
              <li>
                <div onClick={() => {
                  window.location.href = `${process.env.DEFAULT_PRIVACY_DOMAIN}`
                }} className={Helpers.mergeCss(Base.navbarLink, Styles.navbarLink(window.location.host.includes('privacy')))}>Privacy</div>
              </li>
              <li>
                <div onClick={() => {
                  window.location.href = `${process.env.DEFAULT_SUPPORT_DOMAIN}`
                }} className={Helpers.mergeCss(Base.navbarLink, Styles.navbarLink(window.location.host.includes('support')))}>Support</div>
              </li>
            </ul>
            <ul className={Helpers.mergeCss(Base.navbarNav, Styles.navbarNav)}>
              <li><a target="_blank" rel="noopener" className={Base.navbarLink} href="https://www.linkedin.com/company/marvlous"><i className="fab fa-linkedin" /></a></li>
              <li><a target="_blank" rel="noopener" className={Base.navbarLink} href="https://www.instagram.com/marvlouscom"><i className="fab fa-instagram" /></a></li>
              <li><a target="_blank" rel="noopener" className={Base.navbarLink} href="https://www.facebook.com/marvlouscom"><i className="fab fa-facebook" /></a></li>
              <li><a target="_blank" rel="noopener" className={Base.navbarLink} href="https://www.twitter.com/marvlouscom"><i className="fab fa-twitter" /></a></li>
            </ul>
          </div>
        </div>
        <div className={Helpers.mergeCss(Styles.menu(navbarOpen), Base.w12)}>
          <ul className={Helpers.mergeCss(Base.ulUnstyled, Base.dBlock, Base.w100)}>
            <li>
              <div onClick={() => {
                window.location.href = `${process.env.DEFAULT_JOIN_DOMAIN}`
              }} className={Helpers.mergeCss(Base.navbarLink, Styles.navbarLink(window.location.host.includes('join')))}>Join</div>
            </li>
            <li>
              <div onClick={() => {
                window.location.href = `${process.env.DEFAULT_ABOUT_DOMAIN}`
              }} className={Helpers.mergeCss(Base.navbarLink, Styles.navbarLink(window.location.host.includes('about')))}>About</div>
            </li>
            <li>
              <div onClick={() => {
                window.location.href = `${process.env.DEFAULT_TERMS_DOMAIN}`
              }} className={Helpers.mergeCss(Base.navbarLink, Styles.navbarLink(window.location.host.includes('terms')))}>Terms</div>
            </li>
            <li>
              <div onClick={() => {
                window.location.href = `${process.env.DEFAULT_PRIVACY_DOMAIN}`
              }} className={Helpers.mergeCss(Base.navbarLink, Styles.navbarLink(window.location.host.includes('privacy')))}>Privacy</div>
            </li>
            <li>
              <div onClick={() => {
                window.location.href = `${process.env.DEFAULT_SUPPORT_DOMAIN}`
              }} className={Helpers.mergeCss(Base.navbarLink, Styles.navbarLink(window.location.host.includes('support')))}>Support</div>
            </li>
          </ul>
          <ul className={Helpers.mergeCss(Base.ulUnstyled, Base.dFlex, Base.w100)}>
            <li><a target="_blank" rel="noopener" className={Base.navbarLink} href="https://www.linkedin.com/company/marvlous"><i className="fab fa-linkedin" /></a></li>
            <li><a target="_blank" rel="noopener" className={Base.navbarLink} href="https://www.instagram.com/marvlouscom"><i className="fab fa-instagram" /></a></li>
            <li><a target="_blank" rel="noopener" className={Base.navbarLink} href="https://www.facebook.com/marvlouscom"><i className="fab fa-facebook" /></a></li>
            <li><a target="_blank" rel="noopener" className={Base.navbarLink} href="https://www.twitter.com/marvlouscom"><i className="fab fa-twitter" /></a></li>
          </ul>
        </div>
      </nav>
    </header>
  )
}

export default Header