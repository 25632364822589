import React, { Component, Fragment } from 'react'
import {
  Helpers, Base, Routes, history, Validation,
} from 'utils'
import { Router, Route, Switch } from 'react-router-dom'
import {
  ApiDesigns,
  ApiUsers,
  ApiTags,
  ApiNotifications,
  ApiFinds,
  ApiSubscriptions,
  ApiBookmarks,
  ApiAnalytics,
  ApiSettings,
  ApiRevenues,
  ApiTeams,
  ApiMembers
} from 'api'
import { v4 } from 'uuid'
import ColorThief from 'colorthief'
import axios from 'axios'
import queryString from 'query-string'
import gifFrames from 'gif-frames'
import marvlous from 'images/marvlous.png'
import Fingerprint2 from 'fingerprintjs2'
import Styles from './Styles'
import Data from './Data'
import Container from './Container'
import Model from './Model'
import Store from './Store'


// page
import DesignUpload from './DesignUpload/DesignUpload'

import Feed from './Feed/Feed'
import Notifications from './Notifications/Notifications'

// feed detail
import DesignDetail from './DesignDetail/DesignDetail'

// portfolio
import Portfolio from './Portfolio/Portfolio'

import Trends from './Trends/Trends'

import Search from './Search/Search'

import Subscriptions from './Subscriptions/Subscriptions'

import Saved from './Saved/Saved'

import Analytics from './Analytics/Analytics'

import Settings from './Settings/Settings'

import Revenue from './Revenue/Revenue'

import functions from '../functions'

// assets


class Index extends Component {
  state = {
    login: document.getElementById('main-app').getAttribute('data-login') === 'true',
    designUpload: {
      coverData: null,
      imagesData: [],
      filesData: [],
      coverValid: false,
      heightImage: '',
      titleDesign: '',
      titleDesignError: '',
      descriptionDesign: '',
      descriptionDesignTyping: '',
      descriptionDesignError: '',
      descriptionDesignFocus: false,
      commentActive: false,
      tagOption: [],
      tagOptionValue: [],
      colorValue: [],
      loadingColor: '',
      resultUserData: [],
      resultTagData: [],
      topRightMenuOpen: false,
      formLoading: false,
      teamsOption: [],
      teamsOptionValue: {},
    },
    feed: {
      page: 1,
      activeHeightScroll: '',
      loadingData: false,
      endOfData: false,
      loadingFeed: true,
      feedData: [],
      modalView: false,
      tags: [],
      slug: '',
      viewSlug: '',
      design: null,
      viewComment: '',
      previousSlug: '',
      activeScrollHorizontal: 0,
      scrollWidth: '',
      floatMenu: false,
    },
    saved: {
      page: 1,
      activeHeightScroll: '',
      loadingData: false,
      endOfData: false,
      modalView: false,
      slugView: '',
      slug: '',
      design: null,
      viewComment: '',
      previousSlug: '',
      data: [],
      loading: true,
      isDataExist: false,
    },
    subscriptions: {
      page: 1,
      activeHeightScroll: '',
      loadingData: false,
      endOfData: false,
      modalView: false,
      slugView: '',
      slug: '',
      design: null,
      viewComment: '',
      previousSlug: '',
      data: [],
      loading: true,
      isDataExist: false,
    },
    portfolio: {
      modalView: false,
      slug: '',
      viewSlug: '',
      design: null,
      viewComment: '',
      activeTab: null,
      previousSlug: '',
      user: null,
    },
    search: {
      query: '',
      modalView: false,
      slug: '',
      viewSlug: '',
      design: null,
      viewComment: '',
      keyword: '',
      keywordFocus: false,
      keywordTyping: '',
      resultKeyword: null,
      open: false,
      previousSlug: '',
    },
    notifications: {
      modalView: false,
      slug: '',
      viewSlug: '',
      design: null,
      viewComment: '',
      previousSlug: '',
      data: [],
      loading: true,
      isDataExist: false,
      fetchingData: true,
      count: '',
      page: 1,
      activeHeightScroll: '',
      loadingData: false,
      endOfData: false,
    },
    activeProfile: {
      username: '',
    },
    container: {
      menuMore: false,
      menuLeft: false,
    },
    user: {
      info: null,
    },
    analytics: {
      summary: [],
      engagement: [],
      page: 1,
      activeHeightScroll: '',
      loadingData: false,
      endOfData: false,
      loading: true,
      fetchingData: true,
      isDataExist: false,
      activeStatistics: 'view',
      modalView: false,
      slug: '',
      design: null,
      viewComment: '',
    },
    settings: {
      activeTab: 'links',
      profileName: '',
      profileNameError: '',
      profileBio: '',
      profileBioError: '',
      profileLocation: '',
      profileLocationError: '',
      profileAvatar: '',
      profileAvatarError: '',
      profileEditImage: null,
      profileEditImageResult: null,
      profileLoading: false,
      memberEmailInvitation: '',
      memberEmailInvitationError: '',
      memberEmailInvitationTyping: '',
      members: [],
      membersToInvites: [],
      membersToInvitesLoading: [],
      membersToInvitesDeleteConfirm: [],
      membersToInvitesDeleteConfirmLoading: [],
      openMembersToInvites: false,
      accountUsername: '',
      accountUsernameOld: '',
      accountUsernameTyping: '',
      accountUsernameError: '',
      accountEmail: '',
      accountEmailOld: '',
      accountEmailTyping: '',
      accountEmailError: '',
      accountPasswordConfirm: '',
      accountPasswordConfirmError: '',
      accountLoading: false,
      password: '',
      passwordError: '',
      oldPassword: '',
      oldPasswordError: '',
      passwordPasswordConfirm: '',
      passwordPasswordConfirmError: '',
      passwordLoading: false,
      facebookLink: '',
      facebookLinkError: '',
      instagramLink: '',
      instagramLinkError: '',
      twitterLink: '',
      twitterLinkError: '',
      youtubeLink: '',
      youtubeLinkError: '',
      mediumLink: '',
      mediumLinkError: '',
      websiteLink: '',
      websiteLinkError: '',
      loadingLink: false,
      alertCompanyNews: true,
      alertAccountActivity: true,
      alertMeetupsNearYou: true,
      alertSomeoneCommentOnDesign: true,
      alertSomeoneMentionMe: true,
      alertReceiveInvitations: true,
      alertInvitationsExpired: true,
      alertAnyoneSubscribed: true,
      alertLoading: false,
      modalAccount: false,
      modalPassword: false,
      allowZoomOut: false,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      rotate: 0,
      borderRadius: 0,
      width: 100,
      height: 100,
      modalView: false,
      slug: '',
      design: null,
      viewComment: '',
      collapseOpen: false,
    },
    revenue: {
      list: [],
      detail: null,
      page: 1,
      activeHeightScroll: '',
      loadingData: false,
      endOfData: false,
      loading: true,
      fetchingData: true,
      modalView: false,
      slug: '',
      design: null,
      viewComment: '',
    },
    trends: {
      modalView: false,
      slug: '',
      design: null,
      viewComment: '',
    },
    fingerPrint: '',
  }

  async componentDidMount() {
    const { state } = this
    const dataDesigns = await ApiDesigns.index(state.feed.page)
    const dataUsers = await ApiUsers.active()
    const dataTeams = await ApiTeams.active()
    const dataTags = await ApiTags.popular()
    const dataSuggestion = await ApiTags.suggestion()

    const login = document.getElementById('main-app')
      .getAttribute('data-login')

    const designUpload = {
      imagesData: Data.images,
      filesData: Data.files,
      tagOption: dataSuggestion.data,
      teamsOption: dataTeams.data,
    }

    const feed = {
      feedData: dataDesigns.data,
      tags: dataTags.hasOwnProperty('data') && functions.sortByAlphabet(dataTags.data),
      loadingFeed: false,
    }

    const options = { }
    Fingerprint2.get(options, (components) => {
      const values = components.map(component => component.value)
      const fingerPrint = Fingerprint2.x64hash128(values.join(''), 31)
      this.setState({ fingerPrint })
    })

    if (login === 'true') {
      // setInterval(() => {
      this.getRealTimeNotif('feed')
      const dataSubscriptions = await ApiSubscriptions.index(state.subscriptions.page)
      const dataBookmarks = await ApiBookmarks.list(state.saved.page)

      const user = {
        info: dataUsers.data,
      }

      const settings = {
        profileName: dataUsers.data.attributes.name,
        profileBio: dataUsers.data.attributes.bio === null ? '' : dataUsers.data.attributes.bio,
        profileLocation: dataUsers.data.attributes.location === null ? '' : dataUsers.data.attributes.location,
        profileWebsite: dataUsers.data.attributes.website === null ? '' : dataUsers.data.attributes.website,
        profileAvatar: dataUsers.data.attributes.avatar.url,
        accountUsername: dataUsers.data.attributes.username,
        accountUsernameOld: dataUsers.data.attributes.username,
        accountEmail: dataUsers.data.attributes.email,
        accountEmailOld: dataUsers.data.attributes.email,
        members: dataUsers.data.attributes.members.concat(dataUsers.data.attributes.members_invite),
        alertCompanyNews: dataUsers.data.attributes.alert.company_news,
        alertAccountActivity: dataUsers.data.attributes.alert.account_activity,
        alertMeetupsNearYou: dataUsers.data.attributes.alert.meetups_near_you,
        alertSomeoneCommentOnDesign: dataUsers.data.attributes.alert.someone_comment_on_design,
        alertSomeoneMentionMe: dataUsers.data.attributes.alert.someone_mention_me,
        alertReceiveInvitations: dataUsers.data.attributes.alert.receive_invitations,
        alertInvitationsExpired: dataUsers.data.attributes.alert.invitations_expired,
        alertAnyoneSubscribed: dataUsers.data.attributes.alert.anyone_subscribed,
        facebookLink: dataUsers.data.attributes.link !== null ? dataUsers.data.attributes.link.facebook : '',
        instagramLink: dataUsers.data.attributes.link !== null ? dataUsers.data.attributes.link.instagram : '',
        twitterLink: dataUsers.data.attributes.link !== null ? dataUsers.data.attributes.link.twitter : '',
        youtubeLink: dataUsers.data.attributes.link !== null ? dataUsers.data.attributes.link.youtube : '',
        mediumLink: dataUsers.data.attributes.link !== null ? dataUsers.data.attributes.link.medium : '',
        websiteLink: dataUsers.data.attributes.link !== null ? dataUsers.data.attributes.link.website : '',
      }

      const saved = {
        data: dataBookmarks.data,
        loading: false,
        isDataExist: dataBookmarks.data.length > 0,
      }

      const subscriptions = {
        data: dataSubscriptions.data,
        loading: false,
        isDataExist: dataSubscriptions.data.length > 0,
      }

      this.setState(currState => ({
        saved: functions.mergeState(currState.saved, saved),
        designUpload: functions.mergeState(currState.designUpload, designUpload),
        subscriptions: functions.mergeState(currState.subscriptions, subscriptions),
        feed: functions.mergeState(currState.feed, feed),
        user: functions.mergeState(currState.user, user),
        settings: functions.mergeState(currState.settings, settings),
      }), () => {
        this.initializeScrollHorizontal()
      })
    } else {
      this.setState(currState => ({
        designUpload: functions.mergeState(currState.designUpload, designUpload),
        feed: functions.mergeState(currState.feed, feed),
      }), () => {
        this.initializeScrollHorizontal()
      })
    }

    const activePage = window.location.pathname.split('/').filter(item => item)[0]
    this.metaTitleDefault(activePage)

    history.listen((location, action) => {
      const activePage = location.pathname.split('/').filter(item => item)[0]
      this.metaTitleDefault(activePage)
    })
  }

  metaTitleDefault = (activePage) => {
    if (Data.menu.filter(any => (activePage === any.page
      || (typeof activePage === 'undefined'))).length > 0 || activePage === 'upload') {
      
      const metaData = {
        title: typeof activePage === 'undefined' ? '' : `${activePage[0].toUpperCase() + activePage.slice(1)}`,
        image: '',
        url: document.URL,
        description: 'Let the world know your masterpiece artwork design. Marvlous is number #1 place to showcase your masterpiece artwork design and monetize it',
      }

      this.updateMetaTitle(metaData)
    }
  }

  initializeScrollHorizontal = () => {
    if (document.getElementById('design-trends') !== null) {
      const scrollWidth = document.getElementById('design-trends').scrollWidth
      this.handleSetState('feed', 'scrollWidth', scrollWidth)()
    }
  }

  filesValid = async (files) => {
    const size = Helpers.readablizeBytes(files[0].size).split(' ')[0]
    const sizeFile = Helpers.readablizeBytes(files[0].size)

    let state
    if (Helpers.isImage(files[0].type)) {
      const image = await Helpers.addImageProcess(files[0].preview)
      const imageRatio = (image.width / image.height).toFixed(1)
      if (!(+imageRatio <= 1.3)) {
        state = {
          valid: false,
          error: `wrong image ratio, ratio should be (4:3) <br/> or  (4:ratio >= 3)`,
        }
      } else if (sizeFile.includes('MB') && size >= 10.00
          || sizeFile.includes('GB') && size >= 1.00
          || sizeFile.includes('TB') && size >= 1.00
          || sizeFile.includes('PB') && size >= 1.00) {
        state = {
          valid: false,
          error: 'image size too big',
        }
      } else if (sizeFile.includes('KB') && size <= 10.00) {
        state = {
          valid: false,
          error: 'image size too small',
        }
      } else {
        state = {
          valid: true,
          error: '',
        }
      }
    } else if (files[0].type.includes('mp4')) {
      const video = await Helpers.addVideoProcess(files[0].preview)
      if (!(video.width >= 800 && video.height >= 600)) {
        state = {
          valid: false,
          error: `video ${!(video.width >= 800) && 'width'} ${!(video.width >= 800)
            && !(video.height >= 600) && '&'} ${!(video.height >= 600) && 'height'} too small`,
        }
      } else if (sizeFile.includes('MB') && size >= 10.00
          || sizeFile.includes('GB') && size >= 1.00
          || sizeFile.includes('TB') && size >= 1.00
          || sizeFile.includes('PB') && size >= 1.00) {
        state = {
          valid: false,
          error: 'video size too big',
        }
      } else if (sizeFile.includes('KB') && size <= 50.00) {
        state = {
          valid: false,
          error: 'video size too small',
        }
      } else {
        state = {
          valid: true,
          error: '',
        }
      }
    }

    process.env.ENV_APP === 'development' && console.log(state, files[0], sizeFile)
    return state
  }

  handleSetStateSmallCover = (type, subtype, id) => async (files) => {
    const { imagesData } = this.state[type]

    const validFile = await this.filesValid(files)

    const newFilesData = imagesData.map((data) => {
      if (id === data.id) {
        return { ...data, imageData: files[0], ...validFile }
      }
      return data
    })

    this.setState(currState => ({
      designUpload: functions.createStateObj(currState.designUpload, 'imagesData', newFilesData),
    }), async () => {
      if (Helpers.isImage(newFilesData[0].imageData.type)) {
        this.generateColor(newFilesData[0].imageData.preview)

        if (newFilesData[0].imageData.type.includes('gif')) {
          const canvas = await gifFrames({ url: newFilesData[0].imageData.preview, frames: 0, outputType: 'canvas' })
            .then(data => data[0].getImage().toDataURL())
          const data = await fetch(canvas).then(res => res.blob())
          const metadata = { type: newFilesData[0].imageData.type, lastModified: new Date().getTime(), preview: window.URL.createObjectURL(data) }
          const resultImage = new File([data], newFilesData[0].imageData.name, metadata)
          this.handleSetState('designUpload', 'coverData', resultImage)()
        }
      } else {
        Helpers.addVideoProcess(newFilesData[0].imageData.preview).then((video) => {
          if (video.width >= 800 && video.height >= 600) {
            setTimeout(async () => {
              const canvas = document.getElementById('thecanvas')
              const thevideo = document.getElementById('cover-video')
              canvas.width = video.width
              canvas.height = video.height
              canvas.getContext('2d').drawImage(thevideo, 0, 0, video.width, video.height)
              const url = canvas.toDataURL()
              const img = document.getElementById('img-preview')
              img.src = url
              this.generateColor(url)
              const data = await fetch(url).then(res => res.blob())
              const metadata = { type: 'image/png', lastModified: new Date().getTime(), preview: window.URL.createObjectURL(data) }
              const name = newFilesData[0].imageData.name.substring(0, newFilesData[0].imageData.name.length - 4)
              const resultImage = new File([data], `${name}.png`, metadata)
              this.handleSetState('designUpload', 'coverData', resultImage)()
              thevideo.play()
            }, 3000)
          }
        })
      }
    })
  }

  getRealTimeNotif = async (type) => {
    const { fetchingData, page } = this.state.notifications
    const dataCount = await ApiNotifications.count()
    if (type === 'feed') {
      this.setState(currState => ({
        notifications: functions.createStateObj(currState.notifications, 'count', dataCount.data),
      }))
    } else if (fetchingData) {
      const dataNotifications = await ApiNotifications.index(1)
      const state = {
        count: dataCount.data,
        data: dataNotifications.data,
        loading: false,
        isDataExist: dataNotifications.data.length > 0,
        endOfData: false,
        loadingData: false,
        page: 1,
        activeHeightScroll: '',
      }

      this.setState(currState => ({
        notifications: functions.mergeState(currState.notifications, state),
      }))
    }
  }

  getAnalytics = async () => {
    const {
      page, summary, fetchingData, activeStatistics,
    } = this.state.analytics
    if (fetchingData) {
      if (summary.length > 0 && summary.find(any => any.title === activeStatistics).statisticsData.length === 0) {
        this.getChart(activeStatistics)
      } else if (summary.length === 0) {
        const dataEngagement = await ApiAnalytics.engagement(page)
        const dataSummarry = await ApiAnalytics.summary()
        const newSummary = summary.length === 0 ? Data.analytics.map(any => ({
          ...any,
          data: dataSummarry.data[any.id],
        })) : summary.map(any => ({
          ...any,
          data: dataSummarry.data[any.id],
        }))

        const state = {
          engagement: dataEngagement.data,
          summary: newSummary,
          isDataExist: dataSummarry.data.length,
        }

        this.setState(currState => ({
          analytics: functions.mergeState(currState.analytics, state),
        }), () => {
          this.handleSetState('analytics', 'loading', false)()
          const { activeStatistics } = this.state.analytics
          this.getChart(activeStatistics)
        })
      }
    }
  }

  getChart = async (type) => {
    const { summary } = this.state.analytics
    const dataSummary = summary.find(any => any.title === type)

    if (dataSummary.fetchingData) {
      const dataStatistics = await ApiAnalytics.statistics(type)
      const newSummary = summary.map((any) => {
        if (any.title === type) {
          return {
            ...any,
            statisticsData: dataStatistics.data,
            fetchingData: false,
          }
        }
        return { ...any }
      })

      const state = {
        summary: newSummary,
      }

      this.setState(currState => ({
        analytics: functions.mergeState(currState.analytics, state),
      }))
    }
  }

  getRevenue = async () => {
    const { page, summary, fetchingData } = this.state.revenue
    if (fetchingData) {
      const list = await ApiRevenues.list(page)
      const revenue = await ApiRevenues.detail()
      const state = {
        list: list.data,
        revenue: revenue.data,
        fetchingData: false,
        loading: false,
      }
      this.setState(currState => ({
        revenue: functions.mergeState(currState.revenue, state),
      }), () => {

      })
    }
  }

  generateColor = (url) => {
    const colorThief = new ColorThief()
    Helpers.addImageProcess(url).then((image) => {
      colorThief.getPalette(image).map(any => (
        process.env.ENV_APP === 'development' && console.log(`%c Image color rgb: ${any.map(yolo => yolo).join(',')}, hexacode: #${Helpers.rgbToHex(any[0], any[1], any[2])}`,
          `background: rgba(${any.map(yolo => yolo).join(',')}); 
              color: #fff;
              border-radius: 2px;
              box-shadow: 0 1px 4px rgba(0,0,0,0.05);
              font-weight: bold;
              width: 100%;
              padding: 3px 5px;`)
      ))

      const colorValue = colorThief.getPalette(image).map(any => Helpers.rgbToHex(any[0], any[1], any[2]))
      const dominantColorValue = colorThief.getColor(image)
      process.env.ENV_APP === 'development' && console.log(`%c Image dominant color rgb: ${dominantColorValue.map(yolo => yolo).join(',')}, hexacode: #${Helpers.rgbToHex(dominantColorValue[0], dominantColorValue[1], dominantColorValue[2])}`,
          `background: rgba(${dominantColorValue.map(yolo => yolo).join(',')}); 
              color: #fff;
              border-radius: 2px;
              box-shadow: 0 1px 4px rgba(0,0,0,0.05);
              font-weight: bold;
              width: 100%;
              padding: 3px 5px;`
      )

      const newState = {
        colorValue: colorValue,
        loadingColor: Helpers.rgbToHex(dominantColorValue[0], dominantColorValue[1], dominantColorValue[2])
      }

      this.setState(currState => ({
        designUpload: functions.mergeState(currState.designUpload, newState),
      }))
    })
  }

  handleSetStateCover = (type, subtype) => (files) => {

  }

  handleDeleteCover = (type, subtype) => () => {
    this.setState(currState => ({
      [type]: functions.createStateObj(currState[type], subtype, null),
    }))
  }

  handleDeleteTinyCover = (type, subtype, id) => () => {
    const imagesData = this.state[type][subtype]

    const newImagesData = imagesData.map((item) => {
      if (item.id === id) {
        return {
          ...item, imageData: null, error: '', valid: false,
        }
      }
      return { ...item }
    })

    this.setState(currState => ({
      [type]: functions.createStateObj(currState[type], subtype, newImagesData),
    }))
  }

  handleOptions = (value, options) => () => {
    const { state: { designUpload: { descriptionDesign } }, getMention, getHashtag } = this
    const cursor = this.getCaretPosition(document.getElementsByTagName('textarea')[0])
    const theOptions = options === '#' ? getHashtag(descriptionDesign, cursor) : getMention(descriptionDesign, cursor)
    const newDescriptionDesign = descriptionDesign.split(' ').map((any, index, array) => {
      if (!any.includes('\n') && any === theOptions) {
        const atSignPosition = any.indexOf(options)
        return (index == array.length - 1) ? `${options}${any.substr(0, atSignPosition)}${value} ` : `${options}${any.substr(0, atSignPosition)}${value}`
      } if (any.includes('\n') && any.includes(options)) {
        return any.split('\n').map((any) => {
          if (any === theOptions) {
            return `${options}${value} `
          }
          return any
        }).join('\n')
      }
      return any
    }).join(' ')

    const newState = {
      descriptionDesign: newDescriptionDesign,
      resultUserData: [],
      resultTagData: [],
      descriptionDesignFocus: false,
    }

    this.setState(currState => ({
      designUpload: functions.mergeState(currState.designUpload, newState),
    }), () => {
      document.getElementsByTagName('textarea')[0].focus()
    })
  }

  getMention = (value, cursor) => {
    let endPos = value.indexOf(' ', cursor.end)
    if (endPos === -1) {
      endPos = value.length
    }

    const result = /\S+$/.exec(value.slice(0, endPos))
    let lastWord = result ? result[0] : null
    if (lastWord) {
      lastWord = lastWord.replace(/['";:,.\/?\\-]$/, '')
    }

    const mention = (lastWord && lastWord.indexOf('@') == 0) ? lastWord : ''

    return mention
  }

  getHashtag = (value, cursor) => {
    let endPos = value.indexOf(' ', cursor.end)
    if (endPos === -1) {
      endPos = value.length
    }

    const result = /\S+$/.exec(value.slice(0, endPos))
    let lastWord = result ? result[0] : null
    if (lastWord) {
      lastWord = lastWord.replace(/['";:,.\/?\\-]$/, '')
    }

    const hashtag = (lastWord && lastWord.indexOf('#') == 0) ? lastWord : ''

    return hashtag
  }

  getCaretPosition = (position) => {
    let start,
      end
    if (position.setSelectionRange) {
      start = position.selectionStart
      end = position.selectionEnd
    } else if (document.selection && document.selection.createRange) {
      const range = document.selection.createRange()
      start = 0 - range.duplicate().moveStart('character', -100000)
      end = start + range.text.length
    }

    return {
      start,
      end,
    }
  }

  handleSetStateInputUpload = (type, subtype) => (e) => {
    const { value } = e.target

    const cursor = this.getCaretPosition(e.target)
    const mention = this.getMention(value, cursor)
    const hashtag = this.getHashtag(value, cursor)

    if (mention.includes('@') && mention !== '' && mention.substr(1).length > 0) {
      ApiUsers.search(mention.substr(1)).then(({ data }) => {
        const newState = {
          resultUserData: data, resultTagData: [],
        }
        this.handleSetMergeState('designUpload', newState)()
      })
    } else if (hashtag.includes('#') && hashtag !== '' && hashtag.substr(1).length > 0) {
      ApiTags.search(hashtag.substr(1)).then(({ data }) => {
        const newState = {
          resultUserData: [], resultTagData: data,
        }
        this.handleSetMergeState('designUpload', newState)()
      })
    } else {
      const newState = {
        resultUserData: [], resultTagData: [],
      }
      this.handleSetMergeState('designUpload', newState)()
    }

    this.setState(currState => ({
      [type]: functions.createStateObj(currState[type], subtype, value),
    }), () => {

    })
  }

  handleSetStateInput = (type, subtype) => (e) => {
    const { value } = e.target

    if (type !== 'settings') {
      if ((subtype === 'keywordTyping' || subtype === 'keyword') && e.key === 'Enter') {
        const newState = {
          keywordTyping: '',
          keyword: '',
          open: false,
          resultKeyword: null,
          keywordFocus: false,
        }

        this.setState(currState => ({
          [type]: functions.mergeState(currState[type], newState),
        }), () => {
          history.push({
            pathname: `/search/${value}`,
            search: '?tab=designs',
          })
        })
      } else {
        this.setState(currState => ({
          [type]: functions.createStateObj(currState[type], subtype, value),
        }), () => {
          if (value.length > 1 && type === 'search') {
            this.findTheKeyword(value)
          } else {
            this.handleSetState('search', 'resultKeyword', null)()
          }
        })
      }
    } else {
      this.setState(currState => ({
        [type]: functions.createStateObj(currState[type], subtype, value),
      }), () => {
        if (subtype === 'accountUsernameTyping' || subtype === 'accountEmailTyping' || subtype === 'memberEmailInvitationTyping') {
          const typeActive = subtype.includes('Username') ? { label: 'accountUsername', typeCheck: 'username', errorLabel: 'Username' } : 
          (subtype === 'accountEmailTyping' ? { label: 'accountEmail', typeCheck: 'email', errorLabel: 'Email' } : { label: 'memberEmailInvitation', typeCheck: 'email', errorLabel: 'Email' } )
          const objectTosend = subtype !== 'memberEmailInvitationTyping' ? Model.userEmailParams({ value: this.state[type][subtype] }) : Model.emailParams({ email: this.state[type][subtype] }, 'member')
          if (this.state[type][subtype].length > 0) {
            const valid = subtype.includes('Username') ? Validation.usernamePattern(this.state[type][subtype], typeActive.errorLabel)
              : Validation.emailPattern(this.state[type][subtype], typeActive.errorLabel)
            this.setState(currState => ({
              [type]: functions.createStateObj(currState[type], `${typeActive.label}Error`, valid),
            }), () => {
              if (this.state[type][`${typeActive.label}Error`] === '' && subtype !== 'memberEmailInvitationTyping') {
                this.handleCheckUsernameOrEmail(typeActive.typeCheck, objectTosend, typeActive.label)
              } else if (this.state[type][`${typeActive.label}Error`] === '' && subtype === 'memberEmailInvitationTyping'){
                this.handleCheckMemberTeamEmail(typeActive.typeCheck, objectTosend, typeActive.label)
              }
            })
          } else if(subtype !== 'memberEmailInvitationTyping') {
            this.handleSetState(type, `${typeActive.label}Error`, Validation.checkValueNotBlank(this.state[type][subtype], typeActive.errorLabel))()
          } else if(subtype === 'memberEmailInvitationTyping' && this.state[type][subtype].length === 0){
            const newState = {
              [`${typeActive.label}Error`]: '',
              membersToInvites: [],
              openMembersToInvites: false
            }
            this.handleSetMergeState(type, newState)()
          }
        } else if (subtype === 'password' || subtype === 'oldPassword') {
          if (this.state[type][subtype].length > 0) {
            const valid = Validation.passwordPattern(this.state[type][subtype], "It's")
            this.setState(currState => ({
              [type]: functions.createStateObj(currState[type], `${subtype}Error`, valid),
            }))
          }
        }
      })
    }
  }

  findTheKeyword = async (value) => {
    const data = await ApiFinds.find(value)
    const state = {
      resultKeyword: data.data,
      open: true,
    }

    this.setState(currState => ({
      search: functions.mergeState(currState.search, state),
    }))
  }

  handleSetState = (type, subtype, value) => () => {
    const { user } = this.state
    this.setState(currState => ({
      [type]: functions.createStateObj(currState[type], subtype, value),
    }), () => {
      if (subtype === 'activeStatistics') {
        this.getAnalytics()
      }
    })
  }

  // this is for settings ////////////////////
  handleCheckUsernameOrEmail = (typeCheck, value, label) => {
    ApiUsers.userNameOrEmail(typeCheck, 'user', value).then((data) => {
      this.handleSetState('settings', `${label}Error`, '')()
    }).catch((error) => {
      process.env.ENV_APP === 'development' && console.log(error.response.data.message, 'error')
      this.handleSetState('settings', `${label}Error`, error.response.data.message)()
    })
  }

  handleCheckMemberTeamEmail = (typeCheck, value, label) => {
    ApiMembers.available(value).then(({data}) => {
      const newState = {
        [`${label}Error`]: '',
        membersToInvites: data.data,
        openMembersToInvites: true
      }
      this.handleSetMergeState('settings', newState)()
    }).catch((error) => {
      process.env.ENV_APP === 'development' && console.log(error.response.data.message, 'error')
      this.handleSetState('settings', `${label}Error`, error.response.data.message)()
    })
  }

  handleScale = (type, subtype) => (e) => {
    this.setState(currState => ({
      [type]: functions.createStateObj(currState[type], subtype, e),
    }))
  }

  handleSetStateFile = (type, action) => async (files) => {
    const size = Helpers.readablizeBytes(files[0].size).split(' ')[0]
    const sizeFile = Helpers.readablizeBytes(files[0].size)
    let state = {}
    if (action === 'add' && Helpers.isImage(files[0].type)) {
      const image = await Helpers.addImageProcess(files[0].preview)
      if (sizeFile.includes('MB') && size >= 5.00
          || sizeFile.includes('GB') && size >= 1.00
          || sizeFile.includes('TB') && size >= 1.00
          || sizeFile.includes('PB') && size >= 1.00) {
        state = {
          profileEditImage: null,
          profileEditImageResult: null,
          position: { x: 0.5, y: 0.5 },
          scale: 1,
          profileAvatarError: 'Image too Big',
        }
      } else if (sizeFile.includes('KB') && size <= 10.00) {
        state = {
          profileEditImage: null,
          profileEditImageResult: null,
          position: { x: 0.5, y: 0.5 },
          scale: 1,
          profileAvatarError: 'Image too Small',
        }
      } else if (image.width >= 160 && image.height >= 160) {
        state = {
          profileEditImage: files[0],
          profileEditImageResult: null,
          position: { x: 0.5, y: 0.5 },
          scale: 1,
          profileAvatarError: '',
        }
      }
    } else if (action === 'add' && !Helpers.isImage(files[0].type)) {
      state = {
        profileEditImage: null,
        profileEditImageResult: null,
        position: { x: 0.5, y: 0.5 },
        scale: 1,
        profileAvatarError: 'Image wrong type',
      }
    } else {
      state = {
        profileEditImage: null,
        profileEditImageResult: null,
        position: { x: 0.5, y: 0.5 },
        scale: 1,
      }
    }

    this.handleSetMergeState(type, state)()
  }

  setEditorRef = editor => this.editor = editor

  handlePositionChange = (type, subtype) => (position) => {
    this.setState(currState => ({
      [type]: functions.createStateObj(currState[type], subtype, position),
    }))
  }

  saveImage = type => async () => {
    if (this.editor) {
      const canvas = this.editor.getImage().toDataURL()
      const data = await fetch(canvas).then(res => res.blob())
      const metadata = { type: 'image/png', lastModified: new Date().getTime(), preview: window.URL.createObjectURL(data) }
      const profileEditImageResult = new File([data], `${this.state[type].profileEditImage.name.slice(0, -4)}.png`, metadata)
      const state = {
        profileEditImageResult,
        profileAvatar: metadata.preview,
        profileEditImage: null,
      }

      this.setState(currState => ({
        [type]: functions.mergeState(currState[type], state),
      }))
    }
  }

  handleUpdateSettings = tab => () => {
    switch (tab) {
      case 'account':
        this.updateAccount('settings')
        break
      case 'links':
        this.updateLink('settings')
        break
      case 'password':
        this.updatePassword('settings')
        break
      case 'alert':
        this.updateAlert('settings')
        break
      default:
        this.updateProfile('settings')
    }
  }

  handleUpdateSettingsConfirm = tab => () => {
    const { user, settings } = this.state
    switch (tab) {
      case 'password':
        this.handleSetState(type, 'passwordLoading', true)()
        const dataPassword = {
          email: user.info.attributes.email,
          password: settings.passwordPasswordConfirm,
        }
        const objectTosendPassword = Model.userPasswordParams(dataPassword)
        ApiUsers.password(objectTosendPassword)
          .then(({ data }) => {
            this.handleSetState('settings', 'passwordPasswordConfirmError', '')()
            const userId = this.state.user.info.id
            const dataToSend = Model.passwordParams(this.state.settings)
            ApiSettings.password(userId, dataToSend).then((response) => {
              this.handleSetState(type, 'passwordLoading', false)()
              console.log(response, 'success')
            }).catch((error) => {
              console.log(error, 'error')
            })
          })
          .catch((error) => {
            const newState = {
              passwordLoading: false,
              passwordPasswordConfirmError: error.response.data.message,
            }
            this.handleSetMergeState('settings', newState)
          })
        break
      default:
        this.handleSetState(type, 'accountLoading', true)()
        const dataAccount = {
          email: user.info.attributes.email,
          password: settings.accountPasswordConfirm,
        }
        const objectTosendAccount = Model.userPasswordParams(dataAccount)
        ApiUsers.password(objectTosendAccount)
          .then(({ data }) => {
            this.handleSetState('settings', 'accountPasswordConfirmError', '')()
            const userId = this.state.user.info.id
            const dataToSend = Model.accountParams(this.state.settings)
            ApiSettings.account(userId, dataToSend).then((response) => {
              this.handleSetState(type, 'accountLoading', false)()
              console.log(response, 'success')
            }).catch((error) => {
              console.log(error, 'error')
            })
          })
          .catch((error) => {
            const newState = {
              accountLoading: false,
              accountPasswordConfirmError: error.response.data.message,
            }
            this.handleSetMergeState('settings', newState)
          })
    }
  }

  updateAlert = (type) => {
    this.handleSetState(type, 'alertLoading', true)()
    const userId = this.state.user.info.id
    const objectTosend = Model.alertParams(this.state[type])
    ApiSettings.alert(userId, objectTosend).then(({ data }) => {
      this.handleSetState(type, 'alertLoading', false)()
      console.log(data, 'success')
    })
  }

  updatePassword = (type) => {
    const listValidation = [
      { label: 'password', error: 'Password' },
      { label: 'oldPassword', error: 'Password' },
    ]

    listValidation.map(
      (any) => {
        this.state[type][`${any.label}Error`] = this.state[type][`${any.label}Error`] === '' ? this.state[type][any.label] !== '' ? Validation.passwordPattern(this.state[type][any.label], any.error)
          : Validation.checkValueNotBlank(this.state[type][any.label], any.error) : this.state[type][`${any.label}Error`]
        return this.state[type][`${any.label}Error`]
      },
    )

    this.setState(currState => ({ ...currState }), () => {
      const isErrorExist = listValidation.filter(any => this.state[type][`${any.label}Error`]).length
      if (!isErrorExist) {
        this.handleSetState(type, 'modalPassword', true)()
      } else {
        console.log('invalid')
      }
    })
  }

  updateAccount = (type) => {
    const listValidation = [
      { label: 'accountUsername', error: 'Username' },
      { label: 'accountEmail', error: 'Email' },
    ]

    listValidation.map(
      (any) => {
        if (any.label === 'accountUsername') {
          this.state[type][`${any.label}Error`] = this.state[type][`${any.label}Error`] === '' ? this.state[type][any.label] !== '' ? Validation.usernamePattern(this.state[type][any.label], any.error)
            : Validation.checkValueNotBlank(this.state[type][any.label], any.error) : this.state[type][`${any.label}Error`]
          return this.state[type][`${any.label}Error`]
        } if (any.label === 'accountEmail') {
          this.state[type][`${any.label}Error`] = this.state[type][`${any.label}Error`] === '' ? this.state[type][any.label] !== '' ? Validation.emailPattern(this.state[type][any.label], any.error)
            : Validation.checkValueNotBlank(this.state[type][any.label], any.error) : this.state[type][`${any.label}Error`]
          return this.state[type][`${any.label}Error`]
        }
      },
    )

    this.setState(currState => ({ ...currState }), () => {
      const isErrorExist = listValidation.filter(any => this.state[type][`${any.label}Error`]).length
      const isValueSame = listValidation.filter(any => this.state[type][any.label] === this.state[type][`${any.label}Old`]).length
      if (!isErrorExist && isValueSame !== 2) {
        this.handleSetState(type, 'modalAccount', true)()
      } else {
        console.log('invalid')
      }
    })
  }

  updateLink = (type) => {
    const listValidation = [
      { label: 'facebookLink', error: 'Facebook url invalid' },
      { label: 'instagramLink', error: 'Instagram url invalid' },
      { label: 'twitterLink', error: 'Twitter url invalid' },
      { label: 'youtubeLink', error: 'Youtube url invalid' },
      { label: 'mediumLink', error: 'Medium url invalid' },
      { label: 'websiteLink', error: 'Website url invalid' },
    ]

    listValidation.map(
      (any) => {
        this.state[type][`${any.label}Error`] = this.state[type][any.label] === '' ? '' : Validation.webPattern(this.state[type][any.label]) ? ''
          : `${any.error}`
        return this.state[type][`${any.label}Error`]
      },
    )

    this.setState(currState => ({ ...currState }), () => {
      const isErrorExist = listValidation.filter(any => this.state[type][`${any.label}Error`]).length
      if (!isErrorExist) {
        this.handleSetState(type, 'loadingLink', true)()
        const userId = this.state.user.info.id
        const objectTosend = Model.linkParams(this.state[type])
        ApiSettings.link(userId, objectTosend).then(({ data }) => {
          this.handleSetState(type, 'loadingLink', false)()
          console.log(data, 'success')
        })
      } else {
        console.log('invalid')
      }
    })
  }

  updateProfile = (type) => {
    const listValidation = [
      { label: 'profileAvatar', error: '' },
      { label: 'profileName', error: 'Name' },
      { label: 'profileBio', error: 'Your bio reach out our limit, we only limit 157 letter' },
    ]

    listValidation.map(
      (any) => {
        if (any.label === 'profileAvatar') {
          this.state[type][`${any.label}Error`] = this.state[type][`${any.label}Error`]
        } else if (any.label === 'profileName') {
          this.state[type][`${any.label}Error`] = Validation.checkValueNotBlank(this.state[type][any.label], any.error)
          return this.state[type][`${any.label}Error`]
        } else if (any.label === 'profileBio') {
          this.state[type][`${any.label}Error`] = this.state[type][any.label] === '' ? '' : this.state[type][any.label].length > 157 ? `${any.error}` : ''
          return this.state[type][`${any.label}Error`]
        } else {
          this.state[type][`${any.label}Error`] = this.state[type][any.label] === '' ? '' : Validation.webPattern(this.state[type][any.label]) ? ''
            : `${any.error}`
          return this.state[type][`${any.label}Error`]
        }
      },
    )

    this.setState(currState => ({ ...currState }), () => {
      const isErrorExist = listValidation.filter(any => this.state[type][`${any.label}Error`]).length
      if (!isErrorExist) {
        this.handleSetState(type, 'profileLoading', true)()
        const userId = this.state.user.info.id
        const objectTosend = Model.profileParams(this.state[type])
        ApiSettings.profile(userId, objectTosend).then(({ data }) => {
          this.handleSetState(type, 'profileLoading', false)()
          console.log(data, 'success')
        })
      } else {
        console.log('invalid')
      }
    })
  }

  // this is for settings ////////////////

  handleSetMergeState = (type, state) => () => {
    this.setState(currState => ({
      [type]: functions.mergeState(currState[type], state),
    }), () => {
      if (state.hasOwnProperty('modalView')) {
        const url = state.slug !== '' ? state.slugView : this.state[type].previousSlug
        window.history.pushState(null, '', url)
        const activePage = url.split('/').filter(item => item)[0]
        if (Data.menu.filter(any => (activePage === any.page
          || (typeof activePage === 'undefined'))).length > 0 || activePage === 'upload') {
          
          const metaData = {
            title: typeof activePage === 'undefined' ? '' : `${activePage[0].toUpperCase() + activePage.slice(1)}`,
            image: '',
            url: document.URL,
            description: 'Let the world know your masterpiece artwork design. marvlous is number #1 place to showcase your masterpiece artwork design and monetize it',
          }

          this.updateMetaTitle(metaData)
        }
      } else if (type === 'search' && !state.hasOwnProperty('modalView')) {
        debugger
        state.query === ''
          ? history.push({
            pathname: state.slug,
          }) : history.push({
            pathname: state.slug,
            search: state.query,
          })
      }
    })
  }

  handleAddMoreFile = (type, subtype) => () => {
    const { filesData } = this.state[type]
    const newData = {
      id: v4(),
      fileData: null,
    }

    const newFilesData = filesData.concat(newData)

    if (newFilesData.length <= 6) {
      this.setState(currState => ({
        [type]: functions.createStateObj(currState[type], [subtype], newFilesData),
      }))
    }
  }

  handleDeleteMoreFile = (type, subtype, id) => () => {
    const { filesData } = this.state[type]
    const newFilesData = filesData.filter(item => item.id !== id)
    this.setState(currState => ({
      [type]: functions.createStateObj(currState[type], [subtype], newFilesData),
    }))
  }

  handleSetStateSmallFiles = (type, subtype, id) => (files) => {
    const { filesData } = this.state[type]
    const newFilesData = filesData.map((data) => {
      if (id === data.id) {
        return { ...data, fileData: files[0] }
      }
      return data
    })

    this.setState(currState => ({
      designUpload: functions.createStateObj(currState.designUpload, 'filesData', newFilesData),
    }))
  }

  handleInputSelect = (type, subtype) => (newValue) => {
    this.setState(currState => ({
      [type]: functions.createStateObj(currState[type], subtype, newValue),
    }))
  }

  submitUploadDesign = () => {
    const listCheckNotToBlank = [
      { label: 'imagesData', error: 'You need upload an image at least one' },
      { label: 'titleDesign', error: 'Title design' },
    ]

    listCheckNotToBlank.map(
      (any) => {
        if (any.label === 'titleDesign') {
          this.state.designUpload[`${any.label}Error`] = Validation.checkValueNotBlank(this.state.designUpload[any.label], any.error)
        } else {
          this.state.designUpload[any.label] = this.state.designUpload[any.label].map((any, index) => {
            if (!index) {
              return { ...any, error: any.error === '' ? Validation.checkValueNotBlank(any.imageData, any.error) : any.error }
            }
            return { ...any }
          })
        }
      },
    )


    this.setState(currState => ({ ...currState }), () => {
      const isErrorExist = listCheckNotToBlank.map((any) => {
        if (any.label === 'imagesData') {
          const checkImages = this.state.designUpload[any.label].filter(any => any.error !== '')
          if (checkImages.length > 0) {
            return checkImages[0].error
          }
          return ''
        }
        return this.state.designUpload[`${any.label}Error`]
      }).filter(any => any).length
      if (!isErrorExist) {
        this.handleSetState('designUpload', 'formLoading', true)()
        const state = { ...this.state.designUpload, username: this.state.user.info.attributes.username, collectionId: this.state.user.info.attributes.collection_id }
        const objectToSend = Model.designUploadParams(state)
        ApiDesigns.create(objectToSend).then(({ data }) => {
          window.location.href = `${data.attributes.url}`
        })
      }
    })
  }


  // react router
  closeModal = type => () => {
    if (type === 'feed') {
      history.push({
        pathname: Routes.INDEX,
      })
      const newState = {
        modalView: false,
        slug: '',
        design: null,
        viewComment: '',
      }
      this.handleSetMergeState('feed', newState)()
    } else {
      const newState = {
        modalView: false,
        slug: '',
        design: null,
        viewComment: '',
      }
      this.handleSetMergeState(type, newState)()
    }
  }

  goBack = () => {
    history.goBack()
  }

  handleLogout = () => {
    axios.get(`${window.location.origin}/users/logout`).then(() => {
      window.location.href = process.env.DEFAULT_DOMAIN
    })
  }

  handleViews = (data, state) => {
    const newFeeds = state.feed.feedData.map((any) => {
      if (any.id === data.id) {
        return { ...any, attributes: { ...any.attributes, views: data.attributes.views } }
      }
      return { ...any }
    })
    this.handleSetState('feed', 'feedData', newFeeds)()
    this.handleSetState('search', 'open', false)()
  }

  resetState = () => {
    this.setState((currState) => {
      Object.keys(Store.defaultState).map((key, index) => currState[key] = { ...currState[key], ...Store.defaultState[key] })
      return ({ ...currState })
    })
  }

  handleSelect = (state, e) => {
    const fromTop = e.currentTarget.scrollTop
    let dataArrDate
    dataArrDate = state.filter((link) => {
      const section = document.getElementById(link.id)
      if (section.offsetTop <= fromTop && section.offsetTop + section.offsetHeight > fromTop) {
        return section.getAttribute('id')
      }
    })

    return dataArrDate
  }

  handleScroll = type => (e) => {
    const { state } = this
    const { currentTarget } = e
    const heightScroll = currentTarget.scrollHeight - (currentTarget.scrollHeight / 4)
    const activeHeightScroll = currentTarget.scrollTop + currentTarget.clientHeight
    if (type === 'notifications') {
      const activeNotif = this.handleSelect(this.state[type].data, e)

      if (this.state.user.info !== null
        && this.state[type].data.filter(any => (any.attributes.opened_by === null
          || !any.attributes.opened_by.includes(this.state.user.info.attributes.id))).length > 0
      ) {
        const newData = this.state[type].data.map((any) => {
          if (any.id === activeNotif[0].id) {
            return {
              ...any,
              attributes: {
                ...any.attributes,
                opened_by: this.state.user.info !== null ? (any.attributes.opened_by !== null ? any.attributes.opened_by.concat(this.state.user.info.attributes.id) : [this.state.user.info.attributes.id]) : null,
              },
            }
          }
          return { ...any }
        })

        this.handleSetState('notifications', 'data', newData)()

        if (!state[type].endOfData) {
          ApiNotifications.open(activeNotif[0].id).then(async ({ data }) => {
            const dataCount = await ApiNotifications.count()
            this.handleSetState('notifications', 'count', dataCount.data)()
          })
        } else {
          const data = {
            openIds: this.state[type].data.slice(-10).filter(any => (any.attributes.opened_by === null
              || !any.attributes.opened_by.includes(this.state.user.info.attributes.id))).map(any => any.id),
          }
          const objectToSend = Model.notifParams(data)
          ApiNotifications.openAll(objectToSend).then(async ({ data }) => {
            const dataCount = await ApiNotifications.count()
            this.handleSetState('notifications', 'count', dataCount.data)()
            const newData = this.state[type].data.map(any => ({ ...any, attributes: { ...any.attributes, opened_by: this.state.user.info !== null ? (any.attributes.opened_by !== null ? any.attributes.opened_by.concat(this.state.user.info.attributes.id) : [this.state.user.info.attributes.id]) : null } }))
            this.handleSetState('notifications', 'data', newData)()
          })
        }
      }


      if (activeHeightScroll > heightScroll) {
        if (!state[type].endOfFeed && (state[type].activeHeightScroll === '' || activeHeightScroll > state[type].activeHeightScroll)) {
          const page = state[type].page + 1
          const newState = { activeHeightScroll: currentTarget.scrollHeight, page, loadingData: true }
          this.setState(currState => ({
            [type]: functions.mergeState(currState[type], newState),
          }), () => {
            this.handleScrollData(type, page)
          })
        }
      }
    } else if (activeHeightScroll > heightScroll) {
      if (!state[type].endOfFeed && (state[type].activeHeightScroll === '' || activeHeightScroll > state[type].activeHeightScroll)) {
        const page = state[type].page + 1
        const newState = { activeHeightScroll: currentTarget.scrollHeight, page, loadingData: true }
        this.setState(currState => ({
          [type]: functions.mergeState(currState[type], newState),
        }), () => {
          this.handleScrollData(type, page)
        })
      }
    }
  }

  handleScrollData = (type, page) => {
    switch (type) {
      case 'notifications':
        ApiNotifications.index(page).then((response) => {
          const { state } = this
          const set = new Set()
          const theData = state[type].data.concat(response.data)
          const data = theData.filter((item) => {
            const duplicateId = set.has(item.id)
            set.add(item.id)
            return !duplicateId
          })
          const newData = { data, loadingData: false }
          this.setState(currState => ({
            [type]: functions.mergeState(currState[type], newData),
          }), () => {
            setTimeout(() => {
              const newState = { endOfData: response.data.length === 0 }
              this.handleSetMergeState(type, newState)()
            }, 3000)
          })
        })
        break
      case 'saved':
        ApiBookmarks.list(page).then((response) => {
          const { state } = this
          const set = new Set()
          const theData = state[type].data.concat(response.data)
          const data = theData.filter((item) => {
            const duplicateId = set.has(item.id)
            set.add(item.id)
            return !duplicateId
          })
          const newData = { data, loadingData: false }
          this.setState(currState => ({
            [type]: functions.mergeState(currState[type], newData),
          }), () => {
            setTimeout(() => {
              const newState = { endOfData: response.data.length === 0 }
              this.handleSetMergeState(type, newState)()
            }, 3000)
          })
        })
        break
      case 'subscriptions':
        ApiSubscriptions.index(page).then((response) => {
          const { state } = this
          const set = new Set()
          const theData = state[type].data.concat(response.data)
          const data = theData.filter((item) => {
            const duplicateId = set.has(item.id)
            set.add(item.id)
            return !duplicateId
          })
          const newData = { data, loadingData: false }
          this.setState(currState => ({
            [type]: functions.mergeState(currState[type], newData),
          }), () => {
            setTimeout(() => {
              const newState = { endOfData: response.data.length === 0 }
              this.handleSetMergeState(type, newState)()
            }, 3000)
          })
        })
        break
      default:
        ApiDesigns.index(page).then(({ data }) => {
          const { state } = this
          const set = new Set()
          const newFeeds = state[type].feedData.concat(data)
          const feedData = newFeeds.filter((item) => {
            const duplicateId = set.has(item.id)
            set.add(item.id)
            return !duplicateId
          })
          const newData = { feedData, loadingData: false }
          this.setState(currState => ({
            [type]: functions.mergeState(currState[type], newData),
          }), () => {
            setTimeout(() => {
              const newState = { endOfData: data.length === 0 }
              this.handleSetMergeState(type, newState)()
            }, 3000)
          })
        })
    }
  }

  handleMemberInvite = (email, dataLoading) => () => {
    if(!dataLoading.filter(any => any.includes(email)).length > 0){
      this.handleSetState('settings', 'membersToInvitesLoading', dataLoading.concat(email))()
      const objectToSend = Model.emailParams({ email }, 'team')
      ApiTeams.inviteMember(objectToSend).then(({ data }) => {
        const newState = {
          membersToInvitesLoading: this.state['settings']['membersToInvitesLoading'].filter(any => any !== email),
          openMembersToInvites: false,
          members: this.state['settings']['members'].concat(data)
        }
        this.handleSetMergeState('settings', newState)()
      }).catch((error) => {
        console.log(error, 'error')
        // this.handleSetState('settings', `${label}Error`, error.response.data.message)()
      })
    }
  }

  handleDeleteMember = (email, dataLoading, status) => () => {
    if(!dataLoading.filter(any => any.includes(email)).length > 0){
      this.handleSetState('settings', 'membersToInvitesDeleteConfirmLoading', dataLoading.concat(email))()
      const objectToSend = Model.emailParams({ email }, 'team')
      if(!status){
        ApiTeams.deleteMemberInvite(objectToSend).then(({ data }) => {
          this.handleSetState('settings', 'members', this.state['settings']['members'].filter(any => any.email !== email))()
        }).catch((error) => {
          console.log(error, 'error')
        })
      }else{
        ApiTeams.deleteMember(objectToSend).then(({ data }) => {
          this.handleSetState('settings', 'members', this.state['settings']['members'].filter(any => any.email !== email))()
        }).catch((error) => {
          console.log(error, 'error')
        })
      }
    }
  }

  handleScrollTrends = direction => () => {
    if (direction === 'right') {
      document.getElementById('design-trends').scrollLeft += 50
      const activeScrollHorizontal = document.getElementById('design-trends').scrollLeft + document.getElementById('design-trends').clientWidth
      this.handleSetState('feed', 'activeScrollHorizontal', activeScrollHorizontal)()
    } else {
      document.getElementById('design-trends').scrollLeft -= 50
      const activeScrollHorizontal = document.getElementById('design-trends').scrollLeft < 0 ? 0 : document.getElementById('design-trends').scrollLeft
      this.handleSetState('feed', 'activeScrollHorizontal', activeScrollHorizontal)()
    }
  }

  updateMetaTitle = (data) => {
    const title = Object.keys(data).length === 0 && data.constructor === Object ? '' : data.title
    const description = Object.keys(data).length === 0 && data.constructor === Object ? '' : data.description
    const image = Object.keys(data).length === 0 && data.constructor === Object ? '' : data.image
    const url = Object.keys(data).length === 0 && data.constructor === Object ? '' : data.url

    // Title
    document.title = title !== '' ? `${title} | Marvlous • Designs, Artworks, and Products` : 'Marvlous • Designs, Artworks, and Products'
    // Default
    document.querySelector('meta[name="description"]').content = description
    // Schema.org
    document.querySelector('meta[itemprop="name"]').content = title
    document.querySelector('meta[itemprop="description"]').content = description
    document.querySelector('meta[itemprop="image"]').content = image

    // Facebook Open Graph data
    document.querySelector('meta[property="og:title"]').content = title
    document.querySelector('meta[property="og:url"]').content = url
    document.querySelector('meta[property="og:image"]').content = image
    document.querySelector('meta[property="og:description"]').content = description
    document.querySelector('meta[property="og:site_name"]').content = 'Marvlous'

    // Twitter Card data
    document.querySelector('meta[name="twitter:site"]').content = '@marvlouscom'
    document.querySelector('meta[name="twitter:title"]').content = title
    document.querySelector('meta[name="twitter:description"]').content = description
    document.querySelector('meta[name="twitter:image:src"]').content = image
  }

  handleDeleteDesign = (id) => () => {
    ApiDesigns.destroy(id).then(() => {
      window.location.href = `${process.env.DEFAULT_DOMAIN}/analytics`
    })
  }

  render() {
    const {
      state,
      handleSetStateCover,
      handleSetStateSmallCover,
      handleSetStateInput,
      handleSetStateInputUpload,
      handleSetState,
      handleSetMergeState,
      handleLogout,
      handleAddMoreFile,
      handleSetStateSmallFiles,
      handleDeleteMoreFile,
      handleDeleteCover,
      handleDeleteTinyCover,
      handleSetStateFile,
      handleInputSelect,
      handleViews,
      handleOptions,
      resetState,
      submitUploadDesign,
      getRealTimeNotif,
      getAnalytics,
      getRevenue,
      closeModal,
      goBack,
      handleScroll,
      setEditorRef,
      handlePositionChange,
      saveImage,
      handleScale,
      handleUpdateSettings,
      handleUpdateSettingsConfirm,
      handleScrollTrends,
      updateMetaTitle,
      handleMemberInvite,
      handleDeleteMember,
      handleDeleteDesign
    } = this

    const newState = {
      modalView: false,
      slug: '',
      design: null,
      viewComment: '',
      open: false,
    }

    process.env.ENV_APP === 'development' && console.log(this.state, 'state tree')

    return (
      <Router history={history}>
        <Switch>
          <Route
            exact
            path={Routes.INDEX}
            render={props => (
              <Fragment>
                <Container
                  state={state}
                  headerLeft="Feed"
                  headerRight={
                      (
                        <span className={Styles.feedOption}>
                          <i className="la la-angle-down" />
                        </span>
)
                    }
                  content={(
                    <Feed
                      handleSetState={handleSetState}
                      handleSetMergeState={handleSetMergeState}
                      handleScrollTrends={handleScrollTrends}
                      handleScroll={handleScroll}
                      resetState={resetState}
                      typeState="feed"
                      state={state}
                      page="feed"
                    />
                    )}
                  handleSetStateInput={handleSetStateInput}
                  handleSetMergeState={handleSetMergeState}
                  handleSetState={handleSetState}
                  handleLogout={handleLogout}
                  closeModal={closeModal('feed')}
                  search={state.search}
                  typeState="container"
                  page="feed"
                />
                {state.feed.modalView
                    && (
                    <DesignDetail
                      viewComment={state.feed.viewComment}
                      slug={state.feed.slug}
                      state={state}
                      handleViews={handleViews}
                      typeState="container"
                      history={history}
                      resetState={resetState}
                      page="feed"
                      handleSetState={handleSetState}
                      updateMetaTitle={updateMetaTitle}
                      handleSetMergeState={handleSetMergeState}
                      closeModal={handleSetMergeState('feed', newState)}
                    />
                    )
                  }
              </Fragment>
            )
          }
          />

          <Route
            exact
            path={Routes.NOTIFICATIONS}
            render={props => (
              <Fragment>
                <Container
                  state={state}
                  headerLeft="Notifications"
                  content={(
                    <Notifications
                      getRealTimeNotif={getRealTimeNotif}
                      typeState="notifications"
                      handleSetMergeState={handleSetMergeState}
                      handleScroll={handleScroll}
                      resetState={resetState}
                      state={state}
                      page="notifications"
                    />
                  )}
                  handleSetStateInput={handleSetStateInput}
                  handleSetMergeState={handleSetMergeState}
                  handleSetState={handleSetState}
                  handleLogout={handleLogout}
                  closeModal={closeModal('notifications')}
                  search={state.search}
                  typeState="container"
                  page="notifications"
                />
                {state.notifications.modalView
                      && (
                      <DesignDetail
                        viewComment={state.notifications.viewComment}
                        slug={state.notifications.slug}
                        state={state}
                        handleViews={handleViews}
                        history={history}
                        resetState={resetState}
                        typeState="container"
                        page="notifications"
                        handleSetState={handleSetState}
                        updateMetaTitle={updateMetaTitle}
                        handleSetMergeState={handleSetMergeState}
                        closeModal={handleSetMergeState('notifications', newState)}
                      />
                      )
                    }
              </Fragment>
            )
          }
          />

          <Route
            exact
            path={Routes.SEARCH}
            render={props => (
              <Fragment>
                <Container
                  state={state}
                  content={(
                    <Search
                      keyword={props.match.params.keyword}
                      page="search"
                      tab={queryString.parse(props.location.search)}
                      typeState="search"
                      state={this.state}
                      resetState={resetState}
                      handleSetMergeState={handleSetMergeState}
                    />
                    )}
                  handleSetStateInput={handleSetStateInput}
                  handleSetMergeState={handleSetMergeState}
                  handleSetState={handleSetState}
                  handleLogout={handleLogout}
                  search={state.search}
                  typeState="container"
                  page="search"
                />
                {state.search.modalView
                    && (
                    <DesignDetail
                      viewComment={state.search.viewComment}
                      slug={state.search.slug}
                      state={state}
                      handleViews={handleViews}
                      typeState="container"
                      history={history}
                      resetState={resetState}
                      page="search"
                      handleSetState={handleSetState}
                      updateMetaTitle={updateMetaTitle}
                      handleSetMergeState={handleSetMergeState}
                      closeModal={handleSetMergeState('search', newState)}
                    />
                    )
                  }
              </Fragment>
            )
          }
          />

          <Route
            exact
            path={Routes.TRENDS}
            render={props => (
              <Fragment>
                <Container
                  state={state}
                  content={(
                    <Trends
                      page="trends"
                      handleSetMergeState={handleSetMergeState}
                      typeState="trends"
                      state={state}
                      typeState="trends"
                    />
                  )}
                  handleSetStateInput={handleSetStateInput}
                  handleSetMergeState={handleSetMergeState}
                  handleSetState={handleSetState}
                  handleLogout={handleLogout}
                  search={state.search}
                  typeState="container"
                  page="trends"
                />
                {state.trends.modalView
                  && (
                  <DesignDetail
                    viewComment={state.trends.viewComment}
                    slug={state.trends.slug}
                    state={state}
                    handleViews={handleViews}
                    typeState="container"
                    history={history}
                    resetState={resetState}
                    page="trends"
                    handleSetState={handleSetState}
                    updateMetaTitle={updateMetaTitle}
                    handleSetMergeState={handleSetMergeState}
                    closeModal={handleSetMergeState('trends', newState)}
                  />
                  )
                }
              </Fragment>
            )
          }
          />

          <Route
            exact
            path={Routes.SUBSCRIPTIONS}
            render={props => (
              <Fragment>
                <Container
                  state={state}
                  headerLeft="Subscriptions"
                  content={(
                    <Subscriptions
                      handleSetMergeState={handleSetMergeState}
                      handleSetState={handleSetState}
                      handleScroll={handleScroll}
                      typeState="subscriptions"
                      resetState={resetState}
                      state={state}
                    />
                  )}
                  handleSetStateInput={handleSetStateInput}
                  handleSetMergeState={handleSetMergeState}
                  handleSetState={handleSetState}
                  handleLogout={handleLogout}
                  closeModal={closeModal('subscriptions')}
                  search={state.search}
                  typeState="container"
                  page="subscriptions"
                />
                {state.subscriptions.modalView
                  && (
                  <DesignDetail
                    viewComment={state.subscriptions.viewComment}
                    slug={state.subscriptions.slug}
                    state={state}
                    handleViews={handleViews}
                    typeState="container"
                    history={history}
                    resetState={resetState}
                    page="subscriptions"
                    handleSetState={handleSetState}
                    updateMetaTitle={updateMetaTitle}
                    handleSetMergeState={handleSetMergeState}
                    closeModal={handleSetMergeState('subscriptions', newState)}
                  />
                  )
                }
              </Fragment>
            )}
          />

          <Route
            exact
            path={Routes.SAVED}
            render={props => (
              <Fragment>
                <Container
                  state={state}
                  headerLeft="Saved"
                  content={(
                    <Saved
                      handleSetMergeState={handleSetMergeState}
                      handleScroll={handleScroll}
                      resetState={resetState}
                      typeState="saved"
                      state={state}
                    />
                  )}
                  handleSetStateInput={handleSetStateInput}
                  handleSetMergeState={handleSetMergeState}
                  handleSetState={handleSetState}
                  handleLogout={handleLogout}
                  closeModal={closeModal('saved')}
                  search={state.search}
                  typeState="container"
                  page="saved"
                />
                {state.saved.modalView
                  && (
                  <DesignDetail
                    viewComment={state.saved.viewComment}
                    slug={state.saved.slug}
                    state={state}
                    handleViews={handleViews}
                    typeState="container"
                    history={history}
                    resetState={resetState}
                    page="saved"
                    handleSetState={handleSetState}
                    updateMetaTitle={updateMetaTitle}
                    handleSetMergeState={handleSetMergeState}
                    closeModal={handleSetMergeState('saved', newState)}
                  />
                  )
                }
              </Fragment>
            )}
          />

          <Route
            exact
            path={Routes.DESIGNDETAIL}
            render={props => (
              <Fragment>
                <Container
                  state={state}
                  content={(
                    <Feed
                      handleSetState={handleSetState}
                      handleSetMergeState={handleSetMergeState}
                      handleScroll={handleScroll}
                      handleScrollTrends={handleScrollTrends}
                      resetState={resetState}
                      typeState="feed"
                      isSlug={false}
                      state={state}
                      page="feed"
                    />
                    )}
                  handleSetStateInput={handleSetStateInput}
                  handleSetMergeState={handleSetMergeState}
                  handleSetState={handleSetState}
                  handleLogout={handleLogout}
                  closeModal={closeModal('feed')}
                  search={state.search}
                  typeState="container"
                  page="feed"
                />
                <DesignDetail
                  viewComment={typeof props.location.state !== 'undefined' && props.location.state.viewComment}
                  slug={props.match.params.slug}
                  state={state}
                  handleViews={handleViews}
                  typeState="container"
                  history={history}
                  resetState={resetState}
                  page="feed"
                  handleSetState={handleSetState}
                  updateMetaTitle={updateMetaTitle}
                  handleSetMergeState={handleSetMergeState}
                  closeModal={closeModal('feed')}
                />
              </Fragment>
            )
            }
          />

          <Route
            exact
            path={Routes.DESIGNUPLOAD}
            render={props => (
              <DesignUpload
                handleSetStateCover={handleSetStateCover}
                handleSetStateSmallCover={handleSetStateSmallCover}
                handleSetStateInput={handleSetStateInput}
                handleSetStateInputUpload={handleSetStateInputUpload}
                handleOptions={handleOptions}
                handleSetState={handleSetState}
                handleAddMoreFile={handleAddMoreFile}
                handleSetStateSmallFiles={handleSetStateSmallFiles}
                handleDeleteMoreFile={handleDeleteMoreFile}
                handleDeleteCover={handleDeleteCover}
                handleDeleteTinyCover={handleDeleteTinyCover}
                handleInputSelect={handleInputSelect}
                submitUploadDesign={submitUploadDesign}
                typeState="designUpload"
                state={state}
              />
            )}
          />

          <Route
            exact
            path={Routes.ANALYTICS}
            render={props => (
              <Fragment>
                <Container
                  state={state}
                  headerLeft="Analytics"
                  content={(
                    <Analytics
                      handleSetState={handleSetState}
                      handleSetMergeState={handleSetMergeState}
                      handleDeleteDesign={handleDeleteDesign}
                      getAnalytics={getAnalytics}
                      typeState="analytics"
                      resetState={resetState}
                      state={state}
                      page="analytics"
                    />
                    )}
                  handleSetStateInput={handleSetStateInput}
                  handleSetMergeState={handleSetMergeState}
                  handleSetState={handleSetState}
                  handleLogout={handleLogout}
                  search={state.search}
                  typeState="container"
                  page="analytics"
                />
                {state.analytics.modalView
                    && (
                    <DesignDetail
                      viewComment={state.analytics.viewComment}
                      slug={state.analytics.slug}
                      state={state}
                      handleViews={handleViews}
                      typeState="container"
                      history={history}
                      resetState={resetState}
                      page="analytics"
                      handleSetState={handleSetState}
                      updateMetaTitle={updateMetaTitle}
                      handleSetMergeState={handleSetMergeState}
                      closeModal={handleSetMergeState('analytics', newState)}
                    />
                    )
                  }
              </Fragment>
            )
            }
          />

          <Route
            exact
            path={Routes.SETTINGS}
            render={props => (
              <Fragment>
                <Container
                  state={state}
                  headerLeft="Settings"
                  content={(
                    <Settings
                      handleSetState={handleSetState}
                      handleSetStateInput={handleSetStateInput}
                      handleSetStateFile={handleSetStateFile}
                      handleSetMergeState={handleSetMergeState}
                      handlePositionChange={handlePositionChange}
                      handleUpdateSettings={handleUpdateSettings}
                      handleUpdateSettingsConfirm={handleUpdateSettingsConfirm}
                      handleMemberInvite={handleMemberInvite}
                      handleDeleteMember={handleDeleteMember}
                      handleScale={handleScale}
                      setEditorRef={setEditorRef}
                      saveImage={saveImage}
                      typeState="settings"
                      state={state}
                      resetState={resetState}
                      page="settings"
                    />
                    )}
                  handleSetStateInput={handleSetStateInput}
                  handleSetMergeState={handleSetMergeState}
                  handleSetState={handleSetState}
                  handleLogout={handleLogout}
                  search={state.search}
                  typeState="container"
                  page="settings"
                />
                {state.settings.modalView
                    && (
                    <DesignDetail
                      viewComment={state.settings.viewComment}
                      slug={state.settings.slug}
                      state={state}
                      handleViews={handleViews}
                      typeState="container"
                      history={history}
                      resetState={resetState}
                      page="settings"
                      handleSetState={handleSetState}
                      updateMetaTitle={updateMetaTitle}
                      handleSetMergeState={handleSetMergeState}
                      closeModal={handleSetMergeState('settings', newState)}
                    />
                    )
                  }
              </Fragment>
            )
            }
          />

          <Route
            exact
            path={Routes.REVENUE}
            render={props => (
              <Fragment>
                <Container
                  state={state}
                  headerLeft="Revenue"
                  content={(
                    <Revenue
                      handleSetState={handleSetState}
                      handleSetMergeState={handleSetMergeState}
                      typeState="revenue"
                      getRevenue={getRevenue}
                      resetState={resetState}
                      state={state}
                      page="revenue"
                    />
                    )}
                  handleSetStateInput={handleSetStateInput}
                  handleSetMergeState={handleSetMergeState}
                  handleSetState={handleSetState}
                  handleLogout={handleLogout}
                  search={state.search}
                  typeState="container"
                  page="revenue"
                />
                {state.revenue.modalView
                    && (
                    <DesignDetail
                      viewComment={state.revenue.viewComment}
                      slug={state.revenue.slug}
                      state={state}
                      handleViews={handleViews}
                      typeState="container"
                      history={history}
                      resetState={resetState}
                      page="revenue"
                      handleSetState={handleSetState}
                      updateMetaTitle={updateMetaTitle}
                      handleSetMergeState={handleSetMergeState}
                      closeModal={handleSetMergeState('revenue', newState)}
                    />
                    )
                  }
              </Fragment>
            )
            }
          />

          <Route
            exact
            path={Routes.PORTFOLIODETAIL}
            render={props => (
              <Fragment>
                <Container
                  state={state}
                  arrow
                  headerLeft={(
                    <div
                      style={{ marginLeft: '-1px' }}
                      className={Helpers.mergeCss(Base.text, Base.dFlex, Base.alignItemsCenter)}
                    >
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={goBack}
                        className={Base.dBlock}
                      >
                        <i className="la la-arrow-left" />
                      </span>
                      {state.portfolio.activeTab !== null
                        ? (
                          <div
                            style={{ color: '#292929' }}
                            className={Helpers.mergeCss(Base.dBlock, Base.w100, Base.marginLeft3)}
                          >
                            <span className={Base.dBlock}>
                              {`${state.portfolio.user.attributes.name}'s`}
                              {' '}
                              {state.portfolio.activeTab.title}
                            </span>
                            {state.portfolio.activeTab.resultExist
                              && (
                              <span style={{ color: '#6e6d7a', fontSize: '14px', fontWeight: 'normal' }}>
                                {state.portfolio.activeTab.count}
                                {' '}
                                designs
                              </span>
                              )}
                          </div>
                        )
                        : (
                          <div
                            style={{ color: '#292929' }}
                            className={Helpers.mergeCss(Base.dBlock, Base.w100, Base.marginLeft3)}
                          >
                            <span className={Base.dBlock}>
                              Portfolio
                            </span>
                          </div>
                        )
                        }
                    </div>
                    )}
                  content={(
                    <Portfolio
                      handleSetState={handleSetState}
                      handleSetMergeState={handleSetMergeState}
                      updateMetaTitle={updateMetaTitle}
                      history={history}
                      slug={props.match.params.username}
                      typeState="portfolio"
                      resetState={resetState}
                      state={state}
                    />
                    )}
                  slug={props.match.params.username}
                  handleSetStateInput={handleSetStateInput}
                  handleSetMergeState={handleSetMergeState}
                  handleSetState={handleSetState}
                  handleLogout={handleLogout}
                  closeModal={closeModal('portfolio')}
                  search={state.search}
                  typeState="container"
                  page="portfolio"
                />
                {state.portfolio.modalView
                    && (
                    <DesignDetail
                      viewComment={state.portfolio.viewComment}
                      slug={state.portfolio.slug}
                      state={state}
                      handleViews={handleViews}
                      typeState="container"
                      history={history}
                      resetState={resetState}
                      page="portfolio"
                      handleSetState={handleSetState}
                      updateMetaTitle={updateMetaTitle}
                      handleSetMergeState={handleSetMergeState}
                      closeModal={handleSetMergeState('portfolio', newState)}
                    />
                    )
                  }
              </Fragment>
            )
            }
          />
        </Switch>
      </Router>
    )
  }
}

export default Index
