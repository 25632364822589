import base from './api.base'

function index(page) {
  return base.get(`designs/${page}`)
}

function create(data) {
  return base.post('designs', data)
}

function show(id, data) {
  return base.patch(`designs/${id}`, data)
}

function list(slug, page, tab) {
  return base.get(`designs/list/${slug}/${page}?tab=${tab}`)
}

function count(slug, tab) {
  return base.get(`designs/count/${slug}?tab=${tab}`)
}

function destroy(id){
  return base.delete(`designs/${id}`)
}

const ApiDesigns = {
  index,
  create,
  show,
  list,
  count,
  destroy
}

export default ApiDesigns
