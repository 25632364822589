import React, { Fragment } from 'react'
import { Base, Helpers } from 'utils'
import PropTypes from 'prop-types'
import Styles from './Styles'

const Button = ({
  onClick, loading, label, className,
}) => (
  <button onClick={!loading && onClick} className={typeof className !== 'undefined' ? Helpers.mergeCss(Base.btnMarvlous, className) : Base.btnMarvlous}>
    <div className={Helpers.mergeCss(Base.dInlineFlex, Base.alignItemsCenter)}>
      {!loading ? label : (<div className={Base.donutLoading} />)}
    </div>
  </button>
)

export default Button
