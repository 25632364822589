import React, { Component, Fragment } from 'react'
import { Base, Helpers, I18n } from 'utils'
import { Link } from 'react-router-dom'
import {
  Loading, Card, Hint, UserInfo,
} from 'components'
import {
  ApiUsers, ApiSubscriptions, ApiDesigns, ApiLikes, ApiComments, ApiTeams, ApiMembers
} from 'api'
import Dropzone from 'react-dropzone'

import marvlous from 'images/marvlous.png'
import placeholderImage from 'images/user-placeholder.jpg'
import upload from 'images/upload.svg'
import AvatarEditor from 'react-avatar-editor'
import InputRange from 'react-input-range'
import Model from '../Model'
import Data from '../Data'
import functions from '../../functions'
import TabContent from './TabContent'
import Styles from './Styles'

import 'react-input-range/lib/css/index.css'

class Portfolio extends Component {
  state = {
    user: {
      info: null,
      tabData: [],
      tabActive: '',
    },
    cover: {
      image: {
        preview: null,
      },
      oldImage: {
        preview: null,
      },
      imageError: '',
      resultImage: null,
      resultPreview: null,
      editMode: false,
      allowZoomOut: false,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      rotate: 0,
      borderRadius: 0,
      preview: null,
      width: 0,
      height: 175,
      loadingUpdate: false,
    },
  }

  componentDidMount() {
    const { props: { slug, history }, initializeData } = this
    initializeData(slug)
    history.listen((location, action) => {
      if (!location.pathname.split('/').filter(any => any).includes('designs') && 
      typeof location.pathname.split('/').filter(any => any)[0] !== 'undefined') {
        const metaData = {
          title: this.state.user.info === null ? '' : `${this.state.user.info.attributes.name} (${this.state.user.info.attributes.username})`,
          image: '',
          url: slug,
          description: 'Let the world know your masterpiece artwork design. marvlous is number #1 place to showcase your masterpiece artwork design and monetize it',
        }

        this.props.updateMetaTitle(metaData)
      }
    })
  }

  componentWillUnmount() {
    this.props.resetState()
  }

  handleScroll = (e) => {
    const { props: { slug }, state: { user: { tabActive } } } = this
    const tabContent = this.state.user.tabData.find(any => any.title === this.state.user.tabActive)
    const { currentTarget } = e
    const heightScroll = currentTarget.scrollHeight - (currentTarget.scrollHeight / 4)
    const activeHeightScroll = currentTarget.scrollTop + currentTarget.clientHeight
    if (activeHeightScroll > heightScroll) {
      if (!tabContent.endOfPortfolio && (tabContent.activeHeightScroll === '' || activeHeightScroll > tabContent.activeHeightScroll)) {
        const page = tabContent.page + 1
        const newState = { activeHeightScroll: currentTarget.scrollHeight, page, loadingDataPortfolio: true }
        const newTabData = this.state.user.tabData.map((any) => {
          if (any.title === tabActive) {
            return { ...any, ...newState }
          }
          return { ...any }
        })

        this.setState(currState => ({
          user: functions.createStateObj(currState.user, 'tabData', newTabData),
        }), () => {
          const designType = tabActive.toLowerCase().slice(0, -1)
          ApiDesigns.list(slug, page, designType).then(({ data }) => {
            const theContent = this.state.user.tabData.find(any => any.title === tabActive)
            const set = new Set()
            const newPortfolio = theContent.data.concat(data)
            const portfolio = newPortfolio.filter((item) => {
              const duplicateId = set.has(item.id)
              set.add(item.id)
              return !duplicateId
            })

            const newData = { data: portfolio, loadingDataPortfolio: false }
            const theData = this.state.user.tabData.map((any) => {
              if (any.title === tabActive) {
                return { ...any, ...newData }
              }
              return { ...any }
            })
            this.setState(currState => ({
              user: functions.createStateObj(currState.user, 'tabData', theData),
            }), () => {
              setTimeout(() => {
                const newState = { endOfPortfolio: data.length === 0 }
                const newData = this.state.user.tabData.map((any) => {
                  if (any.title === tabActive) {
                    return { ...any, ...newState }
                  }
                  return { ...any }
                })
                this.handleSetState('user', 'tabData', newData)()
              }, 3000)
            })
          })
        })
      }
    }
  }

  saveImageCover = type => async () => {
    if (this.editor) {
      const canvas = this.editor.getImage().toDataURL()
      const data = await fetch(canvas).then(res => res.blob())
      const metadata = { type: 'image/png', lastModified: new Date().getTime(), preview: window.URL.createObjectURL(data) }
      const resultImage = new File([data], `${this.state[type].image.name.slice(0, -4)}.png`, metadata)
      const state = {
        resultImage,
        image: {
          preview: metadata.preview,
        },
        resultPreview: null,
      }

      this.setState(currState => ({
        [type]: functions.mergeState(currState[type], state),
      }))
    }
  }

  initializeData = (slug) => {
    ApiUsers.detail(slug).then(({ data }) => {
      const userState = {
        info: data,
        tabData: (data.attributes.member_in_team || data.attributes.has_team) ? Data.portfolio.map(any => {
          if(any.id === 4){
            if(data.attributes.type_user === 'user'){
              return{...any, title: 'Teams'}
            }else{
              return{...any, title: 'Members'}
            }
          }else{
            return{...any}
          }
        }): Data.portfolio.filter(any => any.title !== ''),
        tabActive: 'Designs',
      }

      const image = {
        preview: data.attributes.cover.url,
      }

      const coverState = {
        image,
        oldImage: image,
      }

      this.props.handleSetState('activeProfile', 'username', data.attributes.username)()

      this.setState(currState => ({
        user: functions.mergeState(currState.user, userState),
        cover: functions.mergeState(currState.cover, coverState),
      }), () => {
        const activeContent = this.state.user.info !== null ? this.state.user.tabData.find(any => any.title === this.state.user.tabActive) : {}
        this.getContentTab('Designs', activeContent.page, slug)()
        this.handleSetState('cover', 'width', document.getElementById('coverwrapper').offsetWidth)()
        const metaData = {
          title: this.state.user.info !== null ? `${this.state.user.info.attributes.name} (${this.state.user.info.attributes.username})` : '',
          image: '',
          url: slug,
          description: 'Let the world know your masterpiece artwork design. marvlous is number #1 place to showcase your masterpiece artwork design and monetize it',
        }
  
        this.props.updateMetaTitle(metaData)
      })
    })
  }

  getContentTab = (type, page, slug) => async () => {
    let data = []
    let count = 0
    let newCountImages = 0
    let newCountVideos = 0
    let newCountMember = 0
    let newCountTeam = 0
    const designType = type.toLowerCase().slice(0, -1)
    const activeTab = this.state.user.tabData.find(any => any.title === type)
    switch (type) {
      case 'Designs':
        data = await ApiDesigns.list(slug, page, designType)
        count = await ApiDesigns.count(slug, designType)
        newCountImages = await ApiDesigns.count(slug, 'Images'.toLowerCase().slice(0, -1))
        newCountVideos = await ApiDesigns.count(slug, 'Videos'.toLowerCase().slice(0, -1))
        newCountMember = (this.state.user.info.attributes.member_in_team || this.state.user.info.attributes.has_team) && this.state.user.info.attributes.type_user === 'team' ? await ApiTeams.count(slug) : 0
        newCountTeam = (this.state.user.info.attributes.member_in_team || this.state.user.info.attributes.has_team) && this.state.user.info.attributes.type_user === 'user' ? await ApiMembers.count(slug) : 0
        break
      case 'Images':
        data = await ApiDesigns.list(slug, page, designType)
        count = await ApiDesigns.count(slug, designType)
        break
      case 'Videos':
        data = await ApiDesigns.list(slug, page, designType)
        count = await ApiDesigns.count(slug, designType)
        break
      case 'Members':
        data = await ApiTeams.member(slug)
        count = await ApiTeams.count(slug)
        break
      case 'Teams':
        data = await ApiMembers.team(slug)
        count = await ApiMembers.count(slug)
        break
      default:
        data = []
        count = 0
    }

    this.setState(currState => ({
      user: {
        ...currState.user,
        tabData: currState.user.tabData.map((any) => {
          const newData = (type === 'Designs' || type === 'Images' || type === 'Videos') ? 
          any.data.filter(item => (data.data.filter(yolo => yolo.id === item.id).length > 0)).length
            ? [...any.data] : any.data.concat(data.data) : []
          if (any.title === type && (type === 'Designs' || type === 'Images' || type === 'Videos')) {
            return {
              ...any, data: newData, loading: false, count: count.data, resultExist: newData.length > 0,
            }
          } else if((any.title === 'Members' && type === 'Members') || (any.title === 'Teams' && type === 'Teams')){
            return{
              ...any, data: data.data, loading: false, count: count.data, resultExist: data.data.length > 0,
            }
          } else if (any.title === 'Images' && type === 'Designs' && any.loading) {
            return { ...any, count: newCountImages.data }
          } else if (any.title === 'Videos' && type === 'Designs' && any.loading) {
            return { ...any, count: newCountVideos.data }
          } else if(any.title === 'Members' && type === 'Designs' && any.loading){
            return { ...any, count: newCountMember.data }
          } else if(any.title === 'Teams' && type === 'Designs' && any.loading){
            return { ...any, count: newCountTeam.data }
          } else {
            return { ...any }
          }
        }),
      },
    }), () => {
      const activeTab = this.state.user.tabData.find(any => any.title === this.state.user.tabActive)
      const newState = {
        activeTab,
        user: this.state.user.info,
      }
      this.props.handleSetMergeState('portfolio', newState)()
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.state.portfolio.previousSlug !== '' && 
      !this.props.state.portfolio.modalView) {
      const metaData = {
        title: this.state.user.info === null ? '' : `${this.state.user.info.attributes.name} (${this.state.user.info.attributes.username})`,
        image: '',
        url: this.props.state.portfolio.previousSlug,
        description: 'Let the world know your masterpiece artwork design. marvlous is number #1 place to showcase your masterpiece artwork design and monetize it',
      }

      this.props.updateMetaTitle(metaData)
    }

    if (prevProps.slug !== this.props.slug) {
      this.initializeData(this.props.slug)
    }
  }

  submitSubscribe = subscribe => () => {
    const objectToSend = {
      userId: this.state.user.info.attributes.id,
    }
    const data = Model.subscriptionParams(objectToSend)

    if (subscribe) {
      ApiSubscriptions.unsubscribe(data).then((response) => {
        this.setState(currState => ({
          user: functions.createStateObj(currState.user, 'info', response.data),
        }))
      })
    } else {
      ApiSubscriptions.subscribe(data).then((response) => {
        this.setState(currState => ({
          user: functions.createStateObj(currState.user, 'info', response.data),
        }))
      })
    }
  }

  submitSubscribeList = (subscribe, userId) => async () => {
    const objectToSend = { userId }
    const dataToSend = Model.subscriptionParams(objectToSend)
    const activeContent = this.state.user.info !== null ? this.state.user.tabData.find(any => (any.title === 'Members' || any.title === 'Teams')) : {}
    const response = subscribe ? await ApiSubscriptions.unsubscribe(dataToSend) : await ApiSubscriptions.subscribe(dataToSend)
    const newData = activeContent.data.map((any) => {
      if (any.attributes.id === userId) {
        return {
          ...any,
          attributes: {
            ...any.attributes,
            subscribed: response.data.attributes.subscribed,
            subscribers: response.data.attributes.subscribers,
          },
        }
      }
      return { ...any }
    })

    const tabContent = this.state.user.tabData.map(any =>{
      if(any.title === 'Members' || any.title === 'Teams'){
        return{...any, data: newData}
      }else{
        return{...any}
      }
    })

    //   handleSetState = (type, subtype, value) => () => {
    this.handleSetState('user', 'tabData', tabContent)()
  }

  handleSetState = (type, subtype, value) => () => {
    this.setState(currState => ({
      [type]: functions.createStateObj(currState[type], subtype, value),
    }), () => {
      if (subtype === 'tabActive') {
        const slug = this.props.slug
        const activeContent = this.state.user.info !== null ? this.state.user.tabData.find(any => any.title === this.state.user.tabActive) : {}
        this.getContentTab(value, activeContent.page, slug)()
      }
    })
  }

  handlePositionChange = (type, subtype) => (position) => {
    this.setState(currState => ({
      [type]: functions.createStateObj(currState[type], subtype, position),
    }))
  }

  setEditorRef = editor => this.editor = editor

  handleScale = (type, subtype) => (e) => {
    this.setState(currState => ({
      [type]: functions.createStateObj(currState[type], subtype, e),
    }))
  }

  handleSetStateFile = (type, subtype) => (files) => {
    const newState = {
      [subtype]: files[0],
      resultPreview: files[0],
      position: { x: 0.5, y: 0.5 },
      scale: 1,
    }

    this.setState(currState => ({
      [type]: functions.mergeState(currState[type], newState),
    }))
  }

  updateCover = type => () => {
    const newStateLoading = {
      loadingUpdate: true
    }
    
    this.setState(currState => ({
      cover: functions.mergeState(currState.cover, newStateLoading),
    }), () => {
      const data = this.state[type]
      const objectToSend = Model.userParams(data)
      if (type === 'cover') {
        ApiUsers.cover(objectToSend).then(({ data }) => {
          const image = {
            preview: data.attributes.cover.url,
          }
  
          const newState = {
            image,
            editMode: false,
            resultPreview: null,
            loadingUpdate: false,
          }
  
          this.setState(currState => ({
            cover: functions.mergeState(currState.cover, newState),
          }))
        })
      } else {
        ApiUsers.avatar(objectToSend).then(({ data }) => {
          console.log(data)
        })
      }
    })
  }

  cancelUpdateCover = type => () => {
    const image = this.state[type].oldImage

    const newState = {
      image,
      editMode: false,
      resultPreview: null,
    }

    this.setState(currState => ({
      [type]: functions.mergeState(currState[type], newState),
    }))
  }

  resetImageCover = type => () => {
    const newState = {
      position: { x: 0.5, y: 0.5 },
      scale: 1,
    }

    this.setState(currState => ({
      [type]: functions.mergeState(currState[type], newState),
    }))
  }

  removeImageCover = type => () => {
    const newState = {
      image: {
        preview: null,
      },
    }

    this.setState(currState => ({
      [type]: functions.mergeState(currState[type], newState),
    }))
  }

  render() {
    const {
      submitSubscribe,
      handleSetStateFile,
      handleSetState,
      handleScroll,
      handlePositionChange,
      setEditorRef,
      saveImageCover,
      handleScale,
      updateCover,
      cancelUpdateCover,
      resetImageCover,
      removeImageCover,
      submitSubscribeList,
      props: { handleSetMergeState, typeState },
      state: { user, cover },
    } = this

    const portfolioContent = user.info !== null ? user.tabData.find(any => any.title === this.state.user.tabActive) : {}

    console.log(this.state, 'state here')

    return (
      <div id="marvlous" className={Helpers.mergeCss(Base.dBlock, Styles.portfolioOverflow)}>
        <div className={Base.row}>
          <div className={Helpers.mergeCss(Base.col, Base.w12)}>
            <div className={Styles.profileWrapper(user.info !== null)}>

              {user.info === null
                && (
                <div className={Helpers.mergeCss(Base.dFlex, Base.marginTop3, Base.justifyContentCenter)}>
                  <Loading />
                </div>
                )
              }

              {user.info !== null
                && (
                <Fragment>
                  {(this.props.state.user.info !== null
                    && this.props.state.user.info.attributes.username === user.info.attributes.username
                    ? (
                      <Fragment>
                        {(!cover.editMode) && (
                          cover.image.preview !== null
                            ? (
                              <div id="coverwrapper" className={Helpers.mergeCss(Styles.coverWrapper, Base.positionRelative)}>
                                <div className={Styles.buttonPosition}>
                                  <button onClick={handleSetState('cover', 'editMode', true)} className={Styles.buttonEdit}>
                                    Edit
                                  </button>
                                </div>
                                <img className={Styles.imgProfileWrapper} src={cover.image.preview} />
                                {user.info.attributes.link !== null
                            && (
                            <ul className={Styles.socialMedia}>
                              {user.info.attributes.link.instagram !== '' && <li><a style={{ color: '#292929', textDecoration: 'none' }} target="_blank" href={`https://${user.info.attributes.link.instagram}`}><i className="fab fa-instagram" /></a></li>}
                              {user.info.attributes.link.facebook !== '' && <li><a style={{ color: '#292929', textDecoration: 'none' }} target="_blank" href={`https://${user.info.attributes.link.facebook}`}><i className="fab fa-facebook" /></a></li>}
                              {user.info.attributes.link.twitter !== '' && <li><a style={{ color: '#292929', textDecoration: 'none' }} target="_blank" href={`https://${user.info.attributes.link.twitter}`}><i className="fab fa-twitter" /></a></li>}
                              {user.info.attributes.link.youtube !== '' && <li><a style={{ color: '#292929', textDecoration: 'none' }} target="_blank" href={`https://${user.info.attributes.link.youtube}`}><i className="fab fa-youtube" /></a></li>}
                              {user.info.attributes.link.medium !== '' && <li><a style={{ color: '#292929', textDecoration: 'none' }} target="_blank" href={`https://${user.info.attributes.link.medium}`}><i className="fab fa-medium" /></a></li>}
                              {user.info.attributes.link.website !== '' && <li><a style={{ color: '#292929', textDecoration: 'none' }} target="_blank" href={`https://${user.info.attributes.link.website}`}><i style={{ fontSize: '19px', position: 'relative', top: '1px' }} className="las la-globe" /></a></li>}
                            </ul>
                            )
                          }
                              </div>
                            )
                            : (
                              <div id="coverwrapper" className={Helpers.mergeCss(Styles.coverWrapper, Base.positionRelative)}>
                                <div className={Styles.buttonPosition}>
                                  <button onClick={handleSetState('cover', 'editMode', true)} className={Styles.buttonEdit}>
                                    Add cover
                                  </button>
                                </div>
                                <div className={Styles.defaultCover} />
                                {user.info.attributes.link !== null
                            && (
                            <ul className={Styles.socialMedia}>
                              {user.info.attributes.link.instagram !== '' && <li><a style={{ color: '#292929', textDecoration: 'none' }} target="_blank" href={`https://${user.info.attributes.link.instagram}`}><i className="fab fa-instagram" /></a></li>}
                              {user.info.attributes.link.facebook !== '' && <li><a style={{ color: '#292929', textDecoration: 'none' }} target="_blank" href={`https://${user.info.attributes.link.facebook}`}><i className="fab fa-facebook" /></a></li>}
                              {user.info.attributes.link.twitter !== '' && <li><a style={{ color: '#292929', textDecoration: 'none' }} target="_blank" href={`https://${user.info.attributes.link.twitter}`}><i className="fab fa-twitter" /></a></li>}
                              {user.info.attributes.link.youtube !== '' && <li><a style={{ color: '#292929', textDecoration: 'none' }} target="_blank" href={`https://${user.info.attributes.link.youtube}`}><i className="fab fa-youtube" /></a></li>}
                              {user.info.attributes.link.medium !== '' && <li><a style={{ color: '#292929', textDecoration: 'none' }} target="_blank" href={`https://${user.info.attributes.link.medium}`}><i className="fab fa-medium" /></a></li>}
                              {user.info.attributes.link.website !== '' && <li><a style={{ color: '#292929', textDecoration: 'none' }} target="_blank" href={`https://${user.info.attributes.link.website}`}><i style={{ fontSize: '19px', position: 'relative', top: '1px' }} className="las la-globe" /></a></li>}
                            </ul>
                            )
                          }
                              </div>
                            )
                        )
                      }
                        {(cover.resultPreview !== null && cover.editMode) && (
                        <div className={Helpers.mergeCss(Base.positionRelative, Styles.avatarWrapper)}>
                          <div className={Styles.buttonPosition}>
                            <button onClick={saveImageCover('cover')} className={Styles.buttonSave}>
                              Apply
                            </button>
                            <button onClick={resetImageCover('cover')} className={Styles.buttonCancel}>
                              Reset
                            </button>
                          </div>
                          <AvatarEditor
                            ref={setEditorRef}
                            scale={parseFloat(cover.scale)}
                            width={cover.width}
                            height={cover.height}
                            position={cover.position}
                            onPositionChange={handlePositionChange('cover', 'position')}
                            rotate={parseFloat(cover.rotate)}
                            borderRadius={cover.width / (100 / cover.borderRadius)}
                            border={0}
                            image={cover.image !== null && cover.image.preview}
                            className="editor-canvas"
                          />
                          <div className={Helpers.mergeCss(Base.row, Styles.rangeCover)}>
                            <div className={Helpers.mergeCss(Base.col, Base.w12)}>
                              <div className="input-range input-range-cover">
                                <InputRange
                                  maxValue={2}
                                  minValue={1}
                                  step={0.01}
                                  value={cover.scale}
                                  onChange={handleScale('cover', 'scale')}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        )}

                        {(cover.resultPreview === null && cover.editMode)
                        && (
                        <div className={Base.positionRelative}>
                          <div className={Styles.overlayDropzone(cover.image.preview !== null)}>
                            <Dropzone
                              onDrop={handleSetStateFile('cover', 'image')}
                              className={Styles.dropzone}
                            >
                              <i className="las la-camera" />
                            </Dropzone>
                            {(cover.image.preview !== null) && <span onClick={removeImageCover('cover')}><i className="las la-times" /></span>}
                          </div>
                          <div className={Styles.buttonPosition}>
                            <button onClick={cover.loadingUpdate ? '' : updateCover('cover')} className={Styles.buttonSave}>
                            {cover.loadingUpdate ?
                              <div className={Styles.donut(true)}/>
                              :
                              'Save'
                            }
                            </button>
                            <button onClick={cancelUpdateCover('cover')} className={Styles.buttonCancel}>
                              Cancel
                            </button>
                          </div>
                          {(cover.image.preview !== null)
                            ? <img className={Styles.imgProfileWrapper} src={cover.image.preview} />
                            : <div className={Styles.defaultCover} />}
                        </div>
                        )
                      }
                      </Fragment>
                    )
                    : (cover.image.preview !== null
                      ? (
                        <div id="coverwrapper" className={Helpers.mergeCss(Styles.coverWrapper, Base.positionRelative)}>
                          <img className={Styles.imgProfileWrapper} src={cover.image.preview} />
                          {user.info.attributes.link !== null
                            && (
                            <ul className={Styles.socialMedia}>
                              {user.info.attributes.link.instagram !== '' && <li><a style={{ color: '#292929', textDecoration: 'none' }} target="_blank" href={`https://${user.info.attributes.link.instagram}`}><i className="fab fa-instagram" /></a></li>}
                              {user.info.attributes.link.facebook !== '' && <li><a style={{ color: '#292929', textDecoration: 'none' }} target="_blank" href={`https://${user.info.attributes.link.facebook}`}><i className="fab fa-facebook" /></a></li>}
                              {user.info.attributes.link.twitter !== '' && <li><a style={{ color: '#292929', textDecoration: 'none' }} target="_blank" href={`https://${user.info.attributes.link.twitter}`}><i className="fab fa-twitter" /></a></li>}
                              {user.info.attributes.link.youtube !== '' && <li><a style={{ color: '#292929', textDecoration: 'none' }} target="_blank" href={`https://${user.info.attributes.link.youtube}`}><i className="fab fa-youtube" /></a></li>}
                              {user.info.attributes.link.medium !== '' && <li><a style={{ color: '#292929', textDecoration: 'none' }} target="_blank" href={`https://${user.info.attributes.link.medium}`}><i className="fab fa-medium" /></a></li>}
                              {user.info.attributes.link.website !== '' && <li><a style={{ color: '#292929', textDecoration: 'none' }} target="_blank" href={`https://${user.info.attributes.link.website}`}><i style={{ fontSize: '19px', position: 'relative', top: '1px' }} className="las la-globe" /></a></li>}
                            </ul>
                            )
                          }
                        </div>
                      ) : (
                        <div id="coverwrapper" className={Helpers.mergeCss(Styles.coverWrapper, Base.positionRelative)}>
                          <div className={Styles.defaultCover} />
                          {user.info.attributes.link !== null
                            && (
                            <ul className={Styles.socialMedia}>
                              {user.info.attributes.link.instagram !== '' && <li><a style={{ color: '#292929', textDecoration: 'none' }} target="_blank" href={`https://${user.info.attributes.link.instagram}`}><i className="fab fa-instagram" /></a></li>}
                              {user.info.attributes.link.facebook !== '' && <li><a style={{ color: '#292929', textDecoration: 'none' }} target="_blank" href={`https://${user.info.attributes.link.facebook}`}><i className="fab fa-facebook" /></a></li>}
                              {user.info.attributes.link.twitter !== '' && <li><a style={{ color: '#292929', textDecoration: 'none' }} target="_blank" href={`https://${user.info.attributes.link.twitter}`}><i className="fab fa-twitter" /></a></li>}
                              {user.info.attributes.link.youtube !== '' && <li><a style={{ color: '#292929', textDecoration: 'none' }} target="_blank" href={`https://${user.info.attributes.link.youtube}`}><i className="fab fa-youtube" /></a></li>}
                              {user.info.attributes.link.medium !== '' && <li><a style={{ color: '#292929', textDecoration: 'none' }} target="_blank" href={`https://${user.info.attributes.link.medium}`}><i className="fab fa-medium" /></a></li>}
                              {user.info.attributes.link.website !== '' && <li><a style={{ color: '#292929', textDecoration: 'none' }} target="_blank" href={`https://${user.info.attributes.link.website}`}><i style={{ fontSize: '19px', position: 'relative', top: '1px' }} className="las la-globe" /></a></li>}
                            </ul>
                            )
                          }
                        </div>
                      ))
                    )
                  }
                  <div className={Helpers.mergeCss(Base.padding3, Styles.profilePadding)}>
                    <div className={Helpers.mergeCss(Base.dInlineBlock, Styles.profileDescriptionHeight)}>
                      <UserInfo
                        state={this.props.state}
                        user={user}
                        submitSubscribe={submitSubscribe}
                        portfolio
                      />
                    </div>
                  </div>
                  <div className={Helpers.mergeCss(Styles.tabPortfolio, Base.text, Base.paddingLeft3, Base.paddingRight3, Base.w100)}>
                    <div className={Helpers.mergeCss(Base.row, Styles.mobilenoMargin, Base.justifyContentCenter)}>
                      {user.tabData.map(any => (
                        <div key={any.id} className={Helpers.mergeCss(Base.col, Styles.tabCol(any.title === this.state.user.tabActive), Styles.w2, Base.w2)}>
                          <div onClick={handleSetState('user', 'tabActive', any.title)} className={Helpers.mergeCss(Base.dFlex, Base.paddingTop, Base.paddingBottom2, Base.alignItemsCenter, Base.justifyContentCenter)}>
                            {any.title}
                            {' '}
                            {any.count === 0 ? null : <span style={{ fontSize: '14px', marginLeft: '5px' }} className={any.title === this.state.user.tabActive ? '' : Base.text}>{any.count}</span> }
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={Helpers.mergeCss(Base.row, Styles.rowPortfolio)}>
                    <TabContent
                      loading={portfolioContent.loading}
                      resultExist={portfolioContent.resultExist}
                      data={portfolioContent.data}
                      loadingPortfolio={portfolioContent.loadingDataPortfolio}
                      endOfPortfolio={portfolioContent.endOfPortfolio}
                      handleSetMergeState={handleSetMergeState}
                      activeContent={portfolioContent.title}
                      submitSubscribeList={submitSubscribeList}
                      handleScroll={handleScroll}
                      typeState={typeState}
                      user={user}
                    />
                  </div>
                </Fragment>
                )
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Portfolio
