import { css } from 'emotion'

const rowNotifications = css`
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 60px;
  @media only screen and (min-width:0px) and (max-width:767px){
    margin-bottom: 60px;
  }
`

const colNotifications = css`
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 0px;
  text-decoration: none;
  color: #212529;
  &:hover{
    text-decoration: none;
    color: #212529;
  }
`

function wrapperNotifications(notReadYet) {
  let active

  if (notReadYet) {
    active = 'background:rgba(17, 129, 235, 0.05);'
  }

  return css`
    border-bottom: 1px solid #eee;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 15px;
    padding-top: 15px;
    ${active}
  `
}

const iconNotifications = css`
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  width: 50px;
  margin-right: 15px;
  div{
    width: 50px;
    height: 50px;    
  }
  img{
    width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
    background: #fff;
  }
`

const descNotifications = css`
  width: calc(100% - 100px);
  display: inline-flex;
  a{
    text-decoration: none;
  }
`

const designThumbNotifications = css`
  height: 150px;
  width:auto;
  position: relative;
  margin-right: 10px;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  &:last-of-type{
    margin-right: 0px;
  }
`

const moreDesign = css`
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 2px;
  background: #fff;
  color: #1181eb;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  right: 10px;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  font-size: 12px;
`

const scrollNotifications = css`
  display: block!important;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
`

const parentWrapper = css`
  width: 225px;
  display: flex;
`

const previewWrapper = css`
  height: auto;
  width: 100%;
  &:last-of-type{
    margin-right: 0px;
  }
  margin-right: 10px;
  video, img{
    width: 100%;
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
    border-radius: 2px;
  }
`

const iconIndication = css`
  position:absolute;
  right: 1px;
  bottom: -3px;
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border-radius: 50%;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  background:#1181eb;
  color: #fff;
  .fa-bookmark{
    position: relative;
    left: -0.2px;
  }
`

const cardNotif = css`
  width: 100%;
  position: relative;
`

const contentComment = css`
  a{
    pointer-events: none;
    cursor: default;
  }
`

export default {
  rowNotifications,
  colNotifications,
  parentWrapper,
  wrapperNotifications,
  iconNotifications,
  descNotifications,
  designThumbNotifications,
  moreDesign,
  scrollNotifications,
  previewWrapper,
  iconIndication,
  cardNotif,
  contentComment,
}
