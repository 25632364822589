import i18next from 'i18next'

const localeEn = require('locales/en.yml')
const localeId = require('locales/id.yml')

const usedLocale = sessionStorage.getItem('locale') || 'en'

i18next.init({
  interpolation: {
    escapeValue: false,
    prefix: '%{',
    suffix: '}',
  },
  lng: usedLocale,
  resources: {
    en: { translation: localeEn.en },
    id: { translation: localeId.id },
  },
})

export default i18next
