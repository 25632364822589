import React, { Fragment } from 'react'
import { Link, matchPath } from 'react-router-dom'
import { Helpers, Base } from 'utils'
import placeholderImage from 'images/user-placeholder.jpg'
import Styles from './Styles'


const ListitemMobile = ({
  label,
  icon,
  active,
  page,
  user,
  handleSetState,
  closeModal,
  typeState,
  state,
  contentOverlay,
  modalActive,
  login,
}) => {
  if (page !== 'more') {
    const content = user === null
      ? (
        <li className={Helpers.mergeCss(Styles.item, Styles.text)}>
          {
          modalActive
            ? (
              <div
                style={{ position: 'relative' }}
                className={active ? Helpers.mergeCss(Styles.active, Styles.text, Base.positionRelative) : Helpers.mergeCss(Styles.text, Base.positionRelative)}
                onClick={closeModal}
              >
                {contentOverlay}
                {page === 'portfolio' && user !== null
                  ? (
                    <Fragment>
                      <img src={(user.attributes.avatar.url === null || user.attributes.avatar.url === '') ? placeholderImage : user.attributes.avatar.url} />
                      {/* <img src={placeholderImage}/> */}
                      <span>
                        {user.attributes.name}
                        {/* {user.attributes.verified &&
                    (<i style={{marginLeft: '4px', fontSize: '12px', position: 'relative',
                        top: '-0.1px', color: '#1181eb'}} className="fa fa-check-circle"/>)
                  } */}
                      </span>
                    </Fragment>
                  )
                  : (
                    <Fragment>
                      <i className={icon} />
                      <span>
                        {label}
                      </span>
                    </Fragment>
                  )
            }
              </div>
            )
            : page === 'feed'
              ? (
                <Link
                  className={active ? Helpers.mergeCss(Styles.active, Styles.text, Base.positionRelative) : Helpers.mergeCss(Styles.text, Base.positionRelative)}
                  to={page === 'feed' ? '/' : page === 'portfolio' && user !== null ? `/${user.attributes.username}` : `/${page}`}
                >
                  {contentOverlay}
                  {page === 'portfolio' && user !== null
                    ? (
                      <Fragment>
                        <img src={(user.attributes.avatar.url === null || user.attributes.avatar.url === '') ? placeholderImage : user.attributes.avatar.url} />
                        {/* <img src={placeholderImage}/> */}
                        <span>
                          {user.attributes.name}
                          {/* {user.attributes.verified &&
                    (<i style={{marginLeft: '4px', fontSize: '12px', position: 'relative',
                        top: '-0.1px', color: '#1181eb'}} className="fa fa-check-circle"/>)
                  } */}
                        </span>
                      </Fragment>
                    )
                    : (
                      <Fragment>
                        <i className={icon} />
                        <span>
                          {label}
                        </span>
                      </Fragment>
                    )
            }
                </Link>
              )
              : (
                <div
                  className={active ? Helpers.mergeCss(Styles.active, Base.positionRelative, Styles.text) : Helpers.mergeCss(Styles.text, Base.positionRelative)}
                  onClick={() => {
                    window.location.href = '/users/login'
                  }}
                >
                  {contentOverlay}
                  {page === 'portfolio' && user !== null
                    ? (
                      <Fragment>
                        <img src={(user.attributes.avatar.url === null || user.attributes.avatar.url === '') ? placeholderImage : user.attributes.avatar.url} />
                        {/* <img src={placeholderImage}/> */}
                        <span>
                          {user.attributes.name}
                          {/* {user.attributes.verified &&
                    (<i style={{marginLeft: '4px', fontSize: '12px', position: 'relative',
                        top: '-0.1px', color: '#1181eb'}} className="fa fa-check-circle"/>)
                  } */}
                        </span>
                      </Fragment>
                    )
                    : (
                      <Fragment>
                        <i className={icon} />
                        <span>
                          {label}
                        </span>
                      </Fragment>
                    )
            }
                </div>
              )
        }
        </li>
      )
      : (
        <li className={Helpers.mergeCss(Styles.item, Base.positionRelative, Styles.text)}>
          {modalActive
            ? (
              <div
                className={active ? Helpers.mergeCss(Styles.active, Styles.text, Base.positionRelative) : Helpers.mergeCss(Styles.text, Base.positionRelative)}
                style={{ position: 'relative' }}
                onClick={closeModal}
              >
                {contentOverlay}
                {page === 'portfolio' && user !== null
                  ? (
                    <Fragment>
                      <img src={(user.attributes.avatar.url === null || user.attributes.avatar.url === '') ? placeholderImage : user.attributes.avatar.url} />
                      {/* <img src={placeholderImage}/> */}
                      <span>
                        {user.attributes.name}
                        {/* {user.attributes.verified &&
                    (<i style={{marginLeft: '4px', fontSize: '12px', position: 'relative',
                        top: '-0.1px', color: '#1181eb'}} className="fa fa-check-circle"/>)
                  } */}
                      </span>
                    </Fragment>
                  )
                  : (
                    <Fragment>
                      <i className={icon} />
                      <span>
                        {label}
                      </span>
                    </Fragment>
                  )
            }
              </div>
            )
            : (
              <Link
                className={active ? Helpers.mergeCss(Styles.active, Base.positionRelative, Styles.text) : Helpers.mergeCss(Styles.text, Base.positionRelative)}
                to={page === 'feed' ? '/' : page === 'portfolio' && user !== null ? `/${user.attributes.username}` : `/${page}`}
              >
                {contentOverlay}
                {page === 'portfolio' && user !== null
                  ? (
                    <Fragment>
                      <img src={(user.attributes.avatar.url === null || user.attributes.avatar.url === '') ? placeholderImage : user.attributes.avatar.url} />
                      {/* <img src={placeholderImage}/> */}
                      <span>
                        {user.attributes.name}
                        {/* {user.attributes.verified &&
                    (<i style={{marginLeft: '4px', fontSize: '12px', position: 'relative',
                        top: '-0.1px', color: '#1181eb'}} className="fa fa-check-circle"/>)
                  } */}
                      </span>
                    </Fragment>
                  )
                  : (
                    <Fragment>
                      <i className={icon} />
                      <span>
                        {label}
                      </span>
                    </Fragment>
                  )
            }
              </Link>
            )
          }
        </li>
      )
    return content
  }
  return (
    <li className={Helpers.mergeCss(Styles.item, Styles.text)}>
      <div
        onClick={handleSetState(typeState, 'menuMore', !state.container.menuMore)}
        className={state.container.menuMore ? Helpers.mergeCss(Styles.active, Styles.text) : Styles.text}
      >
        <i className={icon} />
        <span>
          {label}
        </span>
      </div>
      {login !== 'true'
          && (
          <span className="bulb-no-animation">
            <i />
          </span>
          )
        }
    </li>
  )
}

export default ListitemMobile
