import React, { Component, Fragment } from 'react'
import {
  Helpers, Base, Routes, history, Validation,
} from 'utils'
import { Link } from 'react-router-dom'
import {
  SearchBar, Loading, CardPreview, EndPage,
} from 'components'
import { ApiTags, ApiFinds, ApiSubscriptions } from 'api'
import moment from 'moment'
import Dropzone from 'react-dropzone'
import Slider from 'react-slick'

import marvlous from 'images/marvlous.png'
import upload from 'images/upload.svg'
import Model from '../Model'
import functions from '../../functions'
import Styles from './Styles'
import placeholderImage from 'images/user-placeholder.jpg'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  )
}

class Subscriptions extends Component {
  componentDidMount() {
    const { props: { typeState, handleScroll } } = this
    document.getElementById('marvlous').addEventListener('scroll', handleScroll(typeState), true)
  }

  componentWillUnmount() {
    this.props.resetState()
    const { props: { typeState, handleScroll } } = this
    document.getElementById('marvlous').removeEventListener('scroll', handleScroll(typeState), true)
  }

  submitSubscribe = (subscribe, userId) => async () => {
    const objectToSend = { userId }
    const dataToSend = Model.subscriptionParams(objectToSend)
    const { props: { state: { subscriptions: { data } } } } = this
    const response = subscribe ? await ApiSubscriptions.unsubscribe(dataToSend) : await ApiSubscriptions.subscribe(dataToSend)
    const newData = data.map((any) => {
      if (any.attributes.id === userId) {
        return {
          ...any,
          attributes: {
            ...any.attributes,
            subscribed: response.data.attributes.subscribed,
            subscribers: response.data.attributes.subscribers,
          },
        }
      }
      return { ...any }
    })
    this.props.handleSetState('subscriptions', 'data', newData)()
  }

  render() {
    const { props: { handleSetMergeState, typeState, state: { subscriptions } }, submitSubscribe } = this

    console.log(subscriptions, 'subscriptions')

    return (
      <div id="marvlous" className={Helpers.mergeCss(Base.dBlock, Styles.subscriptionsWrapper)}>
        <div className={Helpers.mergeCss(Base.row, Styles.rowSubscriptions)}>
          <span className={Helpers.mergeCss(Styles.designSubscriptions, Base.w100)}>
            Your subscriptions
          </span>
        </div>
        <Fragment>
          {
            subscriptions.loading
              ? (
                <div className={Helpers.mergeCss(Styles.subscriptions)}>
                  <div className={Helpers.mergeCss(Base.dFlex, Base.justifyContentCenter, Base.alignItemsCenter)}>
                    <Loading />
                  </div>
                </div>
              )
              : (
                <Fragment>
                  {subscriptions.isDataExist
                    ? (
                      <Fragment>
                        {subscriptions.data.map((any) => {
                          const settings = {
                            dots: false,
                            infinite: false,
                            speed: 500,
                            slidesToShow: 2.75,
                            slidesToScroll: 1,
                            arrow: true,
                            nextArrow: <SampleNextArrow />,
                            prevArrow: <SamplePrevArrow />,
                            responsive: [
                              {
                                breakpoint: 1024,
                                settings: {
                                  slidesToShow: 2.5,
                                  slidesToScroll: 1,
                                }
                              },
                              {
                                breakpoint: 1000,
                                settings: {
                                  slidesToShow: 2,
                                  slidesToScroll: 1
                                }
                              },
                              {
                                breakpoint: 767,
                                settings: {
                                  slidesToShow: 2.25,
                                  slidesToScroll: 1
                                }
                              }
                            ]
                          }

                          return (
                            <div
                              key={any.attributes.id}
                              className={Helpers.mergeCss(Styles.subscriptionsWrapperItem, Base.col, Base.w12)}
                            >
                              <div className={Helpers.mergeCss(Base.row, Base.alignItemsCenter)}>
                                <div className={Helpers.mergeCss(Base.col, Styles.w4, Base.w4)}>
                                  <div className={Helpers.mergeCss(Base.dFlex, Styles.infoDescription)}>
                                    <Link style={{ height: '65px' }} to={`/${any.attributes.username}`}>
                                      <img className={Styles.imgSubscriptions} src={(any.attributes.avatar.url === null || any.attributes.avatar.url === '') ? placeholderImage : any.attributes.avatar.url} alt="" />
                                    </Link>
                                    <div className={Helpers.mergeCss(Base.dBlock, Styles.descSubscriptions, Base.paddingLeft3)}>
                                      <Link to={`/${any.attributes.username}`}>
                                        <h5 className={Helpers.mergeCss(Base.dInlineFlex, Base.alignItemsCenter)}>
                                          {any.attributes.name}
                                        </h5>
                                        <span className={Helpers.mergeCss(Styles.mobile, Base.text)} 
                                          style={{ fontSize: '14px' }}>
                                            {any.attributes.username}
                                          {any.attributes.verified && (
                                            <i
                                              style={{
                                                marginLeft: '4px', fontSize: '12px', position: 'relative', top: '-0.1px', color: '#1181eb',
                                              }}
                                              className="fa fa-check-circle"
                                            />
                                          )}
                                        </span>
                                        {any.attributes.type_user === 'team' && (<span className={Styles.team}>Team</span>)}
                                        
                                        <span className={Helpers.mergeCss(Styles.desktop, Base.text)} 
                                          style={{ fontSize: '14px' }}>{any.attributes.username}</span>
                                      </Link>
                                      <span
                                        className={Helpers.mergeCss(Styles.subscriberCountMobile, Base.text, Base.textCenter)}
                                        style={{ fontSize: '14px' }}>
                                        <b style={{fontWeight: 500, marginRight: '.25rem'}}>
                                          {any.attributes.subscribers - 1}
                                        </b>
                                        subscribers
                                      </span>
                                      <p
                                        className={Helpers.mergeCss(Base.text, Styles.bioSubscriptions, Base.marginTop)}
                                        dangerouslySetInnerHTML={{ __html: any.attributes.bio }}
                                      />
                                      <div className={Helpers.mergeCss(Base.dFlex, Styles.buttonWrapper, Base.alignItemsCenter)}>
                                        <button
                                          onClick={submitSubscribe(any.attributes.subscribed, any.attributes.id)}
                                          className={Helpers.mergeCss(Base.btnMarvlous, Styles.btnMarvlous(any.attributes.subscribed))}
                                        >
                                          {any.attributes.subscribed ? 'Subscribed' : 'Subscribe'}
                                        </button>
                                        <span
                                          className={Helpers.mergeCss(Base.marginLeft3, Styles.subscriberCount, Base.text, Base.textCenter)}
                                          style={{ fontSize: '14px' }}
                                        >
                                          <b className={Base.dBlock}>
                                            {any.attributes.subscribers - 1}
                                          </b>
                                          Subscribers
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                                <div className={Helpers.mergeCss(Base.col, Styles.w8, Base.w8)}>
                                  <Slider {...settings}>
                                    {any.attributes.designs.map((item, index) => {
                                      const newState = {
                                        modalView: true,
                                        slugView: `/designs/${item.attributes.slug}`,
                                        slug: item.attributes.slug,
                                        design: item,
                                        previousSlug: '/subscriptions',
                                      }
                                      return (
                                        <div
                                          key={item.attributes.images[0].url}
                                          style={{ cursor: 'pointer' }}
                                          onClick={handleSetMergeState(typeState, newState)}
                                          className={Styles.slick(false)}
                                        >
                                          <CardPreview
                                            images={item.attributes.images}
                                            item={item}
                                            gif={item.attributes.cover.url !== null && item.attributes.cover.url.includes('gif')}
                                            video={item.attributes.cover.url !== null && item.attributes.cover.url.includes('mp4')}
                                            coverData={item.attributes.cover.url}
                                          />
                                        </div>
                                      )
                                    })}
                                  </Slider>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </Fragment>
                    )
                    : null
              }
                </Fragment>
              )
            }
          <EndPage
            loading={subscriptions.loadingData}
            endPage={subscriptions.endOfData}
          />
        </Fragment>
      </div>
    )
  }
}

export default Subscriptions
