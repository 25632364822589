import base from './api.base'

function engagement(page) {
  return base.get(`analytics/engagement/${page}`)
}

function summary() {
  return base.get('analytics/summary')
}

function statistics(kind) {
  return base.get(`analytics/statistics/${kind}`)
}

const ApiAnalytics = {
  engagement,
  summary,
  statistics,
}

export default ApiAnalytics
