import { css } from 'emotion'

const scrollRevenue = css`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
`

const rowRevenue = css`
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 60px;
  @media only screen and (min-width:0px) and (max-width:767px){
    margin-bottom: 60px;
  }
`

const paddingCustom = css`
  padding-left: 18px !important;
  padding-right: 18px !important;
  h6, h4{
    margin-bottom: 0px;
  }
`

const title = css`
  h6{
    padding-bottom: 15px;
    font-weight: bold;
  }
`

const revenueMoney = css`
  h6{
    color: #6c757d!important;
    font-weight: normal;
  }
  span{
    display: block;
    font-weight: bold;
    color: #1181eb;
    font-size: 1.25rem;
  }
`

const bgRevenue = css`
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 11px;
  padding-right: 18px;
  border-bottom: 1px solid #eee;
`

const buttonPaypal = css`
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  background: #000 !important;
  border: 1px solid #000 !important;
  color: #fff !important;
  border-radius: 30px;
  font-weight: bold;
  height: auto;
  border-radius: 2px;
  background: linear-gradient(to right,#273469, #2790c3) !important;
  color: #fff;
  box-shadow: 0 2px 6px 0 rgba(51,105,231,0.4);
  font-size: 14px;
  -webkit-letter-spacing: 0.5px;
  -moz-letter-spacing: 0.5px;
  -ms-letter-spacing: 0.5px;
  letter-spacing: 0.5px;
  border: 0px;
  text-align: center;
  cursor: pointer;
  border: 0px !important;
  padding: 6px 12px !important;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin-top: 10px;
`


const engagement = css`
  padding: 15px 3px;
`

const defaultHeight = css`
  height: 100%;
  > div{
    height: 33.3%;
  }
`

const wrapperAnalytics = css`
  padding: 15px;
`

const cardWrapper = css`
  position: relative;
  display: inline-flex;
  width: 15%;
  height: auto;
  margin-right: 10px;
  span{
    display: none !important;
  }
  p{
    display: none;
  }
`

const summaryWrapper = css`
  span{
    display: none !important;
  }
  p{
    display: none;
  }
`

const engagementWrapper = css`
  font-size: 14px;
`

const paddCustom = css`
  padding-left: 18px;
  padding-right: 18px;
`

const textPrimay = css`
  color: #1181eb;
  font-weight: bold;
`

const paddingAdd = css`
  padding-left: 11px;
  padding-right: 18px;
`


export default{
  scrollRevenue,
  rowRevenue,
  paddingCustom,
  title,
  revenueMoney,
  bgRevenue,
  buttonPaypal,
  engagement,
  defaultHeight,
  wrapperAnalytics,
  cardWrapper,
  summaryWrapper,
  engagementWrapper,
  paddCustom,
  textPrimay,
  paddingAdd,
}
