import { css } from 'emotion'

const adsCustom = css`
  width: 50%;
  box-shadow: 0 0 0 0 rgba(0,0,0,0.1);
  display:inline-block;
`

export default{
  adsCustom,
}