import React, { Component, Fragment } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { Helpers } from 'utils'
import Styles from './Styles'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const CarouselThumb = ({ feedDetail }) => {
  const customRenderItem = item => (
    <item.type {...item.props} />
  )

  const customRenderThumb = children => children.map((item) => {
    const coverData = item.key
    if (Helpers.isImage(coverData)) {
      return (
        <div key={coverData} className={Styles.thumbWrapper}>
          <img key={coverData} className={Styles.imgFluid} src={coverData} />
        </div>
      )
    } if (coverData.includes('mp4')) {
      return (
        <div key={coverData} className={Styles.thumbWrapper}>
          <video
            className={Styles.imgFluid}
            muted
          >
            <source src={coverData} type="video/mp4" />
            <source src={coverData} type="video/ogg" />
          </video>
        </div>
      )
    }
  })

  const renderThumbnailPreview = (coverData, index, loadingColor) => {
    let content = (<div />)
    if (Helpers.isImage(coverData)) {
      content = (
        <div key={coverData} className={Styles.previewWrapper}>
          {
            index === 0 && loadingColor === null ?
            <img id='image-thumb' key={coverData} className={Styles.imgFluid} src={coverData} />
            :
            <img key={coverData} className={Styles.imgFluid} src={coverData} />
          }
        </div>
      )
    } else if (coverData.includes('mp4')) {
      content = (
        <div key={coverData} className={Styles.previewWrapper}>
          {
            index === 0 && loadingColor === null ?
            <video
              className={Styles.imgFluid}
              muted
              id={"video-thumb"}
              loop
            >
              <source src={coverData} type="video/mp4" />
              <source src={coverData} type="video/ogg" />
            </video>
          :
            <video
              className={Styles.imgFluid}
              muted
              autoPlay
              loop
            >
              <source src={coverData} type="video/mp4" />
              <source src={coverData} type="video/ogg" />
            </video>
          }
        </div>
      )
    }

    return content
  }

  return (
    <Carousel
      renderItem={customRenderItem}
      renderThumbs={customRenderThumb}
      showStatus={false}
      showIndicators={false}
      swipeable
      emulateTouch
      showThumbs={feedDetail.attributes.images.length > 1}
    >
      {feedDetail.attributes.images.map((any, index) => renderThumbnailPreview(any.url, index, feedDetail.attributes.loading_color))}
    </Carousel>
  )
}

export default CarouselThumb
