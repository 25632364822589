import React, { Fragment } from 'react'
import { Helpers, Base } from 'utils'
import { Link } from 'react-router-dom'
import { CardPreview } from 'components'
import Styles from './Styles'
import placeholderImage from 'images/user-placeholder.jpg'

const SearchBar = ({
  search,
  listExist,
  onFocus,
  onBlur,
  onKeyUp,
  onChange,
  page,
  handleSetMergeState,
  tiny,
  slug,
}) => {
  const tagLength = tiny ? 3 : (search.resultKeyword !== null && search.resultKeyword.users.length === 0
    && search.resultKeyword.designs.length === 0 && search.resultKeyword.colors.length === 0) ? 6 : 2

  const colorLength = tiny ? 3 : (search.resultKeyword !== null && search.resultKeyword.tags.length === 0
    && search.resultKeyword.users.length === 0 && search.resultKeyword.designs.length === 0) ? 6 : 2

  const userLength = tiny ? 1 : (search.resultKeyword !== null && search.resultKeyword.tags.length === 0
    && search.resultKeyword.designs.length === 0 && search.resultKeyword.colors.length === 0) ? 6 : 3

  const designLength = tiny ? 1 : (search.resultKeyword !== null && search.resultKeyword.tags.length === 0
    && search.resultKeyword.users.length === 0 && search.resultKeyword.colors.length === 0) ? 6 : 3

  return (
    <div className={Styles.searchBar}>
      <div className={Styles.search(search.keywordFocus || (listExist && search.open))}>
        <div className={Base.inputGroup}>
          <div className={Base.inputGroupPrepend}>
            <span className={Base.inputGroupText} id="basic-addon1">
              <i className="la la-search" />
            </span>
          </div>
          <input
            type="text"
            value={search.keyword}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            onKeyUp={onKeyUp}
            className={Base.formControl}
            placeholder="Search"
            aria-label="Search"
            aria-describedby="basic-search"
          />
        </div>
      </div>

      {
            search.resultKeyword !== null && search.open
            && (search.resultKeyword.users.length > 0
            || search.resultKeyword.tags.length > 0
            || search.resultKeyword.designs.length > 0
            || search.resultKeyword.colors.length > 0)
            && (
            <div className={Styles.resultSearch}>
              <div className={Styles.resultSearchContent}>
                {search.resultKeyword.users.length > 0
                && (
                <div>
                  <div className={Styles.resultSearchSubtitle}>
                    <b>Users</b>
                  </div>
                  {search.resultKeyword.users.map((any) => {
                    const content = (
                      <div className={Base.dFlex}>
                        <img src={(any.attributes.avatar.url === null || any.attributes.avatar.url === '') ? placeholderImage : any.attributes.avatar.url} />
                        <div className={Base.dBlock}>
                          <b className={Helpers.mergeCss(Base.marginRight, Base.dBlock)}>
                            {any.attributes.name}
                            {any.attributes.verified && (
                            <i
                              style={{
                                marginLeft: '4px', fontSize: '12px', position: 'relative', top: '-0.1px', color: '#1181eb',
                              }}
                              className="fa fa-check-circle"
                            />
                            )}
                          </b>
                          <span>
                            {`${any.attributes.username}`}
                          </span>
                        </div>
                      </div>
                    )

                    const newState = {
                      slug: `/${any.attributes.username}`,
                      open: false,
                      query: '',
                      open: false,
                      resultKeyword: null,
                      keywordFocus: false,
                    }

                    const contentData = (page === 'search' || page === 'trends')
                      ? (
                        <Link
                          to={`/${any.attributes.username}`}
                          className={Helpers.mergeCss(Styles.resultUser(tiny), tiny ? Base.dBlock : Base.dFlex)}
                          key={any.attributes.username}
                        >
                          {content}
                        </Link>
                      )
                      : (
                        <div
                          style={{ cursor: 'pointer' }}
                          key={any.attributes.username}
                          onClick={handleSetMergeState('search', newState)}
                          className={Helpers.mergeCss(Styles.resultUser(tiny), tiny ? Base.dBlock : Base.dFlex)}
                        >
                          {content}
                        </div>
                      )

                    return contentData
                  }).slice(0, userLength)}
                </div>
                )
            }

                {
              search.resultKeyword.designs.length > 0 && (
                <div>
                  <div className={Styles.resultSearchSubtitle}>
                    <b>Designs</b>
                  </div>
                  {search.resultKeyword.designs.map((any) => {
                    const content = (
                      <div className={Base.dFlex}>
                        <CardPreview
                          images={any.attributes.images}
                          gif={any.attributes.cover.url !== null && any.attributes.cover.url.includes('gif')}
                          tiny
                          item={any}
                          coverData={any.attributes.cover.url}
                        />
                        <div className={Base.dBlock}>
                          <b className={Base.dBlock}>{any.attributes.title}</b>
                          <span>
                            {any.attributes.user.username}
                            {any.attributes.user.verified && (
                            <i
                              style={{
                                marginLeft: '4px', fontSize: '12px', position: 'relative', top: '-0.1px', color: '#1181eb',
                              }}
                              className="fa fa-check-circle"
                            />
                            )}
                          </span>
                        </div>
                      </div>
                    )

                    const newState = {
                      open: false,
                      modalView: true,
                      slugView: `/designs/${any.attributes.slug}`,
                      slug: any.attributes.slug,
                      design: any,
                      previousSlug: page === 'search' ? `/search/${search.keyword}?tab=${search.tabActive}`
                        : page === 'portfolio' ? `/${slug}` : `/${page}`
                      ,
                    }

                    return (
                      <div
                        key={any.attributes.slug}
                        onClick={handleSetMergeState(page, newState)}
                        className={Styles.designItemSearch(tiny)}
                      >
                        {content}
                      </div>
                    )
                  }).slice(0, designLength)}
                </div>
              )
            }

                {
              search.resultKeyword.tags.length > 0
              && (
                <div>
                  <div className={Styles.resultSearchSubtitle}>
                    <b>Trends</b>
                  </div>
                  {search.resultKeyword.tags.map((any) => {
                    const content = (
                      <Fragment>
                        <p>
                          #
                          {any.tag.name}
                        </p>
                        <span className={Base.text}>
                          {any.count}
                          {' '}
                          designs
                        </span>
                      </Fragment>
                    )
                    const newState = page === 'search' ? {
                      keyword: any.tag.name,
                      open: false,
                      tabActive: 'trends',
                      slug: `/search/${any.tag.name}`,
                      query: '?tab=trends',
                    } : {
                      keyword: any.tag.name,
                      open: false,
                      tabActive: 'trends',
                      slug: `/search/${any.tag.name}?tab=trends`,
                      query: '?tab=trends',
                      resultKeyword: null,
                      keywordFocus: false,
                    }
                    const contentData = page === 'trends' || page === 'search'
                        ? (
                          <Link
                            key={any.tag.id}
                            to={`/search/${any.tag.name}?tab=trends`}
                            className={Styles.searchItemSearch}
                          >
                            {content}
                          </Link>
                        )
                        : (
                          <div
                            key={any.tag.id}
                            onClick={handleSetMergeState('search', newState)}
                            className={Styles.searchItemSearch}
                          >
                            {content}
                          </div>
                        )

                    return contentData
                  }).slice(0, tagLength)}
                </div>
              )
            }

                {
              search.resultKeyword.colors.length > 0 && (
                <div>
                  <div className={Styles.resultSearchSubtitle}>
                    <b>Colors</b>
                  </div>
                  {search.resultKeyword.colors.map((any) => {
                    const content = (
                      <Fragment>
                        <div className={Styles.codeBgcolor(any.attributes.code)} />
                        <b>
                          #
                          {any.attributes.code}
                        </b>
                      </Fragment>
                    )

                    const newState = page === 'search' ? {
                      keyword: any.attributes.code,
                      open: false,
                      tabActive: 'colors',
                    } : {
                      keyword: any.attributes.code,
                      open: false,
                      tabActive: '',
                      slug: `/search/${any.attributes.code}?tab=colors`,
                      query: '?tab=colors',
                      resultKeyword: null,
                      keywordFocus: false,
                    }

                    const contentData = page === 'search'
                      ? (
                        <div
                          key={any.attributes.code}
                          onClick={handleSetMergeState('search', newState)}
                          className={Helpers.mergeCss(Styles.codeColor)}
                        >
                          {content}
                        </div>
                      )
                      : page === 'trends'
                        ? (
                          <Link
                            key={any.attributes.code}
                            to={`/search/${any.attributes.code}?tab=colors`}
                            className={Helpers.mergeCss(Styles.codeColor)}
                          >
                            {content}
                          </Link>
                        )
                        : (
                          <div
                            key={any.attributes.code}
                            onClick={handleSetMergeState('search', newState)}
                            className={Helpers.mergeCss(Styles.codeColor)}
                          >
                            {content}
                          </div>
                        )
                    return contentData
                  }).slice(0, colorLength)}
                </div>
              )
            }
              </div>
            </div>
            )
      }
    </div>
  )
}

export default SearchBar
