import { css } from 'emotion'

const searchBar = css`
  position: relative;
`

const resultSearch = css`
  position: absolute;
  width: 100%;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  height: auto;
  top: 36px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  background: transparent;
  b{
    font-weight: bold;
  }
`

const resultSearchContent = css`
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  height: auto;
  width: 100%;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border: 1px solid rgba(17,129,235,0.4) !important;
  border-top: 0px !important;
  background: #fff;
`

const resultSearchSubtitle = css`
  padding: 6px 16px;
`

function search(focus) {
  let focusStyle = ''
  if (focus) {
    focusStyle = `box-shadow: 0 0 0 4px rgba(17, 129, 235, 0.1) !important;
     border: 1px solid rgba(17, 129, 235, 0.4) !important;
     background: #fff;
     border-radius: 2px;
     input{
       background: #fff !important;
     }
     span{
      background: #fff !important;
     }
    `
  }

  return css`
    border: 1px solid transparent !important;
    ${focusStyle}
  `
}

function resultUser(tiny) {
  const tinyStyle = tiny ? 'padding-top: 7px;' : ''
  return css`
      padding: 3px 16px;
      ${tinyStyle}
      align-items: center;
      text-decoration: none;
      &:hover{
        text-decoration: none;
        background: rgba(17,129,235,0.2);
      }
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-shadow: 0 1px 4px rgba(0,0,0,0.05);
        margin-right: 10px;
      }
      b{
        color: #292929;
        font-weight: bold;
      }
      span{
        color: #6e6d7a !important;
        font-size: 14px;
        margin-top: 0px;
        font-weight: normal;
      }
    `
}

const searchItemSearch = css`
  padding: 3px 20px;
  font-weight: bold;
  border-bottom: 0px solid #eee;
  display: block;
  background: #fff;
  cursor: pointer;
  color: #292929;
  text-decoration:none;
  &:hover{
    color: #292929;
    text-decoration:none;
    background: rgba(17,129,235,0.2) !important;
  }
  p{
    font-weight: bold;
    margin: 0px;
  }
  span{
    font-weight: normal;
    font-size: 14px;
  }
`

const codeColor = css`
  padding: 3px 20px;
  font-weight: bold;
  border-bottom: 0px solid #eee;
  display: flex;
  align-items: center;
  background: #fff;
  cursor: pointer;
  color: #292929;
  text-decoration:none;
  &:hover{
    color: #292929;
    text-decoration:none;
    background: rgba(17,129,235,0.2) !important;
  }
  b{
    font-weight: bold;
  }
  font-size: 14px;
`

function codeBgcolor(code) {
  return css`
    background: #${code};
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 2px;
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  `
}

const imgFluid = css`
  max-width: 60px;
  height: auto;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  margin-right: 10px;
`

function designItemSearch(tiny) {
  const tinyStyle = tiny ? `
      display: block;
      padding-top: 10px;
    ` : `align-items: flex-start;
       display: flex;`

  return css`
    padding: 6px 16px;
    ${tinyStyle}
    text-decoration: none;
    color: #292929;
    &:hover{
      text-decoration: none;
      background: rgba(17,129,235,0.2);
      color: #292929;
    }
    b{
      font-weight: bold;
      margin-top: 0px;
    }
    span{
      color: #6e6d7a !important;
      font-size: 14px;
      margin-top: 20px;
      font-weight: normal;
    }
  `
}

function tabCol(active) {
  const activeStyle = active ? `
    font-weight: bold;
    border-bottom: 2px solid #292929;
    color: #292929;
  ` : ''
  return css`
    flex: 0 0 25% !important;
    max-width: 25% !important;
    cursor: pointer;
    ${activeStyle}
  `
}

export default{
  searchBar,
  search,
  resultSearch,
  resultSearchContent,
  resultSearchSubtitle,
  resultUser,
  designItemSearch,
  searchItemSearch,
  codeColor,
  codeBgcolor,
}
