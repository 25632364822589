import base from './api.base'

function active() {
  return base.get(`teams/active`)
}

function member(username){
  return base.get(`teams/member/${username}`)
}

function count(username){
  return base.get(`teams/count/${username}`)
}

function inviteMember(data){
  return base.post(`teams/invite_member`, data)
}

function deleteMember(data){
  return base.post(`teams/delete_member`, data)
}

function deleteMemberInvite(data){
  return base.post(`teams/delete_member_invite`, data)
}

const ApiTeams = {
  active,
  member,
  count,
  inviteMember,
  deleteMember,
  deleteMemberInvite
}

export default ApiTeams
