
import React, { Component, Fragment } from 'react'
import { Base, Helpers, I18n } from 'utils'
import { Link } from 'react-router-dom'
import {
  Input, InputTag, Checkbox, Hint, Loading, SearchBar, Card, EndPage,
} from 'components'
import {
  ApiTags, ApiFinds, ApiSearch, ApiSubscriptions,
} from 'api'
import moment from 'moment'
import Styles from './Styles'
import placeholderImage from 'images/user-placeholder.jpg'

class TabContent extends Component {
  componentDidMount() {
    const { props: { handleScroll } } = this
    document.getElementById('marvlous').addEventListener('scroll', handleScroll, true)
  }

  componentWillUnmount() {
    const { props: { handleScroll } } = this
    document.getElementById('marvlous').removeEventListener('scroll', handleScroll, true)
  }

  renderContent = (type, activeContent, search, state) => {
    const { props: { typeState, submitSubscribe, handleSetMergeState } } = this
    let content = (<div />)
    switch (type) {
      case 'result':
        content = (
          <Fragment>
            {activeContent.data.users.length > 0
                    && (
                    <Fragment>
                      <span className={Helpers.mergeCss(Styles.searchResult, Base.w100)}>
                        {activeContent.users_count}
                        {' '}
                        Users
                      </span>
                      {activeContent.data.users.map(any => (
                        <div
                          key={any.attributes.id}
                          className={Helpers.mergeCss(Base.col, Styles.colCustom, Base.w6)}
                        >
                          <Link
                            to={`/${any.attributes.username}`}
                            className={Helpers.mergeCss(Base.dFlex, Base.alignItemsCenter)}
                          >
                            <img src={(any.attributes.avatar.url === null || any.attributes.avatar.url === '') ? placeholderImage : any.attributes.avatar.url} />
                            <div className={Base.dBlock}>
                              <b className={Base.dBlock} style={{ fontWeight: 'bold' }}>
                                {any.attributes.name}
                                {any.attributes.verified && (
                                <i
                                  style={{
                                    marginLeft: '4px', fontSize: '12px', position: 'relative', top: '-0.1px', color: '#1181eb',
                                  }}
                                  className="fa fa-check-circle"
                                />
                                )}
                              </b>
                              <span>
                                {any.attributes.username}
                              </span>
                            </div>
                          </Link>
                          <button onClick={submitSubscribe(any.attributes.subscribed, any.attributes.id, type)} className={Helpers.mergeCss(Base.btnMarvlous, Styles.btnMarvlous(any.attributes.subscribed))}>
                            {any.attributes.subscribed ? 'Subscribed' : 'Subscribe'}
                          </button>
                        </div>
                      )).slice(0, 6)}
                    </Fragment>
                    )
                  }
            {
                    activeContent.data.designs.length > 0
                    && (
                    <Fragment>
                      <span className={Helpers.mergeCss(Styles.searchResult, Base.w100)}>
                        {activeContent.designs_count}
                        {' '}
                        Designs
                      </span>
                      <div className={Helpers.mergeCss(Base.row, Styles.rowDesigns)}>
                        {activeContent.data.designs.map((item) => {
                          const humanizeDate = moment(item.attributes.created_at.split('T').join(' '), 'YYYY-MM-DD HH:mm:ss').fromNow()
                          const state = {
                            modalView: true,
                            slugView: `/designs/${item.attributes.slug}`,
                            slug: item.attributes.slug,
                            design: item,
                            previousSlug: `/search/${search.keyword}?tab=${search.tabActive}`,
                          }
                          return (
                            <Card
                              key={item.id}
                              item={item}
                              noload
                              handleSetState={handleSetMergeState(typeState, state)}
                              humanizeDate={humanizeDate}
                            />
                          )
                        })}
                      </div>
                    </Fragment>
                    )
                  }
          </Fragment>
        )
        break
      case 'users':
        content = (
          <Fragment>
            {activeContent.data.length > 0
                      && (
                      <Fragment>
                        <span className={Helpers.mergeCss(Styles.searchResult, Base.w100)}>
                          {activeContent.count}
                          {' '}
                          Result
                        </span>
                        {activeContent.data.map(any => {
                          return(
                            <div
                              key={any.attributes.id}
                              className={Helpers.mergeCss(Base.col, Styles.colCustom, Base.w6)}
                            >
                              <Link
                                to={`/${any.attributes.username}`}
                                className={Helpers.mergeCss(Base.dFlex, Base.alignItemsCenter)}
                              >
                                <img src={(any.attributes.avatar.url === null || any.attributes.avatar.url === '') ? placeholderImage : any.attributes.avatar.url} />
                                <div className={Base.dBlock}>
                                  <b className={Base.dBlock} style={{ fontWeight: 'bold' }}>
                                    {any.attributes.name}
                                    {any.attributes.verified && (
                                    <i
                                      style={{
                                        marginLeft: '4px', fontSize: '12px', position: 'relative', top: '-0.1px', color: '#1181eb',
                                      }}
                                      className="fa fa-check-circle"
                                    />
                                    )}
                                  </b>
                                  <span>
                                    {any.attributes.username}
                                  </span>
                                </div>
                              </Link>
                              {state.user.info !== null && state.user.info.id !== any.id ?
                                <button onClick={submitSubscribe(any.attributes.subscribed, any.attributes.id, type)} className={Helpers.mergeCss(Base.btnMarvlous, Styles.btnMarvlous(any.attributes.subscribed))}>
                                  {any.attributes.subscribed ? 'Subscribed' : 'Subscribe'}
                                </button>
                                :
                                null
                                }
                              {/* <button onClick={submitSubscribe(any.attributes.subscribed, any.attributes.id, type)} className={Helpers.mergeCss(Base.btnMarvlous, Styles.btnMarvlous(any.attributes.subscribed))}>
                                {any.attributes.subscribed ? 'Subscribed' : 'Subscribe'}
                              </button> */}
                            </div>
                          )
                        }
                        )}
                      </Fragment>
                      )
                    }
          </Fragment>
        )
        break
      default:
        content = (
          <Fragment>
            {activeContent.data.length > 0
            && (
            <Fragment>
              <span className={Helpers.mergeCss(Styles.searchResult, Base.w100)}>
                {activeContent.count}
                {' '}
                Designs
              </span>
              <div className={Helpers.mergeCss(Base.row, Styles.rowDesigns)}>
                {activeContent.data.map((item) => {
                  const humanizeDate = moment(item.attributes.created_at.split('T').join(' '), 'YYYY-MM-DD HH:mm:ss').fromNow()
                  const state = {
                    modalView: true,
                    slugView: `/designs/${item.attributes.slug}`,
                    slug: item.attributes.slug,
                    design: item,
                    previousSlug: `/search/${search.keyword}?tab=${search.tabActive}`,
                  }
                  return (
                    <Card
                      key={item.id}
                      item={item}
                      noload
                      handleSetState={handleSetMergeState(typeState, state)}
                      humanizeDate={humanizeDate}
                    />
                  )
                })}
              </div>
            </Fragment>
            )
          }
          </Fragment>
        )
        // code block
    }
    return content
  }

  render() {
    const { props: { search, handleSetState, activeContent, state } } = this
    return (
      <div id="marvlous" className={Helpers.mergeCss(Base.dBlock, Styles.searchWrapper)}>
        <div className={Helpers.mergeCss(Base.row, Styles.rowsearch)}>
          {search.tabData.map(any => (
            <div key={any.id} className={Helpers.mergeCss(Base.col, Styles.tabCol(any.label === search.tabActive), Base.w2)}>
              <div onClick={handleSetState('search', 'tabActive', any.label)} className={Helpers.mergeCss(Base.dFlex, Base.paddingTop2, Base.paddingBottom2, Base.justifyContentCenter)}>
                {any.title}
              </div>
            </div>
          ))}
        </div>
        {search.tabData.length > 0
          ? (
            <div className={Helpers.mergeCss(Base.row, Styles.rowResultSearch)}>
              <span className={Helpers.mergeCss(Styles.searchResult, Base.w100)}>
                {search.keyword}
                {' '}
                {activeContent.title}
              </span>
              {activeContent.loading
                ? (
                  <div className={Helpers.mergeCss(Base.dFlex, Base.w100, Base.marginTop3, Base.justifyContentCenter)}>
                    <Loading />
                  </div>
                )
                : activeContent.resultExist ? this.renderContent(search.tabActive, activeContent, search, state)
                  : (
                    <b className={Styles.noResult}>
                      There is no result
                    </b>
                  )
            }
            </div>
          ) : ''
        }
        <EndPage
          loading={activeContent.loadingDataSearch}
          endPage={activeContent.endOfSearch}
        />
      </div>
    )
  }
}

export default TabContent
