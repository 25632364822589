import React, { Fragment } from 'react'
import { Helpers, Base } from 'utils'
import Styles from './Styles'

const Loading = ({ color, tiny = false }) => (
  <Fragment>
    <div className={Helpers.mergeCss(Styles.donut(tiny))} />
  </Fragment>
)

export default Loading
