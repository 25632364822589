import { css } from 'emotion'

const scrollSettings = css`
  display: block!important;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
`

const rowSettings = css`
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 60px;
  height: 100%;
  @media only screen and (min-width:0px) and (max-width:767px){
    margin-bottom: 60px;
  }
`

const settingsLabel = css`
  padding-right: 15px;
 > div {
   padding-left:0px !important;
   padding-right:0px !important;
 }
 &:first-of-type{
   border-bottom: 1px solid #eee;
   > div {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 29px !important;
    padding-right: 18px !important;
    font-weight: bold;
  }
 }
`

const settingsWrapper = css`
`

const borderRight = css`
 border-right: 1px solid #eee;
`

const borderLeft = css`
 border-left: 1px solid #eee;
`

const settingsMenu = css`
 display: block;
 width: 100%;
 li{
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  padding-right: 29px !important;
  padding-left: 29px !important;
  @media only screen and (min-width:0px) and (max-width:767px){
    padding-right: 16px !important;
  }
  > div{
    cursor:pointer;
    display:inline-block;
  }
 }
`

function contentSettings(tab) {
  const alertStyle = tab === 'alert' ? `
    label{
      display: inline-flex;
      align-items: center;
      span{
        height: 20px;
        width: 20px;
        top: auto;
        &::after{
          left: 7px;
          top: 2px;
          width: 7px;
          height: 13px;
        }
      }
    }
    h6{
      margin: 0px;
    }
  ` : `
    label{
      margin:0px;
    }
  `

  return css`
    position: relative;
    ul{
      li{
        padding-top: 0px;
        padding-bottom: 0px;
        
        textarea{
          height: 100px;
        }
        &:first-of-type{
          padding-top: 10px;
        }
        &:last-of-type{
          padding-bottom: 10px;
          button{
            margin-top: 10px;
            margin-bottom: 0px;
          }
        }
        border-bottom: 0px !important;
        &:last-of-type{
        border-bottom: 1px solid #eee !important;
        }
        &:first-of-type{
          padding-bottom: 0px;
          > div{
            > div{
              &:last-of-type{
                margin-left: 15px;
              }
              button{
                padding: 0 12px !important;
                background: #f3f3f4 !important;
                color: #212529!important;
                box-shadow: none !important;
                height: 30px;
                border-radius: 3px;
              }
            }
          }
        }
        > div{
          position: relative;
          cursor: auto;
          width: 100%;
          i.fa{
            position: absolute;
            right: 15px;
            top: 35px;
          }
          h6{
            color: #212529 !important;
          }
          ${alertStyle}
          textarea{
            height: auto;
            resize: none;
          }
        }
        img{
          border-radius: 50%;
          width: 100px;
          box-shadow:0 2px 8px rgba(0,0,0,0.1);
        }
      }
    }
  `
}

const deleteImage = css`
  position: absolute;
  top: 8px;
  right: 8px;
  background: #212529;
  width: 17px;
  height: 17px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 14px;
`

const dropzoneWrapper = css`
  position: relative;
  input{
    width: 100%;
  }
`

const checkAva = css`
  background: #1181eb;
  position: relative;
  font-size: 14px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  color: #fff;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  margin-right: .5rem!important;
`

const deleteAva = css`
  position: relative;
  font-size: 14px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: #212529;
  color: #fff;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
`

const actionAvaWrapper = css`
  position: absolute;
  top: 6px;
  right: 6px;
`

const avatarEditorWrapper = css`
  canvas{
    border-radius: 50%;
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
    border: dashed 2px #1181eb;
    display: block;
  }
`

const wrapperUpload = css`
  margin-bottom: 20px;
  min-height: 100px;
`

function activeMenu(active) {
  const activeStyle = active ? `
     > div{
       font-weight: bold;
     }
  ` : `
  `
  return css`
    ${activeStyle}
  `
}

const locked = css`
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items:center;
  border-radius: 2px;
  color: #1181eb;
  background: rgba(17, 129, 235, 0.15);
  justify-content: center;
  margin-bottom: 25px;
`

const modalConfirm = css`
  button{
    margin-top: 25px;
  }
`

const team = css`
  font-size: 10px;
  padding: 0px 3px;
  background: #f3f3f3;
  border-radius: 2px;
  display: inline-block;
  color: #6c757d;
  margin-left: 5px;
  font-weight: bold;
  height: 16px;
  border: 1px solid #eee;
  text-transform: uppercase;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
`

const memberItem = css`
  align-items: center;
  img{
    width: 35px !important;
    height: 35px !important;
  }
  margin-bottom: 15px ;
  margin-left: 0px !important;
  span{
    margin-left: 10px;
  }
`

const member = css`
  font-size: 12px;
  padding: 0px 4px;
  background: #f3f3f3;
  border-radius: 2px;
  display: inline-block;
  color: #6c757d;
  margin-left: 10px;
  margin-bottom: 0px;
  font-weight: bold;
  height: 18px;
`

const removeButton = css`
  color: rgb(108, 117, 125);
  position: absolute;
  right: 0;
  margin: 0;
  font-size: 14px;
  cursor: pointer;
  z-index: 111;
`

function emailMember(open){
  const openStyle = open ? `
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  ` : ``

  return css`
    position: relative;
    input{
      margin-bottom: 5px;
      ${openStyle}
    }
  `
}

const memberInviteItem = css`
  position: absolute;
  width: 100%;
  height: auto;
  box-shadow: 0 1px 4px rgba(0,0,0,0.07);
  margin-left: 0px !important;
  top: 62px;
  z-index: 1111;
  background: #fff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  p{
    cursor: pointer;
    padding: .375rem .75rem;
    display: flex;
    margin: 0px;
    align-items: center;
    img{
      width: 24px !important;
      height: 24px !important;
      margin-right: 0.5rem;
    }
  }
`

const loadingPosition = css`
  position: absolute;
  right: 15px;
`

const w7 = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const w5 = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    flex: 0 0 100%;
    max-width: 100%;
    border-bottom: 1px solid #eee;
  }
`

const settingsMenuLeft = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    display: none;
  }
`

const contentDesktop = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    display: none;
  }
`

const contentMobile = css`
  @media only screen and (min-width:768px){
    display: none;
  }
`

const menuItem = css`
  margin: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  padding-right: 29px !important;
  padding-left: 29px !important;
  cursor: pointer;
  &:last-of-type{
    border-bottom: 0px;
  }
`

const mobileNoPadding = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    margin-bottom: 0px !important;
  }
`

const marginMinus = css`
  margin-left: -29px;
  margin-right: -29px;
`

function opener(open){
  const openStyle = open ? `
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #292929;
  `
  :
  `
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid #292929;
  `

  const openBorderStyle = open ? `
    border-bottom: 1px solid #eee;
  `:
  `
    border-bottom: 0px solid #eee;
  `
  return css`
    margin: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 29px !important;
    padding-left: 29px !important;
    ${openBorderStyle}
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    &:before{
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      right: 35px;
      ${openStyle}
    }
  `
}

export default{
  scrollSettings,
  rowSettings,
  settingsWrapper,
  settingsLabel,
  borderRight,
  settingsMenu,
  borderLeft,
  contentSettings,
  deleteImage,
  dropzoneWrapper,
  checkAva,
  deleteAva,
  actionAvaWrapper,
  avatarEditorWrapper,
  wrapperUpload,
  activeMenu,
  locked,
  modalConfirm,
  team,
  memberItem,
  member,
  removeButton,
  emailMember,
  memberInviteItem,
  loadingPosition,
  w7,
  w5,
  settingsMenuLeft,
  contentDesktop,
  contentMobile,
  menuItem,
  mobileNoPadding,
  marginMinus,
  opener
}
