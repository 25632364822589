import React, { Fragment, Component } from 'react'
import { Base, Helpers } from 'utils'
import { CardPreview } from 'components'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Styles from './Styles'
import placeholderImage from 'images/user-placeholder.jpg'

const Card = ({
  item,
  humanizeDate,
  handleSetState,
  noload,
  typeState,
}) => {
  return(
    <div className={Helpers.mergeCss(Base.col, Base.w3, Styles.w3, Styles.colFeed)}>
      <div className={Helpers.mergeCss(Base.card, Styles.cardFeed)}>
        <div className={Helpers.mergeCss(Base.cardBody, Styles.cardFeedBody)}>
          {noload
            ? (
              <div onClick={handleSetState}>
                <CardPreview
                  images={item.attributes.images}
                  item={item}
                  gif={item.attributes.cover.url !== null && item.attributes.cover.url.includes('gif')}
                  video={item.attributes.cover.url !== null && item.attributes.images[0].url.includes('mp4')}
                  coverData={item.attributes.cover.url}
                />
              </div>
            )
            : (
              <Link to={{ pathname: `/designs/${item.attributes.slug}` }}>
                <CardPreview
                  images={item.attributes.images}
                  item={item}
                  gif={item.attributes.cover.url !== null && item.attributes.cover.url.includes('gif')}
                  video={item.attributes.cover.url !== null && item.attributes.images[0].url.includes('mp4')}
                  coverData={item.attributes.cover.url}
                />
              </Link>
            )
              }
          <div className={Helpers.mergeCss(Base.row, Base.marginTop2)}>
            {/* <div className={Helpers.mergeCss(Base.col, Base.w12, Base.textMuted)}>
              <span className={Base.textMuted} style={{ fontWeight: '400' }}>
                {item.attributes.views}
                {' '}
                views
              </span>
              <span
                className={Base.textMuted}
                style={{ fontSize: '12px', marginLeft: '3px', marginRight: '3px' }}
              >
                &bull;
              </span>
              <span className={Base.textMuted}>
                {humanizeDate}
              </span>
            </div> */}
          </div>
          {typeState !== 'portfolio' && (
          <div className={Helpers.mergeCss(Base.row, Base.marginTop)}>
            <div className={Helpers.mergeCss(Base.col, Styles.w12, Base.w12)}>
              <Link
                to={`/${item.attributes.user.username}`}
                className={Helpers.mergeCss(Base.dInlineFlex, Base.alignItemsCenter, Styles.linkProfile)}
              >
                <img
                  className={Styles.imgProfile}
                  src={(item.attributes.user.avatar.url === null || item.attributes.user.avatar.url === '')? placeholderImage : item.attributes.user.avatar.url}
                />
                <div className={Helpers.mergeCss(Base.dInlineFlex, Base.alignItemsCenter)}>
                  <span className={Styles.username}>
                    {`${item.attributes.user.username}`}
                    {item.attributes.user.verified
                            && (
                            <i
                              style={{
                                marginLeft: '4px',
                                fontSize: '12px',
                                position: 'relative',
                                top: '-0.1px',
                                color: '#1181eb',
                              }}
                              className="fa fa-check-circle"
                            />
                            )
                          }
                  </span>
                  {item.attributes.team && <span className={Styles.team}>Team</span>}

                </div>
              </Link>
              <span className={Base.textMuted} style={{ fontWeight: '400', marginLeft: 'auto', lineHeight: 1 }}>
                {item.attributes.views}
                {' '}
                views
              </span>
            </div>
          </div>
          )
              }
        </div>
      </div>
    </div>
  )
}

export default Card
