import { css } from 'emotion'

const rowsearch = css`
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 60px;
  border-bottom: 1px solid #eee;
  @media only screen and (min-width:0px) and (max-width:767px){
  }
`

const searchWrapper = css`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
`

const designsearch = css`
  padding: 10px 20px;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  display: block;
  background: #f3f3f4;
`

const searchItem = css`
  padding: 3px 20px;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  display: block;
  background: #f3f3f4;
  cursor: pointer;
  color: #292929;
  text-decoration:none;
  &:hover{
    color: #292929;
    text-decoration:none;
    background: #e7e7e9 !important;
  }
  p{
    font-weight: bold;
    margin: 0px;
  }
  span{
    font-weight: normal;
    font-size: 14px;
  }
  &:nth-child(odd){
    border-right: 1px solid #eee;
  }
`

const rowsearchItem = css`
  margin: 0px;
`

const searchHeader = css`
  height: 60px;
  align-items: center;
  display: flex;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  margin-left: 0px;
  margin-right: 0px;
  z-index: 1111;
  background: #fff;
  position: absolute;
  width: 100%;
  padding-left: 18px;
  padding-right: 18px;
`

function formControl(active = false) {
  return css``
}

const searchBar = css`
  position: relative;
`

const resultSearch = css`
  position: absolute;
  width: 100%;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  height: auto;
  top: 36px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  background: transparent;
  b{
    font-weight: bold;
  }
`

const resultSearchContent = css`
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  height: auto;
  width: 100%;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border: 1px solid rgba(17,129,235,0.4) !important;
  border-top: 0px !important;
  background: #fff;
`

const resultSearchSubtitle = css`
  padding: 6px 16px;
`

function search(focus) {
  let focusStyle = ''
  if (focus) {
    focusStyle = `box-shadow: 0 0 0 4px rgba(17, 129, 235, 0.1) !important;
     border: 1px solid rgba(17, 129, 235, 0.4) !important;
     background: #fff;
     border-radius: 2px;
     input{
       background: #fff !important;
     }
     span{
      background: #fff !important;
     }
    `
  }

  return css`
    border: 1px solid transparent !important;
    ${focusStyle}
  `
}

const resultUser = css`
  padding: 3px 16px;
  align-items: center;
  text-decoration: none;
  &:hover{
    text-decoration: none;
    background: rgba(17,129,235,0.2);
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
    margin-right: 10px;
  }
  b{
    color: #292929;
    font-weight: bold;
  }
  span{
    color: #6e6d7a !important;
    font-size: 14px;
    margin-top: 0px;
    font-weight: normal;
  }
`

const searchItemSearch = css`
  padding: 3px 20px;
  font-weight: bold;
  border-bottom: 0px solid #eee;
  display: block;
  background: #fff;
  cursor: pointer;
  color: #292929;
  text-decoration:none;
  &:hover{
    color: #292929;
    text-decoration:none;
    background: rgba(17,129,235,0.2) !important;
  }
  p{
    font-weight: bold;
    margin: 0px;
  }
  span{
    font-weight: normal;
    font-size: 14px;
  }
`

const codeColor = css`
  padding: 3px 20px;
  font-weight: bold;
  border-bottom: 0px solid #eee;
  display: flex;
  align-items: center;
  background: #fff;
  cursor: pointer;
  color: #292929;
  text-decoration:none;
  &:hover{
    color: #292929;
    text-decoration:none;
    background: rgba(17,129,235,0.2) !important;
  }
  b{
    font-weight: bold;
  }
  font-size: 14px;
`

function codeBgcolor(code) {
  return css`
    background: #${code};
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 2px;
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  `
}

const imgFluid = css`
  max-width: 60px;
  height: auto;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  margin-right: 10px;
`

const designItemSearch = css`
  padding: 3px 16px;
  align-items: flex-start;
  display: flex;
  text-decoration: none;
  color: #292929;
  &:hover{
    text-decoration: none;
    background: rgba(17,129,235,0.2);
    color: #292929;
  }
  b{
    font-weight: bold;
    margin-top: 0px;
  }
  span{
    color: #6e6d7a !important;
    font-size: 14px;
    margin-top: 20px;
    font-weight: normal;
  }
`

function tabCol(active) {
  const activeStyle = active ? `
    font-weight: bold;
    border-bottom: 2px solid #292929;
    color: #292929;
  ` : ''

  return css`
    flex: 0 0 25% !important;
    max-width: 25% !important;
    cursor: pointer;
    ${activeStyle}
  `
}

const rowResultSearch = css`
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
`

const searchResult = css`
  padding: 10px 30px;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  display: block;
`

const noResult = css`
  font-weight: bold;
  display: block;
  padding: 10px 30px;
`

const colCustom = css`
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 3px;
  padding-bottom: 3px;
  border-bottom: 1px solid #eee;
  &:nth-child(odd){
    border-right: 1px solid #eee;
  }
  &:nth-child(even){
    @media only screen and (min-width:768px){
      button{
        right: 35px;
      }
    }
  }
  display: inline-flex;
  align-items:center;
  a{
    color: #292929 !important;
    &:hover{
      text-decoration: none;
    }
  }
  span{
    color:#6e6d7a !important;
  }
  img{
    margin-right: 10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  }
  @media only screen and (min-width:0px) and (max-width:767px){
    button{
      right: 30px;
    }
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
`

function btnMarvlous(subscribe) {
  const subscribeStyle = subscribe ? `background: #f3f3f4 !important;
  color: #212529!important;
  box-shadow: none !important;` : ''
  return css`
    ${subscribeStyle}
    margin: 0px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    position: absolute;
    width: auto !important;
    right: 20px;
  `
}

const rowDesigns = css`
  margin-left: -5px;
  margin-right: -5px;
  padding-left: 20px;
  padding-right: 35px;
  margin-top: 20px;
  @media only screen and (min-width:0px) and (max-width:767px){
    padding-right: 20px;
  }
`

const searchTop = css`
  padding-left: 10px;
  padding-right: 25px;
  @media only screen and (min-width:0px) and (max-width:767px){
    padding-right: 10px;
  }
`

export default{
  rowsearch,
  searchWrapper,
  designsearch,
  searchItem,
  rowsearchItem,
  searchHeader,
  formControl,
  searchBar,
  resultSearch,
  resultSearchContent,
  resultSearchSubtitle,
  search,
  resultUser,
  searchItemSearch,
  codeColor,
  codeBgcolor,
  imgFluid,
  designItemSearch,
  tabCol,
  rowResultSearch,
  searchResult,
  noResult,
  colCustom,
  btnMarvlous,
  rowDesigns,
  searchTop
}
