import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Helpers, Base, Routes } from 'utils'
import marvlous from 'images/marvlous-transparent.png'
import {
  Listitem, SearchBar, Loading, DesignTrends, ListitemMobile,
} from 'components'
import placeholderImage from 'images/user-placeholder.jpg'
import ads from 'images/ads.png'
import Styles from './Styles'
import Data from './Data'


const Container = ({
  page,
  content,
  state,
  match,
  handleLogout,
  typeState,
  headerLeft,
  headerRight,
  slug,
  handleSetState,
  handleSetStateInput,
  handleSetMergeState,
  search,
  closeModal,
  arrow,
}) => {
  const listExist = search.resultKeyword !== null
    && (search.resultKeyword.users.length > 0
     || search.resultKeyword.tags.length > 0
     || search.resultKeyword.designs.length > 0
     || search.resultKeyword.colors.length > 0)

  const widthContainer = page === 'analytics' || page === 'revenue' || page === 'settings' ? Base.w10 : Base.w10
  const centerPosition = !arrow ? Styles.centerPosition : Styles.nothing

  return (
    <Fragment>
      <div className={Helpers.mergeCss(Base.containerFluid, Styles.container, Base.h100)}>
        <div className={Helpers.mergeCss(Base.row, Base.h100)}>
          <div style={{ background: '#fbfbfb' }} className={Helpers.mergeCss(Base.col, Base.w2, Styles.w2, Base.h100)}>
            <h5 className={Styles.marvlous}>
              <img src={marvlous} className={Styles.imgLogo} alt="" />
              <span>
                Marvlous
              </span>
            </h5>
            <div className={Styles.menu}>
              <ul className={Helpers.mergeCss(Base.ulUnstyled, Styles.menuList)}>
                {
                    Data.menu.map((item) => {
                      const active = page === 'portfolio'
                        ? state.user.info !== null && state.user.info.attributes.username === slug ? page === item.page : false
                        : page === item.page

                      const modalActive = (active && page === 'notifications' && state[page].slug !== '' || active && page === 'feed' && state[page].slug !== '' || active && page === 'saved' && state[page].slug !== '' || active && page === 'subscriptions' && state[page].slug !== '' || active && page === 'portfolio' && state[page].slug !== '')

                      return (
                        <Listitem
                          key={item.id}
                          active={active}
                          page={item.page}
                          user={state.user.info}
                          login={state.login}
                          icon={item.icon}
                          label={item.label}
                          match={match}
                          menuMobile={false}
                          handleSetState={handleSetState}
                          typeState={typeState}
                          modalActive={modalActive}
                          closeModal={closeModal}
                          contentOverlay={
                            (item.page === 'notifications' && (state.notifications.count !== ''))
                            && (state.notifications.count === 0 ? null : <p className={Styles.notifications}>{state.notifications.count}</p>)
                          }
                          slug={slug}
                          state={state}
                        />
                      )
                    })
                  }
              </ul>

              { state.container.menuMore && state.user.info !== null
                  && (
                  <div className={Styles.moreMenu(state.login === 'true')}>
                    <ul className={Helpers.mergeCss(Base.ulUnstyled, Styles.menuExtra)}>
                      <li>
                        {state.user.info !== null && state.user.info.attributes.member
                          ? (
                            <Link
                              to="/revenue"
                              className={
                          page === 'revenue'
                            ? Helpers.mergeCss(Styles.active, Styles.text, Base.positionRelative)
                            : Helpers.mergeCss(Styles.text, Base.positionRelative)
                        }
                            >
                              <div>
                                <i className="las la-clipboard-check" />
                              </div>
                              <span className={Helpers.mergeCss(Styles.text)}>
                                Revenue
                              </span>
                            </Link>
                          )
                          : (
                            <div
                              onClick={() => {
                                window.location.href = process.env.DEFAULT_JOIN_DOMAIN
                              }}
                              className={
                          page === 'revenue'
                            ? Helpers.mergeCss(Styles.active, Styles.text, Base.positionRelative)
                            : Helpers.mergeCss(Styles.text, Base.positionRelative)
                        }
                            >
                              <div>
                                <i className="las la-clipboard-check" />
                              </div>
                              <span className={Helpers.mergeCss(Styles.text)}>
                                Revenue
                              </span>
                            </div>
                          )
                        }
                      </li>
                      <li>
                        {state.user.info !== null && state.user.info.attributes.member
                          ? (
                            <Link
                              to="/analytics"
                              className={
                            page === 'analytics'
                              ? Helpers.mergeCss(Styles.active, Base.dFlex, Styles.text, Base.positionRelative)
                              : Helpers.mergeCss(Styles.text, Base.dFlex, Base.positionRelative)
                          }
                            >
                              <div>
                                <i className="las la-chart-line" />
                              </div>
                              <span className={Helpers.mergeCss(Styles.text)}>
                                Analytics
                              </span>
                            </Link>
                          )
                          : (
                            <div
                              onClick={() => {
                                window.location.href = process.env.DEFAULT_JOIN_DOMAIN
                              }}
                              className={
                          page === 'analytics'
                            ? Helpers.mergeCss(Styles.active, Styles.text, Base.positionRelative)
                            : Helpers.mergeCss(Styles.text, Base.positionRelative)
                        }
                            >
                              <div>
                                <i className="las la-chart-line" />
                              </div>
                              <span className={Helpers.mergeCss(Styles.text)}>
                                Analytics
                              </span>
                            </div>
                          )
                        }
                      </li>
                      <li>
                        <Link
                          to="/settings"
                          className={
                          page === 'settings'
                            ? Helpers.mergeCss(Styles.active, Base.dFlex, Styles.text, Base.positionRelative)
                            : Helpers.mergeCss(Styles.text, Base.dFlex, Base.positionRelative)
                        }
                        >
                          <div>
                            <i className="las la-cog" />
                          </div>
                          <span className={Helpers.mergeCss(Styles.text)}>
                            Settings
                          </span>
                        </Link>
                      </li>
                      <li>
                        <a onClick={handleLogout} className={Base.dFlex}>
                          <div>
                            <i className="las la-door-open" />
                          </div>
                          <span className={Helpers.mergeCss(Styles.text)}>
                            Logout
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  )}

              { state.login !== 'true'
                  && (
                  <div className={Helpers.mergeCss(Styles.moreMenu(state.login === 'true'), Styles.indication(state.container.menuMore))}>
                    <ul className={Helpers.mergeCss(Base.ulUnstyled, Styles.menuExtra)}>
                      <li>
                        <div
                          onClick={() => {
                            window.location.href = '/users/login'
                          }}
                          className={Base.dFlex}
                        >
                          <div>
                            <i className="las la-pen-nib" />
                          </div>
                          <span className={Helpers.mergeCss(Styles.text)}>
                            Become designer
                          </span>
                        </div>
                        <span className="indication">
                          <i />
                        </span>
                      </li>
                      <li>
                        <div
                          onClick={() => {
                            window.location.href = '/users/register'
                          }}
                          className={Base.dFlex}
                        >
                          <div>
                            <i className="las la-id-card" />
                          </div>
                          <span className={Helpers.mergeCss(Styles.text)}>
                            Register
                          </span>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => {
                            window.location.href = '/users/login'
                          }}
                          className={Base.dFlex}
                        >
                          <div>
                            <i className="las la-key" />
                          </div>
                          <span className={Helpers.mergeCss(Styles.text)}>
                            Login
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  )}

            </div>
            <div
              style={{
                paddingLeft: '30px', paddingTop: '0px', paddingRight: '15px', paddingBottom: '0px',
              }}
              className={Styles.ads}
            >
              <span />
              {
                process.env.ENV_APP === 'development' &&
                <Fragment>
                  <img src={ads} />
                  <span>
                    Students and Teachers, save up to 60% on Adobe Creative Cloud.
                    ADS VIA CARBON
                  </span>
                </Fragment>
              }
              <div style={{ paddingLeft: '0px', marginTop: '0px', paddingRight: '0px' }} className={Styles.footer}>
                {/* <ul className={Base.ulInline}> */}
                  {/* <li><a className={Base.text} onClick={e => e.preventDefault()}>Jobs</a></li>
                  <li><a className={Base.text} onClick={e => e.preventDefault()}>Terms</a></li>
                  <li><a className={Base.text} onClick={e => e.preventDefault()}>Cookies</a></li>
                  <li><a className={Base.text} onClick={e => e.preventDefault()}>Privacy policy</a></li>
                  <li><a className={Base.text} onClick={e => e.preventDefault()}>Ads info</a></li>
                  <li><a className={Base.text} onClick={e => e.preventDefault()}>More</a></li> */}
                {/* </ul> */}
                <span className={Base.text}>
                  © 2020 Marvlous
                </span>
              </div>
            </div>
          </div>

          <div
            style={{ borderLeft: '1px solid #eee', borderRight: '1px solid #eee' }}
            className={Helpers.mergeCss(Base.col, widthContainer, Styles.w10, Base.h100, Base.padding0)}
          >
            {
                (headerLeft || headerRight)
                && (
                <div className={Styles.feed(arrow)}>
                  <div className={Helpers.mergeCss(Base.dBlock, Base.w100)}>
                    <div className={Helpers.mergeCss(Helpers.mergeCss(Base.row, centerPosition, Styles.rowFeedHeader))}>
                      <div className={Helpers.mergeCss(Base.col, Styles.w6, Styles.desktopHeader, Base.w6)}>
                        <SearchBar
                          onFocus={handleSetState('search', 'keywordFocus', !search.keywordFocus)}
                          onBlur={handleSetState('search', 'keywordFocus', !search.keywordFocus)}
                          onChange={handleSetStateInput('search', 'keyword')}
                          onKeyUp={handleSetStateInput('search', 'keywordTyping')}
                          handleSetMergeState={handleSetMergeState}
                          listExist={listExist}
                          page={page}
                          tiny
                          // the search state
                          slug={slug}
                          search={search}
                        />
                      </div>
                      {arrow
                        ? (
                          <Fragment>
                            <div className={Helpers.mergeCss(Base.col, Styles.w6, Styles.mobileHeader, Base.w12)}>
                              {headerLeft}
                            </div>
                            {/* <div className={Helpers.mergeCss(Base.col, Styles.w6, Styles.mobileHeader, Base.w2)} /> */}
                          </Fragment>
                        )
                        : (
                          <Fragment>
                            <div className={Helpers.mergeCss(Base.col, Styles.mobileHeader, Base.w4)}>
                              <div onClick={handleSetState('container', 'menuLeft', !state.container.menuLeft)} className={Helpers.mergeCss(Styles.hamburgerMenu, Base.dInlineBlock)}>
                                <span/>
                                <span/>
                                <span/>
                              </div>
                            </div>
                            <div className={Helpers.mergeCss(Base.col, Styles.mobileHeader, Base.textCenter, Base.w4)}>
                              {headerLeft}
                            </div>
                            <div className={Helpers.mergeCss(Base.col, Styles.mobileHeader, Base.textRight, Base.w4)}>
                              <img src={marvlous} className={Styles.imgLogoMobile} alt="" />
                            </div>
                          </Fragment>
                        )
                      }
                      <div className={Helpers.mergeCss(Base.col, Base.w6, Styles.desktopHeader, Base.textRight)}>
                        <div className={Helpers.mergeCss(Base.dInlineFlex, Base.alignItemsCenter, Base.h100)}>
                          {state.user.info === null
                            ? (
                              <div
                                onClick={() => {
                                  window.location.href = process.env.DEFAULT_JOIN_DOMAIN
                                }}
                                style={{ marginRight: '20px', textDecoration: 'none' }}
                                className={Styles.linkUpload}
                              >
                                <i style={{ fontSize: '20px', marginRight: '8px' }} className="las la-cloud-upload-alt" />
                                Upload
                              </div>
                            )
                            : (state.user.info.attributes.member
                              ? (
                                <Link style={{ marginRight: '20px', textDecoration: 'none' }} className={Styles.linkUpload} to={Routes.DESIGNUPLOAD}>
                                  <i style={{ fontSize: '20px', marginRight: '8px' }} className="las la-cloud-upload-alt" />
                                  Upload
                                </Link>
                              )
                              : (
                                <div
                                  onClick={() => {
                                    window.location.href = process.env.DEFAULT_JOIN_DOMAIN
                                  }}
                                  style={{ marginRight: '20px', cursor: 'pointer' }}
                                  className={Styles.linkUpload}
                                >
                                  <i style={{ fontSize: '20px', marginRight: '8px' }} className="las la-cloud-upload-alt" />
                                  Upload
                                </div>
                              ))
                            }
                          {state.user.info !== null
                            ? (
                              <div style={{ cursor: 'pointer', minWidth: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center'}} 
                                className={Styles.dropdown} onClick={handleSetState('feed', 'topRightMenuOpen', !state.feed.topRightMenuOpen)}>
                                <img
                                  className={Styles.activeProfile(state.user.info.attributes.username === slug)}
                                  src={(state.user.info.attributes.avatar.url === null || state.user.info.attributes.avatar.url === '') ? placeholderImage : state.user.info.attributes.avatar.url}
                                />
                                <span className={Styles.activeText(state.user.info.attributes.username === slug)}>
                                  {state.user.info.attributes.name}
                                </span>
                                {
                                  state.feed.topRightMenuOpen
                                  && (
                                  <div className={Styles.dropdownList}>
                                    <ul className={Base.ulUnstyled}>
                                      {
                                        state.user.info.attributes.type_user === 'user' && state.user.info.attributes.team_created === null &&
                                        <li>
                                          <Link className={Styles.activeText(page === 'team' && state.user.info.attributes.username === slug)}>
                                            <i className="las la-user-friends"></i>
                                            <span>
                                              Create team
                                            </span>
                                          </Link>
                                        </li>
                                      }
                                      {
                                        state.user.info.attributes.type_user === 'user' && state.user.info.attributes.team_created !== null &&
                                        <Fragment>
                                          {Object.keys(state.user.info.attributes.team_created).length === 0 && state.user.info.attributes.team_created.constructor === Object ?
                                          <li>
                                            <div onClick={() => {
                                              window.location.href = '/setup/team'
                                            }}
                                              className={Styles.activeText(page === 'team' && state.user.info.attributes.username === slug)}>
                                              <i className="las la-user-friends"></i>
                                              <span>
                                                Create team
                                              </span>
                                            </div>
                                          </li>
                                          :
                                          <li>
                                            <Link className={Styles.activeText(page === 'team' && state.user.info.attributes.username === slug)}>
                                              <img style={{width: '20px', height: '20px', marginRight: '8px', borderRadius: '50%'}} 
                                                src={state.user.info.attributes.team_created.url} alt=""/>
                                              <span>
                                                Your team
                                              </span>
                                            </Link>
                                          </li>}
                                        </Fragment>
                                      }
                                      <li>
                                        <Link className={Styles.activeText(page === 'portfolio' && state.user.info.attributes.username === slug)} to={`/${state.user.info.attributes.username}`}>
                                          <i className="la la-columns" />
                                          <span>
                                            Portfolio
                                          </span>
                                        </Link>
                                      </li>
                                      <li>
                                        <Link className={Styles.activeText(page === 'settings')} to="/settings">
                                          <i className="las la-cog" />
                                          <span>
                                            Settings
                                          </span>
                                        </Link>
                                      </li>
                                      <li>
                                        <div onClick={handleLogout}>
                                          <i className="las la-door-open" />
                                          <span>
                                            Logout
                                          </span>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                  )
                                }
                              </div>
                            )
                            : state.feed.loadingFeed && state.login
                              ? <Loading tiny />
                              : (
                                <div
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    window.location.href = '/users/login'
                                  }}
                                >
                                  <img className={Styles.defaultProfile} src={placeholderImage} />
                                  <span>
                                    Login
                                  </span>
                                </div>
                              )
                            }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                )
              }
            {content}
            <div className={Styles.uploadMobile}>
              {state.user.info === null
                ? (
                  <div
                    onClick={() => {
                      window.location.href = process.env.DEFAULT_JOIN_DOMAIN
                    }}
                    style={{ textDecoration: 'none' }}
                    className={Styles.linkUploadMobile}
                  >
                    <i className="las la-cloud-upload-alt" />
                  </div>
                )
                : (state.user.info.attributes.member
                  ? (
                    <Link style={{ textDecoration: 'none' }} className={Styles.linkUploadMobile} to={Routes.DESIGNUPLOAD}>
                      <i className="las la-cloud-upload-alt" />
                    </Link>
                  )
                  : (
                    <div
                      onClick={() => {
                        window.location.href = process.env.DEFAULT_JOIN_DOMAIN
                      }}
                      style={{ cursor: 'pointer' }}
                      className={Styles.linkUploadMobile}
                    >
                      <i className="las la-cloud-upload-alt" />
                    </div>
                  ))
                }
            </div>
            <div className={Styles.menuMobile}>
              <ul className={Helpers.mergeCss(Base.ulUnstyled, Styles.menuList)}>
                {
                  Data.menuMobile.map((item) => {
                    const active = page === 'portfolio'
                      ? state.user.info !== null && state.user.info.attributes.username === slug ? page === item.page : false
                      : page === item.page

                    const modalActive = (active && page === 'notifications' && state[page].slug !== '' || active && page === 'feed' && state[page].slug !== '' || active && page === 'saved' && state[page].slug !== '' || active && page === 'subscriptions' && state[page].slug !== '' || active && page === 'portfolio' && state[page].slug !== '')

                    return (
                      <ListitemMobile
                        key={item.id}
                        active={active}
                        page={item.page}
                        user={state.user.info}
                        login={state.login}
                        icon={item.icon}
                        label={item.label}
                        match={match}
                        handleSetState={handleSetState}
                        typeState={typeState}
                        modalActive={modalActive}
                        closeModal={closeModal}
                        contentOverlay={
                          (item.page === 'notifications' && (state.notifications.count !== ''))
                          && (state.notifications.count === 0 ? null : <p className={Styles.notifications}>{state.notifications.count}</p>)
                        }
                        slug={slug}
                        state={state}
                      />
                    )
                  })
                  }
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.leftMenu(state.container.menuLeft)}>
        <div className={Styles.headerInfo}>
          Account
          <div onClick={handleSetState('container', 'menuLeft', !state.container.menuLeft)} className={Styles.closeButton}>
            <span></span>
            <span></span>
          </div>
        </div>
        <ul className={Helpers.mergeCss(Base.ulUnstyled, Styles.menuListMobile, Styles.menuList)}>
          {
            Data.menuLeftMobile.map((item) => {
              const active = page === 'portfolio'
                ? state.user.info !== null && state.user.info.attributes.username === slug ? page === item.page : false
                : page === item.page

              const modalActive = (active && page === 'notifications' && state[page].slug !== '' || active && page === 'feed' && state[page].slug !== '' || active && page === 'saved' && state[page].slug !== '' || active && page === 'subscriptions' && state[page].slug !== '' || active && page === 'portfolio' && state[page].slug !== '')

              return (
                <Listitem
                  key={item.id}
                  active={active}
                  page={item.page}
                  user={state.user.info}
                  login={state.login}
                  icon={item.icon}
                  label={item.label}
                  match={match}
                  handleSetState={handleSetState}
                  typeState={typeState}
                  modalActive={modalActive}
                  closeModal={closeModal}
                  menuMobile={true}
                  contentOverlay={
                    (item.page === 'notifications' && (state.notifications.count !== ''))
                    && (state.notifications.count === 0 ? null : <p className={Styles.notifications}>{state.notifications.count}</p>)
                  }
                  slug={slug}
                  state={state}
                />
              )
            })
          }
        </ul>
        <div style={{ paddingLeft: '0.75rem', marginTop: '5px', paddingRight: '0px' }} className={Styles.footer}>
          <ul className={Base.ulInline}>
            {/* <li><a className={Base.text} onClick={e => e.preventDefault()}>Jobs</a></li> */}
            <li><a className={Base.text} onClick={e => e.preventDefault()}>Terms</a></li>
            <li><a className={Base.text} onClick={e => e.preventDefault()}>Cookies</a></li>
            <li><a className={Base.text} onClick={e => e.preventDefault()}>Privacy policy</a></li>
            <li><a className={Base.text} onClick={e => e.preventDefault()}>Ads info</a></li>
            <li><a className={Base.text} onClick={e => e.preventDefault()}>More</a></li>
          </ul>
          <span style={{fontWeight: 'bold'}} className={Base.text}>
            © 2020 Marvlous
          </span>
        </div>
      </div>
      <div onClick={handleSetState('container', 'menuLeft', !state.container.menuLeft)} className={Styles.overlayMenu(state.container.menuLeft)}/>
    </Fragment>
  )
}

export default Container
