import React, { Component, Fragment } from 'react'
import { Base, Helpers, I18n } from 'utils'
import { Loading, Card, CardPreview } from 'components'
import moment from 'moment'
import Slider from 'react-slick'
import Styles from './Styles'
import { Link } from 'react-router-dom'
import placeholderImage from 'images/user-placeholder.jpg'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  )
}

class TabContent extends Component {
  componentDidMount() {
    const { props: { handleScroll } } = this
    document.getElementById('marvlous').addEventListener('scroll', handleScroll, true)
  }

  componentWillUnmount() {
    const { props: { handleScroll } } = this
    document.getElementById('marvlous').removeEventListener('scroll', handleScroll, true)
  }

  render() {
    const {
      props: {
        loading,
        resultExist,
        data,
        user,
        typeState,
        handleSetMergeState,
        loadingPortfolio,
        endOfPortfolio,
        activeContent,
        submitSubscribeList,
      },
    } = this

    return (
      <Fragment>
        {loading
          ? (
            <div className={Helpers.mergeCss(Base.col, Styles.paddingDesktop, Base.w12)}>
              <div className={Helpers.mergeCss(Base.dFlex, Base.justifyContentCenter)}>
                <Loading />
              </div>
            </div>
          )
          : resultExist
            ? 
            (
              (activeContent === 'Designs' || activeContent === 'Images' || activeContent === 'Videos') ? data.map((item) => {
              const humanizeDate = moment(item.attributes.created_at.split('T').join(' '), 'YYYY-MM-DD HH:mm:ss').fromNow()
              const state = {
                modalView: true,
                slugView: `/designs/${item.attributes.slug}`,
                slug: item.attributes.slug,
                design: item,
                previousSlug: `/${user.info.attributes.username}`,
              }
              return (
                <Card
                  key={item.id}
                  item={item}
                  noload
                  typeState={typeState}
                  handleSetState={handleSetMergeState(typeState, state)}
                  humanizeDate={humanizeDate}
                />
              )
            }) :
            <Fragment>
              {data.map((any) => {
                const settings = {
                  dots: false,
                  infinite: false,
                  speed: 500,
                  slidesToShow: 2.75,
                  slidesToScroll: 1,
                  arrow: true,
                  nextArrow: <SampleNextArrow />,
                  prevArrow: <SamplePrevArrow />,
                  responsive: [
                    {
                      breakpoint: 1024,
                      settings: {
                        slidesToShow: 2.5,
                        slidesToScroll: 1,
                      }
                    },
                    {
                      breakpoint: 1000,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                      }
                    },
                    {
                      breakpoint: 767,
                      settings: {
                        slidesToShow: 2.25,
                        slidesToScroll: 1
                      }
                    }
                  ]
                }

                return (
                  <div
                    key={any.attributes.id}
                    className={Helpers.mergeCss(Styles.subscriptionsWrapperItem, Base.col, Base.w12)}
                  >
                    <div className={Helpers.mergeCss(Base.row, Base.alignItemsCenter)}>
                      <div className={Helpers.mergeCss(Base.col, Styles.w4, Base.w4)}>
                        <div className={Helpers.mergeCss(Base.dFlex, Styles.infoDescription)}>
                          <Link style={{ height: '65px' }} to={`/${any.attributes.username}`}>
                            <img className={Styles.imgSubscriptions} src={(any.attributes.avatar.url === null || any.attributes.avatar.url === '') ? placeholderImage : any.attributes.avatar.url} alt="" />
                          </Link>
                          <div className={Helpers.mergeCss(Base.dBlock, Styles.descSubscriptions, Base.paddingLeft3)}>
                            <Link to={`/${any.attributes.username}`}>
                              <h5 className={Helpers.mergeCss(Base.dInlineFlex, Base.alignItemsCenter)}>
                                {any.attributes.name}
                              </h5>
                              <span className={Helpers.mergeCss(Styles.mobile, Base.text)} 
                                style={{ fontSize: '14px' }}>
                                  {any.attributes.username}
                                {any.attributes.verified && (
                                  <i
                                    style={{
                                      marginLeft: '4px', fontSize: '12px', position: 'relative', top: '-0.1px', color: '#1181eb',
                                    }}
                                    className="fa fa-check-circle"
                                  />
                                )}
                              </span>
                              {any.attributes.type_user === 'team' && (<span className={Styles.team}>Team</span>)}
                              
                              <span className={Helpers.mergeCss(Styles.desktop, Base.text)} 
                                style={{ fontSize: '14px' }}>{any.attributes.username}</span>
                            </Link>
                            <span
                              className={Helpers.mergeCss(Styles.subscriberCountMobile, Base.text, Base.textCenter)}
                              style={{ fontSize: '14px' }}>
                              <b style={{fontWeight: 500, marginRight: '.25rem'}}>
                                {any.attributes.subscribers - 1}
                              </b>
                              subscribers
                            </span>
                            <p
                              className={Helpers.mergeCss(Base.text, Styles.bioSubscriptions, Base.marginTop)}
                              dangerouslySetInnerHTML={{ __html: any.attributes.bio }}
                            />
                            <div className={Helpers.mergeCss(Base.dFlex, Styles.buttonWrapper, Base.alignItemsCenter)}>
                              <button
                                onClick={submitSubscribeList(any.attributes.subscribed, any.attributes.id)}
                                className={Helpers.mergeCss(Base.btnMarvlous, Styles.btnMarvlous(any.attributes.subscribed))}
                              >
                                {any.attributes.subscribed ? 'Subscribed' : 'Subscribe'}
                              </button>
                              <span
                                className={Helpers.mergeCss(Base.marginLeft3, Base.text, Styles.subscriberCount, Base.textCenter)}
                                style={{ fontSize: '14px' }}
                              >
                                <b className={Base.dBlock}>
                                  {any.attributes.subscribers - 1}
                                </b>
                                Subscribers
                              </span>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className={Helpers.mergeCss(Base.col, Styles.w8, Base.w8)}>
                        <Slider {...settings}>
                          {any.attributes.designs.map((item, index) => {
                            const newState = {
                              modalView: true,
                              slugView: `/designs/${item.attributes.slug}`,
                              slug: item.attributes.slug,
                              design: item,
                              previousSlug: `/${user.info.attributes.username}`,
                            }
                            return (
                              <div
                                key={item.attributes.images[0].url}
                                style={{ cursor: 'pointer' }}
                                onClick={handleSetMergeState(typeState, newState)}
                                className={Styles.slick(false)}
                              >
                                <CardPreview
                                  images={item.attributes.images}
                                  item={item}
                                  gif={item.attributes.cover.url !== null && item.attributes.cover.url.includes('gif')}
                                  video={item.attributes.cover.url !== null && item.attributes.cover.url.includes('mp4')}
                                  coverData={item.attributes.cover.url}
                                />
                              </div>
                            )
                          })}
                        </Slider>
                      </div>
                    </div>
                  </div>
                )
              })}
            </Fragment>
            )
            : (
              <b className={Styles.noResult}>
                There is no content yet
              </b>
            )
        }
        {loadingPortfolio
          && (
          <div className={Helpers.mergeCss(Base.col, Styles.paddingDesktop, Base.marginTop2, Base.marginBottom4, Base.w12)}>
            <div className={Helpers.mergeCss(Base.dFlex, Base.justifyContentCenter)}>
              <Loading />
            </div>
          </div>
          )
        }
        {
            endOfPortfolio
            && (
            <div className={Helpers.mergeCss(Base.col, Base.marginTop2, Base.marginBottom4, Base.w12)}>
              <div className={Helpers.mergeCss(Base.dFlex, Base.justifyContentCenter)}>
                <b>You reach at the end of the page</b>
              </div>
            </div>
            )
          }
      </Fragment>
    )
  }
}

export default TabContent
