import { css } from 'emotion'

const navbar = css`
  height: auto;
  padding-bottom: 0px;
  padding-top: 0px;
  border-bottom: 1px solid #eee;
  position:fixed;
  width: 100%;
  top:0px;
  background: #fff;
  z-index: 111;
  min-height: 60px;
  @media only screen and (min-width:0px) and (max-width:767px){
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
`

const container = css`
  @media only screen and (min-width:0px) and (max-width:767px){
  }
`

const navbarBrand = css`
  padding-top: 9.5px;
  padding-bottom: 9.5px;
  &:hover{
    text-decoration:none;
  }
  span{
    font-weight: bold;
    color: #1181eb;
    margin-left: 3px;
  }
  img{
    width:40px;
    object-fit: cover;
    object-position: center;
    position: relative;
  }
`

const hamburgerMenu = css`
  @media only screen and (min-width:786px){
    display: none;
  }
  cursor: pointer;
  position: absolute;
  right: 20px;
  span{
    width: 25px;
    background: #1181eb;
    display: flex;
    height: 2px;
    border-radius: 1px;
    &:nth-child(2){
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }
`

const navbarCollapse = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    display: none !important;
  }
`

const navbarNav = css`
  flex-direction:row;
  &:first-of-type{
    margin-right:auto;
  }
  &:last-of-type{
    margin-left:auto;
  }
  li{
    display:inline-flex;
    margin-right: 15px;
    &:last-of-type{
      margin-right:0px;
    }
    a{
      color: #212529 !important;
    }
  }
`

function menu(open = false){
  const openStyle = open ? `display: block;` 
  : 
  `display: none;`
  return css`
    @media only screen and (min-width:786px){
      display: none;
    }
    ${openStyle}
    padding-top: 15px;
    border-top: 1px solid #eee;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    ul{
      &:first-of-type{
        li{
          &:first-of-type{
            padding-top: 0px;
          }
          padding-bottom: 15px;
          a{
            color: #212529 !important;
            &:hover{
              color: #212529 !important;
            }
          }
        }
      }
      &:last-of-type{
        li{
          margin-right: 15px;
          a{
            color: #212529 !important;
            &:hover{
              color: #212529 !important;
            }
          }
        }
      }
    }
  `
}

function navbarLink(active){
  const activeStyle = active ? `
    color: #1181eb !important;
    font-weight: bold !important;
  ` 
  : 
  ``
  return css`
    ${activeStyle}
    cursor: pointer;
  `
}

export default{
  navbar,
  container,
  navbarBrand,
  hamburgerMenu,
  navbarCollapse,
  navbarNav,
  menu,
  navbarLink
}