import { Helpers, Base } from 'utils'

function setDataToBrowser(user, slug, designId) {
  let counter = false
  if (user.info !== null) {
    let theData
    if (localStorage.getItem('marvlousUserData') === null) {
      const objectToSend = {
        userId: user.info.attributes.id.toString(),
        data: [{ id: designId, slug }],
      }
      counter = true
      theData = [objectToSend]
    } else if (JSON.parse(localStorage.getItem('marvlousUserData'))
      .filter(item => item.userId === user.info.attributes.id.toString()).length > 0) {
      const userData = JSON.parse(localStorage.getItem('marvlousUserData'))
      const objectToSend = {
        id: designId,
        slug,
      }

      const theUserData = userData.find(any => any.userId === user.info.attributes.id.toString())
      let newArrData
      if (!(theUserData.data.filter(any => any.id === designId).length > 0)) {
        newArrData = theUserData.data.concat(objectToSend)
        counter = true
      } else {
        newArrData = theUserData.data
      }

      theData = userData.map((item) => {
        if (item.userId === user.info.attributes.id.toString()) {
          return { ...item, data: newArrData }
        }
        return { ...item }
      })
    } else {
      const objectToSend = {
        userId: user.info.attributes.id.toString(),
        data: [{ id: designId, slug }],
      }
      counter = true
      theData = JSON.parse(localStorage.getItem('marvlousUserData')).concat(objectToSend)
    }

    localStorage.setItem('marvlousUserData', JSON.stringify(theData))
  } else {
    let theData
    if (localStorage.getItem('marvlousData') === null) {
      const objectToSend = {
        id: designId,
        slug,
      }

      counter = true
      theData = [objectToSend]
    } else if (JSON.parse(localStorage.getItem('marvlousData')).length > 0) {
      const exist = JSON.parse(localStorage.getItem('marvlousData')).filter(any => any.id === designId).length > 0
      theData = JSON.parse(localStorage.getItem('marvlousData'))
      if (!exist) {
        const objectToSend = {
          id: designId,
          slug,
        }
        counter = true
        theData = JSON.parse(localStorage.getItem('marvlousData')).concat(objectToSend)
      }
    }

    localStorage.setItem('marvlousData', JSON.stringify(theData))
  }

  return counter
}

function sortByAlphabet(arr) {
  return arr.sort((a, b) => {
    const first = a.tag.name
    const second = b.tag.name
    return (first < second) ? -1 : (first > second) ? 1 : 0
  }).sort((a, b) => b.count - a.count)
}

function mergeState(originState, ...incomingState) {
  return incomingState.map(data => ({ ...originState, ...data }))[0]
}

function createStateObj(currState, subtype, value) {
  const newState = currState
  newState[subtype] = value

  return newState
}

export default {
  setDataToBrowser,
  sortByAlphabet,
  mergeState,
  createStateObj,
}
