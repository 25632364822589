import React, { Component, Fragment } from 'react'
import { Base, Helpers, I18n } from 'utils'
import { Link } from 'react-router-dom'
import {
  Input, InputTag, Checkbox, Hint, Card, Loading, EndPage, DesignTrends,
} from 'components'
import moment from 'moment'
import Dropzone from 'react-dropzone'

import marvlous from 'images/marvlous.png'
import upload from 'images/upload.svg'
import Styles from './Styles'

class Feed extends Component {
  componentDidMount() {
    const { props: { typeState, handleScroll } } = this
    document.getElementById('marvlous').addEventListener('scroll', handleScroll(typeState), true)
  }

  componentWillUnmount() {
    this.props.resetState()
    const { props: { typeState, handleScroll } } = this
    document.getElementById('marvlous').removeEventListener('scroll', handleScroll(typeState), true)
  }

  render() {
    const {
      props: {
        handleSetMergeState,
        state,
        typeState,
        handleScrollTrends,
        handleSetState,
      },
    } = this

    return (
      <div id="marvlous" className={Helpers.mergeCss(Base.dBlock, Styles.feedWrapper)}>
        <div className={Helpers.mergeCss(Base.row, Styles.rowFeed)}>
          {
            state.feed.loadingFeed
              ? (
                <div className={Helpers.mergeCss(Base.col, Base.marginTop2, Base.w12)}>
                  <div className={Helpers.mergeCss(Base.dFlex, Base.justifyContentCenter)}>
                    <Loading />
                  </div>
                </div>
              )
              : (
                <Fragment>
                  <div className={Helpers.mergeCss(Base.dBlock, Base.w100)}>
                    <div className={Helpers.mergeCss(Base.dBlock, Base.w100)} 
                      style={{ paddingLeft: '15px', 
                        paddingRight: '15px', 
                        paddingTop: '25px', 
                        paddingBottom: '15px'
                      }}>
                      <DesignTrends
                        handleScrollTrends={handleScrollTrends}
                        loading={state.feed.loadingFeed}
                        tags={state.feed.tags}
                        state={state}
                      />
                    </div>
                    {/* <div className={Styles.dropdown}>
                      Subscriptions
                      <i class="las la-angle-down"></i>
                    </div> */}
                  </div>
                  {state.feed.feedData.map((item) => {
                    const humanizeDate = moment(item.attributes.created_at.split('T').join(' '), 'YYYY-MM-DD HH:mm:ss').fromNow()
                    const state = {
                      modalView: true,
                      slugView: `/designs/${item.attributes.slug}`,
                      slug: item.attributes.slug,
                      design: item,
                      previousSlug: '/',
                    }
                    return (
                      <Card
                        key={item.id}
                        item={item}
                        noload
                        handleSetState={handleSetMergeState(typeState, state)}
                        humanizeDate={humanizeDate}
                      />
                    )
                  })}
                  <div className={Styles.floatMenu(state.feed.floatMenu)}>
                    <p onClick={handleSetState(typeState, 'floatMenu', !state.feed.floatMenu)}>
                      <i className="las la-globe"></i>
                    </p>
                    {
                      state.feed.floatMenu &&
                      (<span>
                        <span>Subscriptions</span>
                      </span>)
                    }
                  </div>
                </Fragment>
              )
          }
          <EndPage
            loading={state.feed.loadingData}
            endPage={state.feed.endOfData}
          />
        </div>
      </div>
    )
  }
}

export default Feed
