import base from './api.base'

function index(page) {
  return base.get(`subscriptions/${page}`)
}

function subscribe(data) {
  return base.post('subscriptions/subscribe', data)
}

function unsubscribe(data) {
  return base.post('subscriptions/unsubscribe', data)
}

const ApiSubscriptions = {
  index,
  subscribe,
  unsubscribe,
}

export default ApiSubscriptions
