import base from './api.base'

function detail() {
  return base.get('revenues/detail')
}

function list(page) {
  return base.get(`revenues/list/${page}`)
}

const ApiRevenues = {
  detail,
  list,
}

export default ApiRevenues
