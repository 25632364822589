import { Helpers, Validation } from 'utils'


function commentState(data) {
  const isEnterExist = data.comment.split('').filter(item => (item === '\n' || item === '\r')).length > 0 ? Helpers.addLineBreak(data.comment) : data.comment
  const getUrlandMentionHashtag = isEnterExist.split(' ')
    .filter(item => (Validation.webPattern(item) || item.includes('@') || item.includes('#')))
  const mapUrlandMentionHashtag = isEnterExist.split(' ')
    .map((item) => {
      if (Validation.webPattern(item)) {
        return `<a class=web target=_blank href=${item}>${item}</a>`
      } if (item.includes('@')) {
        if (Validation.mentionPattern(item)) {
          return `<a class=mention href=${window.location.origin}/${item.substr(1)}>${item}</a>`
        }
        return `<a class=mention href=${window.location.origin}/${item.substr(1).slice(0, -1)}>${item.slice(0, -1)}</a>${item.slice(item.length - 1)}`
      } if (item.includes('#')) {
        if (Validation.hashtagPattern(item)) {
          return `<a class=hashtag href=${window.location.origin}/trends/${item.substr(1)}>${item}</a>`
        }
        return `<a class=hashtag href=${window.location.origin}/trends/${item.substr(1).slice(0, -1)}>${item.slice(0, -1)}</a>${item.slice(item.length - 1)}`
      }
      return item
    })

  const isUrlMentionHashtagExist = getUrlandMentionHashtag.length > 0

  return {
    id: '',
    type: 'comment',
    attributes: {
      content: isUrlMentionHashtagExist ? mapUrlandMentionHashtag.join(' ') : isEnterExist,
      user_id: data.userId,
      design_id: data.designId,
      posted_at: data.postedAt,
      created_at: '',
      updated_at: '',
      uuid: data.uuid,
      user: {
        id: data.userId,
        created_at: '',
        updated_at: '',
        name: data.name,
        username: data.username,
        avatar: {
          url: data.url,
        },
        bio: '',
      },
    },
  }
}

const defaultState = {
  feed: {
    modalView: false,
    slug: '',
    design: null,
    viewComment: '',
    previousSlug: '',
  },
  saved: {
    modalView: false,
    slugView: '',
    slug: '',
    design: null,
    viewComment: '',
    previousSlug: '',
  },
  subscriptions: {
    modalView: false,
    slugView: '',
    slug: '',
    design: null,
    viewComment: '',
    previousSlug: '',
    yolo: 'fuck',
    anjing: true,
  },
  portfolio: {
    modalView: false,
    slug: '',
    viewSlug: '',
    design: null,
    viewComment: '',
    activeTab: null,
    previousSlug: '',
  },
  search: {
    query: '',
    modalView: false,
    slug: '',
    viewSlug: '',
    design: null,
    viewComment: '',
    keyword: '',
    keywordFocus: false,
    keywordTyping: '',
    resultKeyword: null,
    open: false,
    previousSlug: '',
  },
  notifications: {
    modalView: false,
    slug: '',
    viewSlug: '',
    design: null,
    viewComment: '',
    previousSlug: '',
  },
  activeProfile: {
    username: '',
  },
  settings: {
    modalView: false,
    slug: '',
    design: null,
    viewComment: '',
    previousSlug: '',
  },
  revenue: {
    modalView: false,
    slug: '',
    design: null,
    viewComment: '',
    previousSlug: '',
  },
  analytics: {
    modalView: false,
    slug: '',
    design: null,
    viewComment: '',
    previousSlug: '',
  },
  trends: {
    modalView: false,
    slug: '',
    design: null,
    viewComment: '',
    previousSlug: '',
  },
}

export default {
  commentState,
  defaultState,
}
