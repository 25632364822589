import React, { Component, Fragment } from 'react'
import {
  Helpers, Base, Routes, history, Validation,
} from 'utils'
import { Link } from 'react-router-dom'
import { SearchBar, Loading } from 'components'
import { ApiTags, ApiFinds } from 'api'
import moment from 'moment'
import Dropzone from 'react-dropzone'

import marvlous from 'images/marvlous.png'
import upload from 'images/upload.svg'
import functions from '../../functions'
import Styles from './Styles'

class Trends extends Component {
  state = {
    trends: {
      data: [],
      keyword: '',
      keywordTyping: '',
      keywordFocus: false,
      open: false,
      resultKeyword: null,
    },
  }

  async componentDidMount() {
    const dataTags = await ApiTags.popular()

    this.setState(currState => ({
      trends: functions.createStateObj(currState.trends, 'data', functions.sortByAlphabet(dataTags.data)),
    }))
  }

  handleSetStateInput = (type, subtype) => (e) => {
    const { value } = e.target
    if ((subtype === 'keywordTyping' || subtype === 'keyword') && e.key === 'Enter') {
      history.push({
        pathname: `/search/${this.state[type].keyword}`,
        search: '?tab=designs',
      })
    } else {
      this.setState(currState => ({
        [type]: functions.createStateObj(currState[type], subtype, value),
      }), () => {
        if (value.length > 1) {
          this.findTheKeyword(value)
        } else {
          this.handleSetState('trends', 'resultKeyword', null)()
        }
      })
    }
  }

  handleSetState = (type, subtype, value) => () => {
    this.setState(currState => ({
      [type]: functions.createStateObj(currState[type], subtype, value),
    }))
  }

  findTheKeyword = async (value) => {
    const data = await ApiFinds.find(value)
    const state = {
      resultKeyword: data.data,
      open: true,
    }

    this.setState(currState => ({
      trends: functions.mergeState(currState.trends, state),
    }))
  }

  render() {
    const {
      state: { trends }, handleSetStateInput, handleSetState, props: { page, handleSetMergeState },
    } = this
    const listExist = trends.resultKeyword !== null
    && (trends.resultKeyword.users.length > 0
     || trends.resultKeyword.tags.length > 0
     || trends.resultKeyword.designs.length > 0
     || trends.resultKeyword.colors.length > 0)

    return (
      <Fragment>
        <div className={Styles.trendsHeader}>
          <div className={Helpers.mergeCss(Base.dBlock, Base.w100)}>
            <div className={Helpers.mergeCss(Base.row, Base.justifyContentCenter, Styles.searchTop)}>
              <div className={Helpers.mergeCss(Base.col, Base.w12)}>
                <SearchBar
                  onFocus={handleSetState('trends', 'keywordFocus', !trends.keywordFocus)}
                  onBlur={handleSetState('trends', 'keywordFocus', !trends.keywordFocus)}
                  onChange={handleSetStateInput('trends', 'keyword')}
                  onKeyUp={handleSetStateInput('trends', 'keywordTyping')}
                  listExist={listExist}
                  handleSetMergeState={handleSetMergeState}
                  page={page}
                  // the search state
                  search={trends}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={Helpers.mergeCss(Base.dBlock, Styles.trendsWrapper)}>
          <div className={Helpers.mergeCss(Base.row, Styles.rowTrends)}>
            <span className={Helpers.mergeCss(Styles.designTrends, Base.w100)}>
              Design Trends
            </span>
            <div className={Helpers.mergeCss(Base.dBlock, Base.w100)}>
              {trends.data.length > 0
                ? (
                  <div className={Helpers.mergeCss(Base.row, Styles.rowTrendsItem)}>
                    {trends.data.map(any => (
                      <Link key={any.tag.id} to={`/search/${any.tag.name}?tab=trends`} className={Helpers.mergeCss(Base.col, Styles.w3, Base.w3, Styles.trendsItem)}>
                        <p>
                          #
                          {any.tag.name}
                        </p>
                        <span className={Base.text}>
                          {any.count}
                          {' '}
                          designs
                        </span>
                      </Link>
                    )).slice(0, 20)}
                  </div>
                )
                : ''
              }
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Trends
