import { css } from 'emotion'

const title = css`
  margin-bottom: 5px;
`

const defaultInput = css`
  margin-bottom: 15px;
`

export default{
  title,
  defaultInput,
}
