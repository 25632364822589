import { css } from 'emotion'

const newItem = css`
  position:relative;
  display: flex;
  align-items: center;
  padding: 0.75rem 2rem;
  padding-left: 0px;
  a, div{
    text-decoration: none;
    display:inline-flex;
    align-items:center;
    cursor: pointer;
    &:hover{
      i{
        color:#1181eb;
      }
      span{
        background: -webkit-linear-gradient(0deg,#1181eb,#0fb8ad);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    i{
      font-size: 20px;
      margin-right: 8px;
    }
  }
  img{
    width: 32px;
    height: 32px;
    border-radius: 50% !important;
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
    margin-right: 8px;
    border: 0px solid transparent;
  }
`

function item(menuMobile){
  const menuMobileStyle = menuMobile ? 
  `
    padding-left: 0.75rem;
  `
  :
  `
  @media (max-width: 1199px) {
    padding: 0.75rem 0rem;
    a, div{
      span{
        display: none;
      }
    }
  }
  `
  return css`
  position:relative;
  display: flex;
  align-items: center;
  padding: 0.75rem 2rem;
  padding-left: 0px;
  a, div{
    text-decoration: none;
    display:inline-flex;
    align-items:center;
    cursor: pointer;
    &:hover{
      i{
        color:#1181eb;
      }
      span{
        color: #1181eb;
      }
    }
    i{
      font-size: 20px;
      margin-right: 8px;
    }
  }
  img{
    width: 32px;
    height: 32px;
    border-radius: 50% !important;
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
    margin-right: 8px;
    border: 0px solid transparent;
  }
  ${menuMobileStyle}
  `
}

const active = css`
  i{
    color:#1181eb;
  }
  span{
    color: #1181eb;
    font-weight: bold;
  }
  img{
    border: 2px solid #1181eb !important;
    border-radius: 2px;
  }
`

const text = css`
  color: #212529 !important;
`

export default{
  item,
  active,
  text,
}
