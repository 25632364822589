import { css } from 'emotion'

const rowTrends = css`
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 60px;
  @media only screen and (min-width:0px) and (max-width:767px){
    margin-bottom: 60px;
  }
`

const trendsWrapper = css`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
`

const designTrends = css`
  padding: 10px 20px;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  display: block;
  background: #f3f3f4;
  padding-left: 30px;
  padding-right: 30px;
`

const trendsItem = css`
  padding: 5px 30px;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  display: block;
  background: #f3f3f4;
  cursor: pointer;
  color: #292929;
  text-decoration:none;
  &:hover{
    color: #292929;
    text-decoration:none;
    background: #e7e7e9 !important;
  }
  p{
    font-weight: bold;
    margin: 0px;
  }
  span{
    font-weight: normal;
    font-size: 14px;
  }
  &:nth-child(odd){
    border-right: 1px solid #eee;
  }
`

const rowTrendsItem = css`
  margin: 0px;
`

const trendsHeader = css`
  height: 60px;
  align-items: center;
  display: flex;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  margin-left: 0px;
  margin-right: 0px;
  z-index: 1111;
  background: #fff;
  position: absolute;
  width: 100%;
  padding-left: 18px;
  padding-right: 18px;
`

function formControl(active = false) {
  return css``
}

const searchBar = css`
  position: relative;
`

const resultSearch = css`
  position: absolute;
  width: 100%;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  height: auto;
  top: 36px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  background: transparent;
  b{
    font-weight: bold;
  }
`

const resultSearchContent = css`
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  height: auto;
  width: 100%;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border: 1px solid rgba(17,129,235,0.4) !important;
  border-top: 0px !important;
  background: #fff;
`

const resultSearchSubtitle = css`
  padding: 6px 16px;
`

function search(focus) {
  let focusStyle = ''
  if (focus) {
    focusStyle = `box-shadow: 0 0 0 4px rgba(17, 129, 235, 0.1) !important;
     border: 1px solid rgba(17, 129, 235, 0.4) !important;
     background: #fff;
     border-radius: 2px;
     input{
       background: #fff !important;
     }
     span{
      background: #fff !important;
     }
    `
  }

  return css`
    border: 1px solid transparent !important;
    ${focusStyle}
  `
}

const resultUser = css`
  padding: 3px 16px;
  align-items: center;
  text-decoration: none;
  &:hover{
    text-decoration: none;
    background: rgba(17,129,235,0.2);
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 2px;
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
    margin-right: 10px;
  }
  b{
    color: #292929;
    font-weight: bold;
  }
  span{
    color: #6e6d7a !important;
    font-size: 14px;
    margin-top: 0px;
    font-weight: normal;
  }
`

const trendsItemSearch = css`
  padding: 3px 20px;
  font-weight: bold;
  border-bottom: 0px solid #eee;
  display: block;
  background: #fff;
  cursor: pointer;
  color: #292929;
  text-decoration:none;
  &:hover{
    color: #292929;
    text-decoration:none;
    background: rgba(17,129,235,0.2) !important;
  }
  p{
    font-weight: bold;
    margin: 0px;
  }
  span{
    font-weight: normal;
    font-size: 14px;
  }
`

const codeColor = css`
  padding: 3px 20px;
  font-weight: bold;
  border-bottom: 0px solid #eee;
  display: flex;
  align-items: center;
  background: #fff;
  cursor: pointer;
  color: #292929;
  text-decoration:none;
  &:hover{
    color: #292929;
    text-decoration:none;
    background: rgba(17,129,235,0.2) !important;
  }
  b{
    font-weight: bold;
  }
  font-size: 14px;
`

function codeBgcolor(code) {
  return css`
    background: #${code};
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 2px;
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  `
}

const imgFluid = css`
  max-width: 60px;
  height: auto;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  margin-right: 10px;
`

const designItemSearch = css`
  padding: 3px 16px;
  align-items: flex-start;
  display: flex;
  text-decoration: none;
  color: #292929;
  &:hover{
    text-decoration: none;
    background: rgba(17,129,235,0.2);
    color: #292929;
  }
  b{
    font-weight: bold;
    margin-top: 0px;
  }
  span{
    color: #6e6d7a !important;
    font-size: 14px;
    margin-top: 20px;
    font-weight: normal;
  }
`

const w3 = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const searchTop = css`
  padding-left: 10px;
  padding-right: 25px;
  @media only screen and (min-width:0px) and (max-width:767px){
    padding-right: 10px;
  }
`

export default{
  rowTrends,
  trendsWrapper,
  designTrends,
  trendsItem,
  rowTrendsItem,
  trendsHeader,
  formControl,
  searchBar,
  resultSearch,
  resultSearchContent,
  resultSearchSubtitle,
  search,
  resultUser,
  trendsItemSearch,
  codeColor,
  codeBgcolor,
  imgFluid,
  designItemSearch,
  w3,
  searchTop
}
