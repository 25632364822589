import { css } from 'emotion'

// feedoverlay
function overlay(tiny) {
  return css`
    background: rgba(0,0,0,0.8);
    top: 0;
    left: 0;
    z-index: 9997;
    width: 100%;
    height: 100%;
    position: fixed;
    overflow-y: scroll;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    visibility: hidden;
    opacity: 0;
    transition: background-color .30s linear;
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 0.07s ease-in;
    transition: all 0.07s ease-in;
    display: flex;
    align-items:center;
  `
}

// feedContentOverlay
function contentOverlay(tiny) {
  return css`
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
    position: absolute;
    left: 50%;
    width: 420px;
    min-height: 210px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-left: -210px;
    padding: 36px 40px;
    background: #fff;
    background-position: fixed;
    box-sizing: border-box;
    border-radius: 2px;
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transition: all 0.1s ease-in;
    transition: all 0.1s ease-in;
    z-index: 111;
    top:auto;
  `
}

// backdropOverlay
function backdropOverlay(tiny) {
  return css`
   z-index: 100;
   position: sticky;
   display: flex;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background:transparent;
 `
}

function mediaWrapper(tiny) {
  return css`
    min-height: auto;
  `
}

export default{
  overlay,
  contentOverlay,
  backdropOverlay,
  mediaWrapper,
}
