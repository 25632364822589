import base from './api.base'

function profile(userId, data) {
  return base.patch(`settings/profile/${userId}`, data)
}

function account(userId, data) {
  return base.patch(`settings/account/${userId}`, data)
}

function password(userId, data) {
  return base.patch(`settings/password/${userId}`, data)
}

function link(userId, data) {
  return base.patch(`settings/link/${userId}`, data)
}

function alert(userId, data) {
  return base.patch(`settings/alert/${userId}`, data)
}


const ApiSettings = {
  profile,
  account,
  password,
  link,
  alert,
}

export default ApiSettings
