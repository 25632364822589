import base from './api.base'

function index(designId) {
  return base.get(`comments/${designId}`)
}

function create(data) {
  return base.post('comments', data)
}

function update(id, data) {
  return base.patch(`comments/${id}`, data)
}

function list(slug) {
  return base.get(`comments/list/${slug}`)
}

function destroy(id) {
  return base.delete(`comments/${id}`)
}

const ApiComments = {
  index,
  create,
  list,
  update,
  destroy,
}

export default ApiComments
