import base from './api.base'


function team(username){
  return base.get(`members/team/${username}`)
}

function count(username){
  return base.get(`members/count/${username}`)
}

function available(data){
  return base.post(`members/available`, data)
}

const ApiMembers = {
  team,
  count,
  available
}

export default ApiMembers
