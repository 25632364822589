
import React, { Fragment } from 'react'
import { Base, Helpers } from 'utils'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { SelectStyle } from './SelectStyle'

const InputTeam = ({
  title, onChange, options, formatOptionLabel, value,
}) => (
  <Fragment>
    <h6 className={Base.textMuted}>{title}</h6>
    <Select
      components={{
        IndicatorSeparator: () => null,
      }}
      placeholder="Choose a team"
      formatOptionLabel={formatOptionLabel}
      onChange={onChange}
      options={options}
      classNamePrefix="custom-select"
      value={value}
      isSearchable={false}
      styles={SelectStyle}
    />
  </Fragment>
)

export default InputTeam
