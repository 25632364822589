import React, { Component, Fragment } from 'react'
import { Base, Helpers, I18n } from 'utils'
import {
  Input, InputTag, Checkbox, Hint, InputTeam,
  Button,
} from 'components'
import { Link } from 'react-router-dom'
import Dropzone from 'react-dropzone'

import marvlous from 'images/marvlous.png'
import upload from 'images/upload.svg'
import Styles from './Styles'
import placeholderImage from 'images/user-placeholder.jpg'

class DesignUpload extends Component {
  formatOptionLabel = ({ value, label, count }) => (
    <div className={Base.dBlock}>
      <b
        className={Base.dBlock}
        style={{ fontWeight: 'bold', fontSize: '1rem', color: '#292929' }}
      >
        #
        {label}
      </b>
      <span className="sublabel" style={{ color: '#6e6d7a', fontSize: '14px' }}>
        {count}
        {' '}
        designs
      </span>
    </div>
  )

  renderThumbnailPreview = (item, size, index) => {
    let content = null
    let activeClass = null
    const { imageData, error } = item
    if (imageData.type.includes('jpg')
        || imageData.type.includes('jpeg')
        || imageData.type.includes('png')
        || imageData.type.includes('gif')) {
      activeClass = size === 'tiny' ? Styles.imgFluidSmall(error !== '') : Styles.imgFluid(error !== '')
      content = (
        <img id={!index ? 'cover-image' : ''} className={activeClass} src={imageData.preview} />
      )
    } else if (imageData.type.includes('mp4')) {
      activeClass = size === 'tiny' ? Styles.videoFluidSmall(error !== '') : Styles.videoFluid(error !== '')
      content = (
        <video
          src={imageData.preview}
          id={!index ? 'cover-video' : ''}
          className={activeClass}
          loop
          muted
        >
          <source src={imageData.preview} type="video/mp4" />
          <source src={imageData.preview} type="video/ogg" />
        </video>
      )
    }
    return (
      <div className={Styles.wrapperFluid(error, size)}>
        {content}
      </div>
    )
  }

  render() {
    const {
      props: {
        handleSetStateSmallCover,
        handleSetStateInput,
        handleSetStateInputUpload,
        handleSetState,
        handleAddMoreFile,
        handleDeleteMoreFile,
        handleSetStateSmallFiles,
        handleDeleteCover,
        handleDeleteTinyCover,
        handleInputSelect,
        submitUploadDesign,
        handleOptions,
        typeState,
        state: {
          designUpload: {
            coverData,
            heightImage,
            imagesData,
            filesData,
            titleDesign,
            titleDesignError,
            commentActive,
            descriptionDesign,
            descriptionDesignError,
            descriptionDesignFocus,
            tagOption,
            tagOptionValue,
            resultUserData,
            resultTagData,
            formLoading,
            teamsOption,
            teamsOptionValue,
            url,
          },
        },
      },
      formatOptionLabel,
      renderThumbnailPreview,
    } = this

    return (
      <div className={Styles.container}>
        <div className={Helpers.mergeCss(Base.row, Base.alignItemsCenter)}>
          <div className={Helpers.mergeCss(Base.col, Base.w4)}>
            <Link style={{ textDecoration: 'none' }} to="/">
              <h5 className={Styles.marvlous}>
                <img src={marvlous} className={Styles.imgLogo} alt="" />
                <span>
                  Marvlous
                </span>
              </h5>
            </Link>
          </div>
          <div className={Helpers.mergeCss(Base.col, Base.w4)}>
            <h6 className={Helpers.mergeCss(Base.textCenter, Base.textMuted)}>
              Upload to your feed
            </h6>
          </div>
          <div className={Helpers.mergeCss(Base.col, Base.w4)}>
            <h6 className={Helpers.mergeCss(Base.textRight, Base.textMuted)}>
              Help
            </h6>
          </div>
        </div>
        <canvas
          style={{
            opacity: 0,
            position: 'absolute',
            zIndex: '-111',
            display: 'none',
          }}
          width="800"
          height="600"
          id="thecanvas"
        />
        <img style={{ opacity: 0, position: 'absolute', zIndex: '-111' }} id="img-result" src={url} />
        <div className={Styles.formPlace}>
          <div className={Styles.multiple}>
            <div className={Helpers.mergeCss(Base.row, Styles.multipleDropzone)}>
              {imagesData.map((item, index, arr) => {
                if (!index) {
                  return (
                    <div key={item.id} className={Helpers.mergeCss(Base.col, Base.w12)}>
                      {item.imageData !== null && (
                        <Fragment>
                          {renderThumbnailPreview(item, 'big', index)}
                            <span onClick={handleDeleteTinyCover(typeState, 'imagesData', item.id)} className={Styles.deleteFileCover('big')}>
                              <i className="la la-trash" />
                            </span>
                            {
                              item.error !== ''
                              && (
                              <div className={Styles.hintDropzoneError}>
                                <Hint
                                  title={item.error}
                                  position="top"
                                  content={(
                                    <div className={Styles.hint}>
                                      <i className="fa fa-exclamation" />
                                    </div>
                                  )}
                                />
                              </div>
                              )
                            }
                        </Fragment>
                      )}
                      <Dropzone
                        onDrop={handleSetStateSmallCover(typeState, 'imagesData', item.id)}
                        className={item.imageData !== null ? Helpers.mergeCss(Styles.dropZone(item.error !== ''), Styles.dNone) : Styles.dropZone(item.error !== '')}
                      >
                        { item.error === ''
                          ? (
                            <div className={Styles.hintDropzone}>
                              <Hint
                                title="Upload your file here, we support PNG, JPG GIF, or even MP4 VIDEO"
                                position="top"
                                content={(
                                  <div className={Styles.hint}>
                                    <i className="fa fa-question" />
                                  </div>
                              )}
                              />
                            </div>
                          )
                          : (
                            <div className={Styles.hintDropzone}>
                              <Hint
                                title={item.error}
                                position="top"
                                content={(
                                  <div className={Styles.hint}>
                                    <i className="fa fa-exclamation" />
                                  </div>
                              )}
                              />
                            </div>
                          )
                        }
                        <div className={Helpers.mergeCss(Base.dFlex, Base.alignItemsCenter, Base.h100, Base.justifyContentCenter, Styles.dropZoneDragClick(true))}>
                          <div className={Helpers.mergeCss(Styles.dBlock, Base.textCenter)}>
                            <img className={Base.dInlineBlock} src={upload} />
                            <div className={Helpers.mergeCss(Base.row, Base.justifyContentCenter)}>
                              <div className={Helpers.mergeCss(Base.col, Base.w8)}>
                                <h2 className={Helpers.mergeCss(Base.text, Styles.labelDropZone)}>
                                  Drag and drop or browse to upload an Image PNG, JPG, GIF or MP4 Video
                                </h2>
                              </div>
                            </div>
                            <div className={Helpers.mergeCss(Base.row, Base.justifyContentCenter)}>
                              <div className={Helpers.mergeCss(Base.col, Base.w6)}>
                                <span className={Base.textRead}>800 x 600 is minimum requirement, for better result use 1600×1200 or larger recommended, up to 10MB each</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Dropzone>
                      <h6 className={Base.textMuted}>
                        Multiple
                        <div className={Helpers.mergeCss(Base.marginLeft2, Base.dInlineBlock)}>
                          <Hint
                            title="If you wanna add more, drag n drop below here"
                            position="top"
                            content={(
                              <div className={Styles.hint}>
                                <i className="fa fa-question" />
                              </div>
                            )}
                          />
                        </div>
                      </h6>
                    </div>
                  )
                }
                return (
                  <div key={item.id} className={Helpers.mergeCss(Base.col, Base.w2)}>
                    {item.imageData !== null && renderThumbnailPreview(item, 'tiny', index)}
                    {item.imageData !== null && (
                      <Fragment>
                        <span onClick={handleDeleteTinyCover(typeState, 'imagesData', item.id)} className={Styles.deleteFileCover('tiny')}>
                          <i className="la la-trash" />
                        </span>
                        {item.error !== ''
                          && (
                          <div className={Styles.hintDropzoneErrorSmall}>
                            <Hint
                              title={item.error}
                              position="top"
                              content={(
                                <div className={Styles.hint}>
                                  <i className="fa fa-exclamation" />
                                </div>
                              )}
                            />
                          </div>
                          )
                        }
                      </Fragment>
                    )
                      }
                    {arr[0].imageData !== null
                      ? (
                        <Dropzone
                          onDrop={handleSetStateSmallCover(typeState, 'imagesData', item.id)}
                          className={item.imageData !== null ? Helpers.mergeCss(Styles.dropZoneSmall, Styles.dNone) : Styles.dropZoneSmall}
                        >
                          <div className={Helpers.mergeCss(Base.dFlex, Base.alignItemsCenter, Base.h100, Base.justifyContentCenter, Styles.dropZoneDragClick(true))}>
                            <div className={Helpers.mergeCss(Styles.dBlock, Base.textCenter)}>
                              <img className={Base.dInlineBlock} src={upload} />
                            </div>
                          </div>
                        </Dropzone>
                      )
                      : (
                        <div
                          className={item.imageData !== null ? Helpers.mergeCss(Styles.dropZoneSmall, Styles.dNone) : Styles.dropZoneSmall}
                        >
                          <div className={Helpers.mergeCss(Base.dFlex, Base.alignItemsCenter, Base.h100, Base.justifyContentCenter, Styles.dropZoneDragClick(true))}>
                            <div className={Helpers.mergeCss(Styles.dBlock, Base.textCenter)}>
                              <img className={Base.dInlineBlock} src={upload} />
                            </div>
                          </div>
                        </div>
                      )
                        }
                  </div>
                )
              })
              }
            </div>
            <div>
              <img
                style={{
                  width: '100%',
                  boxShadow: '0 1px 4px rgba(0,0,0,0.05)',
                  borderRadius: '2px',
                  display: 'none',
                }}
                id="img-preview"
              />
            </div>
          </div>
          {teamsOption.length > 0
            && (
            <div className={Helpers.mergeCss(Styles.team(Object.keys(teamsOptionValue).length === 0 && teamsOptionValue.constructor === Object), Base.positionRelative)}>
              <InputTeam
                title="Teams"
                onChange={handleInputSelect(typeState, 'teamsOptionValue')}
                value={teamsOptionValue}
                options={teamsOption}
              />
              {!(Object.keys(teamsOptionValue).length === 0 && teamsOptionValue.constructor === Object) &&
                <span onClick={handleSetState(typeState, 'teamsOptionValue', {})} style={{position: 'absolute',
                  right: '10px',
                  top: '35px',
                  display: 'inline-block',
                  cursor: 'pointer',
                  zIndex: '111'}}>
                  <i className="las la-times"></i>
                </span>
              }
            </div>
            )
          }
          <div className={Styles.title}>
            <Input
              title={I18n.t('form.title')}
              placeholder={I18n.t('form.placeholder.title')}
              value={titleDesign}
              error={titleDesignError}
              onChange={handleSetStateInput(typeState, 'titleDesign')}
            />
          </div>
          <div className={Styles.tags}>
            <InputTag
              title={
                <Fragment>
                  {I18n.t('form.tags')}
                  <div className={Helpers.mergeCss(Base.marginLeft2, Base.dInlineBlock)}>
                    <Hint
                      title="You dont have to put #(hashtag) sign, it automatically there when you choose or add it"
                      position="top"
                      content={(
                        <div className={Styles.hint}>
                          <i className="fa fa-question" />
                        </div>
                      )}
                    />
                  </div>
                </Fragment>
              }
              onChange={handleInputSelect(typeState, 'tagOptionValue')}
              options={tagOption}
              formatOptionLabel={formatOptionLabel}
            />
          </div>
          <div className={Helpers.mergeCss(Styles.description, Styles.positionRelative)}>
            {
              resultUserData.length > 0 && descriptionDesignFocus
              && (
              <div className={Styles.userMention}>
                <ul className={Base.ulUnstyled}>
                  {resultUserData.map(any => (
                    <li onClick={handleOptions(any.username, '@')} key={any.username}>
                      <div className={Base.dFlex}>
                        <img src={(any.avatar.url === null || any.avatar.url === '') ? placeholderImage : any.avatar.url} />
                        <b className={Base.marginRight}>
                          {any.name}
                        </b>
                        <span>
                          {`${any.username}`}
                        </span>
                      </div>
                    </li>
                  )).slice(0, 5)
                  }
                </ul>
              </div>
              )
            }
            {
              resultTagData.length > 0 && descriptionDesignFocus
              && (
              <div className={Styles.userHashtag}>
                <ul className={Base.ulUnstyled}>
                  {resultTagData.map(any => (
                    <li onClick={handleOptions(any.tag.name, '#')} key={any.tag.name}>
                      <div className={Base.dFlex}>
                        <b className={Base.marginRight}>
                          #
                          {any.tag.name}
                        </b>
                      </div>
                      <span className={Base.text}>
                        {any.count}
                        {' '}
                        designs
                      </span>
                    </li>
                  )).slice(0, 5)
                  }
                </ul>
              </div>
              )
            }
            <Input
              type="textarea"
              title={I18n.t('form.description')}
              placeholder={I18n.t('form.placeholder.description')}
              value={descriptionDesign}
              error={descriptionDesignError}
              onFocus={handleSetState(typeState, 'descriptionDesignFocus', true)}
              onChange={handleSetStateInputUpload(typeState, 'descriptionDesign')}
              onKeyUp={handleSetStateInputUpload(typeState, 'descriptionDesignTyping')}
            />
          </div>
          <div className={Styles.files}>
            <h6 className={Base.textMuted}>
              Add original file
            </h6>
            {filesData.map((item, index) => (
              <div key={item.id} className={Styles.dropZoneSmallWrapper}>
                {!!index && (
                <p onClick={handleDeleteMoreFile(typeState, 'filesData', item.id)} className={Styles.btnDelete}>
                  <i className="la la-times" />
                </p>
                )}
                <Dropzone
                  onDrop={handleSetStateSmallFiles(typeState, 'imagesData', item.id)}
                  className={Styles.dropZoneSmallFiles}
                >
                  <div className={Helpers.mergeCss(Base.dFlex, Base.alignItemsCenter, Base.h100, Base.justifyContentCenter, Styles.dropZoneDragClick(false))}>
                    <div className={Helpers.mergeCss(Styles.dBlock, Base.textCenter)}>
                      {item.fileData !== null
                        ? (
                          <span className={Base.textMuted}>
                            {Helpers.readablizeBytes(item.fileData.size)}
                            {' '}
                            {item.fileData.name}
                          </span>
                        )
                        : <img className={Base.dInlineBlock} src={upload} />
                          }
                    </div>
                  </div>
                </Dropzone>
              </div>
            ))}
            <span className={Helpers.mergeCss(Base.dBlock, Styles.more)}>
              <span onClick={handleAddMoreFile(typeState, 'filesData')} className={Helpers.mergeCss(Base.textRead, Base.dInlineFlex, Base.alignItemsCenter)}>
                Add more
                {' '}
                <i className="ml-2 la la-plus" />
              </span>
            </span>
          </div>
          <div className={Styles.checkbox}>
            <Checkbox
              title={I18n.t('form.settings')}
              onChange={handleSetState(typeState, 'commentActive', !commentActive)}
              label={I18n.t('form.disable-comments')}
              checked={commentActive}
            />
          </div>
          <div className={Styles.uploadButton}>
            <Button
              onClick={submitUploadDesign}
              loading={formLoading}
              label="Upload"
            />
          </div>
        </div>
      </div>
    )
  }
}

export default DesignUpload
