import { css } from 'emotion'

const scrollAnalytics = css`
  display: block!important;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
`

const rowAnalytics = css`
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 60px;
  @media only screen and (min-width:0px) and (max-width:767px){
    margin-bottom: 60px;
  }
`

const borderRight = css`
  border-right: 1px solid #eee;
`

const borderBottom = css`
  border-bottom: 1px solid #eee;
`

const impression = css`
  padding: 15px 15px;
`

const result = css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.85);
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 111;
  border-radius: 2px;
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  &:hover{
    opacity:0;
    cursor: pointer;
  }
  p{
    font-size: 20px;
    i{
      margin-right: 10px;
    }
    margin:0px;
  }
  b{
    display:none !important;
    font-size: 14px;
    overflow: hidden;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical;
  }
`

const engagement = css`
  padding: 15px 3px;
`

const defaultHeight = css`
  height: 100%;
  > div{
    height: 33.3%;
  }
`

const wrapperAnalytics = css`
  padding: 15px 28px;
`

const cardWrapper = css`
  position: relative;
  display: inline-flex;
  width: 15%;
  height: auto;
  margin-right: 10px;
  span{
    display: none !important;
  }
  p{
    display: none;
  }
`

const summaryWrapper = css`
  span{
    display: none !important;
  }
  p{
    display: none;
  }
`

const engagementWrapper = css`
  font-size: 14px;
`

const paddCustom = css`
  padding-left: 18px;
  padding-right: 18px;
`

const deleteButton = css`
    font-size: 14px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #212529;
    color: #fff;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
`

export default{
  scrollAnalytics,
  rowAnalytics,
  borderRight,
  borderBottom,
  impression,
  result,
  engagement,
  defaultHeight,
  wrapperAnalytics,
  cardWrapper,
  summaryWrapper,
  engagementWrapper,
  paddCustom,
  deleteButton
}
