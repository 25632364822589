
import React, { Fragment } from 'react'
import { Base, Helpers } from 'utils'
import PropTypes from 'prop-types'
import CreatableSelect from 'react-select/creatable'
import { components } from "react-select"
import { SelectStyle } from './SelectStyle'
import Styles from './Styles'

const InputTag = ({
  title, onChange, options, formatOptionLabel,
}) => {
  const Menu = props => {
    const optionSelectedLength = props.getValue().length || 0;
    return (
      <components.Menu {...props}>
        {optionSelectedLength < 10 ? (
          props.children
        ) : (
          <div className={Styles.maxLimit}>Max limit achieved</div>
        )}
      </components.Menu>
    );
  }

  const isValidNewOption = (inputValue, selectValue) => inputValue.length > 0 && selectValue.length < 10

  return(
    <Fragment>
      <h6 className={Base.textMuted}>{title}</h6>
      <CreatableSelect
        isMulti
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
          Menu
        }}
        placeholder=""
        formatOptionLabel={formatOptionLabel}
        onChange={onChange}
        options={options}
        classNamePrefix="custom-select"
        isValidNewOption={isValidNewOption}
        styles={SelectStyle}
      />
    </Fragment>
  )
}

export default InputTag
