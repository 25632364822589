import base from './api.base'

function search(keyword, tab, page) {
  return base.get(`search/search/${keyword}?tab=${tab}&page=${page}`)
}

function result(keyword, page) {
  return base.get(`search/result/${keyword}?page=${page}`)
}

function count(keyword, tab, page) {
  return base.get(`search/count/${keyword}?tab=${tab}&page=${page}`)
}

const ApiSearch = {
  search,
  result,
  count,
}

export default ApiSearch
