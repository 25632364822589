import axios from 'axios'

const API_URL = window.location.origin
const token = document.querySelector('[name="csrf-token"]')

const base = axios.create({
  baseURL: `${API_URL}/api/v1/`,
  headers: {
    common: {
      'X-CSRF-Token': token.content,
    },
  },
})

export default base
