import React, { Component, Fragment } from 'react'
import { Base, Helpers, I18n } from 'utils'
import { Link } from 'react-router-dom'
import {
  Input, InputTag, Checkbox, Hint, Loading, SearchBar, Card,
} from 'components'
import {
  ApiTags, ApiFinds, ApiSearch, ApiSubscriptions,
} from 'api'
import moment from 'moment'
import Dropzone from 'react-dropzone'
import marvlous from 'images/marvlous.png'
import upload from 'images/upload.svg'
import Styles from './Styles'
import functions from '../../functions'
import Model from '../Model'


import Data from '../Data'

import TabContent from './TabContent'

class Search extends Component {
  state = {
    search: {
      tabData: [],
      tabActive: '',
      keyword: '',
      keywordFocus: false,
      keywordTyping: '',
      resultKeyword: null,
      open: false,
    },
  }

  componentWillUnmount() {
    this.props.resetState()
  }

  async componentDidMount() {
    const tab = this.props.tab.tab || 'users'
    const state = {
      tabData: Data.search,
      tabActive: tab,
      keyword: this.props.keyword,
    }

    this.setState(currState => ({
      search: functions.mergeState(currState.search, state),
    }), () => {
      const page = this.state.search.tabData.find(any => any.label === this.state.search.tabActive).page
      this.handleSearch(tab, state.keyword, page)
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.keyword !== this.props.keyword) {
      const tab = this.props.tab.tab || 'users'
      const state = {
        tabData: Data.search,
        tabActive: tab,
        keyword: this.props.keyword,
      }
      
    this.setState(currState => ({
      search: functions.mergeState(currState.search, state),
    }), () => {
      this.handleSearch(this.props.tab.tab, this.props.keyword, 1)
      })
    }
  }

  handleScroll = (e) => {
    const { props: { slug }, state: { search: { tabActive } } } = this
    const tabContent = this.state.search.tabData.find(any => any.label === tabActive)
    const { currentTarget } = e
    const heightScroll = currentTarget.scrollHeight - (currentTarget.scrollHeight / 4)
    const activeHeightScroll = currentTarget.scrollTop + currentTarget.clientHeight
    if (activeHeightScroll > heightScroll) {
      if (!tabContent.endOfSearch && (tabContent.activeHeightScroll === '' || activeHeightScroll > tabContent.activeHeightScroll)) {
        const page = tabContent.page + 1
        const newState = {
          activeHeightScroll: currentTarget.scrollHeight, page, loadingDataSearch: true, keyword: this.state.search.keyword,
        }
        const newTabData = this.state.search.tabData.map((any) => {
          if (any.label === tabActive) {
            return { ...any, ...newState }
          }
          return { ...any }
        })

        this.setState(currState => ({
          search: functions.createStateObj(currState.search, 'tabData', newTabData),
        }), () => {
          if (tabActive !== 'result') {
            ApiSearch.search(this.state.search.keyword, tabActive, page).then(({ data }) => {
              const theContent = this.state.search.tabData.find(any => any.label === tabActive)
              const set = new Set()
              const newSearch = theContent.data.concat(data)
              const search = newSearch.filter((item) => {
                const duplicateId = set.has(item.id)
                set.add(item.id)
                return !duplicateId
              })

              const newData = { data: search, loadingDataSearch: false }
              const theData = this.state.search.tabData.map((any) => {
                if (any.label === tabActive) {
                  return { ...any, ...newData }
                }
                return { ...any }
              })
              this.setState(currState => ({
                search: functions.createStateObj(currState.search, 'tabData', theData),
              }), () => {
                setTimeout(() => {
                  const newState = { endOfSearch: data.length === 0 }
                  const newData = this.state.search.tabData.map((any) => {
                    if (any.label === tabActive) {
                      return { ...any, ...newState }
                    }
                    return { ...any }
                  })
                  this.handleSetState('search', 'tabData', newData)()
                }, 3000)
              })
            })
          } else {
            ApiSearch.result(this.state.search.keyword, page).then(({ data }) => {
              const theContent = this.state.search.tabData.find(any => any.label === tabActive)
              const set = new Set()
              const newSearch = theContent.data.designs.concat(data.designs)
              const search = newSearch.filter((item) => {
                const duplicateId = set.has(item.id)
                set.add(item.id)
                return !duplicateId
              })

              const newData = { data: { users: data.users, designs: search }, loadingDataSearch: false }
              const theData = this.state.search.tabData.map((any) => {
                if (any.label === tabActive) {
                  return { ...any, ...newData }
                }
                return { ...any }
              })
              this.setState(currState => ({
                search: functions.createStateObj(currState.search, 'tabData', theData),
              }), () => {
                setTimeout(() => {
                  const newState = { endOfSearch: data.designs.length === 0 }
                  const newData = this.state.search.tabData.map((any) => {
                    if (any.label === tabActive) {
                      return { ...any, ...newState }
                    }
                    return { ...any }
                  })
                  this.handleSetState('search', 'tabData', newData)()
                }, 3000)
              })
            })
          }
        })
      }
    }
  }

  handleSearch = async (tab, keyword, page) => {
    const { search } = this.state
    const dataSearch = search.tabData.find(any => any.label === tab)

    let response = tab === 'result' ? {} : []
    let count = tab === 'result' ? {} : 0
    if (tab === 'result') {
      response = dataSearch.keyword === keyword ? await ApiSearch.result(keyword, page) : await ApiSearch.result(keyword, 1)
      count = await ApiSearch.count(keyword, tab, page)
    } else {
      response = dataSearch.keyword === keyword ? await ApiSearch.search(keyword, tab, page) : await ApiSearch.search(keyword, tab, page)
      count = await ApiSearch.count(keyword, tab, page)
    }

    const tabData = search.tabData.map((any) => {
      if (any.label === 'result' && any.label === tab) {
        let newData = {}
        if (any.keyword === keyword && any.data !== null && any.data.designs.filter(item => (response.data.designs.filter(yolo => yolo.id === item.id).length > 0)).length) {
          newData = { ...any.data }
        } else if (any.keyword === keyword && any.data !== null && !any.data.designs.filter(item => (response.data.designs.filter(yolo => yolo.id === item.id).length > 0)).length) {
          newData = { ...any.data, designs: any.data.designs.concat(response.data.designs) }
        } else if (any.keyword !== keyword && any.data !== null) {
          newData = { ...response.data }
        } else {
          newData = { ...response.data }
        }

        return {
          ...any,
          data: newData,
          loading: false,
          resultExist: (newData.designs.length > 0 || newData.users.length > 0),
          page: any.keyword === keyword ? any.page : 1,
          users_count: count.data.users,
          designs_count: count.data.designs,
        }
      } if (any.label !== 'result' && any.label === tab) {
        let newData = []
        if (any.keyword === keyword && any.data.length > 0 && any.data.filter(item => (response.data.filter(yolo => yolo.id === item.id).length))) {
          newData = [...any.data]
        } else if (any.keyword === keyword && any.data.length > 0 && !any.data.designs.filter(item => (response.data.designs.filter(yolo => yolo.id === item.id).length > 0)).length) {
          newData = any.data.concat(response.data)
        } else if (any.keyword !== keyword && any.data.length > 0) {
          newData = [].concat(response.data)
        } else {
          newData = [].concat(response.data)
        }
        debugger
        return {
          ...any,
          data: newData,
          loading: false,
          resultExist: newData.length > 0,
          page: any.keyword === keyword ? any.page : 1,
          count: count.data,
        }
      }
      return { ...any }
    })

    const newState = {
      tabData,
      open: false,
    }

    this.handleSetMergeState('search', newState)()
  }

  handleSetStateInput = (type, subtype) => (e) => {
    const { value } = e.target
    if ((subtype === 'keywordTyping' || subtype === 'keyword') && e.key === 'Enter') {
      const newState = {
        keywordTyping: value,
        open: false,
        tabData: this.state.search.tabData.map((any) => {
          if (any.label === this.state.search.tabActive) {
            return { ...any, loading: true }
          }
          return { ...any }
        }),
      }
      this.setState(currState => ({
        [type]: functions.mergeState(currState[type], newState),
      }), () => {
        if (value.length > 0) {
          const page = this.state.search.tabData.find(any => any.label === this.state.search.tabActive).page
          this.updateUrl(type)
          this.handleSearch(this.state.search.tabActive, this.state.search.keywordTyping, page)
        } else {
          this.handleSetState('search', 'resultKeyword', null)()
        }
      })
    } else {
      this.setState(currState => ({
        [type]: functions.createStateObj(currState[type], subtype, value),
      }), () => {
        if (value.length > 1) {
          this.updateUrl(type)
          this.findTheKeyword(value)
        } else {
          this.handleSetState('search', 'resultKeyword', null)()
        }
      })
    }
  }

  handleSetState = (type, subtype, value) => () => {
    this.setState(currState => ({
      [type]: functions.createStateObj(currState[type], subtype, value),
    }), () => {
      if (subtype === 'tabActive') {
        const page = this.state.search.tabData.find(any => any.label === this.state.search.tabActive).page
        this.updateUrl(type)
        this.handleSearch(value, this.state.search.keyword, page)
      }
    })
  }

  updateUrl = (type) => {
    const url = `/search/${this.state[type].keyword}?tab=${this.state[type].tabActive}`
    window.history.pushState(null, '', url)
  }

  handleSetMergeState = (type, state) => () => {
    this.setState(currState => ({
      [type]: functions.mergeState(currState[type], state),
    }), () => {
      if (state.hasOwnProperty('keyword')) {
        const page = this.state.search.tabData.find(any => any.label === this.state.search.tabActive).page
        this.updateUrl(type)
        this.handleSearch(this.state.search.tabActive, this.state.search.keyword, page)
      }
    })
  }

  findTheKeyword = async (value) => {
    const data = await ApiFinds.find(value)
    const state = {
      resultKeyword: data.data,
      open: true,
    }

    this.setState(currState => ({
      search: functions.mergeState(currState.search, state),
    }))
  }

  submitSubscribe = (subscribe, userId, type) => async () => {
    const objectToSend = { userId }
    const data = Model.subscriptionParams(objectToSend)
    const { search: { tabData } } = this.state
    const response = subscribe ? await ApiSubscriptions.unsubscribe(data) : await ApiSubscriptions.subscribe(data)
    const newData = tabData.map((any) => {
      if (any.label === type && type === 'result') {
        return {
          ...any,
          data: {
            ...any.data,
            users: any.data.users.map((item) => {
              if (item.attributes.id === userId) {
                return {
                  ...item,
                  attributes: {
                    ...item.attributes,
                    subscribed: response.data.attributes.subscribed,
                  },
                }
              }
              return { ...item }
            }),
          },
        }
      } if (any.label === type && type === 'users') {
        return {
          ...any,
          data: any.data.map((item) => {
            if (item.attributes.id === userId) {
              return {
                ...item,
                attributes: {
                  ...item.attributes,
                  subscribed: response.data.attributes.subscribed,
                },
              }
            }
            return { ...item }
          }),
        }
      }
      return { ...any }
    })

    this.setState(currState => ({
      search: functions.createStateObj(currState.search, 'tabData', newData),
    }))
  }

  render() {
    const {
      state: { search }, handleSetStateInput, handleSetState, handleScroll, handleSetMergeState, submitSubscribe, props: { page, typeState },
    } = this
    const listExist = search.resultKeyword !== null
    && (search.resultKeyword.users.length > 0
     || search.resultKeyword.tags.length > 0
     || search.resultKeyword.designs.length > 0
     || search.resultKeyword.colors.length > 0)

    const activeContent = search.tabData.length > 0 && search.tabData.find(any => any.label === this.state.search.tabActive)

    return (
      <Fragment>
        <div className={Styles.searchHeader}>
          <div className={Helpers.mergeCss(Base.dBlock, Base.w100)}>
            <div className={Helpers.mergeCss(Base.row, Styles.searchTop, Base.justifyContentCenter)}>
              <div className={Helpers.mergeCss(Base.col, Base.w12)}>
                <SearchBar
                  onFocus={handleSetState('search', 'keywordFocus', !search.keywordFocus)}
                  onBlur={handleSetState('search', 'keywordFocus', !search.keywordFocus)}
                  onChange={handleSetStateInput('search', 'keyword')}
                  onKeyUp={handleSetStateInput('search', 'keywordTyping')}
                  handleSetMergeState={this.props.handleSetMergeState}
                  listExist={listExist}
                  page={page}
                  state={this.props.state}
                  // the search state
                  search={search}
                />
              </div>
            </div>
          </div>
        </div>
        <TabContent
          search={this.state.search}
          activeContent={activeContent}
          handleSetState={handleSetState}
          submitSubscribe={submitSubscribe}
          handleSetMergeState={this.props.handleSetMergeState}
          handleScroll={handleScroll}
          state={this.props.state}
          typeState={typeState}
        />
        {/* <div className={Helpers.mergeCss(Base.dBlock, Styles.searchWrapper)}>
          <div className={Helpers.mergeCss(Base.row, Styles.rowsearch)}>
            {search.tabData.map(any => {
              return(
                <div key={any.id} className={Helpers.mergeCss(Base.col, Styles.tabCol(any.label === this.state.search.tabActive), Base.w2)}>
                  <div onClick={handleSetState('search', 'tabActive', any.label)} className={Helpers.mergeCss(Base.dFlex, Base.paddingTop2, Base.paddingBottom2, Base.justifyContentCenter)}>
                    {any.title}
                  </div>
                </div>
              )
            })}
          </div>
          {search.tabData.length > 0 ?
            <div className={Helpers.mergeCss(Base.row, Styles.rowResultSearch)}>
              <span className={Helpers.mergeCss(Styles.searchResult, Base.w100)}>
                {this.state.search.keyword} {activeContent.title}
              </span>
              {activeContent.loading ?
                <div className={Helpers.mergeCss(Base.dFlex, Base.w100, Base.marginTop3, Base.justifyContentCenter)}>
                  <Loading/>
                </div>
                :
                activeContent.resultExist ? this.renderContent(this.state.search.tabActive, activeContent) :
                <b className={Styles.noResult}>
                  There is no result
                </b>
              }
            </div> : ''
          }
        </div> */}
      </Fragment>
    )
  }
}

export default Search
