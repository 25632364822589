import base from './api.base'


function like(data) {
  return base.post('like_comments/like', data)
}

function unlike(data) {
  return base.post('like_comments/unlike', data)
}

const ApiLikeComments = {
  like,
  unlike,
}

export default ApiLikeComments
