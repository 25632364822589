import base from './api.base'

function create(data) {
  return base.post('challenges', data)
}

function index(){
  return base.get('challenges')
}

function detail(slug){
  return base.get(`challenges/detail/${slug}`)
}

function accept(slug, data){
  return base.patch(`challenges/accept/${slug}`, data)
}

const ApiChallenges = {
  create,
  index,
  detail,
  accept
}

export default ApiChallenges
