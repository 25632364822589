import { css } from 'emotion'

const savedWrapper = css`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
`

const rowSaved = css`
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 13px;
  padding-right: 15px;
  margin-top: 60px;
  @media only screen and (min-width:0px) and (max-width:767px){
    margin-bottom: 60px;
  }
`

const designSaved = css`
  padding: 10px 30px;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  display: block;
`


const rowSavedCard = css`
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 16px;
  padding-right: 30px;
`

export default{
  savedWrapper,
  rowSaved,
  designSaved,
  rowSavedCard,
}
