import React, { Component, Fragment } from 'react'
import { Base, Helpers, I18n } from 'utils'
import { Link } from 'react-router-dom'
import {
  Loading, Card, Hint, CardPreview,
} from 'components'
import {
  ApiUsers, ApiSubscriptions, ApiDesigns, ApiLikes, ApiComments,
} from 'api'
import moment from 'moment'
import Dropzone from 'react-dropzone'
import { Line, Chart } from 'react-chartjs-2'

import marvlous from 'images/marvlous.png'
import upload from 'images/upload.svg'
import Styles from './Styles'
import Model from '../Model'
import Data from '../Data'
import functions from '../../functions'

const options = {
  legend: {
    display: true,
    padding: 0,
    position: 'top',
    align: 'end',
  },
  scales: {
    xAxes: [{
      gridLines: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        display: true,
        padding: 10,
      },
    }],
    yAxes: [{
      gridLines: {
        display: true,
        drawBorder: false,
      },
      ticks: {
        display: true,
        padding: 15,
        beginAtZero: true,
      },
    }],
  },
  elements: {
    point: {
      radius: 0,
    },
  },
}

class Analytics extends Component {
  componentDidMount() {
    const { props: { getAnalytics } } = this

    getAnalytics()

    const draw = Chart.controllers.line.prototype.draw
    Chart.Legend.prototype.afterFit = function () {
      this.height = this.height + 10
    }
    Chart.controllers.line = Chart.controllers.line.extend({
      draw() {
        draw.apply(this, arguments)
        const ctx = this.chart.chart.ctx
        const theStroke = ctx.stroke
        ctx.stroke = function () {
          ctx.save()
          ctx.shadowColor = 'rgba(0, 0, 0, 0.1)'
          ctx.shadowBlur = 10
          ctx.shadowOffsetX = 0
          ctx.shadowOffsetY = 20
          theStroke.apply(this, arguments)
          ctx.restore()
        }
      },
    })
  }

  componentWillUnmount() {
    this.props.resetState()
  }

  render() {
    const { props: { state: { analytics }, typeState, handleSetState, handleDeleteDesign } } = this
    let dataLastYear = [null, null, null, null, null, null]
    let dataThisYear = [null, null, null, null, null, null]

    if (!analytics.loading && analytics.summary.length > 0 && !analytics.summary.find(any => any.title === analytics.activeStatistics).fetchingData) {
      const dataStatistics = analytics.summary.find(any => any.title === analytics.activeStatistics)
      dataLastYear = dataStatistics.statisticsData[0].last_year.map((any) => {
        if (any !== null) {
          return any.attributes[`${analytics.activeStatistics}s`]
        }
        return any
      })
      dataThisYear = dataStatistics.statisticsData[0].this_year.map((any) => {
        if (any !== null) {
          return any.attributes[`${analytics.activeStatistics}s`]
        }
        return any
      })
    }

    const dataChart = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [{
        label: 'view this year',
        data: dataThisYear,
        pointDot: true,
        fill: false,
        lineTension: 0,
        borderColor: '#1181eb',
      },
      {
        label: 'view last year',
        data: dataLastYear,
        pointDot: true,
        fill: false,
        lineTension: 0,
        borderColor: '#0fb8ad',
      },
      ],
    }

    process.env.ENV_APP === 'development' && console.log(analytics, 'analytics')

    process.env.ENV_APP === 'development' && console.log(dataLastYear, dataThisYear, 'aaaaa')

    return (
      <div className={Helpers.mergeCss(Styles.scrollAnalytics)}>
        <div className={Helpers.mergeCss(Base.row, Styles.rowAnalytics)}>
          <div className={Helpers.mergeCss(Base.col, Base.w12)}>
            {
              analytics.loading ? (
                <div className={Helpers.mergeCss(Base.row, Base.marginTop2)}>
                  <div className={Helpers.mergeCss(Base.col, Base.w12)}>
                    <div className={Helpers.mergeCss(Base.dFlex, Base.justifyContentCenter)}>
                      <Loading />
                    </div>
                  </div>
                </div>
              ) : (analytics.isDataExist
                ? (
                  <Fragment>
                    <div className={Helpers.mergeCss(Base.row, Styles.borderBottom)}>
                      <div className={Helpers.mergeCss(Base.col, Base.w2, Styles.borderRight)}>
                        <div className={Helpers.mergeCss(Base.row, Styles.defaultHeight)}>
                          {analytics.summary.map(any => (
                            <div onClick={handleSetState('analytics', 'activeStatistics', any.title)} key={any.title} className={any.id === 2 ? Helpers.mergeCss(Base.col, Base.w12) : Helpers.mergeCss(Base.col, Base.w12, Styles.borderBottom)}>
                              <div className={Styles.impression}>
                                <b style={{ fontSize: '14px' }}>
                                  Most
                                  {' '}
                                  {any.title}
                                </b>
                                <br />
                                <div className={Base.positionRelative}>
                                  <Fragment>
                                    <div className={Styles.result}>
                                      <div className={Helpers.mergeCss(Base.dBlock, Base.padding2)}>
                                        <p className={Base.textCenter}>
                                          {
                                          any.title === 'view'
                                            ? <i className="las la-chart-line" />
                                            : (any.title === 'comment'
                                              ? <i className="las la-sms" />
                                              : <i className="la la-thumbs-up" />
                                            )
                                        }

                                          {any.data !== null && any.data.attributes[`${any.title}s`]}
                                        </p>
                                      </div>
                                    </div>
                                    <div className={Styles.summaryWrapper}>
                                      {
                                      any.data === null
                                        ? 'Theres no data yet'
                                        : (
                                          <CardPreview
                                            images={any.data.attributes.images}
                                            item={any.data}
                                            gif={any.data.attributes.cover.url !== null && any.data.attributes.cover.url.includes('gif')}
                                            video={any.data.attributes.cover.url !== null && any.data.attributes.cover.url.includes('mp4')}
                                            coverData={any.data.attributes.cover.url}
                                          />
                                        )
                                    }
                                    </div>
                                  </Fragment>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className={Helpers.mergeCss(Base.col, Base.w10)}>
                        <div style={{ paddingRight: '25px' }}>
                          <b
                            className={Base.dBlock}
                            style={{ paddingTop: '15px', fontSize: '14px' }}
                          >
                            {!analytics.loading && analytics.summary.length > 0 && `${analytics.summary.find(any => any.title === analytics.activeStatistics).title} statistics`}
                          </b>
                          {!analytics.loading && analytics.summary.length > 0 && !analytics.summary.find(any => any.title === analytics.activeStatistics).fetchingData
                      && (
                        <div style={{ paddingBottom: '15px' }} className={Base.marginTop2}>
                          <Line data={dataChart} options={options} />
                        </div>
                      )
                    }
                        </div>
                      </div>
                    </div>
                    <div className={Helpers.mergeCss(Base.row)}>
                      <div className={Helpers.mergeCss(Base.col, Base.w12, Base.padding0)}>
                        <div className={Helpers.mergeCss(Base.dBlock, Styles.wrapperAnalytics)}>
                          <div className={Base.row}>
                            <div className={Helpers.mergeCss(Base.col, Base.w5, Styles.paddCustom)}>
                              <b style={{
                                fontSize: '14px',
                                display: 'block',
                              }}
                              >
                                Detail
                              </b>
                            </div>
                            <div className={Helpers.mergeCss(Base.col, Base.w2, Base.textCenter, Styles.paddCustom)}>
                              <b style={{
                                fontSize: '14px',
                                display: 'block',
                              }}
                              >
                                Impressions
                              </b>
                            </div>
                            <div className={Helpers.mergeCss(Base.col, Base.w2, Base.textCenter, Styles.paddCustom)}>
                              <b style={{
                                fontSize: '14px',
                                display: 'block',
                              }}
                              >
                                Engagement
                              </b>
                            </div>
                            <div className={Helpers.mergeCss(Base.col, Base.w2, Base.textCenter, Styles.paddCustom)}>
                              <b style={{
                                fontSize: '14px',
                                display: 'block',
                              }}
                              >
                                Rate
                              </b>
                            </div>
                            <div className={Helpers.mergeCss(Base.col, Base.w1, Base.textCenter, Styles.paddCustom)}>
                              <b style={{
                                fontSize: '14px',
                                display: 'block',
                              }}
                              >
                                
                              </b>
                            </div>
                          </div>
                          {
                        analytics.engagement.map(any => (
                          <div key={any.id} className={Helpers.mergeCss(Base.row, Styles.engagementWrapper, Base.paddingTop2, Base.paddingBottom2)}>
                            <div className={Helpers.mergeCss(Base.col, Base.w5, Styles.paddCustom)}>
                              <div className={Base.dFlex}>
                                <div className={Styles.cardWrapper}>
                                  <CardPreview
                                    images={any.attributes.images}
                                    item={any}
                                    gif={any.attributes.cover.url !== null && any.attributes.cover.url.includes('gif')}
                                    video={any.attributes.cover.url !== null && any.attributes.cover.url.includes('mp4')}
                                    coverData={any.attributes.cover.url}
                                  />
                                </div>
                                <div className={Base.dBlock}>
                                  <span className={Helpers.mergeCss(Base.dBlock, Base.text)}>
                                    {moment(any.attributes.created_at.split('T').join(' '), 'YYYY-MM-DD HH:mm:ss').format('dddd, MMMM Do YYYY, h:mm:ss a')}
                                  </span>
                                  {any.attributes.title}
                                </div>
                              </div>
                            </div>
                            <div className={Helpers.mergeCss(Base.col, Base.w2, Base.textCenter, Styles.paddCustom)}>
                              {any.attributes.views}
                            </div>
                            <div className={Helpers.mergeCss(Base.col, Base.w2, Base.textCenter, Styles.paddCustom)}>
                              {any.attributes.comments}
                            </div>
                            <div className={Helpers.mergeCss(Base.col, Base.w2, Base.textCenter, Styles.paddCustom)}>
                              {any.attributes.engagement}
                            </div>
                            <div className={Helpers.mergeCss(Base.col, Base.w1, Base.textCenter, Styles.paddCustom)}>
                              <span onClick={handleDeleteDesign(any.id)} className={Styles.deleteButton}>
                                <i className="la la-trash"></i>
                              </span>
                            </div>
                          </div>
                        ))
                      }
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ) : '')
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Analytics
