
export const SelectStyle = {
  control: (base, state) => ({
    ...base,
    border: '0px',
    background: '#f3f3f4',
    borderRadius: '2px',
    boxShadow: 'none',
    '&:hover': {
      border: '0px solid black',
    },
  }),
  option: (base, state) => ({
    ...base,
    border: '0px',
  }),
  menu: base => ({
    ...base,
    // override border radius to match the box
    borderRadius: '2px',
    fontSize: '14px',
    // kill the gap
    marginTop: 0,
    border: '0px',
  }),
  menuList: base => ({
    ...base,
    border: '0px',
    // kill the white space on first and last option
    padding: 0,
  }),
}
