import { css } from 'emotion'

const designTrend = css`
  background: #fff !important;
  padding: 0px 0px;
  margin-top: 0px;
  border-radius: 2px;
`

const popularDesign = css`
  padding: 0px 0px;
  font-weight: bold;
  margin-bottom: 15px;
  border-bottom: 0px solid #eee;
  display: block;
  cursor: pointer;
  i{
    margin-left: 10px;
  }
`

const tagList = css`
  list-style: none;
  padding: 0px;
  margin: 0px;
  li{
    margin-right: 8px;
    margin-bottom: 0px;
    display: inline-block;
    a{
      display: block;
      padding: 2px 12px;
      margin-bottom: 8px;
      border-radius: 30px;
      cursor: pointer;
      background: rgba(17, 129, 235, 0.1);
      &:hover{
        text-decoration: none;
      }
      p{
        color: #1181eb;
        font-weight: bold;
        margin: 0px;
        margin-left: 5px;
        font-size: 14px;
        display:inline-block;
      }
      span{
        color: #1181eb !important;
        font-weight: bold;
        font-size: 14px;
      }
      border-bottom: 0px solid #eee;
    }
    &:last-of-type{
      div{
        border-bottom:0px;
      }
    }
  }
  @media only screen and (min-width:0px) and (max-width:767px){
    li{
      a{
        margin-bottom: 0px;
      }
    }
    list-style: none;
    padding: 0px;
    margin: 0px;
    display: flex;
    width: 100%;
    white-space: nowrap;
    overflow-y: auto;
  }
`

function scrollButton(direction, disappear) {
  const disappearStyle = disappear ? 'display: none;' : 'display: inline-flex;'
  const directionStyle = direction === 'right' ? `
    right: 0;
    justify-content: flex-end;
    background: linear-gradient(to right, transparent 0.01%, rgb(255,255,255)) !important;
    border-bottom-left-radius: 50%;
    border-top-left-radius: 50%;
  `
    : `
    left: 0;
    justify-content: flex-start;
    background: linear-gradient(to left, transparent 0.01%, rgb(255,255,255)) !important;
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
  `
  return css`
    @media only screen and (min-width: 768px){
      display: none;
    }
    height: 100%;
    width: 40px;
    position: absolute;
    top: 0;
    ${directionStyle}
    ${disappearStyle}
    align-items: center;
    font-size: 20px;
    cursor: pointer;
  `
}

export default{
  designTrend,
  popularDesign,
  tagList,
  scrollButton,
}
