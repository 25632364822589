import { Validation } from 'utils'
import { css } from 'emotion'

function mergeCss(...options) {
  return css`
    ${options.map(option => option)}
  `
}

function readablizeBytes(bytes) {
  const s = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB']
  const e = Math.floor(Math.log(bytes) / Math.log(1024))
  return `${(bytes / Math.pow(1024, e)).toFixed(2)} ${s[e]}`
}

function addImageProcess(src) {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = () => resolve(image)
    image.onerror = reject
    image.src = src
  })
}

function addVideoProcess(src) {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video')
    video.addEventListener('canplay', () => {
      resolve({
        video,
        duration: Math.round(video.duration * 1000),
        height: video.videoHeight,
        width: video.videoWidth,
      })
    })
    video.onerror = reject
    video.src = src
  })
}

function isImage(data) {
  if (data.includes('jpg')
     || data.includes('jpeg')
     || data.includes('png')
     || data.includes('gif')) {
    return true
  }
  return false
}

function rgbToHex(r, g, b) {
  return [r, g, b].map((x) => {
    const hex = x.toString(16)
    return hex.length === 1 ? `0${hex}` : hex
  }).join('')
}

function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null
}

function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}


function getCurrentTime() {
  const date = new Date()
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour12: false,
  }

  const originDate = date.toLocaleString('en-US', options)
  const dateFormat = originDate.split(',')[0].split('/').reverse()
  const newFormat = `${dateFormat[0]}-${dateFormat[2]}-${dateFormat[1]}${originDate.split(',')[1]}`

  return newFormat
}

function groupArrWithRange(arr, range) {
  let i = 0
  let a = 1
  const data = []
  do {
    data.push(arr.slice((i++ * range), (a++ * range)))
  } while (i < Math.ceil(arr.length / range))

  return data
}

function addLineBreak(value) {
  const newValue = value.split('').map((item, key) => {
    if ((item === '\n' || item === '\r')
        && (key !== (value.length - 1))
        && (!Validation.webPattern(item))
    ) {
      return ' <br/> '
    } if ((!item.indexOf('\n')) && (key === (value.length - 1))) {
      return ''
    }
    return item
  }).join('')

  return newValue
}

function formatUrl(str) {
  const website = str.split('.').map((any, index) => {
    if (index === 0) {
      let newValue
      if ((any.includes('https://') && any.includes('www')
        || any.includes('http://') && any.includes('www')
        || (!any.includes('http://') || !any.includes('https://')) && any.includes('www'))) {
        newValue = ''
      } else if ((any.includes('http://') || any.includes('https://')) && !any.includes('www')) {
        newValue = any.includes('https://') ? any.replace('https://', '') : any.replace('http://', '')
      } else if ((!any.includes('http://') || !any.includes('https://')) && !any.includes('www')) {
        newValue = `${any}`
      } else {
        newValue = any
      }
      return newValue
    }
    return any
  }).filter(any => any !== '').join('.')

  return website
}

function numberWithCommas(x, separator) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${separator}`)
}

export default {
  mergeCss,
  readablizeBytes,
  addImageProcess,
  addVideoProcess,
  isImage,
  rgbToHex,
  dataURLtoFile,
  getCurrentTime,
  groupArrWithRange,
  addLineBreak,
  formatUrl,
  hexToRgb,
  numberWithCommas,
}
