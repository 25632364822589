function checkValueNotBlank(value, text) {
  let isValid
  if (/^\s*$/.test(value)) {
    isValid = false
  } else if (/^\d+$/.test(value)) {
    isValid = value > 0
  } else if (/^\s+$/.test(value)) {
    isValid = value.length > 0
  } else {
    isValid = !!value
  }

  return isValid ? '' : `${text} must be filled in`
}

function checkValueDateNotBlank(value, text) {
  const isValid = value.length > 0

  return isValid ? '' : `${text} must be filled in`
}

function checkValueImageNotBlank(value, text){
  const isValid = value !== null

  return isValid ? '' : `${text} must be filled in`
}

function emailPattern(value, text) {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  let isValid = false
  if (pattern.test(String(value))) {
    isValid = true
  }

  return isValid ? '' : `${text} must an email format`
}

function usernamePattern(value, text) {
  const pattern = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{3,29}$/
  let isValid = false
  if (pattern.test(String(value))) {
    isValid = true
  }

  return isValid ? '' : `${text} must a username format`
}

function isDateBeforeToday(date, text) {
  let isValid = true
  if (new Date(date) < new Date(new Date().toDateString())) {
    isValid = false
  }
  return isValid ? '' : `${text} must be today`
}

function webPattern(value) {
  let isValid = false
  // const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}|[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  // const expression = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/
  const pattern = /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/
  if (pattern.test(value)) {
    isValid = true
  }
  return isValid
}

function isUrl(value) {
  try {
    return new URL(value)
  } catch (e) {
    if (e instanceof TypeError) {
      return 'Invalid'
    }
  }
}

function mentionPattern(value) {
  const pattern = /^((?=[^\w!])@\w+\b)$/
  let isValid = false
  if (pattern.test(value)) {
    isValid = true
  }
  return isValid
}

function hashtagPattern(value) {
  const pattern = /^(?!\s)#[A-Za-z]\w*\b$/
  let isValid = false
  if (pattern.test(value)) {
    isValid = true
  }
  return isValid
}

function passwordPattern(value, text) {
  /* Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character: */
  const pattern = /^(?=.*[a-z])(?=.*\d)[A-Za-z0-9\d$@$!%*?&#]{8,}$/
  let isValid = false
  if (pattern.test(String(value))) {
    isValid = true
  }

  return isValid ? '' : '8 min char and number'
}


const Validation = {
  checkValueNotBlank,
  checkValueDateNotBlank,
  checkValueImageNotBlank,
  emailPattern,
  usernamePattern,
  isDateBeforeToday,
  webPattern,
  passwordPattern,
  mentionPattern,
  hashtagPattern,
}

export default Validation
