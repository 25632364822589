import { css } from 'emotion'

const subscriptionsWrapper = css`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
`

const rowSubscriptions = css`
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 60px;
  @media only screen and (min-width:0px) and (max-width:767px){
    margin-bottom: 0px;
  }
`

const designSubscriptions = css`
  padding: 10px 30px;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  display: block;
`

const subscriptions = css`
  padding: 10px 40px;
`

const imgSubscriptions = css`
  width: 65px;
  height: 65px;
  border-radius: 50%;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
`

const subscriptionsWrapperItem = css`
  border-bottom: 1px solid #eee;
  padding: 10px 20px;
  padding-left: 30px !important;
  @media only screen and (min-width:0px) and (max-width:767px){
    margin-bottom: 20px;
  }
`

const bioSubscriptions = css`
  display:-webkit-box ;
  overflow: hidden ;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  @media only screen and (min-width:0px) and (max-width:767px){
    display: none;
  }
`

function btnMarvlous(subscribe) {
  const subscribeStyle = subscribe ? `background: #f3f3f4 !important;
  color: #212529!important;
  box-shadow: none !important;` : ''
  return css`
    ${subscribeStyle}
    margin: 0px;
    width: auto;
    margin-top: 10px;
    height: 30px;
    font-size: 14px !important;
    border-radius: 3px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
  `
}

const descSubscriptions = css`
  width: calc(100% - 75px);
  a{
    color: #292929;
    &:hover{
      text-decoration: none;
    }
  }
`

const imgFluid = css`
  max-width: 100%;
  height: auto;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
`

function slick(last) {
  const lastStyle = last ? '' : ' padding-right: 25px;'
  return css`
    ${lastStyle}
  `
}

const team = css`
  font-size: 10px;
  padding: 0px 3px;
  background: #f3f3f3;
  border-radius: 2px;
  display: inline-block;
  color: #6c757d;
  margin-left: 5px;
  font-weight: bold;
  height: 16px;
  border: 1px solid #eee;
  text-transform: uppercase;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
`

const infoDescription = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    flex-wrap: wrap;
    > a{
      width: 100%
    }
    > div{
      margin-top: 10px;
      width: 100%;
      padding-left: 0px !important;
    }
  }
`

const subscriberCount = css`
  display: block;
  @media only screen and (min-width:0px) and (max-width:990px){
    display: none;
  }
`

const subscriberCountMobile = css`
  display: block;
  @media only screen and (min-width:768px){
    display: none;
  }
`

const w4 = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }
`

const w8 = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const desktop = css`
  display: block;
  @media only screen and (min-width:0px) and (max-width:767px){
    display: none;
  }
`

const mobile = css`
  margin-left: .25rem!important;
  color: #6c757d!important;
  @media only screen and (min-width:768px){
    display: none;
  }
`

const buttonWrapper = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    justify-content: center;
  }
`

export default{
  subscriptionsWrapper,
  rowSubscriptions,
  designSubscriptions,
  subscriptions,
  imgSubscriptions,
  subscriptionsWrapperItem,
  bioSubscriptions,
  btnMarvlous,
  descSubscriptions,
  imgFluid,
  slick,
  team,
  infoDescription,
  subscriberCount,
  w4,
  w8,
  desktop,
  mobile,
  subscriberCountMobile,
  buttonWrapper
}
