import React, { Fragment } from 'react'
import { Helpers, Base } from 'utils'
import { Link } from 'react-router-dom'
import { Loading } from 'components'
import Styles from './Styles1'

const DesignTrends = ({
  loading,
  tags,
  handleScrollTrends,
  state,
}) => (
  <div className={Styles.designTrend}>
    {/* <span className={Styles.popularDesign}>
      Feed
      <i class="las la-angle-down"></i>
    </span> */}
    <div className={Base.positionRelative}>
      <span onClick={handleScrollTrends('left')} className={Styles.scrollButton('left', state.feed.activeScrollHorizontal === 0)}>
        <i className="las la-angle-left" />
      </span>
      <ul id="design-trends" className={Styles.tagList}>
        {loading
          ? (
            <div className={Helpers.mergeCss(Base.dFlex, Base.paddingTop2, Base.paddingBottom3, Base.justifyContentCenter)}>
              <Loading />
            </div>
          )
          : tags.length > 0 && (
            tags.map(any => (
              <li key={any.tag.id}>
                <Link to={`/search/${any.tag.name}?tab=trends`}>
                  <span className={Base.text}>
                    {any.count}
                  </span>
                  <p>
                    #
                    {any.tag.name}
                  </p>
                </Link>
              </li>
            )).slice(0, 16)
          )}
      </ul>
      <span onClick={handleScrollTrends('right')} className={Styles.scrollButton('right', state.feed.activeScrollHorizontal === state.feed.scrollWidth)}>
        <i className="las la-angle-right" />
      </span>
    </div>
  </div>
)

export default DesignTrends
