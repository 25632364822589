import { css } from 'emotion'

const container = css`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0px 40px;  
  @media only screen and (min-width:0px) and (max-width:767px){
    padding: 0px 15px;
  }
  @media (min-width: 576px){
    max-width: 540px;
  }
  @media (min-width: 768px){
    max-width: 720px;
  }
  @media (min-width: 992px){
    max-width: 840px;
  }
`

const marvlous = css`
  display: flex;
  margin: 0;
  align-items: center;
  height: 60px;
  span{
    color: #1181eb;
    font-weight: bold;
    margin-left: 3px;
  }
`

const imgLogo = css`
  width:40px;
  height:auto;
  object-fit: cover;
  object-position: center;
  position:relative;
`

const formPlace = css`
  padding-left: 4px;
  padding-right: 4px;
`

function dropZoneDragClick(padding) {
  const paddingStyle = padding ? `
    padding-top: 75%;
    overflow:hidden;
  ` : ``
  return css`
    position: relative;
    ${paddingStyle}
    > div:first-child{
      text-align: center!important;
      display: block;
      position: absolute;
      top: 25%;
      @media only screen and (min-width:0px) and (max-width:767px){
        display: none;
      }
    }
  `
}


function dropZone(error) {
  const errorStyleActive = error ? 'border: 2px dashed #dc3545!important;' : 'border: dashed 2px #f3f3f4;'
  const errorStyleHover = error ? 'border: 2px dashed #dc3545!important;' : 'border: dashed 2px #1181eb;'
  return css`
    margin-bottom: 1rem;
    margin-top: 1rem !important;
    border-radius: 3px;
    background: #fff !important;
    ${errorStyleActive}
    font-size: 14px;
    width: 100%;
    height: 0;
    padding-bottom: calc(74.4% + 2px);
    overflow: hidden;
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
    position: relative;
    img{
      width: 62px;
      height: auto;
      margin-bottom: 1.5rem !important;
    }
    &:hover{
      ${errorStyleHover}
    }
  `
}

const dropZoneSmall = css`
  margin-bottom: 0rem;
  width: 100%;
  height: auto;
  border-radius: 3px;
  background: #fff !important;
  border: dashed 2px #f3f3f4;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  font-size: 14px;
  width: 100%;
  height: 0;
  padding-bottom: calc(70.4% + 2px);
  overflow: hidden;
  img{
    width: 26px;
    height: auto;
  }
  &:hover{
    border: dashed 2px #1181eb;
  }
`

const dropZoneSmallFiles = css`
  margin-bottom: 0rem;
  width: 100%;
  height: auto;
  border-radius: 3px;
  background: #fff !important;
  border: dashed 2px #f3f3f4;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  font-size: 14px;
  height: 50px;
  img{
    width: 18px;
    height: auto;
    position: relative;
    top: -2px;
  }
  &:hover{
    border: dashed 2px #1181eb;
  }
`

const labelDropZone = css`

`

const positionRelative = css`
  position: relative;
`

function imgFluid(error) {
  const errorStyle = error ? '' : ''
  return css`
    ${errorStyle}
    width: 100%;
    height: auto;
    border-radius: 3px;
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
    margin-top: 0rem !important;
  `
}

function imgFluidSmall(error) {
  // const errorStyle = error ? 'border: 2px dashed #dc3545!important;' : 'border: dashed 2px #1181eb;'
  const errorStyle = ''
  return css`
    ${errorStyle}
    width: 100%;
    height: auto;
    border-radius: 3px;
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
    margin-top: 0rem !important;
  `
}

const dNone = css`
  display: none;
`

const multiple = css`
  h6{
    margin-top: 1rem !important;
  }
`

const multipleDropzone = css`
  margin-bottom: 1rem !important;
`

const description = css`
  margin-top: 1.5rem;
  textarea{
    height: 10rem;
    resize: none;
  }
`

function wrapperFluid(error, type){
  let errorStyle = ''
  if(type === 'tiny'){
    errorStyle = error ? `
      border: 2px dashed #dc3545!important;
      border-radius: 3px;
    ` 
    :
      `
      border: dashed 2px #1181eb;
      border-radius: 3px;
    `
  }else{
    errorStyle = error ? `
      border: 2px dashed #dc3545!important;
      border-radius: 3px;
      margin-top: 1rem !important;
    ` 
    :
      `
      border: dashed 2px #1181eb;
      border-radius: 3px;
      margin-top: 1rem !important;
    `
  }

  return css`
    ${errorStyle}
    width: 100%;
    height: 0;
    padding-bottom: calc(75% + -3px);
    overflow: hidden;
    position: relative;
    margin-top: 0rem;
    border-radius: 3px;
  `
}

function videoFluid(error) {
  const errorStyle = error ? 'border: 0px dashed #dc3545!important;' : 'border: dashed 0px #1181eb;'
  return css`
    width: 100%;
    height:auto;
    border-radius: 3px;
    ${errorStyle}
    margin-top: 0rem !important;  
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  `
}

function videoFluidSmall(error) {
  const errorStyle = error ? 'border: 0px dashed #dc3545!important;' : 'border: dashed 0px #1181eb;'

  return css`
    width: 100%;
    height:auto;
    border-radius: 3px;
    ${errorStyle}
    margin-top: 0rem !important;  
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  `
}

const btnMarvlous = css`
  width: 100%;
  height: auto;
  border-radius: 2px;
  background: #1181eb !important;
  color: #fff;
  box-shadow: 0 2px 6px 0 rgba(51, 105, 231, 0.4);
  font-size: 14px;
  letter-spacing: 0.5px;
  border: 0px;
  text-align: center;
  cursor: pointer;
  border:0px !important;
  padding: .5rem 3rem !important;
  margin-top: 10px;
  margin-bottom: 25px;
  text-decoration: none;
  &:focus{
    box-shadow: 0 0 0 0 rgba(51, 105, 231, 0.4);
    outline: 0;
  }
  &:hover{
    box-shadow: 0 0 0 0 rgba(51, 105, 231, 0.4);
    text-decoration: none;
  }
`

const more = css`
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  span{
    cursor: pointer;
  }
  i{
    font-size: 14px;
    position: relative;
    top: 1px;
  }
`

const hint = css`
  width: 18px;
  height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #f3f3f4;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  i{
    font-size: 10px;
  }
  margin-left: 0px;
`

const hintDropzone = css`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 100;
`

const hintDropzoneError = css`
  position: absolute;
  top: calc(1rem + 15px);
  right: 30px;
`

const btnDelete = css`
  position:absolute;
  top: -6px;
  right: -6px;
  background: #212529;
  border-radius: 2px;
  width: 15px;
  height: 15px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  z-index: 111;
  i{
    position:relative;
    right: -0.5px;
    top: 0.5px;
  }
`

const dropZoneSmallWrapper = css`
  margin-bottom: 15px;
  &:last-of-type{
    margin-bottom: 0px;
  }
  position: relative;
`

const wrapperFluidFile = css`
  margin-bottom: 0rem;
  width: 100%;
  height: auto;
  border-radius: 2px;
  background: #fff !important;
  border: dashed 2px #f3f3f4;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  font-size: 14px;
  height: 50px;
  display: flex;
  align-items:center;
  justify-content: center;
`

function deleteFileCover(type) {
  let topPosition = 'calc(1rem + 17px)'
  let leftPosition = '30px'
  let fontSize = '14px'
  let width = '20px'
  let height = '20px'
  if (type === 'tiny') {
    topPosition = '10px'
    leftPosition = '25px'
    fontSize = '12px'
    width = '15px'
    height = '15px'
  }
  return css`
    position: absolute;
    top: ${topPosition};
    left: ${leftPosition};
    font-size: ${fontSize};
    width: ${width};
    height: ${height};
    border-radius: 50%;
    background: #212529;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `
}

const previewWrapper = css`
  width: 100%;
  height: 0;
  padding-bottom: calc(75% + 2px);
  overflow: hidden;
  position: relative;
  margin-top: 1rem;
`

const canvasDraw = css`
  mage-rendering: optimizeSpeed;             
  image-rendering: -moz-crisp-edges;          
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -o-crisp-edges;            
  image-rendering: pixelated;                 
  -ms-interpolation-mode: nearest-neighbor;
`

const userHashtag = css`
  position: absolute;
  width: 100%;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  height: 217px;
  top: -189px;
  display: flex;
  align-items: flex-end;
  background: transparent;
  ul{
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
    height:auto;
    width: 100%;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border: 1px solid #f3f3f4 !important;
    border-bottom: 0px !important;
    background: #fff;
    li{
      cursor: pointer;
      width: 100%;
      padding: .15rem .75rem;
      background: #fff;
      &:hover{
        background: rgba(17,129,235, 0.2);
      }
      &:first-of-type{
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
      }
    }
  }
  img{
    width:24px;
    height:24px;
    border-radius: 50%;
    margin-right: 0.5rem;
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  }
`

const userMention = css`
  position: absolute;
  width: 100%;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  height: 217px;
  top: -189px;
  display: flex;
  align-items: flex-end;
  background: transparent;
  ul{
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
    height:auto;
    width: 100%;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border: 1px solid #f3f3f4 !important;
    border-bottom: 0px !important;
    background: #fff;
    li{
      cursor: pointer;
      width: 100%;
      padding: .375rem .75rem;
      background: #fff;
      &:hover{
        background: rgba(17,129,235, 0.2);
      }
      &:first-of-type{
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
      }
    }
  }
  img{
    width:24px;
    height:24px;
    border-radius: 50%;
    margin-right: 0.5rem;
    box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  }
`

const hintDropzoneErrorSmall = css`
  position: absolute;
  top: 3px;
  right: 20px;
`

function team(unselected){
  const selectedStyle = !unselected ? `
  svg{
    display: none;
  }
  ` : `
  `
  return css`
    ${selectedStyle}
    margin-bottom: 15px;
  `
}


export default{
  container,
  imgLogo,
  marvlous,
  formPlace,
  dropZoneDragClick,
  dropZone,
  labelDropZone,
  positionRelative,
  imgFluid,
  dNone,
  multiple,
  multipleDropzone,
  dropZoneSmall,
  dropZoneSmallFiles,
  imgFluidSmall,
  description,
  wrapperFluid,
  videoFluid,
  videoFluidSmall,
  btnMarvlous,
  more,
  hint,
  hintDropzone,
  hintDropzoneError,
  btnDelete,
  dropZoneSmallWrapper,
  wrapperFluidFile,
  deleteFileCover,
  previewWrapper,
  canvasDraw,
  userHashtag,
  userMention,
  hintDropzoneErrorSmall,
  team,
}
