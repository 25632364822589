import React, { Fragment, Component } from 'react'
import Styles from './Styles'
import creativeMarket from 'images/creative-market.svg'
import { Base, Helpers, I18n } from 'utils'

class Adsense extends Component {
  componentDidMount () {
    process.env.ENV_APP === 'production' && window.innerWidth > 767 && (window.adsbygoogle = window.adsbygoogle || []).push({})
  }

  render () {
    return (
      <Fragment>
        {
          process.env.ENV_APP === 'production' ?
          <Fragment>
            {/* Ads marvlous new */}
            <ins class="adsbygoogle mobile-none"
              style={{display: "block"}}
              data-ad-client="ca-pub-8794465840040120"
              data-ad-slot="1702156451"
              data-ad-format="auto"
              data-full-width-responsive="true"></ins>
            </Fragment>
            :
            <Fragment>
              <img className={Helpers.mergeCss(Styles.adsCustom)} src={creativeMarket} />
              <span className={Helpers.mergeCss(Base.dBlock, Base.textCenter, Base.marginTop2, Base.text)}>
                Flawless design is easy with millions of ready-made assets to choose from
              </span>
              <b style={{ color: '#dbdbde', textTransform: 'uppercase', fontSize: '12px' }}>Advertise on Marvlous</b>
            </Fragment>
        }
      </Fragment>
    )
  }
}

export default Adsense