import base from './api.base'

function index() {
  return base.get('likes')
}

function like(data) {
  return base.post('likes/like', data)
}

function unlike(data) {
  return base.post('likes/unlike', data)
}

function list(slug) {
  return base.get(`likes/list/${slug}`)
}

const ApiLikes = {
  index,
  like,
  unlike,
  list,
}

export default ApiLikes
