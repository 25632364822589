import React, { Fragment } from 'react'
import { Link, matchPath } from 'react-router-dom'
import { Helpers, Base } from 'utils'
import placeholderImage from 'images/user-placeholder.jpg'
import Styles from './Styles'


const Listitem = ({
  label,
  icon,
  active,
  page,
  user,
  handleSetState,
  closeModal,
  typeState,
  state,
  contentOverlay,
  modalActive,
  login,
  menuMobile
}) => {
  if (page !== 'more') {
    const content = user === null
      ? (
        <li className={Helpers.mergeCss(Styles.item(menuMobile), Styles.text)}>
          {
          modalActive
            ? (
              <div
                style={{ position: 'relative' }}
                className={active ? Helpers.mergeCss(Styles.active, Styles.text, Base.positionRelative) : Helpers.mergeCss(Styles.text, Base.positionRelative)}
                onClick={closeModal}
              >
                {contentOverlay}
                <Fragment>
                  <i className={icon} />
                  <span>
                    {label}
                  </span>
                </Fragment>
              </div>
            )
            : page === 'feed'
              ? (
                <Link
                  className={active ? Helpers.mergeCss(Styles.active, Styles.text, Base.positionRelative) : Helpers.mergeCss(Styles.text, Base.positionRelative)}
                  to={page === 'feed' ? '/' : page === 'portfolio' && user !== null ? `/${user.attributes.username}` : `/${page}`}
                >
                  {contentOverlay}
                  <Fragment>
                    <i className={icon} />
                    <span>
                      {label}
                    </span>
                  </Fragment>
                </Link>
              )
              : (page === 'revenue' || page === 'analytics')
                ? (
                  <div
                    className={active ? Helpers.mergeCss(Styles.active, Base.positionRelative, Styles.text) : Helpers.mergeCss(Styles.text, Base.positionRelative)}
                    onClick={() => {
                      window.location.href = process.env.DEFAULT_JOIN_DOMAIN
                    }}
                  >
                    {contentOverlay}
                    <Fragment>
                      <i className={icon} />
                      <span>
                        {label}
                      </span>
                    </Fragment>
                  </div>
                )
                : (
                  <div
                    className={active ? Helpers.mergeCss(Styles.active, Base.positionRelative, Styles.text) : Helpers.mergeCss(Styles.text, Base.positionRelative)}
                    onClick={() => {
                      window.location.href = '/users/login'
                    }}
                  >
                    {contentOverlay}
                    <Fragment>
                      <i className={icon} />
                      <span>
                        {label}
                      </span>
                    </Fragment>
                  </div>
                )
            }
        </li>
      )
      : (
        <li className={Helpers.mergeCss(Styles.item(menuMobile), Base.positionRelative, Styles.text)}>
          {modalActive
            ? (
              <div
                className={active ? Helpers.mergeCss(Styles.active, Styles.text, Base.positionRelative) : Helpers.mergeCss(Styles.text, Base.positionRelative)}
                style={{ position: 'relative' }}
                onClick={closeModal}
              >
                {contentOverlay}
                <Fragment>
                  <i className={icon} />
                  <span>
                    {label}
                  </span>
                </Fragment>
              </div>
            )
            : !state.user.info.attributes.member && (page === 'revenue' || page === 'analytics')
              ? (
                <div
                  className={active ? Helpers.mergeCss(Styles.active, Base.positionRelative, Styles.text) : Helpers.mergeCss(Styles.text, Base.positionRelative)}
                  onClick={() => {
                    window.location.href = process.env.DEFAULT_JOIN_DOMAIN
                  }}
                >
                  {contentOverlay}
                  <Fragment>
                    <i className={icon} />
                    <span>
                      {label}
                    </span>
                  </Fragment>
                </div>
              )
              : (
                <Link
                  className={active ? Helpers.mergeCss(Styles.active, Base.positionRelative, Styles.text) : Helpers.mergeCss(Styles.text, Base.positionRelative)}
                  to={page === 'feed' ? '/' : page === 'portfolio' && user !== null ? `/${user.attributes.username}` : `/${page}`}
                >
                  {contentOverlay}
                  <Fragment>
                    <i className={icon} />
                    <span>
                      {label}
                    </span>
                  </Fragment>
                </Link>
              )
          }
        </li>
      )
    return content
  }
  return (
    <li className={Helpers.mergeCss(Styles.item, Styles.text)}>
      <div
        onClick={handleSetState(typeState, 'menuMore', !state.container.menuMore)}
        className={state.container.menuMore ? Helpers.mergeCss(Styles.active, Styles.text) : Styles.text}
      >
        <i className={icon} />
        <span>
          {label}
        </span>
      </div>
      {login !== 'true'
          && (
          <span className="bulb-no-animation">
            <i />
          </span>
          )
        }
    </li>
  )
}

export default Listitem
